import "../../../tablesTools/styles.css";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";

export const transformDataTypeColumns: (
  t: any,
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  t: any,
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number
) => {
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: minWidth ? minWidth : 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(columna) : columna}
        </strong>
      ),
      renderCell: (params: any) => (
        <Stack direction="row" alignItems="center">
          {typeof params.value === "boolean" ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <input
                type="checkbox"
                checked={params.value}
                style={{
                  width: "18px",
                  height: "18px",
                  backgroundColor: "lightblue",             
                  accentColor: "var(--bg-success)"
                }}
                readOnly
              />
            </Stack>
          ) : (          
              params.value       
          )}
        </Stack>
      ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
