import { actionsTypes } from "../../data";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CardIcon from "../../../card/CardIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterColumnsView from "./../Step1Elements/FilterColumnsView";
import FilterRowsView from "./../Step1Elements/FilterRowsView";
import GroupingView from "./../Step1Elements/GroupingView";
import NewColumnView from "./../Step1Elements/NewColumnView";
import OrderRowsView from "./OrderRowsView";
import React from "react";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

const ElementComponent = ({
  element,
  handleEditElement,
  handleViewInfo,
  deleteElement,
}) => {
  const { t } = useLanguage();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderElementViewByType = (type: string | number | undefined) => {
    switch (type) {
      case "NUEVA_COLUMNA":
        return <NewColumnView element={element} />;
      case "AGRUPAR_VALORES":
        return <GroupingView element={element} />;
      case "ORDENAR_FILAS":
        return <OrderRowsView element={element} />;
      case "FILTRAR":
        return <FilterRowsView element={element} />;
      case "FILTRAR_COLUMNAS":
        return <FilterColumnsView element={element} />;
      default:
        return null;
    }
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        backgroundColor:
          element.mensajes_error && element.mensajes_error.length > 0
            ? "var(--very-light-magenta)"
            : "#aeeee5",
        m: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between" /* , gap: 2 */,
        }}
      >
        <span style={{ width: "100%", color: "var(--text-main)" }}>
          {actionsTypes
            .find((action) => action.value === element.tipo)
            ?.label?.toUpperCase() || "-"}
        </span>
        <Stack direction={"row"} justifyContent={"space-between"} gap={0.8}>
          <CardIcon
            Icon={ZoomInIcon}
            onClickAction={(e) => handleViewInfo(e, element)}
            tooltipText={t("Ver tabla")}
          />
          <CardIcon
            Icon={EditIcon}
            onClickAction={(e) => handleEditElement(e, element)}
            tooltipText={t("Editar")}
          />
          <CardIcon
            Icon={DeleteIcon}
            onClickAction={(e) => deleteElement(e, element)}
            tooltipText={t("general.delete")}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {renderElementViewByType(element.tipo)}
      </AccordionDetails>
    </Accordion>
  );
};

export default ElementComponent;
