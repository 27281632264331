import { useRef, useState } from "react";
import Button from "../../buttons/Button";
import ExpenseTargetRow from "./ExpenseTargetRow";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Stack from "@mui/material/Stack";
import TargetInput from "./TargetInput";
import useLanguage from "../../../context/LanguageProvider";

const TargetsFilesInputs = ({
  message,
  setMessage,
  expensesTargets,
  allowedFileNames,
  handleFinish,
}) => {
  const { t } = useLanguage();
  const targetNameRef: any = useRef(null);
  const columnaNameRef: any = useRef(null);
  const configMode = localStorage.getItem("configMode");
  const expenseTarget = JSON.parse(
    localStorage.getItem("expenseTarget") as string
  );
  const [newName, setNewName] = useState<string>(
    configMode === "EDIT" || configMode === "COPY" ? expenseTarget.nombre : ""
  );
  const [columnName, setColumnName] = useState<string>(
    configMode === "EDIT" || configMode === "COPY" ? expenseTarget.columna : ""
  );
  const [newTarget, setNewTarget] = useState<any>(
    configMode === "EDIT" || configMode === "COPY"
      ? expenseTarget
      : { nombre: newName, columna: columnName }
  );

  return (
    <FormSelectLayout title={""} required={false}>
      <Stack width="100%" sx={{ minHeight: "200px", padding: "10px" }}>
        <TargetInput
          setNewTarget={setNewTarget}
          newName={newName}
          setNewName={setNewName}
          targetNameRef={targetNameRef}
          newTarget={newTarget}
          columnaNameRef={columnaNameRef}
          columnName={columnName}
          setColumnName={setColumnName}
          message={message}
          setMessage={setMessage}
          expensesTargets={expensesTargets}
          allowedFileNames={allowedFileNames}
        />
        {newTarget &&
          newTarget.involucrados &&
          newTarget.involucrados.map((item, index) => {
            return (
              <ExpenseTargetRow
                key={index}
                row={item}
                rows={newTarget.involucrados}
                setRows={setNewTarget}
              />
            );
          })}
      </Stack>
      <Stack alignItems="center">
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={() => handleFinish(newTarget)}
          disabled={
            newName === "" ||
            !newName ||
            columnName === "" ||
            !columnName ||
            message.text !== ""
          }
        />
      </Stack>
    </FormSelectLayout>
  );
};

export default TargetsFilesInputs;
