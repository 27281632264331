import { Box } from "@material-ui/core";
import { Button } from "../../buttons";
import { FileType } from "../types";
import { Info } from "../../icons";
import { upload_file_icon_styles } from "../../../styles/app-styles";
import { useDropzone } from "react-dropzone";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import SwitchComponent from "../../switch/Switch.component";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useLanguage from "../../../context/LanguageProvider";
import {
  checkDuplicatedFiles,
  checkFileInList,
  checkFileInUploadList,
  checkNotAllowedFiles,
  getFileName,
  hasRepeatedFiles,
  isDateFormatCorrect,
} from "../utils";
import AddFileIcon from "./AddFileIcon";
import DeleteFileIcon from "./DeleteFileIcon";
import ErrorMessage from "./ErrorMessage";
/* eslint-disable no-useless-escape */
interface IUploadModalContent {
  allowedFileNames: FileType[] | undefined;
  filesToUpload: File[] | null;
  setFilesToUpload: (files: File[]) => void;
  toggleAllowedFilesModal: () => void;
  handleCloseUploadFileModal: () => void;
  handleInsertFiles: () => void;
  setFileNameDefaultValue: (name: string) => void;
  isErrorAllowedFileNames: boolean;
  dataRowsFiles: any;
  isRestartChecked: boolean;
  setIsRestartChecked: any;
}

const UploadModalContent = ({
  allowedFileNames,
  setFilesToUpload,
  filesToUpload,
  toggleAllowedFilesModal,
  handleCloseUploadFileModal,
  handleInsertFiles,
  setFileNameDefaultValue,
  isErrorAllowedFileNames,
  dataRowsFiles,
  isRestartChecked,
  setIsRestartChecked,
}: IUploadModalContent) => {
  const { t } = useLanguage();
  const [notAllowedFiles, setNotAllowedFiles] = useState<boolean>(false);
  const [repeatedFiles, setRepeatedFiles] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv", ".parquet"],
    },
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
    },
  });

  useEffect(() => {
    checkNotAllowedFiles(
      filesToUpload || [],
      allowedFileNames,
      setNotAllowedFiles
    );
    if (
      hasRepeatedFiles(
        filesToUpload,
        allowedFileNames,
        repeatedFiles,
        setRepeatedFiles,
        dataRowsFiles
      )
    ) {
      setIsRestartChecked(true);
    } else {
      setIsRestartChecked(false);
    }
  }, [filesToUpload, allowedFileNames]);

  const handleDeleteFile = (fileName: File) => {
    const newFiles = filesToUpload?.filter((f: any) => f.path !== fileName);
    setRepeatedFiles(checkDuplicatedFiles(filesToUpload));
    setFilesToUpload(newFiles || []);
  };

  const handleAddName = (name: string) => {
    setFileNameDefaultValue(name);    
  };

  const isAllowedFile = (file) => {
    const allowedName = allowedFileNames
      ?.map((f: any) => f.nombre)
      ?.includes(getFileName(file.path));
    const isTyped = !!allowedFileNames?.find(
      (f: any) => f.nombre === getFileName(file.path)
    )?.tipado;
    return allowedName && isTyped;
  };

  const isFileCorrect = (file) => {
    return isAllowedFile(file) && isDateFormatCorrect(file.path);
  };

  const getAvatarColor = (file, index) => {
    return isFileCorrect(file)
      ? !checkFileInUploadList(
          file,
          index,
          filesToUpload || [],
          repeatedFiles,
          setRepeatedFiles
        )
        ? !checkFileInList(file, dataRowsFiles)
          ? "var(--bg-disabled) !important"
          : "var(--light-yellow) !important"
        : "var(--bg-error-hover) !important"
      : "var(--bg-error-hover) !important";
  };

  const getTextColor = (file, index) => {
    return isFileCorrect(file)
      ? !checkFileInUploadList(
          file,
          index,
          filesToUpload || [],
          repeatedFiles,
          setRepeatedFiles
        )
        ? !checkFileInList(file, dataRowsFiles)
          ? "var(--text-main) !important"
          : "var(--text-info) !important"
        : "var(--text-error) !important"
      : "var(--text-error) !important";
  };

  const getFullFilename = (file) => {
    return (
      file.path.split(".")[0].toUpperCase() + "." + file.path.split(".")[1]
    );
  };

  const getFileText = (file, index) => {
    return isFileCorrect(file)
      ? !checkFileInUploadList(
          file,
          index,
          filesToUpload || [],
          repeatedFiles,
          setRepeatedFiles
        )
        ? !checkFileInList(file, dataRowsFiles)
          ? ""
          : `- ${t(
              "dataManagementTexts.uploadModalContent.fileAlreadyExistsRestatedCalculation"
            )} ${
              isRestartChecked
                ? t("dataManagementTexts.uploadModalContent.itWillBeRestarted")
                : ""
            }`
        : t("dataManagementTexts.uploadModalContent.fileAlreadyIncluded")
      : t("Falta parametrizar nombre y/o columnas del archivo.");
  };  

  return (
    <Stack alignItems={"center"} width={"680px"}>
      <Stack alignItems={"center"} width={"680px"}>
        <Typography
          color="primary"
          sx={{ width: "100%", marginBottom: "10px", fontSize: "14px" }}
        >
          {t("dataManagementTexts.uploadModalContent.fileNameFormat_1")}:
          <br />
          <b>
            {t("dataManagementTexts.uploadModalContent.fileNameFormat_2")}
          </b>{" "}
          ({t("dataManagementTexts.uploadModalContent.fileNameFormat_3")})
          <Info
            text={
              !isErrorAllowedFileNames
                ? `${t(
                    "dataManagementTexts.uploadModalContent.allowedName"
                  )} ${allowedFileNames?.map((file: any) => " " + file.nombre)}`
                : t("dataManagementTexts.uploadModalContent.cannotUploadNames")
            }
            color="blue"
          />
        </Typography>
        <div {...getRootProps({ className: "dropzone1" })}>
          <input {...getInputProps()} multiple />
          <p>{t("dataManagementTexts.uploadModalContent.dragSelectFiles")}</p>
          <UploadFileIcon sx={upload_file_icon_styles} />
        </div>
        <Stack sx={{ width: "680px", overflow: "auto", mt: "10px" }}>
          {filesToUpload && filesToUpload.length && !isErrorAllowedFileNames && (
            <>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: "10px",
                  width: "97%",
                }}
              >
                <Typography
                  variant="button"
                  gutterBottom
                  color="primary"
                  mt="10px"
                  sx={{ fontWeight: "bold" }}
                >
                  {t("dataManagementTexts.uploadModalContent.filesToUpload")}
                </Typography>
                <Box>
                  {hasRepeatedFiles(
                    filesToUpload,
                    allowedFileNames,
                    repeatedFiles,
                    setRepeatedFiles,
                    dataRowsFiles
                  ) && (
                    <SwitchComponent
                      checked={isRestartChecked}
                      handleChange={() =>
                        setIsRestartChecked((prev: boolean) => !prev)
                      }
                      label={t("dataManagement.uploadModal.restartCalculation")}
                      color="primary"
                      labelPlacement="start"
                    />
                  )}
                </Box>
              </Stack>
              <List dense={true} sx={{ height: "150px" }}>
                {filesToUpload?.map((file: any, index: number) => {
                  return (
                    <ListItem
                      key={file.path}
                      secondaryAction={
                        <>
                          {!isAllowedFile(file) && (
                            <AddFileIcon
                              file={file}
                              handleAddName={handleAddName}
                            />
                          )}
                          <DeleteFileIcon
                            file={file}
                            handleDeleteFile={handleDeleteFile}
                          />
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: getAvatarColor(file, index),
                          }}
                        >
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          color: getTextColor(file, index),
                          fontWeight: "bold",
                          maxWidth: "500px",
                        }}
                        primary={
                          <p
                            style={{
                              fontWeight: "bold",
                              margin: 0,
                              padding: 0,
                            }}
                          >{`${getFullFilename(file)} ${getFileText(
                            file,
                            index
                          )}`}</p>
                        }
                        secondary={file.size + " bytes"}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
          {isErrorAllowedFileNames && (
            <ErrorMessage
              message={t(
                "dataManagementTexts.uploadModalContent.cannotGetAllowedFiles"
              )}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          title={t("dataManagementTexts.uploadModalContent.cancel")}
          color="grey"
          type="button"
          onClick={handleCloseUploadFileModal}
        />
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={
            notAllowedFiles === true
              ? t("dataManagementTexts.uploadModalContent.filledIllegalNames")
              : ""
          }
          placement="top"
        >
          <div>
            <Button
              title={t("dataManagementTexts.uploadModalContent.accept")}
              color="blue"
              type="button"
              onClick={handleInsertFiles}
              disabled={
                notAllowedFiles === true ||
                !filesToUpload?.length ||
                isErrorAllowedFileNames ||
                repeatedFiles
              }
            />
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default UploadModalContent;
