import { add_new_elements_button } from "../../../../styles/app-styles";
import { IconButton } from "@mui/material";
import { useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import GroupersProvider from "../../../../context/GroupersProvider";
import useLanguage from "../../../../context/LanguageProvider";
interface AddBaseButtonProps {
  handleAddBase: () => void;
}

const AddBaseButton = ({ handleAddBase }: AddBaseButtonProps) => {
  const { t } = useLanguage();
  const { groupersState } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const { bases } = groupersState;

  const isDisabled = () => {
    return bases.length < 1 || bases[0].columnas.length === 0;
  };

  return (
    <IconButton
      onClick={handleAddBase}
      disabled={isDisabled()}
      sx={add_new_elements_button(isDisabled)}
    >
      {t("dataModelText.groupManagement.step1.addBaseButton.newBase")}
      <AddIcon />
    </IconButton>
  );
};

export default AddBaseButton;
