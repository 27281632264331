import { info_section_container_styles } from "../../styles/app-styles";
import CustomizedTable from "../customizedTable/CustomizedTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ViewInfoTableProps {
  title: string;
  columns: string[];
  rows: any[];
  width?: string | number;
}

const ViewInfoTable = ({ title, columns, rows, width }: ViewInfoTableProps) => {
  return rows && rows?.length ? (
    <Stack sx={info_section_container_styles}>
      <Typography
        variant="subtitle1"
        sx={{
          color: "var(--text-main)",
          fontWeight: "bold",
          paddingBottom: "5px",
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>
      <CustomizedTable COLUMNS={columns} ROWS={rows} width={width ? width : undefined} />
    </Stack>
  ) : (
    <></>
  );
};

export default ViewInfoTable;
