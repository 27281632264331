import { Criteria } from "../../parameterization/types";
import { CRITERIA_URL } from "../../../api/axios";
import { CustomizedTable } from "../../customizedTable";
import { Stack, Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState } from "react";
import Dialog from "../../dialog/Dialog";
import SearchIcon from "@mui/icons-material/Search";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoModal from "../../criteriaManagement/ViewInfoModal";
interface SchemaInfoModalProps {
  open: boolean;
  handleClose: () => void;
  schema: any;
}

const SchemaInfoModal = ({
  open,
  handleClose,
  schema,
}: SchemaInfoModalProps) => {
  const { t } = useLanguage();
  const [selectedCriteria, setSelectedCriteria] = useState<Criteria>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();
  let COLUMNS: string[] = [];
  let ROWS: any[] = [];

  if (schema) {
    const columnOne =
      schema?.tipo_destino ===
      t("preprorationParameterization.schemaInfoModal.bucketExpenseUnderscore")
        ? t("preprorationParameterization.schemaInfoModal.bucketExpense")
        : t("preprorationParameterization.schemaInfoModal.grouper");

    COLUMNS = [columnOne, schema.tipo_criterio.toUpperCase()];
    ROWS = schema?.esquema_criterios?.map((item: any) => {
      return {
        columnOne: item.destino,
        Criterio:
          schema.tipo_criterio.toUpperCase() ===
          t("preprorationParameterization.schemaInfoModal.percentage").toUpperCase() ? (
            `${item.criterio} %`
          ) : (
            <>
              <Typography
                sx={{
                  color: "var(--text-success)",
                  cursor: "pointer",
                  display: "flex",
                  fontSize: "15px",
                  fontWeight: "600",
                  justifyContent: "center",
                }}
                onClick={() => handleClickOnCriteria(item)}
              >
                {item.criterio}
                <SearchIcon
                  sx={{ fontSize: "20px", marginTop: "1px", marginLeft: "5px" }}
                ></SearchIcon>
              </Typography>
            </>
          ),
      };
    });
  }

  /****************************************** View Criteria Info *************************************/
  const { data: dataGetCriteria } = useApiQuery(
    CRITERIA_URL,
    false,
    t("criteriaManagement.getCriteria.error")
  );

  const handleClickOnCriteria = (item: any) => {
    setSelectedCriteria(
      dataGetCriteria?.find(
        (criteria: Criteria) => criteria.nombre === item.criterio
      )
    );
    handleToggleViewInfoModal();
  };

  return (
    <>
      <Dialog
        title={t(
          "preprorationParameterization.schemaInfoModal.criteriaOutline"
        )}
        open={open}
        handleClose={handleClose}
        maxWidth="xl"
      >
        <Stack sx={{ margin: "0 15px 5px 15px", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              marginBottom: "10px",
              color: "var(--text-main)",
            }}
          >
            {schema?.nombre}
          </Typography>
          {schema && ROWS && ROWS.length !== 0 && (
            <>
              <CustomizedTable COLUMNS={COLUMNS} ROWS={ROWS} width={450} />
            </>
          )}
        </Stack>
      </Dialog>
      {selectedCriteria && (
        <ViewInfoModal
          criteria={selectedCriteria}
          open={openViewInfoModal}
          handleClose={handleToggleViewInfoModal}
          title={selectedCriteria?.nombre}
        />
      )}
    </>
  );
};

export default SchemaInfoModal;
