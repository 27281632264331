import { info_section_container_styles } from "../../styles/app-styles";
import CopyButton from "../buttons/CopyButton";
import Dialog from "../dialog/Dialog";
import EditCriteriaButton from "../buttons/EditCriteriaButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";

interface ViewInfoProps {
  item: any;
  open: boolean;
  title: string | undefined;
  handleClose: () => void;
  content?: any;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  isEditRol?: boolean;
  width?: string;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
}

const ViewInfoModalContainer = ({
  item,
  open,
  title,
  handleClose,
  content,
  handleEdit,
  handleCopy,
  isEditRol,
  width,
  maxWidth,
}: ViewInfoProps) => {
  const { t } = useLanguage();

  const handleEditAndClose = (e: any, id: number) => {
    handleEdit && handleEdit(e, id);
    handleClose();
  };

  const handleCopyAndClose = (e: any, id: number) => {
    handleCopy && handleCopy(e, id);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t("Información general")}
        maxWidth={maxWidth ? maxWidth : undefined}
      >
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding="0 100px 30px 100px"
          position="relative"
          width={width ? width : "auto"}
        >
          {handleEdit && isEditRol && (
            <EditCriteriaButton
              handleClick={handleEditAndClose}
              selectedCriteria={item}
              top="15px"
            />
          )}
          {handleCopy && isEditRol && (
            <CopyButton
              handleClick={handleCopyAndClose}
              selectedCriteria={item}
            />
          )}
          <Stack sx={{...info_section_container_styles, width: width ? width : "auto"}}>
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                display: "flex",
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
                justifyContent: "center",
                fontSize: "18px",
                mb: "10px",
              }}
            >
              {title === "" ? item?.nombre : title}
            </Typography>
            {content}
          </Stack>          
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoModalContainer;
