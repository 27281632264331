import { getCriteriasByDestiny } from "../../utils";
import { Item } from "../../../../basicGrid/BasicGrid";
import { transformDataForCheckboxSelect2 } from "../../../../../utils/util";
import { useEffect, useRef, useState } from "react";
import Select from "../../../../forms/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../../context/LanguageProvider";
import useMultiSelect from "../../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../../configCriteria/select.styles";

const DestinyValidationRow = ({
  schemaRow,
  destinyValidationRows,
  setDestinyValidationRows,
  criteriaData,
  iterations,
  newSchemaRows,
  iterationToEdit
}) => {
  const { t } = useLanguage();
  const criteriaSelectRef: any = useRef();
  const validationsSelectRef: any = useRef();
  const [validationsOptions, setValidationsOptions] = useState<any[]>([]);
  const actualRow = destinyValidationRows.find(
    (row) => row.destino === schemaRow.destino
  ); 

  const onCriteriaChange = (crit) => {
    const selectedCrit = crit
      ? [criteriaData?.find((c) => c?.id === crit.value)]
      : undefined;            
    if (crit && selectedCrit && selectedCrit.length > 0) {  
      setValidationsOptions(transformDataForCheckboxSelect2(selectedCrit));
    } else {      
      setValidationsOptions([]);
    }
    setValidationSelectedOptions([]);
    validationsSelectRef.current?.clearValue();
  };

  const [
    criteriaSelectedOption,
    setCriteriaSelectedOption,
    criteriaSelectedOptionChangeHandler,
  ] = useSingleSelect(
    (crit) => onCriteriaChange(crit),
    actualRow &&
      actualRow.validaciones_conservar?.length > 0 ?
      actualRow.validaciones_conservar[0]?.criterio : undefined
  );

  const [
    validationSelectedOptions,
    setValidationSelectedOptions,
    validationSelectedOptionsChangeHandler,
  ] = useMultiSelect(
    actualRow &&
    actualRow.validaciones_conservar?.length > 0 ?
    actualRow.validaciones_conservar[0]?.validaciones : undefined
  );

  useEffect(() => {
    const newDestinyValidationRow = {
      destino: schemaRow.destino,
      validaciones_conservar: [
        {
          validaciones: [],
          criterio: criteriaSelectedOption,
        },
      ],
    };
    
    setDestinyValidationRows((prev) => {
      return prev
        ? [...prev, newDestinyValidationRow]
        : [newDestinyValidationRow];
    });
  }, []);


  useEffect(() => {
    if (actualRow) {      
      const updatedDestinyValidationRows = destinyValidationRows?.map(
        (validation) => {
          if (validation.destino === schemaRow.destino) {            
            const updatedDestinyValidationRow = {
              destino: actualRow?.destino,
              validaciones_conservar:
              validation?.validaciones_conservar?.map((row) => {
                  return {
                    ...row,
                    criterio: criteriaSelectedOption,
                    validaciones: validationSelectedOptions,
                  };
                }),
            };            
            return updatedDestinyValidationRow;
          }
          return validation;
        }
      );
      setDestinyValidationRows(updatedDestinyValidationRows);
    }
  }, [validationSelectedOptions]);

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      width="100%"
      gap={1}
    >
      <Item sx={{ width: "100%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {schemaRow?.destino}
        </Typography>
      </Item>
      <Stack width={"100%"}>
        <Select
          reference={criteriaSelectRef}
          styles={selectStyles(criteriaSelectedOption)}
          options={getCriteriasByDestiny(
            schemaRow?.destino,
            iterations,
            newSchemaRows,
            iterationToEdit
          )}
          onChange={criteriaSelectedOptionChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "preprorationParameterization.newSchemaContent.chooseCriteria"
          )}
          defaultValue={criteriaSelectedOption}
          isClearable
        />
      </Stack>
      <Stack width={"100%"}>
        <Select
          reference={validationsSelectRef}
          styles={optionalSelectStyles(validationSelectedOptions)}
          options={validationsOptions}
          onChange={validationSelectedOptionsChangeHandler}
          closeMenuOnSelect={false}
          placeholder={"Seleccionar validaciones..."}
          defaultValue={validationSelectedOptions}
          isClearable
          isMulti
        />
      </Stack>
    </Stack>
  );
};

export default DestinyValidationRow;
