import {
  active_inactive_typography_styles,
  mb_20_styles,
  no_cards_error_typography_styles,
  no_elements_box_styles,
} from "../../../styles/app-styles";
import { Box, Typography } from "@mui/material";
import { EDIT_ACTIVE_USER_URL, GET_ALL_USERS_URL } from "../../../api/axios";
import { transformDataAdminData } from "../transformDataAdminData";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState } from "react";
import Button from "../../buttons/Button";
import React from "react";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import UserForm from "./UserForm";
import ViewUserModal from "./ViewUserModal";
import VirtualizatedTable from "../../virtualizatedTable/VirtualizedTable";
import useApi from "../../../hooks/useApi";
import useAuth from "../../../hooks/useAuth";
import Dialog from "../../dialog/Dialog";
interface UsersViewProps {
  setConfigOpen: React.Dispatch<React.SetStateAction<boolean>>;
  configOpen: boolean;
}

const UsersView = ({ setConfigOpen, configOpen }: UsersViewProps) => {
  const { t } = useLanguage();
  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "">("");
  const [user, setUser] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();
  const [selectedRows, setSelectedRows] = useState([]);
  const { auth }: any = useAuth();
  const [open, handleToggleModal] = useDialog();

  const {
    data: dataUsers,
    isSuccess: isSuccesUsers,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useApiQuery(
    GET_ALL_USERS_URL,
    true,
    t("administratorPanel.users.errorLoadingUsers")
  );

  const handleAdd = () => {
    setUser(undefined);
    setSelectedUser(undefined);
    setConfigMode("CREATE");
    setConfigOpen(true);
  };

  const handleFinish = () => {
    setConfigOpen(false);
    refetchUsers();
    setConfigMode("");
    window.location.reload();
  };

  const handleEdit = () => {
    setConfigMode("EDIT");
    setUser(dataUsers?.find((user: any) => user.id === selectedRows[0]));
    setConfigOpen(true);
  };

  const handleInfo = () => {
    setSelectedUser(
      dataUsers?.find((user: any) => user.id === selectedRows[0])
    );
    handleToggleViewInfoModal();
  };

  const isData = isSuccesUsers && !isLoadingUsers;

  const optional_columns = [
    "is_active",
    "nombre",
    "apellido",
    "ultimo_ingreso",
    "compania",
    "email",
    "empresa_asignada",
    "empresas_habilitadas",
    "rol",
  ];

  const transform_column_names = (column: string) => {
    switch (column) {
      case "is_active":
        return t("administratorPanel.users.active");
      case "nombre":
        return t("administratorPanel.users.name");
      case "apellido":
        return t("administratorPanel.users.lastName");
      case "email":
        return t("administratorPanel.users.email");
      case "empresa_asignada":
        return t("Empresa asignada");
      case "empresas_habilitadas":
        return t("Empresas habilitadas");
      case "rol":
        return t("Rol");
        case "ultimo_ingreso":
          return t("Último ingreso");
      default:
        return column;
    }
  };

  const onSuccessPutState = () => {
    refetchUsers();
  };

  const { isLoading: isLoadingPutCriteria, callApi: putActive } = useApi(
    "",
    "POST",
    t("administratorPanel.editUser.codes"),
    undefined,
    onSuccessPutState,
    undefined,
    false
  );

  const handleChangeSwitch = (params) => {
    const row = params.row;
    if (auth.user !== row.email) {
      putActive(EDIT_ACTIVE_USER_URL, {
        id: row.id,
        is_active: !row.is_active,
      });
    } else {
      handleToggleModal();
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingUsers || isLoadingPutCriteria}
        message={t("administratorPanel.users.loadingUsers")}
      />
      <SimpleBackdrop
        open={isLoadingPutCriteria}
        message={t("general.loading")}
      />
      {!configOpen ? (
        <Stack spacing={2} sx={{ width: "100%", marginTop: "0px !important" }}>
          <Stack
            direction="row"
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography width="100%" sx={active_inactive_typography_styles}>
              {t("administratorPanel.users.activeUsers")}
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Button
                title={t("Ver detalles")}
                color="outlined"
                type="button"
                onClick={handleInfo}
                disabled={!selectedRows || selectedRows.length !== 1}
                margin={false}
                width={155}
              />
              <Button
                title={t("Editar")}
                color="blue"
                type="button"
                onClick={handleEdit}
                disabled={!selectedRows || selectedRows.length !== 1}
                margin={false}
              />
              <Button
                title={t("Crear")}
                color="blue-greeny"
                type="button"
                onClick={handleAdd}
                disabled={false}
                margin={false}
              />
            </Stack>
          </Stack>
          <Stack sx={mb_20_styles}>
            {isData &&
            dataUsers.filter((user: any) => user.is_active).length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.users.noActiveUsers")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <Stack sx={{ width: "100%" }}>
                  {dataUsers?.length > 0 && (
                    <VirtualizatedTable
                      columns={
                        transformDataAdminData(
                          t,
                          dataUsers.filter((user: any) => user.is_active),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).columns
                      }
                      rows={
                        transformDataAdminData(
                          t,
                          dataUsers.filter((user: any) => user.is_active),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).rows
                      }
                      rowsPerPageOptions={[5, 10, 20]}
                      rowHeight={35}
                      tableHeight={"400px"}
                      hideFooter={true}
                      toolbar={true}
                      columnsButton={true}
                      filterButton={true}
                      densitySelector={true}
                      exportButton={false}
                      saveViewButton={false}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                    />
                  )}
                </Stack>
              )
            )}
          </Stack>
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.users.inactiveUsers")}
          </Typography>

          <Stack sx={mb_20_styles}>
            {isData &&
            dataUsers.filter((user: any) => !user.is_active).length === 0 ? (
              <Box sx={no_elements_box_styles}>
                <Typography variant="h6" sx={no_cards_error_typography_styles}>
                  {t("administratorPanel.users.noInactiveUsers")}
                </Typography>
              </Box>
            ) : (
              isData && (
                <Stack sx={{ width: "100%" }}>
                  {dataUsers?.length > 0 && (
                    <VirtualizatedTable
                      columns={
                        transformDataAdminData(
                          t,
                          dataUsers.filter((user: any) => !user.is_active),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).columns
                      }
                      rows={
                        transformDataAdminData(
                          t,
                          dataUsers.filter((user: any) => !user.is_active),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).rows
                      }
                      rowsPerPageOptions={[5, 10, 20]}
                      rowHeight={35}
                      tableHeight={"400px"}
                      hideFooter={true}
                      toolbar={true}
                      columnsButton={true}
                      filterButton={true}
                      densitySelector={true}
                      exportButton={false}
                      saveViewButton={false}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                    />
                  )}
                </Stack>
              )
            )}
          </Stack>
          {/* <AddCriteriaButton handleClick={handleAdd} /> */}
          <ViewUserModal
            user={selectedUser}
            open={openViewInfoModal}
            handleClose={handleToggleViewInfoModal}
            title={
              selectedUser?.nombre ? selectedUser?.nombre : selectedUser?.email
            }
            handleEdit={handleEdit}
          />
        </Stack>
      ) : (
        <UserForm
          handleFinish={handleFinish}
          configMode={configMode}
          user={user}
          users={dataUsers}
        />
      )}
      <Dialog
        open={open}
        handleClose={handleToggleModal}
        title={t("administratorPanel.users.notPossibleToDisableUserLabel")}
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          maxWidth="470px"
        >
          <Typography
            sx={{
              color: "var(--text-main)",
              fontSize: "18px",
              marginTop: "30px",
              maxWidth: "440px",
              textAlign: "center",
            }}
          >
            {t("administratorPanel.users.notPossibleToDisableUserError")}
          </Typography>
          <Stack direction="row" sx={{ marginTop: "25px" }}>
            <Button
              title={t("administratorPanel.users.accept")}
              type="button"
              onClick={handleToggleModal}
              color="blue-greeny"
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default UsersView;
