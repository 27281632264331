export const selectStyles = (selectedOptions: any, dashBoard?: boolean, variability?: boolean, optionPadding?: string) => {
  return {
    control: (styles: any, { isFocused, isDisabled }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: isDisabled
        ? "1px solid var(--text-info)"
        :
        dashBoard ?
          "1px solid var(--bg-main)"
          : !selectedOptions || selectedOptions.length === 0
            ? isFocused
              ? "3px solid #f90d4a"
              : "1px solid #f90d4a"
            : isFocused
              ? "2px solid var(--bg-success)"
              : "1px solid #d7d7d7",
    }),
    group: (styles, state) => ({
      borderBottom: "1px dotted var(--bg-disabled)",
      padding: "5px",
      margin: "5px",      
    }),
    groupHeading: (provided, state) => ({
      fontSize: "24px",
      fontWeight: "bold",
    }),
    option: (styles: any, { isSelected}) => ({
      ...styles,
      /* color: "var(--text-main) !important", */
      cursor: "pointer",
      backgroundColor: isSelected ? "var(--bg-secondary)" : "default",
      color: isSelected ? "white" : "var(--text-main) ",
      padding: optionPadding ? optionPadding : "8px",
      zIndex: 9999,    
      /* "&:hover": {
        backgroundColor: "var(--bg-hover)",
        color: "var(--bg-hover)"
      }, */
    }),
    /* menuPortal: base => ({ ...base, zIndex: 99999999 }), // Ajusta el z-index del portal del menú
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999999, // Ajusta el z-index del menú desplegable
    }) */
  };
};

export const selectStylesVariability = (selectedOption: any) => {
  return {
    control: (styles: any, { isDisabled }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: isDisabled
        ? "1px solid var(--bg-disabled)" :
        !selectedOption || selectedOption === undefined ?
          "1px solid var(--bg-error)"
          : "1px solid var(--bg-main)",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Establecer un zIndex alto para el menú desplegable
      maxHeight: '200px', // Ajusta la altura máxima del menú según sea necesario
      overflowY: 'auto', // Añade un scroll si el contenido excede la altura máxima
    }),
  };
};

export const selectSchemaStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border:
        !selectedOptions || selectedOptions.length === 0
          ? isFocused
            ? "3px solid #f90d4a"
            : "1px solid #f90d4a"
          : isFocused
            ? "2px solid var(--bg-success)"
            : "1px solid #d7d7d7",
    }),
    /*
    menu: () => ({
      "overflow-y": "scroll"
    })*/
  };
};

export const selectMultiStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused, isDisabled }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border:
        !selectedOptions || selectedOptions.length === 0
          ? isFocused
            ? "2px solid var(--bg-success)"
            : "1px solid #c9c9c9"
          : isFocused
            ? "2px solid var(--bg-success)"
            : "1px solid #c9c9c9",
      backgroundColor: isDisabled ? "#f5f5f5" : "#fff",
    }),
    option: (styles: any, { isFocused, isSelected }: any) => ({
      ...styles,
      backgroundColor: isSelected
        ? "var(--bg-success)"
        : isFocused
          ? "#f0f0f0"
          : null,
      color: isSelected ? "white" : "var(--text-main)",  // Color del texto de las opciones
      cursor: "pointer",
    }),
  };
};


export const selectDisabledStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: "1px solid var(--text-info)",
      backgroundColor: "#d7d7d7",
    }),
  };
};

export const optionalSelectStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: isFocused ? "2px solid var(--bg-success)" : "1px solid #c9c9c9",

    }),
    /* menu: (provided) => ({
      ...provided,
      maxHeight: '180px',
      overflowY: 'scroll',
    }), */

    /* option: (provided) => ({
      ...provided,
      maxHeight: '10px' // ajusta la altura de cada opción
    }), */
  };
};
