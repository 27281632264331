import {
  COMPANY_PARAMETERS_COST_STRUCTURE_URL,
  COMPANY_PARAMETERS_GASTOS_URL,
  COMPANY_PARAMETERS_PNL_LEVEL_URL,
  COMPANY_PARAMETERS_PNL_URL,
} from "../api/axios";
import { createContext, useContext, useEffect, useState } from "react";
import { PnlElementType } from "../components/companyParameterization/StepperView/data";
import useApi from "../hooks/useApi";
import useLanguage from "./LanguageProvider";

const PnLParamContext = createContext<any>({});

const PnLParamProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useLanguage();

  /**************************** Api Querys **********************************/

  useEffect(() => {
    getExpensesParams();
    getPnlLevels();
    getPnlParams();  
  }, []);

  const {
    isLoading: isLoadingGetExpensesParams,
    callApi: getExpensesParams,
    data: dataGetExpensesParams,
  } = useApi(
    COMPANY_PARAMETERS_COST_STRUCTURE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessPostExpensesParams = () => {
    getExpensesParams();
    setCounter(0)
  };


  const {
    isLoading: isLoadingPostExpensesParams,
    callApi: postExpensesParams,
  } = useApi(
    COMPANY_PARAMETERS_COST_STRUCTURE_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPostExpensesParams,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPnlParams,
    callApi: getPnlParams,
    data: dataGetPnlParams,
  } = useApi(
    COMPANY_PARAMETERS_PNL_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPnlLevels,
    callApi: getPnlLevels,
    data: dataGetPnlLevels,
  } = useApi(
    COMPANY_PARAMETERS_PNL_LEVEL_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    () => getPnlParams(),
    undefined,
    false
  );

  /************************************** Estados generales ************************************/

  const [message, setMessage] = useState({
    type: "",
    text: "",
  });
  const [counter, setCounter] = useState(0);

  /*************************************** Estados Step 1 **************************************/

  const [expensesFileName, setExpensesFileName] = useState("");
  const [keyExpensesColumns, setKeyExpensesColumns] = useState<any>({
    monto_gasto: "",
    bucket: "",
    agrupador: "",
  });
  const [bucketsList, setBucketsList] = useState<any[]>([]);

  /*************************************** Estados Step 3 **************************************/

  const [pnlElements, setPnlElements] = useState<PnlElementType[]>([]);
  const [pnlElementConfig, setPnlElementConfig] = useState<PnlElementType>();
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>(1);

  return (
    <PnLParamContext.Provider
      value={{
        message,
        setMessage,
        expensesFileName,
        keyExpensesColumns,
        setKeyExpensesColumns,
        bucketsList,
        setBucketsList,
        setExpensesFileName,
        getExpensesParams,
        dataGetExpensesParams,
        isLoadingGetExpensesParams,
        isLoadingPostExpensesParams,
        postExpensesParams,
        getPnlParams,
        getPnlLevels,
        dataGetPnlLevels,
        dataGetPnlParams,
        selectedLevel,
        setSelectedLevel,
        setPnlElements,
        pnlElements,
        pnlElementConfig,
        setPnlElementConfig,
        isLoadingPnlLevels,
        isLoadingPnlParams,
        counter, setCounter
      }}
    >
      {children}
    </PnLParamContext.Provider>
  );
};

export default PnLParamProvider;

export const usePnLParamProvider = (): any => {
  const context = useContext(PnLParamContext);
  if (!context) {
    throw new Error(
      "usePnLParamProvider must be used within a PnLParamProvider"
    );
  }
  return context;
};
