import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { tooltip_text_styles } from "../../../styles/app-styles";
import { truncateString } from "../../parameterization/utils";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";

const ExpenseTargetCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
}) => {
  return (
    <Stack sx={{ padding: "3px 3px 0 3px" }}>
      <Stack
        alignItems="center"
        sx={{ wordBreak: "break-word", display: "inline" }}
      >
        <Tooltip title={item.nombre}>
          <Typography sx={tooltip_text_styles}>
            {truncateString(item.nombre, 100)}
          </Typography>
        </Tooltip>
      </Stack>
      <Divider sx={{ margin: "6px 0" }} />
      {showInfo && (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "700",
              display: "inline",
            }}
            color="var(--text-main)"
          >
            Columna:{" "}
            {
              <span
                style={{
                  color: "var(--text-info)",
                  textAlign: "left",
                  display: "inline",
                  textTransform: "capitalize",
                  fontWeight: "300",
                }}
              >
                {item?.columna}
              </span>
            }
          </Typography>
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </>
      )}
    </Stack>
  );
};

export default ExpenseTargetCard;
