import { selectStyles } from "../../../configCriteria/select.styles";
import { useRef } from "react";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
interface SingleOptionSelectorProps {
  defaultValue: any;
  options: any[];
  title: string;
  saveSelectedOption: (option: any) => void;
  placeholder?: string;
  stylesFunction?: any;
  isDisabled?: boolean;
}

const SingleOptionSelector = ({
    defaultValue,
    options,
    title,
    saveSelectedOption,
    placeholder,
    stylesFunction,
    isDisabled
  }: SingleOptionSelectorProps) => {
    const { t } = useLanguage();
  const selectRef: any = useRef(null);
  const onChangeOption = (option) => {
    saveSelectedOption(option);
  }
  const [selectedOption, setSelectedOption, optionChangeHandler] =
    useSingleSelect(onChangeOption, defaultValue);
    
  return (
    <Stack sx={{ width: "100%" }}>
      <FormSelectLayout title={title} required={true} margin="0">
        <Select
          reference={selectRef}
          isClearable
          styles={stylesFunction ? stylesFunction(selectedOption) : selectStyles(selectedOption)}
          options={options}
          className="pp_select_schema"
          onChange={optionChangeHandler}
          closeMenuOnSelect
          isSearchable     
          placeholder={placeholder ? placeholder : t("general.selectValue")}
          defaultValue={defaultValue}
          isDisabled={isDisabled ? isDisabled : false}
        />
      </FormSelectLayout>
    </Stack>
  )
}

export default SingleOptionSelector