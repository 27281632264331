import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import FormLayout from "../../../forms/FormLayout";
import MessageBlock from "../../../forms/MessageBlock";
import RelationshipLine from "./RelationshipLine";
import RelationshipsFilesInputs from "./RelationshipsFilesInputs";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles2,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step4 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    isLoadingPostExpensesParams,
    dataGetRelationshipsParams,
    relationships,
    setRelationships,
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetRelationshipsParams) {
      setRelationships(dataGetRelationshipsParams);
    }
  }, [dataGetRelationshipsParams]);

  /************************************* Validación step completo **********************************/

  const isStep4Complete = () => {
    return true;
  };

  useEffect(() => {
    if (isStep4Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step4: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step4: false };
      });
    }
  }, [relationships]);

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[4]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles2}>
        <FormLayout width="90%">
          <RelationshipsFilesInputs />
        </FormLayout>
      </Stack>
      <Stack sx={{ width: "80%", mt: 2, gap: 2 }}>
        {relationships &&
          relationships.map((relationship: any) => {
            return (
              <RelationshipLine
                key={relationship.nombre}
                relationship={relationship}
              />
            );
          })}
      </Stack>
    </Stack>
  );
};

export default Step4;