import { param_steps_container } from "../../../styles/app-styles";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { usePnLParamProvider } from "../../../context/PnLParamProvider";
import AbmBuckets from "./Abmbuckets";
import AbmGroupers from "./AbmGroupers";
import ComponentLayout from "../../layout/ComponentLayout";
import FormLayout from "../../forms/FormLayout";
import MessageBlock from "../../forms/MessageBlock";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import useLanguage from "../../../context/LanguageProvider";
import Button from "../../buttons/Button";

const CostStructure = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setBucketsList,
    dataGetExpensesParams,
    postExpensesParams,
    isLoadingPostExpensesParams,
    isLoadingGetExpensesParams,
    bucketsList,
    counter
  } = usePnLParamProvider();
  

  /********************************** Seteo inicial de variables *************************************/  
  
 

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetExpensesParams) {
      setBucketsList(dataGetExpensesParams?.buckets);
    }
  }, [dataGetExpensesParams]);

  /************************************* Validaciones *******************************************/

  //Si varía bucketsList, y no es la primera carga, setear un mensaje que diga que hay cambios sin guardar; cuando se posteen, eliminar el mensaje
   
  useEffect(() => {
    if (counter > 0) {
      setMessage({
        type: "warning",
        text: t("Hay cambios sin guardar"),
      });
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  }, [counter]);  

  /************************************* Posteo **********************************/

  const handleSaveCostStructure = () => {
    postExpensesParams(undefined, {
      buckets: bucketsList,
    });
  };

  return (
    <ComponentLayout
      title={t("menuItemsOptions.pnLParameterization.costStructure.title")}
      icon={<SquareFootIcon />}
    >
      <Stack sx={{ ...param_steps_container, pt: "25px" }}>
        <SimpleBackdrop
          open={isLoadingPostExpensesParams || isLoadingGetExpensesParams}
          message={t("general.loading")}
        />
        <MessageBlock message={message} />
        <FormLayout width="90%">
          <AbmBuckets />
          <AbmGroupers />
        </FormLayout>
      </Stack>
      <Stack width={"100%"} direction="row" justifyContent={"center"}>
        <Button
          title={t("GUARDAR")}
          color="blue-greeny"
          type="button"
          onClick={handleSaveCostStructure}
          disabled={false}
        />
      </Stack>
    </ComponentLayout>
  );
};

export default CostStructure;
