import { Stack } from "@mui/material";
import { useEffect } from "react";
import { usePnLParamProvider } from "../../../context/PnLParamProvider";
import BucketLevelSelector from "./Levels/BucketLevelSelector";
import ComponentLayout from "../../layout/ComponentLayout";
import FormLayout from "../../forms/FormLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import ImportButton from "../../importElements/ImportButton";
import Levels from "./Levels/Levels";
import MessageBlock from "../../forms/MessageBlock";
import PnlElements from "./PnlElements/PnlElements";
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import {
  GET_ELEMENTS_FOR_IMPORT_URL,
  POST_IMPORT_ELEMENTS_URL,
} from "../../../api/axios";
import {
  form_layouts_container_styles2,
  param_steps_container,
} from "../../../styles/app-styles";

const PnLDefinition = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    isLoadingPostExpensesParams,
    dataGetPnlParams,
    dataGetPnlLevels,
    getPnlParams,
    getPnlLevels,
    isLoadingPnlLevels,
    isLoadingPnlParams,
  } = usePnLParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    getPnlParams();
  }, []);

  /************************************* Validación step completo **********************************/

  const onSuccessImportElements = () => {
    getPnlLevels();
    getPnlParams();
  };

  const {
    callApi: getElementsForImport,
    data: dataGetElementsForImport,
    isLoading: isLoadingGetElementsForImport,
  } = useApi(
    GET_ELEMENTS_FOR_IMPORT_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  const {
    isLoading: isLoadingPostImportElements,
    callApi: postImportElements,
  } = useApi(
    POST_IMPORT_ELEMENTS_URL,
    "POST",
    undefined,
    undefined,
    onSuccessImportElements,
    undefined,
    false
  );

  return (
    <ComponentLayout
      title={t("menuItemsOptions.pnLParameterization.pnLDefinition.title")}
      icon={<SettingsInputSvideoIcon />}
    >
      <Stack sx={{ ...param_steps_container, pt: "25px" }}>
        <SimpleBackdrop
          open={
            isLoadingPostExpensesParams ||
            isLoadingPostImportElements ||
            isLoadingGetElementsForImport ||
            isLoadingPnlLevels ||
            isLoadingPnlParams
          }
          message={t("general.loading")}
        />
        <MessageBlock message={message} />
        <Stack sx={{ ...form_layouts_container_styles2, width: "100%" }}>
          <FormLayout width="100%">
            <Grid2 container direction={"row"} xs={12} gap={2} width={"98%"}>
              <Stack
                direction="row"
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                width="100%"
              >
                {dataGetPnlParams && dataGetPnlLevels && <BucketLevelSelector />}
                <ImportButton
                  postImportElements={postImportElements}
                  getElements={getElementsForImport}
                  dataGetElements={dataGetElementsForImport}
                  GET_ELEMENTS_URL={GET_ELEMENTS_FOR_IMPORT_URL}
                  message={t("importElements.willDeleteAll")}
                />
              </Stack>
              {dataGetPnlParams && dataGetPnlLevels && <Levels />}
              {dataGetPnlParams && dataGetPnlLevels && <PnlElements />}
            </Grid2>
          </FormLayout>
        </Stack>
      </Stack>
    </ComponentLayout>
  );
};

export default PnLDefinition;
