import "./styles.css";
import { Button } from "../buttons";
import { downloadByType, useStyles } from "./utils";
import { GridColDef } from "@mui/x-data-grid";
import { PreviewDataType } from "./types";
import { transformData } from "./transformDataManagement";
import { transformDataPreview } from "./transformDataPreview";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useNavigate } from "react-router-dom";
import { useSnackbar as useNotistackSnackbar, VariantType } from "notistack";
import { useState, useEffect } from "react";
import AlphaImportDialog from "./AlphaImportData/AlphaImportDialog";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ComponentLayout from "../layout/ComponentLayout";
import ConfirmationModal from "../dialog/ConfirmationModal";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import Dialog from "../dialog/Dialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import SuccessUploadModalContent from "./SuccessUploadModalContent";
import TableDataManagement from "./table";
import TasksModal from "./TasksModal";
import TypeModalContent from "../companyParameterization/StepperView/Step3/TypeModalContent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadModalContent from "./UploadModal/UploadModalContent";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import {
  ALLOWED_FILE_NAMES_URL,
  COLUMNS_FILES_URL,
  DELETE_FILE_URL,
  DELETE_PERIOD_URL,
  STAGES_URL,
  ROWS_FILES_URL,
  ROWS_PREVIEW_URL,
  COMPANY_PARAMETERS_FILE_URL,
  COMPANY_URL,
} from "../../api/axios";


/**
 * @component
 * @name DataManagement
 * @description - Componente que muestra las tablas archivos por períodos ni bien se renderiza el componente, validaciones por período al accionar el switch y la previsualización de un archivo al hacer click en el ícono de previsualizar.  También permite descargar un archivo al hacer click en el ícono para descarga de cada archivo.  El botón de correr validaciones acciona el proceso de validación de todos los archivos por período, y el de subir archivo permite cargar otro archivo a la tabla de archivos por período, siendo posible seleccionar más d euno a la vez.
 * @returns {JSX.Element}
 * @category Data Management
 */

const DataManagement = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useNotistackSnackbar();
  const { setHasCompletedStages, userRolData, companyData, setCompanyData }: any =
    useInfoContext();
  const [filePreview, setFilePreview] = useState<any>();
  const [filesToUpload, setFilesToUpload] = useState<File[] | null>([]);
  const [openUploadFileModal, toggleUploadFileModal] = useDialog();
  const [openSuccessFileModal, toggleSuccessFileModal] = useDialog();
  const [openDownloadFileModal, toggleDownloadFileModal] = useDialog();
  const [openConfirmCloseUploadModal, toggleConfirmCloseUploadModal] =
    useDialog();
  const [periodToDelete, setPeriodToDelete] = useState<string>("");
  const [fileToDelete, setFileToDelete] = useState<PreviewDataType>();
  const [isUploading, setIsUploading] = useState(false);
  const [openDeleteModal, toggleDeleteModal] = useDialog();
  const [openDeleteFileModal, toggleDeleteFileModal] = useDialog();
  const [openAllowedFilesModal, toggleAllowedFilesModal] = useDialog();
  const [progress, setProgress] = useState(0);
  const [isRestartChecked, setIsRestartChecked] = useState<boolean>(true);
  const [hasUnprocessedFiles, setHasUnprocessedFiles] =
    useState<boolean>(false);
  const [openTasksModal, toggleTasksModal] = useDialog();
  const [openImportAlphaDialog, handleToggleImportAlphaDialog] = useDialog();
  const [fileData, setFileData] = useState<any>();

  const {
    data: dataColumnsFiles,
    isLoading: isLoadingColumnsFiles,
    isSuccess: isSuccessColumnsFiles,
    isError: isErrorColumnsFiles,
    refetch: refetchColumnsFiles,
  } = useApiQuery(
    COLUMNS_FILES_URL,
    false,
    t("dataManagement.getColumnsFiles.error")
  );
  const {
    data: dataRowsFiles,
    isLoading: isLoadingRowsFiles,
    isSuccess: isSuccessRowsFiles,
    isError: isErrorRowsFiles,
    refetch: refetchRowsFiles,
  } = useApiQuery(
    ROWS_FILES_URL,
    true,
    t("dataManagement.getRowsFiles.error"),
    hasUnprocessedFiles ? 5000 : undefined
  );

  const {
    data: dataInfoDatalake,
    isLoading: isLoadingDataInfoDatalake,
    callApi: getDataInfoDataLake,
  } = useApi(
    "archivo/ver_datalake",
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessGetUserCompany = (data: any) => {
    setCompanyData(data);
  };

  const { callApi: getUserCompany } = useApi(
    COMPANY_URL,
    "GET",
    undefined,
    undefined,
    onSuccessGetUserCompany,
    undefined,
    false
  );

  useEffect(() => {
    dataInfoDatalake && handleToggleImportAlphaDialog();
  }, [dataInfoDatalake]);

  useEffect(() => {
    dataRowsFiles &&
    dataRowsFiles.some((row: any) =>
      row.archivos.some(
        (archivo: any) =>
          archivo.estado === "SUBIDO" || archivo.estado === "PROCESANDO"
      )
    )
      ? setHasUnprocessedFiles(true)
      : setHasUnprocessedFiles(false);
  }, [dataRowsFiles]);

  const {
    data: getCompletedStages,
    isLoading: isLoadingCompletedStages,
    refetch: refetchCompletedStages,
    isFetching: isFetchingCompletedStages,
  } = useApiQuery(STAGES_URL, false);

  const {
    data: allowedFileNames,
    error: isErrorAllowedFileNames,
    callApi: refetchAllowedFileNames,
  } = useApi(
    ALLOWED_FILE_NAMES_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    userRolData &&
      userRolData.administracion_de_datos.subir_archivos &&
      refetchAllowedFileNames();
      getUserCompany();
  }, [userRolData]);

  const [previewError, setPreviewError] = useState<string>("");
  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [columnsPreview, setColumnsPreview] = useState<
    GridColDef<any, any, any>[]
  >([
    {
      field: "",
      headerName: "",
      width: 0,
    },
  ]);
  const [rowsPreview, setRowsPreview] = useState<any[]>([]);

  const classes = useStyles();

  //Eliminar archivo

  const handleSuccessDeleteFile = () => {
    refetchRowsFiles();
    refetchColumnsFiles();
  };

  const { isLoading: isLoadingDeleteFile, callApi: deleteFile } = useApi(
    DELETE_FILE_URL(fileToDelete?.dato.id, fileToDelete?.periodo.periodo_id),
    "DELETE",
    t("dataManagement.deleteFile.codes"),
    undefined,
    handleSuccessDeleteFile
  );

  //Descargar y transformar info

  useEffect(() => {
    if (isSuccessColumnsFiles && isSuccessRowsFiles) {
      const { columns, rows } = transformData(
        dataColumnsFiles,
        dataRowsFiles,
        handlePreview,
        handleDownload,
        handleConfirmDeleteModal,
        handleDeleteFile,
        userRolData,
        t
      );
      setColumns(columns);
      setRows(rows);
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [dataColumnsFiles, dataRowsFiles]);

  //Eliminar Período

  const handleConfirmDeleteModal = (id: string) => {
    setPeriodToDelete(id);
    toggleDeleteModal();
  };

  const { isLoading: isLoadingDeletePeriod, callApi: deletePeriod } = useApi(
    `${DELETE_PERIOD_URL}/${periodToDelete}`,
    "DELETE",
    t("dataManagement.deletePeriod.codes"),
    undefined,
    handleSuccessDeleteFile
  );

  const handleDeletePeriod = () => {
    toggleDeleteModal();
    deletePeriod();
  };

  //Descargar Archivo

  const { isLoading: isLoadingDownloadFile, callApi: downloadFile } = useApi(
    undefined,
    "GET",
    t("dataManagement.downloadFile.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingDownloadSingleFile,
    callApi: downloadSingleFile,
  } = useApi(
    undefined,
    "GET",
    t("dataManagement.downloadFile.codes"),
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  );

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleDownload = (
    type: "FILE" | "PERIOD" | "COLUMN" | "ALL",
    data: any
  ) => {
    if (dataRowsFiles.length === 0) {
      handleClickVariant(t("dataManagement.downloadFile.empty"), "info");
      return;
    }
    downloadByType(
      type,
      data,
      downloadSingleFile,
      downloadFile,
      toggleDownloadFileModal
    );
    type !== "FILE" && toggleDownloadFileModal();
  };

  const handleGoToDownloads = () => {
    navigate(t("dataManagementTexts.dataManagement.taskManager"), {
      state: { tab: "downloads" },
      replace: false,
    });
  };

  //Eliminar archivo
  const handleDeleteFile = (data: PreviewDataType) => {
    setFileToDelete(data);
    toggleDeleteFileModal();
  };

  const handleAcceptDeleteFile = () => {
    toggleDeleteFileModal();
    deleteFile();
  };

  //Previsualizar archivo

  const onSuccessPreviewFile = (data: any) => {
    const { columns, rows } = transformDataPreview(data);
    setPreviewError("");
    setColumnsPreview(columns);
    setRowsPreview(rows);
    refetchRowsFiles();
  };

  const onErrorPreviewFile = () => {
    if (dataPreviewFile.data === "No existe la coleccion") {
      setPreviewError(t("dataManagementTexts.dataManagement.fileStillLoading"));
    } else {
      setPreviewError(t("dataManagementTexts.dataManagement.cannotGetPreView"));
    }
  };

  const {
    data: dataPreviewFile,
    isLoading: isLoadingPreviewFile,
    callApi: getRowsPreview,
    error: isErrorPreviewFile,
  } = useApi(
    undefined,
    "GET",
    t("dataManagement.downloadFile.codes"),
    undefined,
    onSuccessPreviewFile,
    onErrorPreviewFile,
    false
  );

  const handlePreview = (data: PreviewDataType) => {
    getRowsPreview(
      `${ROWS_PREVIEW_URL}/${data.dato.id}/periodo/${data.periodo.periodo_id}`
    );
    setFilePreview({ file: data.dato, period: data.periodo });
  };

  //Subir archivo

  const handleInsertFiles = () => {
    if (filesToUpload) {
      setIsUploading(true);
      toggleUploadFileModal();
      toggleSuccessFileModal();
    }
  };

  const handleCloseSuccessFileModal = () => {
    refetchCompletedStages && refetchCompletedStages();
    toggleSuccessFileModal();
    setFilesToUpload(null);
    setIsUploading(false);
    refetchColumnsFiles();
    refetchRowsFiles();
    setIsRestartChecked(true);
  };

  const handleCloseConfirmUploadFileModal = () => {
    toggleConfirmCloseUploadModal();
    handleCloseSuccessFileModal();
  };

  const handleCloseUploadFileModal = () => {
    toggleUploadFileModal();
    setFilesToUpload(null);
  };

  useEffect(() => {
    isUploading && refetchRowsFiles();
    refetchCompletedStages && refetchCompletedStages();
    getCompletedStages !== undefined &&
      setHasCompletedStages(getCompletedStages);
  }, [dataRowsFiles, getCompletedStages]);

  useEffect(() => {
    (isFetchingCompletedStages || isLoadingCompletedStages) &&
      setHasCompletedStages("loading");
  }, [isFetchingCompletedStages, isLoadingCompletedStages]);

  const handleClickImportAlphaDialog = () => {
    getDataInfoDataLake();
  };

  /********************************* Obtener datos del archivo a tipar **********************************/

  const onSuccessGetFilesParams = (data: any) => {
    setFileData(data);
    toggleAllowedFilesModal();
  };

  const {
    isLoading: isLoadingFilesParams,
    callApi: getFilesParams,
  } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessGetFilesParams,
    undefined,
    false
  );

  const setFileNameDefaultValue = (name: string) => {
    const existentFile = allowedFileNames.find((file) => file.nombre === name);
    if (existentFile) {
      getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${existentFile.id}`);
    } else {
      setFileData({ nombre: name });
      toggleAllowedFilesModal();
    }
  };

  const refetchData = () => {
    refetchAllowedFileNames();
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingDeletePeriod}
        message={t("dataManagementTexts.dataManagement.deletingPeriodFiles")}
      />
      <SimpleBackdrop
        open={isLoadingDeleteFile}
        message={t("dataManagement.deleteFile.loading")}
      />
      <SimpleBackdrop
        open={isLoadingDataInfoDatalake || isLoadingFilesParams}
        message={t("general.loading")}
      />
      <SimpleBackdrop
        open={isLoadingDownloadFile || isLoadingDownloadSingleFile}
        message={t("dataManagementTexts.dataManagement.downloadingFile")}
      />
      <ComponentLayout
        title={t("dataManagementTexts.dataManagement.dataManagement")}
        icon={<UploadFileIcon />}
        rightComponent={
          <Button
            title={t("dataManagementTexts.dataManagement.importFromAlpha")}
            color="blue"
            type="button"
            onClick={() => handleClickImportAlphaDialog()}
          />
        }
      >
        <>
          <Stack width="100%">
            {isLoadingColumnsFiles || isLoadingRowsFiles ? (
              <CustomSkeleton height={4} />
            ) : isErrorColumnsFiles || isErrorRowsFiles ? (
              <p>
                {t("dataManagementTexts.dataManagement.errorCouldNotDownload")}
              </p>
            ) : (
              <TableDataManagement
                columns={columns}
                rows={rows}
                rowsPerPageOptions={[5, 12, 24]}
                rowHeight={35}
                tableHeight={280}
                hideFooter={true}
              />
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {userRolData?.administracion_de_datos.subir_archivos && (
              <div>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <Button
                    title={t("ver tareas")}
                    color="outlined"
                    type="button"
                    onClick={toggleTasksModal}
                    width={200}
                  />
                  <Button
                    title={t("dataManagementTexts.dataManagement.uploadFiles")}
                    color="blue-greeny"
                    type="button"
                    onClick={toggleUploadFileModal}
                    width={200}
                    disabled={!companyData?.gastos_ventas_parametrizados}
                  />
                </Stack>
                {openTasksModal && (
                  <Dialog
                    open={openTasksModal}
                    handleClose={toggleTasksModal}
                    title={t("Detalle de tareas")}
                    maxWidth="xl"
                  >
                    <TasksModal hasUnprocessedFiles={hasUnprocessedFiles} />
                  </Dialog>
                )}
                <Dialog
                  open={openAllowedFilesModal}
                  handleClose={toggleAllowedFilesModal}
                  title={t("dataManagementTexts.dataManagement.allowedFiles")}
                  maxWidth="xl"
                >
                  <Stack width={"1200px"}>
                    <TypeModalContent
                      fileData={fileData}
                      setFileData={setFileData}
                      handleClose={toggleAllowedFilesModal}
                      dataFilesList={allowedFileNames}
                      refetchData={refetchData}
                    />
                  </Stack>
                </Dialog>
                <Dialog
                  open={openUploadFileModal}
                  handleClose={handleCloseUploadFileModal}
                  title={t("dataManagementTexts.dataManagement.fileUpload")}
                  maxWidth="lg"
                >
                  {allowedFileNames && allowedFileNames.length > 0 && (
                    <UploadModalContent
                      allowedFileNames={allowedFileNames}
                      filesToUpload={filesToUpload}
                      setFilesToUpload={setFilesToUpload}
                      toggleAllowedFilesModal={toggleAllowedFilesModal}
                      handleCloseUploadFileModal={handleCloseUploadFileModal}
                      handleInsertFiles={handleInsertFiles}
                      setFileNameDefaultValue={setFileNameDefaultValue}
                      isErrorAllowedFileNames={isErrorAllowedFileNames}
                      dataRowsFiles={dataRowsFiles}
                      isRestartChecked={isRestartChecked}
                      setIsRestartChecked={setIsRestartChecked}
                    />
                  )}
                </Dialog>
                <Dialog
                  open={openSuccessFileModal}
                  handleClose={
                    progress === 100
                      ? handleCloseSuccessFileModal
                      : toggleConfirmCloseUploadModal
                  }
                  title={t("dataManagementTexts.dataManagement.fileUpload")}
                  maxWidth="lg"
                  actions={
                    <>
                      <Button
                        title={t("dataManagementTexts.dataManagement.close")}
                        color="grey"
                        type="button"
                        onClick={
                          progress === 100
                            ? handleCloseSuccessFileModal
                            : toggleConfirmCloseUploadModal
                        }
                      />
                    </>
                  }
                >
                  <SuccessUploadModalContent
                    filesToUpload={filesToUpload}
                    progress={progress}
                    setProgress={setProgress}
                    isRestartChecked={isRestartChecked}
                  />
                </Dialog>
                <ConfirmationModal
                  open={openDeleteModal}
                  handleClose={toggleDeleteModal}
                  handleAccept={handleDeletePeriod}
                  message={t(
                    "dataManagementTexts.dataManagement.deleteSelectedPeriod"
                  )}
                  title={t("dataManagementTexts.dataManagement.deletePeriod")}
                />
                <ConfirmationModal
                  open={openDeleteFileModal}
                  handleClose={toggleDeleteFileModal}
                  handleAccept={handleAcceptDeleteFile}
                  message={`${t(
                    "dataManagementTexts.dataManagement.confirmDeleteFile_1"
                  )} ${fileToDelete?.dato.nombre}-${
                    fileToDelete?.periodo.periodo
                  }${t(
                    "dataManagementTexts.dataManagement.confirmDeleteFile_2"
                  )}`}
                  customMessage={true}
                  title={t("dataManagementTexts.dataManagement.deleteFile")}
                />
              </div>
            )}
          </Stack>

          <div className="preview_container">
            <div className="dm_preview_title_container">
              <h2 className="dm_title_preview">
                {dataPreviewFile &&
                  columnsPreview &&
                  rowsPreview &&
                  `${t("dataManagementTexts.dataManagement.preview")} -
                ${filePreview?.file.nombre}-${filePreview?.period.periodo}`}
              </h2>
            </div>
            <Stack width="100%">
              <Stack width="100%">
                {isLoadingPreviewFile ? (
                  <CustomSkeleton height={2} />
                ) : dataPreviewFile &&
                  columnsPreview &&
                  rowsPreview &&
                  !isErrorPreviewFile ? (
                  <TableDataManagement
                    columns={columnsPreview}
                    rows={rowsPreview}
                    rowHeight={35}
                    tableHeight={320}
                    hideFooter={true}
                    columnsButton={true}
                    filterButton={false}
                    densitySelector={false}
                    exportButton={true}
                  />
                ) : (
                  previewError && (
                    <div className="dm_preview_error">
                      <p>{previewError}</p>
                    </div>
                  )
                )}
              </Stack>
            </Stack>
          </div>
          {isUploading && (
            <SimpleBackdrop
              open={isUploading}
              message={`${t("dataManagementTexts.dataManagement.uploading")} ${
                filesToUpload?.length
              } ${t("dataManagementTexts.dataManagement.files")}`}
            />
          )}
          <ConfirmationModal
            open={openConfirmCloseUploadModal}
            handleClose={toggleConfirmCloseUploadModal}
            handleAccept={handleCloseConfirmUploadFileModal}
            customMessage={true}
            message={t(
              "dataManagementTexts.dataManagement.closingWindowStopUpload"
            )}
            title={t("dataManagementTexts.dataManagement.closeFileUpload")}
          />
          <Dialog
            open={openDownloadFileModal}
            handleClose={toggleDownloadFileModal}
            title={t("dataManagementTexts.dataManagement.fileDownload")}
            maxWidth="sm"
            actions={
              <>
                <Button
                  title={t("dataManagementTexts.dataManagement.close")}
                  color="grey"
                  type="button"
                  onClick={toggleDownloadFileModal}
                />
                <Button
                  title={t("dataManagementTexts.dataManagement.goDownloads")}
                  color="blue"
                  type="button"
                  onClick={handleGoToDownloads}
                />
              </>
            }
          >
            <Stack alignItems="center">
              <Typography
                variant="h6"
                mb="15px"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CloudDownloadIcon fontSize="large" className={classes.icon} />
                {t("dataManagementTexts.dataManagement.downloadProccesStarted")}
              </Typography>
              <Typography sx={{ color: "var(--text-main)" }}>
                {t("dataManagementTexts.dataManagement.viewDownloadStatus")}
              </Typography>
            </Stack>
          </Dialog>
          <AlphaImportDialog
            open={openImportAlphaDialog}
            handleClose={handleToggleImportAlphaDialog}
            dataAlpha={dataInfoDatalake}
            refetchRowsFiles={refetchRowsFiles}
            refetchColumnsFiles={refetchColumnsFiles}
          />
        </>
      </ComponentLayout>
    </>
  );
};

export default DataManagement;
