interface EmptyTextareaProps {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  minRows?: number;
  maxRows?: number;
}

export default function TextAreaAutoSize({
  value,
  setValue,
  placeholder,
}: EmptyTextareaProps) {

  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      style={{
        boxSizing: 'border-box',
        width: '100%',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        padding: '12px',
        borderRadius: '12px',
        color: 'var(--text-main)',
        border: '1px solid var(--bg-light)',
        boxShadow: '0px 2px 2px var(--bg-light)',
        outline: 'none',
        resize: 'none',
      }}
    />
  );
}
