import { useEffect, useState } from "react";
import { useGetProrrationTasksModel } from "../../api";
import { useNavigate } from "react-router-dom";
import { useParameterization } from "../../../../hooks/useParameterization";
import { useSnackbar } from "notistack";
import CalculateBucketButton from "./CalculateBucketButton";
import CalculateEverythingButton from "./CalculateEverythingButton";
import ConfirmationModal from "../../../dialog/ConfirmationModal";
import ResetButton from "./ResetButton";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useInfoContext from "../../../../hooks/useInfoContext";
import useLanguage from "../../../../context/LanguageProvider";
import {
  POST_PRORRATION_CALCULATE_URL,
  POST_RESET_CALCULATION_URL,
} from "../../../../api/axios";
import {
  checkNotTransferredExpenses,
  handlePendingTasks,
  handleRefetchRowsDataNt,
  handleResetOptions,
} from "../../utils";

const CalculationButtons = () => {
  const { t } = useLanguage();
  const { setHasCompletedStages }: any = useInfoContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    parameterizationState,
    parameterizationDispatch,
    openConfirmationModal,
    handleToggleConfirmationModal,
    buckets,
    refetchBuckets,
    getProrationResultsNoTrasladados,
    refetchCompletedStages,
    getCompletedStages,
  } = useParameterization();
  const {
    selectedStage,
    configType,
    actionType,
    bucketSelectedOption,
    selectedIteration,
    pendingTasks,
  } = parameterizationState;
  const [openWarningModal, handleToggleWarningModal] = useDialog();
  const [confirmationStatus, setConfirmationStatus] = useState<any>({
    type: "",
    status: false,
  });

  /********************************* Manejo de tareas pendientes *******************************/

  const { data: dataProrrateo, refetch: refetchProrrateo } =
    useGetProrrationTasksModel(pendingTasks);

  useEffect(() => {
    if (dataProrrateo && dataProrrateo.length > 0) {
      handlePendingTasks(
        dataProrrateo,
        parameterizationDispatch,
        enqueueSnackbar,
        refetchProrrateo,
        refetchBuckets
      );
    }
  }, [dataProrrateo]);

  /******************************** Textos *************************************/

  const message =
    configType ===
      t("modelParameterizationTexts.step2.resetCalcultatonStageBucket") ||
    configType ===
      t("modelParameterizationTexts.step2.resetAllCalculationsStages")
      ? `¿${t("modelParameterizationTexts.step2.confirmPartOne")} ` +
        configType +
        `? ${t("modelParameterizationTexts.step2.confirmPartTwo")}.`
      : configType;

  const messageWarningModal = `${t(
    "modelParameterizationTexts.step2.stillExpensesNotTransferredStage"
  )} ${(selectedStage?.etapa as number) + 1}. ¿${t(
    "modelParameterizationTexts.step2.confirmStartCalculationAnyway"
  )}?`;

  const hasCustomMessage =
    configType ===
      t("modelParameterizationTexts.step2.resetCalcultatonStageBucket") ||
    configType ===
      t("modelParameterizationTexts.step2.resetAllCalculationsStages");

  /*************************************** Reset ************************************/

  const onSuccessResetCalculation = (data: any, resetType: string) => {
    if (
      resetType ===
        t("modelParameterizationTexts.step2.resetAllCalculationsStages") ||
      resetType ===
        t("modelParameterizationTexts.step2.resetCalcultatonStageBucket")
    ) {
      refetchCompletedStages();
      refetchBuckets();
      setHasCompletedStages(getCompletedStages);
      localStorage.removeItem("selectedBucket");
      localStorage.removeItem("selectedStage");
      setTimeout(() => {
        navigate("/parametrizacion-modelo/asignacion-etapas");
      }, 1500);
    } else if (
      resetType !==
      t("modelParameterizationTexts.step2.resetCalculationCurrentIteration")
    ) {
      if (selectedStage && !selectedIteration) {
        refetchBuckets();
        parameterizationDispatch({
          type: "SET_SELECTED_STAGE",
          payload: {
            ...selectedStage,
            calculado: false,
          },
        });
      }
    } else {
      if (selectedIteration) {
        refetchBuckets();
        parameterizationDispatch({
          type: "SET_SELECTED_ITERATION",
          payload: {
            ...selectedIteration,
            calculado: false,
          },
        });
        parameterizationDispatch({
          type: "SET_TAB_VALUE",
          payload: t(
            "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
          ),
        });
      }
    }
  };

  const {
    isLoading: isLoadingResetCalculation,
    callApi: postResetCalculation,
  } = useApi(
    undefined,
    "POST",
    t("modelParameterization.postResetCalculation.codes"),
    undefined,
    onSuccessResetCalculation
  );

  const handleReset = (resetType: string) => {
    if (!bucketSelectedOption || !selectedStage) return;
    const resetOptions = handleResetOptions(
      resetType,
      bucketSelectedOption,
      selectedStage,
      selectedIteration?.iteracion
    );
    postResetCalculation(POST_RESET_CALCULATION_URL, resetOptions, resetType);
  };

  /********************************************** Calcular ****************************************/

  const onSuccessCalculateProrration = () => {
    parameterizationDispatch({ type: "SET_PENDING_TASKS", payload: true });
    handleRefetchRowsDataNt(
      parameterizationState,
      parameterizationDispatch,
      getProrationResultsNoTrasladados
    );
    refetchBuckets();
    if (selectedIteration) {
      parameterizationDispatch({
        type: "SET_TAB_VALUE",
        payload: t(
          "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
        ),
      });
    }
  };

  const { isLoading: calculateLoading, callApi: postCalculateProrration } =
    useApi(
      undefined,
      "POST",
      t("modelParameterization.postCalculateProrration.codes"),
      undefined,
      onSuccessCalculateProrration
    );

  /**
   * Calcula el prorrateo según el tipo de cálculo especificado y realiza la solicitud al servidor.
   *
   * @param {string} calcType - Tipo de cálculo a realizar. Puede ser "Calcular todo" o "Calcular bucket actual".
   * Otros valores de `calcType` son manejados como un caso predeterminado.
   */

  const handleCalculate = (calcType: string) => {
    const calcOptionsMap = {
      [t("modelParameterizationTexts.step2.calculateEverything")]: {
        bucket: t("modelParameterizationTexts.step2.all"),
        etapa: t("modelParameterizationTexts.step2.all"),
      },
      [t("modelParameterizationTexts.step2.calculateCurrentBucket")]: {
        bucket: bucketSelectedOption?.value || "",
        etapa: selectedStage?.etapa || "",
        iteracion: "",
      },
      default: {
        bucket: bucketSelectedOption?.value || "",
        etapa: selectedStage?.etapa || "",
        iteracion: selectedIteration?.iteracion || "",
      },
    };

    const calcOptions = calcOptionsMap[calcType] || calcOptionsMap.default;
    postCalculateProrration(POST_PRORRATION_CALCULATE_URL, calcOptions);
  };

  /****************************************** Confirmación de modales **************************************/

  const handleCloseConfirmationModal = () => {
    setConfirmationStatus({ type: configType, status: false });
    handleToggleConfirmationModal();
  };

  const handleAcceptConfirmationModal = () => {
    setConfirmationStatus({ type: configType, status: true });
    handleToggleConfirmationModal();
  };

  const handleAcceptWarningModal = () => {
    setConfirmationStatus({ type: configType, status: true });
    handleToggleWarningModal();
  };

  const handleConfirmation = (configType: string, actionType: string) => {
    parameterizationDispatch({
      type: "SET_CONFIG_TYPE",
      payload: configType,
    });
    parameterizationDispatch({
      type: "SET_ACTION_TYPE",
      payload: actionType,
    });
    if (
      actionType !== "reset" &&
      checkNotTransferredExpenses(
        bucketSelectedOption?.value as number,
        selectedStage?.etapa as number,
        buckets
      )
    ) {
      handleToggleWarningModal();
    } else {
      handleToggleConfirmationModal();
    }
  };

  useEffect(() => {
    if (confirmationStatus.status) {
      if (actionType === "reset") {
        handleReset(configType);
      } else {
        handleCalculate(configType);
      }
    }
  }, [confirmationStatus]);

  return (
    <Stack direction="row" alignItems="center">
      <ResetButton handleConfirmation={handleConfirmation} />
      {selectedStage?.etapa !== 0 && (
        <CalculateBucketButton handleConfirmation={handleConfirmation} />
      )}
      <CalculateEverythingButton handleConfirmation={handleConfirmation} />
      <SimpleBackdrop
        open={calculateLoading}
        message={t("modelParameterizationTexts.step2.startingCalculation")}
      />
      <SimpleBackdrop
        open={isLoadingResetCalculation}
        message={t("modelParameterizationTexts.step2.resetting")}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleAccept={handleAcceptConfirmationModal}
        message={message}
        customMessage={hasCustomMessage}
        title={t("modelParameterizationTexts.step2.confirm")}
      />
      <ConfirmationModal
        open={openWarningModal}
        handleClose={handleToggleWarningModal}
        handleAccept={handleAcceptWarningModal}
        message={messageWarningModal}
        customMessage={true}
        title={t("modelParameterizationTexts.step2.confirmStartCalculation")}
      />
    </Stack>
  );
};

export default CalculationButtons;
