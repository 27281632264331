import { getBackgroundColor } from "../../utils";
import { grid_item_element_styles } from "../../../../../styles/app-styles";
import { Item } from "../../../../basicGrid/BasicGrid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../../context/LanguageProvider";
import IterationImplicitPercentageRowsElements from "./IterationImplicitPercentageRowsElements";

const IterationImplicitZeroElement = ({ iteration }) => {
  const { t } = useLanguage();
  const newCriteria = iteration?.criterio_cero?.caso === "CRITERIO_NUEVO";
  const modifyCriteria = iteration?.criterio_cero?.caso === "ELIMINAR";
  const percentageOption = iteration?.criterio_cero?.caso === "PORCENTAJE";
  const arePercentageRows =
    iteration?.criterio_cero?.porcentaje &&
    iteration?.criterio_cero?.porcentaje.length > 0;   

  return (
    <Stack width="100%" gap={1}>
      <Stack
        sx={{
          ...grid_item_element_styles(
            getBackgroundColor,
            "match",
            () => "100%"
          ),
          color: "white",
          minHeight: "30px",
          borderRadius: "4px",
        }}
      >
        Caso criterio cero -{" "}
        {newCriteria ? "Criterio nuevo" : modifyCriteria ? "Modificar un criterio" : "Porcentaje"}
      </Stack>

      {newCriteria && (
        <>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Criterio
          </Stack>
          <Item sx={{ width: "100%" }}>
            <Typography color={"primary"} sx={{ fontSize: "14px" }}>
              {iteration?.criterio_cero?.criterio_receptor?.criterio?.label}
              {" ("}
              {iteration?.sin_match?.criterio_receptor?.receptor?.label}
              {")"}
            </Typography>
          </Item>
        </>
      )}
      {modifyCriteria && (
        <>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Validaciones a incluir
          </Stack>
          <Item sx={{ width: "100%" }}>
            <Typography color={"primary"} sx={{ fontSize: "14px" }}>
              {iteration?.criterio_cero?.criterio_receptor?.validaciones_conservar
                ?.map((v, index) => (
                  <span key={index}>
                    <strong>{`${v?.criterio.label}`}</strong>
                    {v?.validaciones?.length > 0 && ": "}
                    {v?.validaciones?.length > 0
                      ? v?.validaciones?.map((val) => val?.label).join(", ")
                      : ""}
                  </span>
                ))
                .reduce((prev, curr, idx, arr) => (
                  <>
                    {prev}
                    {idx > 0 && ", "}
                    {curr}
                  </>
                ))}
            </Typography>
          </Item>
        </>
      )}
      {percentageOption && arePercentageRows && (
        <IterationImplicitPercentageRowsElements
          iteration={iteration}
          type={"criterio_cero"}
        />
      )}
    </Stack>
  );
};

export default IterationImplicitZeroElement;
