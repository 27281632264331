import { FileType } from "../../../dataManagement/types";
import { transformDataTypeColumns } from "./transformDataTypeColumns";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useState } from "react";
import Button from "../../../buttons/Button";
import ConfirmationModal from "../../../dialog/ConfirmationModal";
import Dialog from "../../../dialog/Dialog";
import ElementComponent from "../../../card/ElementComponent";
import NotAllowedToDeleteFileModal from "./NotAllowedToDeleteFileModal";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TypeModalContent from "./TypeModalContent";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import VirtualizatedTable from "../../../virtualizatedTable/VirtualizedTable";
import {
  COMPANY_PARAMETERS_FILE_URL,
} from "../../../../api/axios";

const OtherFilesInputs = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    allowedFileNames,
    refetchAllowedFileNames,
    getOtherFilesParams,
    dataGetOtherFilesParams,
  } = useCompanyParamProvider();
  const [elementToEdit, setElementToEdit] = useState<any>();
  const [openAddElementModal, toggleAddElementModal] = useDialog();
  const [openConfirmDeleteElement, toggleConfirmDeleteElement] = useDialog();
  const [selectedFileForDelete, setSelectedFileForDelete] =
    useState<FileType>();
  const [
    openNotAllowedToDeleteFileModal,
    handleToggleNotAllowedToDeleteFileModal,
  ] = useDialog();

  /*************************************** Propiedades del elemento ******************************/

  const getBackgroundColor = (element) => {
    const findItem = allowedFileNames.find(
      (item) => item.nombre === element.nombre
    );
    return findItem && findItem?.tipado
      ? "var(--bg-light)"
      : "var(--very-light-magenta)";
  };

  /****************************************** Editar elemento ************************************/

  const handleEditElement = (e, element) => {
    e.stopPropagation();
    setElementToEdit(element);
    toggleAddElementModal();
  };

  const refetchData = () => {
    setElementToEdit(undefined);
    getOtherFilesParams();
    refetchAllowedFileNames();
  };

  const handleToggleEditModal = () => {
    if (openAddElementModal) {
      setElementToEdit(undefined);
    }
    toggleAddElementModal();
  };

  /************************************ Eliminar elemento *************************************/

  const onSuccessDeleteFile = () => {
    refetchData();
    setSelectedFileForDelete(undefined);
  };

  const { isLoading: isLoadingDeleteFile, callApi: deleteFile } = useApi(
    undefined,
    "DELETE",
    undefined,
    undefined,
    onSuccessDeleteFile
  );

  const handleDeleteFileName = () => {
    if (selectedFileForDelete) {
      deleteFile(`${COMPANY_PARAMETERS_FILE_URL}/${selectedFileForDelete?.id}`);
      toggleConfirmDeleteElement();
    }
  };

  const deleteElement = (e, file) => {
    e.stopPropagation();
    if (file.eliminable) {
      setSelectedFileForDelete(file);
      toggleConfirmDeleteElement();
    } else {
      setSelectedFileForDelete(file);
      handleToggleNotAllowedToDeleteFileModal();
    }
  }; 

  return (
    <Stack width="100%" sx={{ minHeight: "200px", gap: 1 }}>
      <SimpleBackdrop
        open={isLoadingDeleteFile}
        message={t("general.loading")}
      />
      <Stack width={"100%"} direction="row" justifyContent={"center"}>
        <Button
          title={t("Agregar archivo")}
          color="blue-greeny"
          type="button"
          onClick={toggleAddElementModal}
          width={200}
          disabled={false}
        />
      </Stack>
      {dataGetOtherFilesParams &&
        dataGetOtherFilesParams.map((item) => {
          return (
            <ElementComponent
              key={item.nombre}
              element={item}
              summary={`${item?.nombre}${
                item?.descripcion?.label && item.descripcion.label !== "-"
                  ? " - " + item.descripcion.label.trim()
                  : ""
              }`}
              details={
                <Stack sx={{ width: "100%" }}>
                  {item?.columnas?.length > 0 && (
                    <VirtualizatedTable
                      columns={
                        transformDataTypeColumns(t, item?.columnas).columns
                      }
                      rows={transformDataTypeColumns(t, item?.columnas).rows}
                      rowsPerPageOptions={[5, 10, 20]}
                      rowHeight={30}
                      tableHeight={`${(120 + 30*item?.columnas?.length)}px`}
                      hideFooter={true}
                      toolbar={true}
                      columnsButton={true}
                      filterButton={true}
                      densitySelector={false}
                      exportButton={false}
                      saveViewButton={false}
                      checkboxSelection={false}
                      setSelectedRows={() => <></>}
                    />
                  )}
                </Stack>
              }
              handleEditElement={handleEditElement}
              deleteElement={deleteElement}
              getBackgroundColor={getBackgroundColor}
            />
          );
        })}
      <Dialog
        open={openAddElementModal}
        handleClose={handleToggleEditModal}
        title={t("dataManagementTexts.dataManagement.allowedFiles")}
        maxWidth="xl"
      >
        <Stack width={"1200px"}>
          <TypeModalContent
            fileData={elementToEdit}
            setFileData={setElementToEdit}
            handleClose={handleToggleEditModal}
            dataFilesList={allowedFileNames}
            refetchData={refetchData}
            type="OTRO"
          />
        </Stack>
      </Dialog>
      <ConfirmationModal
        open={openConfirmDeleteElement}
        handleClose={toggleConfirmDeleteElement}
        handleAccept={handleDeleteFileName}
        message={`${t("dataManagementTexts.allowFilesModalContent.delete")} ${
          selectedFileForDelete?.nombre
        }`}
        title={t("dataManagementTexts.allowFilesModalContent.deleteFile")}
      />
      <NotAllowedToDeleteFileModal
        open={openNotAllowedToDeleteFileModal}
        handleClose={handleToggleNotAllowedToDeleteFileModal}
        fileName={selectedFileForDelete?.nombre}
      />   
    </Stack>
  );
};

export default OtherFilesInputs;
