import { ActionMeta } from "react-select";
import { back_arrow_styles } from "../../../../styles/app-styles";
import { COMPANY_URL, SCHEMA_URL, SCHEMAS_URL } from "../../../../api/axios";
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../../buttons/Button";
import ComponentLayout from "../../../layout/ComponentLayout";
import ConfirmationModal from "../../../dialog/ConfirmationModal";
import Dialog from "../../../dialog/Dialog";
import NewSchemaForm from "./NewSchemaForm";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useInfoContext from "../../../../hooks/useInfoContext";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";

const ConfigSchema = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { setCompanyData }: any = useInfoContext();
  const assignTypeSelectRef: any = useRef();
  const columnSelectRef: any = useRef();
  const [openNoFiltersModal, toggleNoFiltersModal] = useDialog();
  const configMode = localStorage.getItem("configMode");
  const schema = JSON.parse(localStorage.getItem("schema") as string);
  const [newSchemaRows, setNewSchemaRows] = useState<any[]>(
    configMode === "EDIT" || configMode === "COPY"
      ? schema?.criterios_destinos && schema?.criterios_destinos.length
        ? schema.criterios_destinos
        : schema?.porcentaje && schema?.porcentaje?.length
        ? schema?.porcentaje
        : []
      : []
  );
  const [schemaName, setSchemaName] = useState<string>(
    configMode === "EDIT"
      ? schema?.nombre
      : configMode === "COPY"
      ? `${schema?.nombre} - Copia`
      : ""
  );
  const [iterations, setIterations] = useState<any[]>(
    configMode === "EDIT" || configMode === "COPY" ? schema?.iteraciones : []
  );
  const [openConfirmationDestinyTypeModal, toggleConfirmationDestinyTypeModal] =
    useDialog();
  const [newDestinyTypeSelectedOption, setNewDestinyTypeSelectedOption] =
    useState<any>();
  const [openConfirmationAssignTypeModal, toggleConfirmationAssignTypeModal] =
    useDialog();
  const [newAssignTypeSelectedOption, setNewAssignTypeSelectedOption] =
    useState<any>();

  const onSuccessGetUserCompany = (data: any) => {
    setCompanyData(data);
  };

  const { callApi: getUserCompany } = useApi(
    COMPANY_URL,
    "GET",
    undefined,
    undefined,
    onSuccessGetUserCompany,
    undefined,
    false
  );

  useEffect(() => {
    getUserCompany();
  }, []);

  /******************************** Selects ******************************************/

  const [destinyTypeSelectedOption, setDestinyTypeSelectedOption] = useState(
    configMode === "COPY" || configMode === "EDIT"
      ? { value: schema?.tipo_destino, label: schema?.tipo_destino }
      : undefined
  );

  const destinyTypeSelectedOptionsChangeHandler = (
    value: any,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        if (destinyTypeSelectedOption !== undefined) {
          setNewDestinyTypeSelectedOption(value);
          toggleConfirmationDestinyTypeModal();
        } else {
          setDestinyTypeSelectedOption(value);
        }
        break;
      default:
        break;
    }
  };

  const acceptChangeDestinyType = () => {
    setDestinyTypeSelectedOption(newDestinyTypeSelectedOption);
    setAssignTypeSelectedOption(undefined);
    assignTypeSelectRef?.current?.clearValue();
    setNewSchemaRows([]);
    setIterations([]);
    toggleConfirmationDestinyTypeModal();
  };

  const [assignTypeSelectedOption, setAssignTypeSelectedOption] = useState(
    configMode === "COPY" || configMode === "EDIT"
      ? schema?.tipo_preprorrateo === "IMPLICITA"
        ? { value: "IMPLICITA", label: "Asignación implícita" }
        : { value: "EXPLICITA", label: "Asignación explícita" }
      : undefined
  );

  const assignTypeSelectedOptionsChangeHandler = (
    value: any,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        if (assignTypeSelectedOption !== undefined) {
          setNewAssignTypeSelectedOption(value);
          toggleConfirmationAssignTypeModal();
        } else {
          setAssignTypeSelectedOption(value);
        }
        break;
      default:
        break;
    }
  };

  const acceptChangeAssignType = () => {
    setAssignTypeSelectedOption(newAssignTypeSelectedOption);
    setNewSchemaRows([]);
    setIterations([]);
    toggleConfirmationAssignTypeModal();
  };

  const [newCriteriaSelectedOption, setNewCriteriaSelectedOption] =
    useState(undefined);
  const [openConfirmationCriteriaModal, toggleConfirmationCriteriaModal] =
    useDialog();

  const criteriaSelectedOptionChangeHandler = (
    value: any,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        if (
          criteriaSelectedOption !== undefined &&
          iterations &&
          iterations.length > 0
        ) {
          setNewCriteriaSelectedOption(value);
          toggleConfirmationCriteriaModal();
        } else {
          setNewCriteriaSelectedOption(value);
          setCriteriaSelectedOption(value);
          setColumnSelectedOption(undefined);
          columnSelectRef?.current?.clearValue();
        }
        break;
      default:
        break;
    }
  };

  const [criteriaSelectedOption, setCriteriaSelectedOption] = useState(
    configMode === "COPY" || configMode === "EDIT"
      ? schema?.tipo_preprorrateo === "IMPLICITA"
        ? schema?.criterio_receptor?.criterio
        : undefined
      : undefined
  );  

  const acceptChangeCriteria = () => {
    setCriteriaSelectedOption(newCriteriaSelectedOption);
    setIterations([]);
    toggleConfirmationCriteriaModal();
    setColumnSelectedOption(undefined);
    columnSelectRef?.current?.clearValue();
  };

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnSelectedOptionChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "COPY" || configMode === "EDIT"
      ? schema?.tipo_preprorrateo === "IMPLICITA"
        ? schema?.criterio_receptor?.receptor
        : undefined
      : undefined
  );

  const onSuccessPostSchema = () => {
    localStorage.removeItem("schema");
    localStorage.removeItem("configMode");
    navigate("/administracion-esquemas");
  };

  const { isLoading: isLoadingPostSchema, callApi: postSchema } = useApi(
    "",
    "POST",
    t("configSchema.sendSchema.codes"),
    undefined,
    onSuccessPostSchema
  );

  const { isLoading: isLoadingPutSchema, callApi: putSchema } = useApi(
    "",
    "PUT",
    t("configSchema.sendSchema.codes"),
    undefined,
    onSuccessPostSchema
  );

  const handleCreateSchema = () => {
    const completedInfo = {
      nombre: schemaName,
      tipo_destino: destinyTypeSelectedOption?.value,
      tipo_preprorrateo: assignTypeSelectedOption?.value.toUpperCase(),
      // si es IMPLICITA
      criterio_receptor:
        assignTypeSelectedOption?.value === "IMPLICITA"
          ? {
              criterio: criteriaSelectedOption?.value,
              receptor: columnSelectedOption?.value,
            }
          : undefined,
      // si es EXPLICITA
      criterios_destinos:
        assignTypeSelectedOption?.value === "EXPLICITA" &&
        newSchemaRows[0]?.criterio
          ? newSchemaRows.map((line) => {
              return {
                destino: line?.destino,
                criterio: line?.criterio?.value,
              };
            })
          : undefined,
      porcentaje:
        assignTypeSelectedOption?.value === "EXPLICITA" &&
        newSchemaRows[0]?.porcentaje
          ? newSchemaRows.map((line) => {
              return {
                destino: line?.destino,
                porcentaje: line?.porcentaje,
              };
            })
          : undefined,
      iteraciones:
        iterations && iterations.length > 0
          ? iterations.map((iteration) => {
              return {
                iteracion: iteration?.iteracion,
                sin_match: {
                  caso: iteration?.sin_match?.caso,
                  criterios_destinos:
                    iteration?.sin_match?.criterios_destinos?.map((line) => {
                      return {
                        destino: line?.destino,
                        criterio: line?.criterio?.value,
                        validaciones_conservar:
                          line?.validaciones_conservar?.map((validation) => {
                            return {
                              validaciones: validation.validaciones.map(
                                (val) => val.value
                              ),
                              criterio: validation.criterio.value,
                            };
                          }),
                      };
                    }),
                  porcentaje: iteration?.sin_match?.porcentaje?.map((line) => {
                    return {
                      destino: line?.destino,
                      porcentaje: line?.porcentaje,
                    };
                  }),
                  criterio_receptor:
                    assignTypeSelectedOption?.value === "IMPLICITA" &&
                    iteration?.sin_match?.criterio_receptor
                      ? {
                          criterio:
                            iteration?.sin_match?.criterio_receptor?.criterio
                              ?.value,
                          receptor:
                            iteration?.sin_match?.criterio_receptor?.receptor
                              ?.value,
                          validaciones_conservar:
                            iteration?.sin_match?.criterio_receptor?.validaciones_conservar?.map(
                              (validation) => {
                                return {
                                  validaciones: validation.validaciones.map(
                                    (val) => val.value
                                  ),
                                  criterio: validation.criterio.value,
                                  receptor: validation.receptor.value,
                                };
                              }
                            ),
                        }
                      : undefined,
                },
                criterio_cero: {
                  caso: iteration?.criterio_cero.caso,
                  criterios_destinos:
                    iteration?.criterio_cero?.criterios_destinos?.map(
                      (line) => {
                        return {
                          destino: line?.destino,
                          criterio: line?.criterio?.value,
                          validaciones_conservar:
                            line?.validaciones_conservar?.map((validation) => {
                              return {
                                validaciones: validation.validaciones.map(
                                  (val) => val.value
                                ),
                                criterio: validation.criterio.value,
                              };
                            }),
                        };
                      }
                    ),
                  porcentaje: iteration?.criterio_cero?.porcentaje?.map(
                    (line) => {
                      return {
                        destino: line?.destino,
                        porcentaje: line?.porcentaje,
                      };
                    }
                  ),
                  criterio_receptor:
                    assignTypeSelectedOption?.value === "IMPLICITA" &&
                    iteration?.criterio_cero?.criterio_receptor
                      ? {
                          criterio:
                            iteration?.criterio_cero?.criterio_receptor
                              ?.criterio?.value,
                          receptor:
                            iteration?.criterio_cero?.criterio_receptor
                              ?.receptor?.value,
                          validaciones_conservar:
                            iteration?.criterio_cero?.criterio_receptor?.validaciones_conservar?.map(
                              (validation) => {
                                return {
                                  validaciones: validation.validaciones.map(
                                    (val) => val.value
                                  ),
                                  criterio: validation.criterio.value,
                                  receptor: validation.receptor.value,
                                };
                              }
                            ),
                        }
                      : undefined,
                },
              };
            })
          : undefined,
    };
    if (configMode === "CREATE" || configMode === "COPY") {
      postSchema(SCHEMAS_URL, completedInfo);
    } else {
      putSchema(SCHEMA_URL(schema?.id), completedInfo);
    }
  };

  const handleBack = () => {
    localStorage.removeItem("schema");
    localStorage.removeItem("configMode");
    navigate("/administracion-esquemas");
  };

  return (
    <ComponentLayout
      title={t("Configuración del esquema")}
      icon={<ArrowBackIcon sx={back_arrow_styles} onClick={handleBack} />}
    >
      <SimpleBackdrop
        open={isLoadingPostSchema || isLoadingPutSchema}
        message={t("general.loading")}
      />
      <SimpleBackdrop
        open={false}
        message={
          configMode === "CREATE" || configMode === "COPY"
            ? t("configSchema.sendSchema.loading")
            : t("configSchema.putSchema.loading")
        }
      />
      <Dialog
        open={openNoFiltersModal}
        handleClose={toggleNoFiltersModal}
        title={t("configCriteria.configCriteriaComponent.noFilters")}
        maxWidth="lg"
        actions={
          <>
            <Button
              title={t("configCriteria.configCriteriaComponent.goBack")}
              color="grey"
              type="button"
              onClick={toggleNoFiltersModal}
            />
            <Button
              title={t("configCriteria.configCriteriaComponent.continue")}
              color="blue"
              type="button"
              onClick={() => <></>}
            />
          </>
        }
      >
        <Typography variant="h6" color="primary">
          {t("configCriteria.configCriteriaComponent.nonAppliedFilterWarning")}
        </Typography>
      </Dialog>
      <NewSchemaForm
        handleCreateSchema={handleCreateSchema}
        destinyTypeSelectedOption={destinyTypeSelectedOption}
        destinyTypeSelectedOptionsChangeHandler={
          destinyTypeSelectedOptionsChangeHandler
        }
        assignTypeSelectRef={assignTypeSelectRef}
        assignTypeSelectedOption={assignTypeSelectedOption}
        assignTypeSelectedOptionsChangeHandler={
          assignTypeSelectedOptionsChangeHandler
        }
        criteriaSelectedOption={criteriaSelectedOption}
        criteriaSelectedOptionChangeHandler={
          criteriaSelectedOptionChangeHandler
        }
        columnSelectRef={columnSelectRef}
        columnSelectedOption={columnSelectedOption}
        columnSelectedOptionChangeHandler={columnSelectedOptionChangeHandler}
        newSchemaRows={newSchemaRows}
        setNewSchemaRows={setNewSchemaRows}
        schemaName={schemaName}
        setSchemaName={setSchemaName}
        iterations={iterations}
        setIterations={setIterations}
      />
      <ConfirmationModal
        title={t("Modificar tipo de destino")}
        message={
          "¿Confirma que desea modificar el tipo de destino? Esto eliminará los datos cargados hasta el momento."
        }
        customMessage={true}
        open={openConfirmationDestinyTypeModal}
        handleClose={toggleConfirmationDestinyTypeModal}
        handleAccept={acceptChangeDestinyType}
      />
      <ConfirmationModal
        title={t("Modificar tipo de asignación")}
        message={
          "¿Confirma que desea modificar el tipo de asignación? Esto eliminará los datos cargados hasta el momento."
        }
        customMessage={true}
        open={openConfirmationAssignTypeModal}
        handleClose={toggleConfirmationAssignTypeModal}
        handleAccept={acceptChangeAssignType}
      />
      <ConfirmationModal
        title={t("Modificar criterio")}
        message={
          "¿Confirma que desea modificar el criterio? Esto eliminará las iteraciones añadidas hasta el momento."
        }
        customMessage={true}
        open={openConfirmationCriteriaModal}
        handleClose={toggleConfirmationCriteriaModal}
        handleAccept={acceptChangeCriteria}
      />
    </ComponentLayout>
  );
};

export default ConfigSchema;
