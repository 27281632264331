import Stack from '@mui/material/Stack'
import { white_shadow_box_styles } from '../../styles/app-styles'
interface WhiteShadowBoxProps {
  children: React.ReactNode
  sx?: any
}

const WhiteShadowBox = ({children, sx}: WhiteShadowBoxProps) => {
  return (
    <Stack sx={sx? sx : white_shadow_box_styles}>
        {children}
    </Stack>
  )
}

export default WhiteShadowBox