import "./styles.css";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { AddButton, Button } from "../buttons";
import { ConfirmationModal as CalculateConfirmationModal } from "../dialog";
import { ConfirmationModal as ResetConfirmationModal } from "../dialog";
import { GridColDef } from "@mui/x-data-grid";
import { IncomingSchemaOption } from "./types";
import { transformData } from "../virtualizatedTable/transformDataForPreproration";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useState, useEffect } from "react";
import { VariantType, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import Dialog from "../dialog/Dialog";
import IconButton from "@mui/material/IconButton";
import PeriodsFilter from "../periods/PeriodsFilter";
import SchemaInfoModal from "./showSchemeInfoModal/SchemaInfoModal";
import SchemaPeriodContentModal from "./SchemaPeriodContentModal";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import TuneIcon from "@mui/icons-material/Tune";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useTabs from "../../hooks/useTabs";
import VirtualizatedTable from "../virtualizatedTable/VirtualizedTable";
import {
  CRITERIA_URL,
  DEASIGN_PREPRORRATION_URL,
  FLOWS_URL,
  GET_PREPRORRATEO_GASTOS_URL,
  POST_PREPRORATION_CALCULATE_URL,
  POST_REVERSE_CALCULATION_URL,
  SCHEMAS_URL,
} from "../../api/axios";
import { useNavigate } from "react-router-dom";

const PreprorationParameterization = () => {
  const { t, language } = useLanguage();
  const { companyData }: any = useInfoContext();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userRolData }: any = useInfoContext();
  const [tabValue, handleChange] = useTabs("BUCKET_GASTO");
  const [openEsquemaDialog, handleToggleSchemaDialog] = useDialog();
  const [openEsquemaInfoModal, handleToggleEsquemaInfoModal] = useDialog();
  const [
    openCalculateConfirmationModal,
    handleToggleCalculateConfirmationModal,
  ] = useDialog();
  const [openResetConfirmationModal, handleToggleResetConfirmationModal] =
    useDialog();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedSchemaInfo, setSelectedSchemaInfo] = useState<any>();
  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();

  /*************************************** Tabs **************************************************/

  const tabs = companyData?.casos_preprorrateo?.map((tab) => {
    if (tab === "BUCKET_GASTO") {
      return {
        value: "BUCKET_GASTO",
        label: t(
          "preprorationParameterization.preprorationParameterization.tabs.bucketExpense"
        ),
      };
    } else {
      return {
        value: tab,
        label: tab,
      };
    }
  });

  /*************************************** Seteos iniciales ***************************************/

  useEffect(() => {
    handleChange(null, "BUCKET_GASTO");
  }, [language]);

  useEffect(() => {
    getPreprorationCriterias();
    const preprorrationTab = localStorage.getItem("preprorrationTab");
    if (preprorrationTab !== null) {
      handleChange(null, preprorrationTab);
    } else {
      handleChange(null, "BUCKET_GASTO");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("preprorrationTab", tabValue);
    getFlows();

    return () => {
      // Eliminar preprorrationTab del localStorage al desmontar el componente
      localStorage.removeItem("preprorrationTab");
    };
  }, [tabValue]);

  const handleClickVariant = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  /*********************************** Api Querys **********************************/

  const { callApi: getPreprorationCriterias, isLoading: criteriasLoading } =
    useApi(
      `${CRITERIA_URL}?tipo=pre_prorrateo`,
      "GET",
      undefined,
      undefined,
      undefined,
      undefined,
      false
    );

  const {
    data: dataPreproration,
    isLoading: isLoadingPreproration,
    isSuccess: isSuccessPreproration,
    isError: isErrorPreproration,
    refetch: refetchPreproration,
  } = useApiQuery(
    `${GET_PREPRORRATEO_GASTOS_URL}?tipo_destino=${tabValue}`,
    true,
    t("preprorationParameterization.getPreprorationBuckets.error")
  );

  const { data: dataSchemas, isLoading: dataSchemasLoading } = useApiQuery(
    SCHEMAS_URL,
    false,
    undefined
  );

  const onSuccessPreprorationCalculate = () => {
    refetchPreproration();
  };

  const { data: dataFlows, callApi: getFlows } = useApi(
    FLOWS_URL(tabValue),
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPreprorationCalculate,
    callApi: preprorationCalculate,
  } = useApi(
    undefined,
    "POST",
    t("preprorration.preprorrationCalculate.codes"),
    undefined,
    onSuccessPreprorationCalculate,
    undefined,
    true
  );

  const refetchDataPreproration = () => {
    refetchPreproration();
  };

  const {
    callApi: reverseCalculation,
    isLoading: isLoadingReverseCalculation,
  } = useApi(
    POST_REVERSE_CALCULATION_URL,
    "POST",
    t(
      "preprorationParameterization.preprorationParameterization.reverseCalculationMessages.codes"
    ),
    undefined,
    refetchDataPreproration,
    undefined,
    true
  );

  const {
    callApi: deassignFlow,
    isLoading: isLoadingDeassignFlow,
  } = useApi(
    DEASIGN_PREPRORRATION_URL,
    "POST",
    undefined,
    undefined,
    refetchDataPreproration,
    undefined,
    true
  );

  /************************************** Transform Data para tablas *****************************/

  useEffect(() => {
    if (isSuccessPreproration) {
      if (tabValue) {
        if (
          dataPreproration &&
          dataPreproration.data &&
          dataPreproration.data.length > 0
        ) {
          const { columns, rows } = transformData(
            dataPreproration.data,
            handleClickOnSchemaName
          );
          setColumns(columns);
          setRows(rows);
        } else {
          setColumns([]);
          setRows([]);
        }
      }
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [dataPreproration, tabValue]);

  const handleSelectedRows = (rows: any) => {
    setSelectedRows(rows);
  };

  const handleClickOnSchemaName = (schemaName: string) => {
    const schema = dataSchemas?.find((schema: IncomingSchemaOption) => {
      return schema.nombre === schemaName;
    });
    if (schema) {
      setSelectedSchemaInfo(schema);
      handleToggleEsquemaInfoModal();
    }
  };

  const handleReverseCalculation = async () => {
    handleToggleResetConfirmationModal();
    reverseCalculation();
  };

  const checkIfAllRowsHasSchemasApplied = () => {
    if (tabValue) {
      if (
        dataPreproration &&
        dataPreproration.data &&
        dataPreproration.data.length > 0
      ) {
        return dataPreproration.data.every(
          (item: any) => !!item.FLUJO_APLICADO.ID
        );
      } else {
        return false;
      }
    }
  };

  const handlePreprorationCalculate = async () => {
    handleToggleCalculateConfirmationModal();
    if (tabValue) {
      if (
        dataPreproration &&
        dataPreproration.data &&
        dataPreproration.data.length > 0
      ) {
        if (
          dataPreproration.data.every((item: any) => !!item.FLUJO_APLICADO.ID)
        ) {
          preprorationCalculate(POST_PREPRORATION_CALCULATE_URL(tabValue));
        } else {
          handleClickVariant(
            t(
              "preprorationParameterization.preprorationParameterization.schemasToApplyMissing"
            ),
            "error"
          );
        }
      } else {
        handleClickVariant(
          t(
            "preprorationParameterization.preprorationParameterization.noDataToCalculation"
          ),
          "error"
        );
      }
    }
  };

  const handleClosePeriodsModal = () => {
    refetchDataPreproration();
    handleTogglePeriodsDialog();
  };

  const goToParameterization = () => {
    navigate("/parametrizacion-empresa", {
      state: { step: 1 },
      replace: false,
    });
  };

  const handleDeassignFlow = () => {
    deassignFlow(undefined, {
      grupos: selectedRows,
      tipo_destino: tabValue,
    })
  }

  return (
    <>
      <SimpleBackdrop
        open={isLoadingReverseCalculation}
        message={t(
          "preprorationParameterization.preprorationParameterization.reversingCalculation"
        )}
      />
      <SimpleBackdrop
        open={isLoadingPreprorationCalculate}
        message={t("preprorration.preprorrationCalculate.loading")}
      />
      <SimpleBackdrop
        open={dataSchemasLoading || criteriasLoading || isLoadingDeassignFlow}
        message={t("general.loading")}
      />
      <ComponentLayout
        title={t(
          "preprorationParameterization.preprorationParameterization.settingUpPreproration"
        )}
        icon={<TuneIcon />}
        rightComponent={
          <Button
            title={t(
              "preprorationParameterization.preprorationParameterization.periods"
            )}
            color="blue"
            type="button"
            onClick={() => handleTogglePeriodsDialog()}
          />
        }
      >
        <Stack spacing={2} sx={{ width: "100%", margin: "0px !important" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0px !important",
            }}
          >
            {companyData && (
              <Stack
                sx={{
                  width: "50%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                {userRolData?.parametrizacion_de_la_empresa
                  .visualizacion_y_edicion && (
                  <AddButton onClick={() => goToParameterization()} />
                )}
                <ColorTabs
                  value={tabValue}
                  handleChange={handleChange}
                  tabs={tabs}
                  marginBottom="0px"
                />
              </Stack>
            )}
            {userRolData &&
              userRolData.parametrizacion_de_preprorrateo_y_prorrateo
                .visualizacion_y_edicion && (
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row">
                    <Tooltip
                      title={
                        !checkIfAllRowsHasSchemasApplied()
                          ? t(
                              "preprorationParameterization.preprorationParameterization.allRowsNeedSchemas"
                            )
                          : ""
                      }
                    >
                      <div>
                        <Button
                          title={t(
                            "preprorationParameterization.preprorationParameterization.calculate"
                          )}
                          color="blue-greeny"
                          type="button"
                          disabled={
                            !checkIfAllRowsHasSchemasApplied() ||
                            dataPreproration.deshabilitar_botones === true
                          }
                          onClick={() =>
                            handleToggleCalculateConfirmationModal()
                          }
                        />
                      </div>
                    </Tooltip>
                    <Button
                      title={t(
                        "preprorationParameterization.preprorationParameterization.reverseCalculation"
                      )}
                      color="grey"
                      type="button"
                      onClick={() => handleToggleResetConfirmationModal()}
                    />
                    <Button
                      title={t("Desasignar flujo")}
                      color="blue"
                      type="button"
                      onClick={() => handleDeassignFlow()}
                      disabled={selectedRows.length === 0}
                    />
                    <Button
                      title={t("Asignar flujo")}
                      color="blue-greeny"
                      type="button"
                      onClick={() => handleToggleSchemaDialog()}
                      disabled={
                        selectedRows.length === 0 ||
                        dataPreproration.deshabilitar_botones === true
                      }
                    />
                  </Stack>
                </Stack>
              )}
          </Box>
          <Stack width="100%">
            {isLoadingPreproration ? (
              <CustomSkeleton height={5} />
            ) : isErrorPreproration ? (
              <p>
                {t(
                  "preprorationParameterization.preprorationParameterization.tabs.errorDownloadingInfo"
                )}
              </p>
            ) : rows.length > 0 ? (
              <VirtualizatedTable
                columns={columns}
                rows={rows}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={35}
                tableHeight={"65vh"}
                hideFooter={true}
                toolbar={true}
                columnsButton={true}
                filterButton={true}
                densitySelector={false}
                exportButton={false}
                setSelectedRows={handleSelectedRows}
                isPreproration={true}
              />
            ) : (
              <Box
                sx={{
                  width: "95%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "700", color: "var(--text-info)" }}
                >
                  {t(
                    "preprorationParameterization.preprorationParameterization.tabs.noExpensesToPreprorate"
                  )}{" "}
                  {tabValue}.
                </Typography>
              </Box>
            )}
          </Stack>
          <Dialog
            title={t("Flujo de esquemas")}
            open={openEsquemaDialog}
            handleClose={handleToggleSchemaDialog}
            maxWidth="xl"
          >
            <SchemaPeriodContentModal
              handleToggleModal={handleToggleSchemaDialog}
              selectedRows={selectedRows}
              tabValue={tabValue}
              dataFlows={dataFlows}
              refetchPreproration={refetchPreproration}
            />
          </Dialog>
          {dataSchemas && selectedSchemaInfo && (
            <SchemaInfoModal
              open={openEsquemaInfoModal}
              handleClose={handleToggleEsquemaInfoModal}
              schema={selectedSchemaInfo}
            />
          )}
          <CalculateConfirmationModal
            open={openCalculateConfirmationModal}
            handleClose={handleToggleCalculateConfirmationModal}
            handleAccept={handlePreprorationCalculate}
            message={t(
              "preprorationParameterization.preprorationParameterization.startCalculation"
            )}
            title={t(
              "preprorationParameterization.preprorationParameterization.resetCalculation"
            )}
          />
          <ResetConfirmationModal
            open={openResetConfirmationModal}
            handleClose={handleToggleResetConfirmationModal}
            handleAccept={handleReverseCalculation}
            message={t(
              "preprorationParameterization.preprorationParameterization.resetCalculation"
            )}
            title={t(
              "preprorationParameterization.preprorationParameterization.confirmReset"
            )}
          />
        </Stack>
        <PeriodsFilter
          openPeriodsDialog={openPeriodsDialog}
          handleTogglePeriodsDialog={handleClosePeriodsModal}
        />
      </ComponentLayout>
    </>
  );
};

export default PreprorationParameterization;
