import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import FormLayout from "../../../forms/FormLayout";
import MessageBlock from "../../../forms/MessageBlock";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TargetLine from "./TargetLine";
import TargetsFilesInputs from "./TargetsFilesInputs";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles2,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step5 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    isLoadingPostExpensesParams,
    expensesTargets,
    setExpensesTargets,
    dataGetExpenseTargetParams,
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
    if (dataGetExpenseTargetParams) {
      setExpensesTargets(dataGetExpenseTargetParams);
    }
  }, [dataGetExpenseTargetParams]);

  /************************************* Validación step completo **********************************/

  const isStep5Complete = () => {
    return true;
  };

  useEffect(() => {
    if (isStep5Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step5: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step5: false };
      });
    }
  }, [expensesTargets]);

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[5]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles2}>
        <FormLayout width="90%">
          <TargetsFilesInputs />
        </FormLayout>
      </Stack>
      <Stack sx={{ width: "80%", mt: 2, gap: 2 }}>
        {expensesTargets &&
          expensesTargets.map((target: any) => {
            return <TargetLine key={target.nombre} expenseTarget={target} />;
          })}
      </Stack>
    </Stack>
  );
};

export default Step5;
