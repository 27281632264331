import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import Stack from "@mui/material/Stack";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import useLanguage from "../../context/LanguageProvider";
import CardIcon from "./CardIcon";

interface ElementFileComponentProps {
  element: any;
  summary: any;
  details: any;
  handleEditElement?: any;
  handleViewInfo?: any;
  deleteElement?: any;
  getBackgroundColor?: any;
}

const ElementComponent = ({
  element,
  summary,
  details,
  handleEditElement,
  handleViewInfo,
  deleteElement,
  getBackgroundColor,
}: ElementFileComponentProps) => {
  const { t } = useLanguage();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        backgroundColor: getBackgroundColor
          ? getBackgroundColor(element)
          : "var(--bg-light)",
        m: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            width: "100%",
            color: "var(--text-main)",
            fontWeight: "bold",
          }}
        >
          {summary}
        </span>
        <Stack direction={"row"} justifyContent={"space-between"} gap={0.8}>
          {handleViewInfo && (
            <CardIcon
              Icon={ZoomInIcon}
              onClickAction={(e) => handleViewInfo(e, element)}
              tooltipText={t("Ver tabla")}
            />
          )}
          {handleEditElement && (
            <CardIcon
              Icon={EditIcon}
              onClickAction={(e) => handleEditElement(e, element)}
              tooltipText={t("Editar")}
            />
          )}
          {deleteElement && (
            <CardIcon
              Icon={DeleteIcon}
              onClickAction={(e) => deleteElement(e, element)}
              tooltipText={t("general.delete")}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};

export default ElementComponent;
