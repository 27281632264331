import { useEffect, useState } from "react";
import KeyColumnSingleSelect from "./KeyColumnSingleSelect";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  key_column_label_styles,
  key_column_select_styles,
} from "../../../../styles/app-styles";

interface KeyColumnSelectProps {
  inputLabel: string;
  optional?: boolean;
  columnKey: string;
  defaultValue?: {
    value: string | undefined;
    label: string | undefined;
  };
  setKeyColumns: any;
  dataGetParams: any;
  fileData: any;
}

const KeyColumnSelect = ({
  inputLabel,
  optional,
  columnKey,
  setKeyColumns,
  dataGetParams,
  fileData,
}: KeyColumnSelectProps) => {
  const [defaultValue, setDefaultValue] = useState<
    { value: string | undefined; label: string | undefined } | undefined
  >(undefined);

  useEffect(() => {
    if (dataGetParams) {
      if (
        dataGetParams.columnas_clave &&
        dataGetParams.columnas_clave[columnKey]
      ) {
        setDefaultValue({
          value: dataGetParams.columnas_clave[columnKey],
          label: dataGetParams.columnas_clave[columnKey],
        });
      } else {
        setDefaultValue({
          value: undefined,
          label: undefined,
        });
      }
    }
  }, [dataGetParams]);

  return (
    <Stack
      sx={{ ...key_column_select_styles, fontSize: "12px", fontWeight: "bold" }}
    >
      <Typography
        sx={{
          ...key_column_label_styles,
          fontSize: "12px",
          fontWeight: "bold",
          flex: dataGetParams?.edicion_columnas_gasto === false ? 0.35 : 1,
        }}
      >
        {inputLabel}
      </Typography>
      <Stack sx={{ width: "100%", flex: dataGetParams?.edicion_columnas_gasto === false ? 0.35 : 1 }}>
        {defaultValue && (
          <KeyColumnSingleSelect
            defaultValue={defaultValue}
            columnKey={columnKey}
            optional={optional}
            setKeyColumns={setKeyColumns}
            dataGetParams={dataGetParams}
            fileData={fileData}
          />
        )}
      </Stack>
      {dataGetParams?.edicion_columnas_gasto === false &&
      <Stack sx={{ flex: 0.3 }}>        
          No se puede modificar la columna ya que está siendo utilizada en el cálculo.
      </Stack>}
    </Stack>
  );
};

export default KeyColumnSelect;
