import { stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect } from "react";
import FormLayout from "../../../forms/FormLayout";
import MessageBlock from "../../../forms/MessageBlock";
import OtherFilesInputs from "./OtherFilesInputs";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import {
  form_layouts_container_styles2,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step3 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    isLoadingPostExpensesParams,
  } = useCompanyParamProvider();

  /********************************** Seteo inicial de variables *************************************/

  useEffect(() => {
    setMessage({ type: "", text: "" });
  }, []);

  /************************************* Validación step completo **********************************/

  const isStep3Complete = () => {
    return true;
  };

  useEffect(() => {
    if (isStep3Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step3: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step3: false };
      });
    }
  }, []);

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[3]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={{ ...form_layouts_container_styles2, width: "100%" }}>
        <FormLayout width="100%">
          <OtherFilesInputs />
        </FormLayout>
      </Stack>
    </Stack>
  );
};

export default Step3;
