import { Box, Typography } from "@mui/material";
import { ConfirmationModal as DeleteCriteriaConfirmationModal } from "../dialog";
import { CRITERIA_URL } from "../../api/axios";
import { filterPreProrrateoValue, filterProrrateoValue } from "./filters";
import { IdOption } from "../configCriteria/types";
import { MultiValue } from "react-select";
import { sortByName, sortByIdDesc } from "./utils";
import { TabValue } from "../colorTabs/types";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import AddCriteriaButton from "./AddCriteriaButton";
import BasicGrid from "../basicGrid/BasicGrid";
import ChipFilters from "../chipFilters/ChipFilters";
import ComponentLayout from "../layout/ComponentLayout";
import ConfigCriteria from "../configCriteria/ConfigCriteria.component";
import ConstructionIcon from "@mui/icons-material/Construction";
import CriteriaCard from "./CriteriaCard";
import CustomButton from "../buttons/Button";
import Dialog from "../dialog/Dialog";
import FilterCriteriaModal from "./FilterCriteriaModal";
import SearchInput from "../forms/SearchInput";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import ToggleSort from "./ToggleSort";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import ViewInfoModal from "./ViewInfoModal";

const CriteriaManagement = ({ type }) => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const url = window.location.href;
  const {
    data: dataGetCriteria,
    isLoading: isLoadingCriteria,
    isSuccess: isSuccessCriteria,
    isError: isErrorCriteria,
    refetch: refetchCriteria,
  } = useApiQuery(
    CRITERIA_URL,
    false,
    t("criteriaManagement.getCriteria.error")
  );

  type Criteria = {
    id: number;
    nombre: string;
    buckets_usados: {
      value: number;
      label: string;
    }[];
    criterio_bucket: any;
    criterio_receptor_gasto: any;
  };

  const [tabValue, setTabValue] = useState<TabValue>(
    type === "prorrateo" ? "PRORRATEO" : "PREPRORRATEO"
  );
  const [openConfigCriteria, setOpenConfigCriteria] = useState<boolean>(false);
  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "COPY">(
    "CREATE"
  );
  const [criteria, setCriteria] = useState<Criteria>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<typeof dataGetCriteria>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();
  const [
    openDeleteCriteriaConfirmationModal,
    handleToggleDeleteCriteriaConfirmationModal,
  ] = useDialog();
  const [selectedCriteria, setSelectedCriteria] = useState<Criteria>();
  const [criteriaToDelete, setCriteriaToDelete] = useState<number>();
  const [orderCriteria, setOrderCriteria] = useState("alphabet");
  const [openFilterModal, toggleFilterModal] = useDialog();
  const [filterSelectedOption, setFilterSelectedOption] = useState<string>(
    "withoutCriteriaFilter"
  );
  const [chipsList, setChipsList] = useState<
    MultiValue<IdOption> | { value: number; label: string }[] | undefined
  >(undefined);
  const [bucketsList, setBucketsList] = useState<any>([]);
  const [searchedList, setSearchedList] = useState<any>([]);
  const [modalFilterList, setModalFilterList] = useState<any>([]);
  const [totalList, setTotalList] = useState<any>([]);

  useEffect(() => {
    if (type === "prorrateo") {
      setTabValue("PRORRATEO");
    } else if (type === "preprorrateo") {
      setTabValue("PREPRORRATEO");
    }
  }, [type]);

  useEffect(() => {
    if (dataGetCriteria) {
      const dataByTabValue =
        tabValue === "PRORRATEO"
          ? filterProrrateoValue(dataGetCriteria, t)
          : filterPreProrrateoValue(dataGetCriteria, t);
      setFilteredData(dataByTabValue);
      if (
        (searchValue === "" || !searchValue) &&
        filterSelectedOption === "withoutCriteriaFilter"
      ) {
        setSearchedList(dataByTabValue);
        setModalFilterList(dataByTabValue);
        setTotalList(dataByTabValue);
      } else {
        getTotalList(searchedList, modalFilterList);
      }
    }
  }, [dataGetCriteria, tabValue, url]);

  const handleAddCriteria = () => {
    setConfigMode("CREATE");
    setOpenConfigCriteria(true);
  };

  const handleFinish = () => {
    setOpenConfigCriteria(false);
    refetchCriteria();
  };

  const handleEdit = (e: any, id: number) => {
    setConfigMode("EDIT");
    setCriteria(filteredData?.find((criteria: Criteria) => criteria.id === id));
    setOpenConfigCriteria(true);
  };

  const handleCopy = (e: any, id: number) => {
    setConfigMode("COPY");
    setCriteria(filteredData?.find((criteria: Criteria) => criteria.id === id));
    setOpenConfigCriteria(true);
  };

  const handleInfo = (e: any, id: number) => {
    setSelectedCriteria(
      dataGetCriteria?.find((criteria: Criteria) => criteria.id === id)
    );
    handleToggleViewInfoModal();
  };

  //Eliminar criterio

  const onSuccessDeleteCriteria = () => {
    refetchCriteria();
  };

  const { isLoading: isLoadingDeleteCriteria, callApi: deleteCriteriaById } =
    useApi(
      `${CRITERIA_URL}?id=${criteriaToDelete}`,
      "DELETE",
      t("criteriaManagement.deleteCriteria.codes"),
      undefined,
      onSuccessDeleteCriteria
    );

  const handleDelete = () => {
    deleteCriteriaById();
    handleToggleDeleteCriteriaConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setCriteriaToDelete(id);
    handleToggleDeleteCriteriaConfirmationModal();
  };

  //Filtrar criterios

  const filterSearchData = (data: any, value: string) => {
    if (
      //TODO buscar la manera de hacer esto mejor para que sea "all" en inglés, etc
      value.toLowerCase() === "t" ||
      value.toLowerCase() === "to" ||
      value.toLowerCase() === "tod" ||
      value.toLowerCase() === "todo" ||
      value.toLowerCase() === "todos"
    ) {
      return data?.filter((criteria: Criteria) => {
        return (
          criteria.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          criteria.criterio_bucket.some((bucket: any) =>
            bucket.label?.toLowerCase().includes(value?.toLowerCase())
          ) ||
          criteria.criterio_bucket.length === 0 ||
          ""
        );
      });
    } else {
      return data?.filter((criteria: Criteria) => {
        return (
          criteria.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          criteria.criterio_bucket.some((bucket: any) =>
            bucket.label?.toLowerCase().includes(value?.toLowerCase())
          ) ||
          ""
        );
      });
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setSearchedList(filterSearchData(filteredData, value));
    } else {
      setSearchedList(filteredData);
    }
    getTotalList(filterSearchData(filteredData, value), modalFilterList);
  };

  const handleSubmitFilterModal = (filterValue, buckets) => {
    if (filterValue === "criteriaBuckets") {
      const filteredByBucket = filteredData.filter((criteria: Criteria) => {
        return criteria.buckets_usados.some((bucket: any) => {
          return buckets?.some((selectedBucket: any) => {
            return selectedBucket.value === bucket.value;
          });
        });
      });
      setModalFilterList(filteredByBucket);
      getTotalList(searchedList, filteredByBucket);
    } else if (filterValue === "activeCriteria") {
      const filterByActiveCriteria = filteredData.filter(
        (criteria: Criteria) => criteria.buckets_usados.length > 0
      );
      setModalFilterList(filterByActiveCriteria);
      getTotalList(searchedList, filterByActiveCriteria);
    } else if (filterValue === "inactiveCriteria") {
      const filterByInactiveCriteria = filteredData.filter(
        (criteria: Criteria) => criteria.buckets_usados.length === 0
      );
      setModalFilterList(filterByInactiveCriteria);
      getTotalList(searchedList, filterByInactiveCriteria);
    } else {
      setModalFilterList(filteredData);
      getTotalList(searchedList, filteredData);
    }
    if (openFilterModal) toggleFilterModal();
    setChipsList(chipsByFilter(filterValue, buckets));
  };

  const chipsByFilter = (filterValue, buckets) => {
    if (filterValue === "criteriaBuckets") {
      return buckets;
    } else if (filterValue === "activeCriteria") {
      return [{ value: 1, label: "Criterios activos" }];
    } else if (filterValue === "inactiveCriteria") {
      return [{ value: 2, label: "Criterios inactivos" }];
    } else {
      return [];
    }
  };

  const arraysIntersection = (array1, array2) => {
    return array1.filter((item1) => {
      return array2.some((item2) => item2.id === item1.id);
    });
  };

  const getTotalList = (searchedList, modalFilterList) => {
    if (searchedList.length > 0 && modalFilterList.length > 0) {
      setTotalList(arraysIntersection(searchedList, modalFilterList));
    } else {
      setTotalList([]);
    }
  };

  const handleDeleteFilter = (e, filter) => {
    if (filterSelectedOption === "criteriaBuckets") {
      const newBucketsList = bucketsList.filter(
        (bucket) => bucket.value !== filter.value
      );
      if (newBucketsList.length === 0) {
        setFilterSelectedOption("withoutCriteriaFilter");
        handleSubmitFilterModal("withoutCriteriaFilter", []);
        setBucketsList([]);
      } else {
        setBucketsList(newBucketsList);
        handleSubmitFilterModal(filterSelectedOption, newBucketsList);
      }
    } else {
      setFilterSelectedOption("withoutCriteriaFilter");
      handleSubmitFilterModal("withoutCriteriaFilter", []);
      setBucketsList([]);
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingCriteria}
        message={t(
          "criteriaManagementTexts.criteriaManagement.lookingCriteria"
        )}
      />
      <SimpleBackdrop
        open={isLoadingDeleteCriteria}
        message={t(
          "criteriaManagementTexts.criteriaManagement.deletingCriteria"
        )}
      />
      {!openConfigCriteria ? (
        <ComponentLayout
          title={t(
            "Administración de criterios de " + type === "prorrateo" ? t("tabs.proration") : t("tabs.prepro")
          )}
          icon={<ConstructionIcon />}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleSort
                firstState={"alphabet"}
                secondState={"dateTime"}
                state={orderCriteria}
                setState={setOrderCriteria}
                firstTitle={t(
                  "criteriaManagementTexts.criteriaManagement.sortAlphabet"
                )}
                secondTitle={t(
                  "criteriaManagementTexts.criteriaManagement.sortDate"
                )}
              />
              <CustomButton
                title={t("criteriaManagementTexts.criteriaManagement.filter")}
                color="blue"
                type="button"
                onClick={toggleFilterModal}
                margin={false}
              />
            </Box>
            <SearchInput
              value={searchValue}
              handleChange={(e) => handleSearch(e.target.value)}
              placeholder={t(
                "criteriaManagementTexts.criteriaManagement.searchByCriteriaBucket"
              )}
              clearIcon={true}
              handleDelete={() => handleSearch("")}
              size="small"
            />
            <ChipFilters
              filters={chipsList}
              handleDeleteFilter={handleDeleteFilter}
            />
            {totalList?.length > 0 ? (
              <BasicGrid
                data={
                  orderCriteria === "dateTime"
                    ? sortByIdDesc(totalList)
                    : sortByName(totalList)
                }
                Card={CriteriaCard}
                handleEdit={
                  userRolData &&
                  userRolData.administracion_de_criterios
                    .visualizacion_y_edicion &&
                  handleEdit
                }
                handleCopy={
                  userRolData &&
                  userRolData.administracion_de_criterios
                    .visualizacion_y_edicion &&
                  handleCopy
                }
                handleInfo={handleInfo}
                handleDelete={
                  userRolData &&
                  userRolData.administracion_de_criterios
                    .visualizacion_y_edicion &&
                  handleClickOnDelete
                }
                showInfo={tabValue === t("tabs.proration")}
                xs={4}
              />
            ) : (
              isSuccessCriteria &&
              !isLoadingCriteria && (
                <Box
                  sx={{
                    width: "95%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "700", color: "var(--text-info)" }}
                  >
                    {t(
                      "criteriaManagementTexts.criteriaManagement.noCriteriaAvailable"
                    )}
                  </Typography>
                </Box>
              )
            )}
            {isErrorCriteria && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "var(--text-error)",
                  textAlign: "center",
                }}
              >
                {t("criteriaManagementTexts.criteriaManagement.notGetCriteria")}
              </Typography>
            )}
            {userRolData &&
              (userRolData.administracion_de_criterios
                .visualizacion_y_creacion ||
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion) && (
                <AddCriteriaButton handleClick={handleAddCriteria} />
              )}
          </Stack>
        </ComponentLayout>
      ) : (
        <ConfigCriteria
          criteriaType={type === "prorrateo" ? "PRORRATEO" : "PRE_PRORRATEO"}
          handleFinish={handleFinish}
          configMode={configMode}
          criteria={criteria}
          criteriaList={dataGetCriteria}
        />
      )}
      <ViewInfoModal
        criteria={selectedCriteria}
        open={openViewInfoModal}
        handleClose={handleToggleViewInfoModal}
        title={selectedCriteria?.nombre}
        handleEdit={handleEdit}
      />
      <DeleteCriteriaConfirmationModal
        open={openDeleteCriteriaConfirmationModal}
        handleClose={handleToggleDeleteCriteriaConfirmationModal}
        handleAccept={handleDelete}
        message={t("criteriaManagementTexts.criteriaManagement.deleteCriteria")}
        title={t("dialog.confirmTitle")}
      />
      <Dialog
        open={openFilterModal}
        handleClose={toggleFilterModal}
        title={t("dialog.filter")}
        maxWidth="lg"
      >
        <FilterCriteriaModal
          filterSelectedOption={filterSelectedOption}
          setFilterSelectedOption={setFilterSelectedOption}
          bucketsList={bucketsList}
          setBucketsList={setBucketsList}
          toggleFilterModal={toggleFilterModal}
          handleApply={(filterValue, buckets) =>
            handleSubmitFilterModal(filterValue, buckets)
          }
        />
      </Dialog>
    </>
  );
};

export default CriteriaManagement;
