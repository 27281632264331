import { GET_MEASURE_URL } from "../../../../../api/axios";
import { useEffect, useRef, useState } from "react";
import Button from "../../../../buttons/Button";
import CustomizedDialogs from "../../../../dialog/Dialog";
import SimpleBackdrop from "../../../../backdrop/SimpleBackdrop";
import TextField from "@mui/material/TextField";
import useApi from "../../../../../hooks/useApi";
import useLanguage from "../../../../../context/LanguageProvider";
import { handleElementRepeatedName } from "../../../../../utils/util";
interface IEditDefaultNameModal {
  openEditNameDialog: boolean;
  handleToggleEditNameDialog: () => void;
  refetchData: () => void;
  measureToEdit: any;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  setConfigMode: (value: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  dataGetExpenses: any;
}

const EditDefaultNameModal = ({
  openEditNameDialog,
  handleToggleEditNameDialog,
  refetchData,
  measureToEdit,
  configMode,
  setConfigMode,
  dataGetExpenses,
}: IEditDefaultNameModal) => {
  const { t } = useLanguage();
  const newNameRef = useRef<HTMLInputElement>(null);
  const [newName, setNewName] = useState<string>(measureToEdit?.nombre);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setNewName(measureToEdit?.nombre);
    }, [measureToEdit]);

  const onSuccess = () => {
    refetchData();
    setConfigMode("");
  };

  const { isLoading: isLoadingPutMeasure, callApi: putMeasure } = useApi(
    GET_MEASURE_URL(measureToEdit?.id),
    "PUT",
    t("dataModel.putMeasure.codes"),
    undefined,
    onSuccess,
    undefined,
    true
  );

  const handleEditName = () => {
    handleToggleEditNameDialog();
    const completedInfo = {
      nombre: newName,
      tipo: measureToEdit?.tipo,
    };
    if (configMode === "EDIT") {
      putMeasure(GET_MEASURE_URL(measureToEdit?.id), completedInfo);
    }
  };

  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
  };

  useEffect(() => {
    handleElementRepeatedName(
      t,
      newName,
      dataGetExpenses,
      measureToEdit?.id,
      configMode,
      setMessage
    );
  }, [newName]);

  return (
    <CustomizedDialogs
      open={openEditNameDialog}
      handleClose={handleToggleEditNameDialog}
      title={t("modelParameterizationTexts.modelTable.editName")}
      maxWidth="md"
    >
      <div style={{ display: "flex", alignItems: "center", minWidth: "500px" }}>
        <TextField
          type="text"
          ref={newNameRef}
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={t(
            "dataModelText.measureManagement.configMeasure.configExpensesMeasure.newName"
          )}
          autoFocus
          size="small"
          error={message !== ""}
          helperText={message}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
        <Button
          title={t("modelParameterizationTexts.modelTable.apply")}
          color="blue-greeny"
          type="button"
          onClick={() => handleEditName()}
          disabled={!newName || message !== "" || newName.trim().toLowerCase() === measureToEdit?.nombre.trim().toLowerCase()}
        />
      </div>
      <SimpleBackdrop
        open={isLoadingPutMeasure}
        message={t("dataModel.putMeasure.loading")}
      />
    </CustomizedDialogs>
  );
};

export default EditDefaultNameModal;
