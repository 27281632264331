import { memo } from "react";
import { Stack } from "@mui/material";
import drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);
interface Props {
  results: any;
}

HighchartsMore(Highcharts);

const WaterfallChart = memo((props: Props) => {
  const { results } = props;

  drilldown(Highcharts);

  const options = {
    chart: {
      type: "waterfall",
      height: "auto",
      /* maxHeight: "200px", */
      /* width: "100%", */
      style: {
        /* width: "100%", */
        height: "auto",
        /* maxHeight: "1000px", */
      },
      zooming: {
        type: "xy",
      },
    },
    title: {
      text: results?.titulo_grafico,
      style: {
        color: "var(--text-main)",
        fontSize: "18px",
        fontWeight: "bold",
        textShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      },
    },
    xAxis: {
      type: "category",
      title: {
        text: results.nombre_eje_x,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
    },
    yAxis: {
      title: {
        text: results.nombre_eje_y,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        upColor: "var(--chart-1)",
        color: "var(--chart-3)",
        data: results.series,
        dataLabels: {
          y: 0,
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y / 1000, 0, ",") + "k";
          },
          style: {
            fontWeight: "bold",
          },
        },
        pointPadding: 0,
      },
    ],
    accessibility: {
      enabled: false,
    },
    tooltip: {
      y: 0,
      series: {
        name: "",
      },
      pointFormat: "<b>${point.y:,.2f}</b>",
    },
    exporting: {
      enabled: true, // Esto habilita el botón de exportación
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <Stack sx={{ width: "100%", height: "100%", margin: "0px !important" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
});

export default WaterfallChart;
