import { Item } from "../../../../basicGrid/BasicGrid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const DestinyValidationRowElement = ({ schemaRow }) => {    
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      width="100%"
      gap={1}
    >
      <Item sx={{ width: "50%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {schemaRow?.destino}
        </Typography>
      </Item>
      <Item sx={{ width: "50%" }}>
        <Typography color="primary" sx={{ fontSize: "14px" }}>
          {schemaRow && schemaRow.validaciones_conservar && schemaRow?.validaciones_conservar
            ?.map((v, index) => (
              <span key={index}>
                <strong>{`${v?.criterio?.label || "-"}`}</strong>
                {v?.validaciones?.length > 0 && ": "}
                {v?.validaciones?.length > 0
                  ? v?.validaciones?.map((val) => val?.label).join(", ")
                  : ""}
              </span>
            ))
            .reduce((prev, curr, idx, arr) => (
              <>
                {prev}
                {idx > 0 && ", "}
                {curr}
              </>
            )) || "-"}
        </Typography>
      </Item>
    </Stack>
  );
};

export default DestinyValidationRowElement;
