import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

interface AddCriteriaButtonProps {
  handleClick: () => void;
}

const AddConfigButton = ({ handleClick }: AddCriteriaButtonProps) => {
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        backgroundColor: "var(--bg-secondary)",
        borderRadius: "100%",
        bottom: "25px",
        boxShadow: "5px 5px 10px var(--text-info)",
        height: "58px",
        position: "fixed",
        right: "25px",
        transition: "all 0.4s",
        width: "25px",
        ":hover": {
          scale: "1.1",
          backgroundColor: "var(--bg-secondary)",
          boxShadow: "5px 5px 10px var(--text-info)",
        },
        ":active": { scale: "0.95" },
      }}
    >
      <AddIcon sx={{ fontSize: "35px", fontWeight: "bold" }} />
    </Button>
  );
};

export default AddConfigButton;