import { getBackgroundColor, isSum100 } from "../../utils";
import { grid_item_element_styles, white_shadow_box_styles } from "../../../../../styles/app-styles";
import { useEffect, useState } from "react";
import Button from "../../../../buttons/Button";
import ConfirmationModal from "../../../../dialog/ConfirmationModal";
import IterationExplicitNoMatch from "../explicit/IterationExplicitNoMatch";
import IterationExplicitZero from "../explicit/IterationExplicitZero";
import IterationImplicitNoMatch from "../implicit/IterationImplicitNoMatch";
import IterationImplicitZero from "../implicit/IterationImplicitZero";
import MessageBlock from "../../../../forms/MessageBlock";
import Stack from "@mui/material/Stack/Stack";
import useDialog from "../../../../../hooks/useDialog";
import useLanguage from "../../../../../context/LanguageProvider";
import WhiteShadowBox from "../../../../layout/WhiteShadowBox";

const NewIterationModal = ({
  iterations,
  setIterations,
  handleToggleModal,
  newSchemaRows,
  criteriaData,
  assignTypeSelectedOption,
  criteriaSelectedOption,
  columnSelectedOption,
  destinyTypeSelectedOption,
  destinitySelectOptions,
  iterationToEdit,
}) => {
  const { t } = useLanguage();
  const [newIterationSchemaRow, setNewIterationSchemaRow] = useState<any>();
  const [openConfirmEditModal, toggleConfirmEditModal] = useDialog();
  const [message, setMessage] = useState<any>();
  
  const handleApply = () => {
    if (iterationToEdit) {
      //Caso editar iteración
      const newIterations = iterations
        .map((iteration) => {
          if (iteration.iteracion === iterationToEdit.iteracion) {
            return newIterationSchemaRow;
          }
          return iteration;
        })
        .filter(
          (iteration) => iteration.iteracion <= newIterationSchemaRow.iteracion
        );
      setIterations(newIterations);
    } else {
      //caso nueva iteración
      setIterations((prev) => {
        return prev
          ? [...prev, newIterationSchemaRow]
          : [newIterationSchemaRow];
      });
    }
    handleToggleModal();
  };

  useEffect(() => {
    if (iterationToEdit) {
      setNewIterationSchemaRow(iterationToEdit);
    } else {
      setNewIterationSchemaRow({
        iteracion: iterations?.length + 1 || 1,
        tipo_preprorrateo: assignTypeSelectedOption?.value,
      });
    }
  }, []);

  const isExplicitNoMatchCompleted = () => {
    let isCompletedNewCriteria = !!newIterationSchemaRow?.sin_match?.caso;
    let isCompletedPercentages = !!newIterationSchemaRow?.sin_match?.caso;

    if (newIterationSchemaRow?.sin_match?.caso == "CRITERIO_NUEVO") {
      const allDestiniesHaveCriterias =
        (newIterationSchemaRow?.sin_match?.criterios_destinos?.every(
          (criterio_destino) => criterio_destino.criterio
        ) &&
          newIterationSchemaRow?.sin_match?.criterios_destinos?.length ===
            newSchemaRows?.length) ||
        false;

      isCompletedNewCriteria = allDestiniesHaveCriterias;
    }
    if (newIterationSchemaRow?.sin_match?.caso === "PORCENTAJE") {
      const percentagesSum100 =
        newIterationSchemaRow?.sin_match?.porcentaje &&
        newIterationSchemaRow?.sin_match?.porcentaje.length > 0
          ? isSum100(newIterationSchemaRow?.sin_match?.porcentaje)
            ? true
            : false
          : false;

      isCompletedPercentages = percentagesSum100;
    }
    return isCompletedNewCriteria && isCompletedPercentages;
  };

  const isExplicitZeroCompleted = () => {
    let isCompletedNewCriteria = !!newIterationSchemaRow?.criterio_cero?.caso;
    let isCompletedPercentages = !!newIterationSchemaRow?.criterio_cero?.caso;
    if (newIterationSchemaRow?.criterio_cero?.caso == "CRITERIO_NUEVO") {
      const allDestiniesHaveCriterias =
        newIterationSchemaRow?.criterio_cero?.criterios_destinos?.every(
          (criterio_destino) => criterio_destino.criterio
        ) &&
        newIterationSchemaRow?.criterio_cero?.criterios_destinos?.length ===
          newSchemaRows?.length;

      isCompletedNewCriteria = allDestiniesHaveCriterias;
    }
    if (newIterationSchemaRow?.criterio_cero?.caso == "PORCENTAJE") {
      const percentagesSum100 =
        newIterationSchemaRow?.criterio_cero?.porcentaje &&
        newIterationSchemaRow?.criterio_cero?.porcentaje.length > 0
          ? isSum100(newIterationSchemaRow?.criterio_cero?.porcentaje)
            ? true
            : false
          : false;
      isCompletedPercentages = percentagesSum100;
    }
    return isCompletedNewCriteria && isCompletedPercentages;
  };

  const areExplicitValidationsCompleted = () => {
    let isCompletedSinMatch = !!newIterationSchemaRow?.sin_match?.caso;
    let isCompletedCritCero = !!newIterationSchemaRow?.sin_match?.caso;
    if (newIterationSchemaRow?.sin_match?.caso == "ELIMINAR") {
      const allDestiniesHaveCriterias =
        newIterationSchemaRow?.sin_match?.criterios_destinos?.every(
          (criterio_destino) =>
            criterio_destino?.validaciones_conservar?.every(
              (val) => val?.criterio
            )
        ) &&
        newIterationSchemaRow?.sin_match?.criterios_destinos?.length ===
          newSchemaRows?.length;

      isCompletedSinMatch = allDestiniesHaveCriterias;
    }
    if (newIterationSchemaRow?.criterio_cero?.caso == "ELIMINAR") {
      const allDestiniesHaveCriterias =
        newIterationSchemaRow?.criterio_cero?.criterios_destinos?.every(
          (criterio_destino) =>
            criterio_destino?.validaciones_conservar?.every(
              (val) => val?.criterio
            )
        ) &&
        newIterationSchemaRow?.criterio_cero?.criterios_destinos?.length ===
          newSchemaRows?.length;

      isCompletedCritCero = allDestiniesHaveCriterias;
    }
    return isCompletedSinMatch && isCompletedCritCero;
  };
  const isImplicitNoMatchCompleted = () => {
    let isCompletedNewCriteria = !!newIterationSchemaRow?.sin_match?.caso;
    let isCompletedValidations = !!newIterationSchemaRow?.sin_match?.caso;
    let isCompletedPercentages = !!newIterationSchemaRow?.sin_match?.caso;
    if (newIterationSchemaRow?.sin_match?.caso == "CRITERIO_NUEVO") {
      const allDestiniesHaveCriterias =
        (newIterationSchemaRow?.sin_match?.criterio_receptor?.criterio &&
          newIterationSchemaRow?.sin_match?.criterio_receptor?.receptor) ||
        false;
      isCompletedNewCriteria = allDestiniesHaveCriterias;
    }
    if (newIterationSchemaRow?.sin_match?.caso == "ELIMINAR") {
      const allDestiniesHaveValidations =
        newIterationSchemaRow?.sin_match?.criterio_receptor?.validaciones_conservar?.every(
          (val) => val?.criterio
        ) || false;

      isCompletedValidations = allDestiniesHaveValidations;
    }
    if (newIterationSchemaRow?.sin_match?.caso === "PORCENTAJE") {
      const percentagesSum100 =
        newIterationSchemaRow?.sin_match?.porcentaje &&
        newIterationSchemaRow?.sin_match?.porcentaje.length > 0
          ? isSum100(newIterationSchemaRow?.sin_match?.porcentaje)
            ? true
            : false
          : false;

      isCompletedPercentages = percentagesSum100;
    }
    return (
      isCompletedNewCriteria && isCompletedValidations && isCompletedPercentages
    );
  };

  const isImplicitCeroCompleted = () => {
    let isCompletedNewCriteria = !!newIterationSchemaRow?.criterio_cero?.caso;
    let isCompletedValidations = !!newIterationSchemaRow?.criterio_cero?.caso;
    let isCompletedPercentages = !!newIterationSchemaRow?.criterio_cero?.caso;
    if (newIterationSchemaRow?.criterio_cero?.caso == "CRITERIO_NUEVO") {
      const allDestiniesHaveCriterias =
        (newIterationSchemaRow?.criterio_cero?.criterio_receptor?.criterio &&
          newIterationSchemaRow?.criterio_cero?.criterio_receptor?.receptor) ||
        false;
      isCompletedNewCriteria = allDestiniesHaveCriterias;
    } else if (newIterationSchemaRow?.criterio_cero?.caso == "ELIMINAR") {
      const allDestiniesHaveValidations =
        newIterationSchemaRow?.criterio_cero?.criterio_receptor?.validaciones_conservar?.every(
          (val) => val?.criterio
        ) || false;

      isCompletedValidations = allDestiniesHaveValidations;
    } else if (newIterationSchemaRow?.criterio_cero?.caso === "PORCENTAJE") {
      const percentagesSum100 =
        newIterationSchemaRow?.criterio_cero?.porcentaje &&
        newIterationSchemaRow?.criterio_cero?.porcentaje.length > 0
          ? isSum100(newIterationSchemaRow?.criterio_cero?.porcentaje)
            ? true
            : false
          : false;

      isCompletedPercentages = percentagesSum100;
    }
    return (
      isCompletedNewCriteria && isCompletedValidations && isCompletedPercentages
    );
  };

  const isIterationCompleted = () => {
    let isCompleted = false;
    if (assignTypeSelectedOption?.value === "EXPLICITA") {
      if (
        isExplicitNoMatchCompleted() &&
        isExplicitZeroCompleted() &&
        areExplicitValidationsCompleted()
      ) {
        isCompleted = true;
      }
    } else if (assignTypeSelectedOption?.value === "IMPLICITA") {
      if (isImplicitNoMatchCompleted() && isImplicitCeroCompleted()) {
        isCompleted = true;
      }
    }
    return isCompleted;
  };

  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "space-between",
        height: "100%",
        minHeight: "200px",
        gap: 2,
      }}
    >
      <MessageBlock message={message} />
      <Stack
        sx={{
          ...grid_item_element_styles(
            getBackgroundColor,
            "iteration",
            () => "100%"
          ),
          color: "white",
          minHeight: "30px",
          borderRadius: "4px",
        }}
      >
        Iteración{" "}
        {iterationToEdit
          ? iterationToEdit.iteracion
          : iterations?.length + 1 || 1}
      </Stack>

      {assignTypeSelectedOption?.value === "EXPLICITA" ? (
        <>
          <WhiteShadowBox sx={{ ...white_shadow_box_styles, margin: 0 }}>
            <IterationExplicitNoMatch
              newSchemaRows={newSchemaRows}
              newIterationSchemaRow={newIterationSchemaRow}
              setNewIterationSchemaRow={setNewIterationSchemaRow}
              criteriaData={criteriaData}
              setMessage={setMessage}
              iterations={iterations}
              destinyTypeSelectedOption={destinyTypeSelectedOption}
              iterationToEdit={iterationToEdit}
            />
          </WhiteShadowBox>
          <WhiteShadowBox sx={{ ...white_shadow_box_styles, margin: 0 }}>
            <IterationExplicitZero
              newSchemaRows={newSchemaRows}
              newIterationSchemaRow={newIterationSchemaRow}
              setNewIterationSchemaRow={setNewIterationSchemaRow}
              criteriaData={criteriaData}
              setMessage={setMessage}
              iterations={iterations}
              destinyTypeSelectedOption={destinyTypeSelectedOption}
              iterationToEdit={iterationToEdit}
            />
          </WhiteShadowBox>
        </>
      ) : (
        <>
          <WhiteShadowBox sx={{ ...white_shadow_box_styles, margin: 0 }}>
            <IterationImplicitNoMatch
              criteriaData={criteriaData}
              newSchemaRows={newSchemaRows}
              newIterationSchemaRow={newIterationSchemaRow}
              setNewIterationSchemaRow={setNewIterationSchemaRow}
              criteriaSelectedOption={criteriaSelectedOption}
              iterations={iterations}
              firstCriteria={{
                criterio: criteriaSelectedOption,
                receptor: columnSelectedOption,
              }}
              destinyTypeSelectedOption={destinyTypeSelectedOption}
              destinitySelectOptions={destinitySelectOptions}
              iterationToEdit={iterationToEdit}
            />
          </WhiteShadowBox>
          <WhiteShadowBox sx={{ ...white_shadow_box_styles, margin: 0 }}>
            <IterationImplicitZero
              criteriaData={criteriaData}
              newSchemaRows={newSchemaRows}
              newIterationSchemaRow={newIterationSchemaRow}
              setNewIterationSchemaRow={setNewIterationSchemaRow}
              criteriaSelectedOption={criteriaSelectedOption}
              iterations={iterations}
              firstCriteria={{
                criterio: criteriaSelectedOption,
                receptor: columnSelectedOption,
              }}
              destinyTypeSelectedOption={destinyTypeSelectedOption}
              destinitySelectOptions={destinitySelectOptions}
              iterationToEdit={iterationToEdit}
            />
          </WhiteShadowBox>
        </>
      )}
      <ConfirmationModal
        open={openConfirmEditModal}
        handleClose={toggleConfirmEditModal}
        handleAccept={handleApply}
        message={t(
          "¿Confirma que desea editar la iteración? Esta acción borrará las iteraciones siguientes"
        )}
        customMessage={true}
        title={t("Editar iteración")}
      />
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("periods.periodsDialogContent.cancel")}
          color="grey"
          type="button"
          onClick={handleToggleModal}
        />
        <Button
          title={t("periods.periodsDialogContent.apply")}
          color="blue-greeny"
          type="button"
          onClick={
            !iterationToEdit?.iteracion ||
            iterationToEdit?.iteracion === iterations?.length
              ? handleApply
              : toggleConfirmEditModal
          }
          disabled={!isIterationCompleted()}
        />
      </Stack>
    </Stack>
  );
};

export default NewIterationModal;
