import { Button } from "../../../buttons";
import { ConfirmationModal } from "../../../dialog";
import { GET_MEASURE_URL, MEASURE_URL } from "../../../../api/axios";
import { removeDuplicates } from "../../../periods/utils";
import { Stack, TextField } from "@mui/material";
import { useGetGastosProrrateo } from "../../../parameterization/api";
import { useState, useEffect } from "react";
import CustomSkeleton from "../../../skeleton/CustomSkeleton";
import SearchInput from "../../../forms/SearchInput";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import TreeDataCustomGroupingColumn from "../../../parameterization/stagesSettings/TreedataTable";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import { handleElementRepeatedName } from "../../../../utils/util";
interface ConfigExpensesMeasureProps {
  handleFinish: () => void;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  dataGetMeasure: any;
  setConfigMode: (value: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  dataGetExpenses: any;
  dataGetComposed: any;
  dataGetSales: any;
}

const ConfigExpensesMeasure = ({
  handleFinish,
  configMode,
  dataGetMeasure,
  setConfigMode,
  dataGetExpenses,
  dataGetComposed,
  dataGetSales,
}: ConfigExpensesMeasureProps) => {
  const { t } = useLanguage();
  const [newActionName, setNewActionName] = useState(
    configMode === "EDIT" ? dataGetMeasure.nombre : ""
  );
  const [originalSelectionModel, setOriginalSelectionModel] = useState<any[]>(
    []
  );
  const [filteredRows, setFilteredRows] = useState<any[]>();
  const [isExpanded, setIsExpanded] = useState<boolean>(
    configMode == "EDIT" || configMode == "COPY" ? true : false
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  /******************************* CARGA DE DATOS ***********************************/
  const {
    data: rows,
    isLoading: isLoadingRows,
    error: errorRows,
    refetch: refetch,
    isFetching: isFetchingRows,
  } = useGetGastosProrrateo();

  const filterRowAndBucket: any = (id_bucket: number, id_grouper?: number) => {
    if (id_grouper !== undefined) {
      return rows?.find((row: any) => {
        return (
          row.bucket_gasto_id === id_bucket && row.agrupador_id === id_grouper
        );
      });
    } else {
      return rows?.find((row: any) => {
        return row.bucket_gasto_id === id_bucket && row.hierarchy.length === 1;
      });
    }
  };

  const saveSelectedRows = () => {
    const selectedRows: any[] = [];

    dataGetMeasure.bucket_agrupador.forEach((bucket: any) => {
      bucket.agrupadores.forEach((agrupador: any) => {
        selectedRows.push(filterRowAndBucket(bucket.id, agrupador.id)?.id);
        selectedRows.push(filterRowAndBucket(bucket.id)?.id);
      });
    });
    setOriginalSelectionModel(selectedRows);
  };

  useEffect(() => {
    if ((configMode === "EDIT" || configMode === "COPY") && rows) {
      saveSelectedRows();
    }
  }, [configMode, rows]);

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  /*************************** POST DATOS ************************/

  const closeAndClean = (data: any, newCreation?: boolean) => {
    setNewActionName("");
    setOriginalSelectionModel([]);
    setIsExpanded(false);
    if (searchValue !== "") {
      setFilteredRows(rows);
    }
    setSearchValue("");
    if (newCreation === true) {
      setOpenConfirmationModal(false);
      setConfigMode("CREATE");
    } else {
      handleFinish();
    }
  };

  const { isLoading: isLoadingPostMeasure, callApi: postMeasure } = useApi(
    MEASURE_URL,
    "POST",
    t("dataModel.postNewMeasure.codes"),
    undefined,
    closeAndClean,
    undefined,
    true
  );

  const { isLoading: isLoadingPutMeasure, callApi: putMeasure } = useApi(
    GET_MEASURE_URL(dataGetMeasure?.id),
    "PUT",
    t("dataModel.putMeasure.codes"),
    undefined,
    closeAndClean,
    undefined,
    true
  );

  const handleFinishPost = (newCreation?: boolean) => {
    const completedInfo = {
      nombre: newActionName,
      tipo: "GASTOS",
      bucket_agrupador:
        originalSelectionModel &&
        rows &&
        getObjectsByIds(rows, originalSelectionModel),
    };
    if (configMode === "EDIT") {
      putMeasure(
        GET_MEASURE_URL(dataGetMeasure?.id),
        completedInfo,
        newCreation
      );
    } else {
      postMeasure(MEASURE_URL, completedInfo, newCreation);
    }
  };

  const getObjectsByIds = (data: any, idsToFind: any) => {
    const groupedData: any = {};

    for (const id of idsToFind) {
      const obj = data.find((item) => item.id === id);

      if (obj) {
        const { bucket_gasto_id, agrupador_id } = obj;
        if (!groupedData[bucket_gasto_id]) {
          groupedData[bucket_gasto_id] = [];
        }
        if (agrupador_id !== "") {
          groupedData[bucket_gasto_id].push(agrupador_id);
        }
      }
    }

    return Object.entries(groupedData).map(
      ([bucket_gasto_id, agrupadores_ids]) => ({
        bucket: parseInt(bucket_gasto_id, 10),
        agrupadores: agrupadores_ids,
      })
    );
  };

  /************************** VALIDACIÓN, NAVEGACIÓN Y LIMPIEZA ******************************/

  const handleSearch = (e: any) => {
    const searchInput = e.target.value;
    setSearchValue(searchInput);
    if (searchInput === "") {
      setFilteredRows(rows); // Restaurar los datos originales cuando el searchValue está vacío
    } else {
      const filteredRows = rows?.filter(
        (row: any) =>
          row?.agrupador?.toLowerCase().includes(searchInput.toLowerCase()) ||
          row?.hierarchy?.some((item: string) =>
            item.toLowerCase().includes(searchInput.toLowerCase())
          )
      );
      setFilteredRows(filteredRows);
    }
  };

  const findRowsChildrens = (row: any) => {
    const rows = filteredRows?.filter((item: any) => {
      return item.hierarchy[0] === row.hierarchy[0];
    });
    return rows?.map((item) => item.id);
  };

  const selectAllRowsMeasure = (string: string) => {
    if (string === "todas") {
      const allRows = rows?.map((item: any) => {
        return item.id;
      });
      allRows && setOriginalSelectionModel(allRows);
    }
    if (string === "ninguna") {
      setOriginalSelectionModel([]);
    }
  };

  const onCellClick = (e: any) => {
    if (e.field === "__check__") {
      if (e.row.hierarchy.length > 1) {
        if (
          e.row.hierarchy.length === 2 &&
          originalSelectionModel?.includes(e.row.id)
        ) {
          const currentRowId = e.row.id;
          const parentRowId = filteredRows?.filter(row => row.hierarchy.length === 1).filter(row => row.hierarchy[0] === e.row.hierarchy[0])[0].id;
          const selectedRowAndParent = [currentRowId, parentRowId];
          setOriginalSelectionModel(() => {
            return originalSelectionModel?.filter(
              (data: any) => !selectedRowAndParent.includes(data)
            );
          });
        } else {
          if (originalSelectionModel?.includes(e.row.id)) {
            // se puede usar prev en vez del estado
            setOriginalSelectionModel(
              originalSelectionModel.filter((data: any) => data != e.row.id)
            );
          } else {
            setOriginalSelectionModel((prevData) => [
              ...(prevData as any[]),
              e.row.id,
            ]);
          }
        }
      } else {
        if (originalSelectionModel?.includes(e.row.id)) {
          const originalModel = originalSelectionModel.filter(
            (data: any) => !findRowsChildrens(e.row)?.includes(data)
          );
          originalModel && setOriginalSelectionModel(originalModel);
        } else {
          setOriginalSelectionModel((prevData) =>
            removeDuplicates([
              ...(prevData as any[]),
              ...(findRowsChildrens(e.row) as any),
            ])
          );
        }
      }
    } else {
      return;
    }
  };

  const handleValidateMeasureName = () => {
    const fullList = [...dataGetExpenses, ...dataGetSales, ...dataGetComposed];
    if (newActionName.trim().toUpperCase() === "GASTOS-NO-CONTEMPLADOS") {
      setMessage(t("general.repeatedName"));
    } else {
      handleElementRepeatedName(
        t,
        newActionName,
        fullList,
        dataGetMeasure?.id,
        configMode,
        setMessage
      );
    }
  };

  useEffect(() => {
    handleValidateMeasureName();
  }, [newActionName]);

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const validateAddExpensesAction = () => {
    let validation = true;
    if (newActionName != "") {
      if (originalSelectionModel.length > 0) {
        validation = false;
      }
    }
    return validation;
  };

  const isValidData = () => {
    return !(
      message !== "" ||
      newActionName.trim() === ""
    );
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPostMeasure || isLoadingPutMeasure}
        message={
          configMode === "EDIT"
            ? t("dataModel.putMeasure.loading")
            : t("dataModel.postNewMeasure.loading")
        }
      />
      <Stack
        sx={{
          width: "100%",
          pb: "10px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          type="text"
          label={t(
            "dataModelText.measureManagement.configMeasure.configExpensesMeasure.newMeasureName"
          )}
          variant="outlined"
          fullWidth
          value={newActionName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewActionName(event.target.value);
          }}
          error={message !== ""}
          helperText={message}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <Button
            title={t(
              "dataModelText.measureManagement.configMeasure.configExpensesMeasure.cancel"
            )}
            color="light-grey"
            type="button"
            onClick={() => closeAndClean(false)}
          />
          <Button
            title={t(
              "dataModelText.measureManagement.configMeasure.configExpensesMeasure.accept"
            )}
            color="blue-greeny"
            type="submit"
            onClick={handleConfirmationModal}
            disabled={!isValidData () || validateAddExpensesAction()}
          />
        </Stack>
      </Stack>
      <Stack width="100%">
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder={t(
            "dataModelText.measureManagement.configMeasure.configExpensesMeasure.searchByBucketGrouper"
          )}
        />
      </Stack>
      {isLoadingRows || isFetchingRows ? (
        <CustomSkeleton height={5} />
      ) : (
        <Stack
          sx={{
            pb: "20px",
            width: "100%",
          }}
        >
          <TreeDataCustomGroupingColumn
            selectionModel={[]}
            setSelectionModel={() => undefined}
            rows={filteredRows}
            errorPreprorrationExpenses={errorRows}
            refetch={refetch}
            isExpanded={isExpanded}
            handleExpand={handleExpand}
            addActions={true}
            onCellClick={onCellClick}
            originalSelectionModel={originalSelectionModel}
            selectAllRowsMeasure={selectAllRowsMeasure}
          />
        </Stack>
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleConfirmationModal}
        handleAccept={() => handleFinishPost(false)}
        message={
          configMode === "EDIT"
            ? t(
                "dataModelText.measureManagement.configMeasure.configExpensesMeasure.editMeasure"
              ).toLowerCase()
            : t(
                "dataModelText.measureManagement.configMeasure.configExpensesMeasure.newMeasure"
              ).toLowerCase()
        }
        title={
          configMode === "EDIT"
            ? t(
                "dataModelText.measureManagement.configMeasure.configExpensesMeasure.editMeasure"
              )
            : t(
                "dataModelText.measureManagement.configMeasure.configExpensesMeasure.newMeasure"
              )
        }
      >
        <Button
          title={"Aceptar y crear otra medida"}
          color="outlined"
          type="button"
          onClick={() => handleFinishPost(true)}
          width={320}
          margin={false}
        />
      </ConfirmationModal>
    </>
  );
};

export default ConfigExpensesMeasure;
