import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../context/LanguageProvider";
import { Item } from "../basicGrid/BasicGrid";
import Delete from "../icons/Delete";

const SchemaPeriodRow = ({ schemaRow, handleDeleteSchema, index, dataPeriods }) => {
  const { t } = useLanguage();

  const transformPeriod = () => {   
    const periods = schemaRow?.periodos.map((period) => {      
      const periodData = dataPeriods.find((data) => data.id === period);     
      return periodData?.fecha;
    });    
    return periods;
  }   
  
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      width="100%"
      gap={1}
    >
      <Item sx={{ width: "50%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {transformPeriod().map((period) => period).join(", ")}
        </Typography>
      </Item>
      <Item sx={{ width: "50%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {schemaRow?.esquema?.label}
        </Typography>
        </Item>
        <Stack width={"50px"} direction={"row"} justifyContent={"center"}>
          <Delete
            tooltipPlacement="bottom"
            onClick={() => handleDeleteSchema(index)}
          />
        </Stack>
        
    </Stack>
  );
};

export default SchemaPeriodRow;
