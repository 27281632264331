import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { convertUnitAllNumber } from "../marketBasket/AnalysisView/utils";
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);
interface LinesChartProps {
  results: any;
  unit?: string;
}

function parseDate(mmYYYY) {
  const [month, year] = mmYYYY.split("-").map(Number);
  return new Date(year, month - 1);
}

const LinesChart = ({ results, unit }: LinesChartProps) => {
  const options = {
    chart: {
      scrollablePlotArea: {
        minWidth: 700,
        width: "100%",
      },
      zooming: {
        type: "xy",
      },
    },
    colors: [
      "var(--chart-1)",
      "var(--chart-2)",
      "var(--chart-3)",
      "var(--chart-4)",
      "var(--chart-5)",
      "var(--chart-6)",
      "var(--chart-7)",
      "var(--chart-8)",
      "var(--chart-9)",
      "var(--chart-10)",
    ],
    accessibility: {
      enabled: false,
    },
    series: results?.data
      ?.sort((a, b) => {
        const dateA: any =
          a.data_serie.length > 0
            ? parseDate(a.data_serie[0].valor_eje_x)
            : new Date();
        const dateB: any =
          b.data_serie.length > 0
            ? parseDate(b.data_serie[0].valor_eje_x)
            : new Date();

        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          return dateA - dateB;
        }

        return 0; // Si no Date no es válido, mantiene el orden original
      })
      ?.map((serie: any) => {
        return {
          name: serie?.nombre_serie,
          lineWidth: 4,
          /*   marker: {
          radius: 4,
        }, */
          data: serie?.data_serie?.map((dato: any) => {
            return {
              y: dato.valor_eje_y,
              name: dato.valor_eje_x,
            };
          }),
        };
      }),
    title: false /* {
      text: results.titulo_grafico,
    } */,
    /* subtitle: {
      text: "Porcentaje",
    }, */
    xAxis: {
      type: "category",
      title: {
        text: results?.nombre_eje_x,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
    },
    yAxis: [
      {
        title: {
          text: results?.nombre_eje_y,
          style: {
            fontWeight: "900",
            fontSize: "16px",
            textTransform: "uppercase",
          },
        },
        labels: {
          align: "left",
          x: 3,
          y: 16,
          format: "{value:.,0f}",
        },
        showFirstLabel: false,
      },
    ],

    legend: results?.data?.some((serie: any) => serie.nombre_serie)
      ? {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        }
      : false,
    tooltip: {
      shared: true,
      crosshairs: true,
      y: 1,
      points: [{ key: "", series: { name: "" }, y: 0 }],

      useHTML: false,
      /* formatter: function () {
        return `<b>${this.points[0].key}: ${this.y}</b>`;
      }, */
      formatter: function () {
        let tooltip = `<b>${this.points[0].key}:</b>`;

        this.points.forEach((point) => {
          tooltip += `<br>${point.series.name}: <b> ${convertUnitAllNumber(
            unit ? unit : "unidades",
            point.y
          )} </b>`; // Redondear y eliminar decimales
        });

        return tooltip;
      },
    },
    plotOptions: {
      className: "popup-on-click",
      marker: {
        lineWidth: 1,
      },
    },
    exporting: {
      enabled: true, // Esto habilita el botón de exportación
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LinesChart;
