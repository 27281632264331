import { Stack, Typography } from "@mui/material";
import useLanguage from "../../../../context/LanguageProvider";
import Dialog from "../../../dialog/Dialog";
import Button from "../../../buttons/Button";

interface NotAllowedToDeleteFileModalProps {
  open: boolean;
  handleClose: () => void;
  fileName: string | undefined;
}

const NotAllowedToDeleteFileModal = ({
  open,
  handleClose,
  fileName,
}: NotAllowedToDeleteFileModalProps) => {
  const { t } = useLanguage();
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title={t("dataManagementTexts.notAllowedToDeleteFileModal.cannotAddName")}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "var(--text-main)",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          {`${t(
            "dataManagementTexts.notAllowedToDeleteFileModal.notPossibleDelete_1"
          )} ${fileName} ${t("companyParam.textNotAllowed")}`}
        </Typography>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title={t("dataManagementTexts.notAllowedToDeleteFileModal.accept")}
            type="button"
            onClick={handleClose}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default NotAllowedToDeleteFileModal;
