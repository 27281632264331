import { stepper_buttons_container_styles2 } from "../../../styles/app-styles";
import { useCompanyParamProvider } from "../../../context/CompanyParamProvider";
import Button from "../../buttons/Button";
import CustomAccordion from "../../accordion/CustomAccordion";
import Edit from "../../icons/Edit";
import ExchangeRateCard from "./ExchangeRateCard";
import ExpensesCard from "./ExpensesCard";
import FileDescriptionCard from "./FileDescriptionCard";
import RelationshipsCard from "./RelationshipsCard";
import SalesCard from "./SalesCard";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";

const SummaryView = ({ setIsEditOpen }) => {
  const { t } = useLanguage();
  const {
    dataExchangeParams,
    dataGetExpensesParams,
    dataGetSalesParams,
    dataGetOtherFilesParams,
    dataGetRelationshipsParams,
    isLoadingGetExpensesParams,
    isLoadingGetSalesParams,
    isLoadingOtherFilesParams,
    isLoadingRelationshipsParams,
    setStepToEdit,
  } = useCompanyParamProvider();
  const { userRolData }: any = useInfoContext();

  const handleEdit = (step: number) => {
    setStepToEdit(step);
    setIsEditOpen(true);
  };

  const stepsElements = [
    {
      ...dataExchangeParams,
      id: 0,
      title: "Tipo de cambio",
      card: ExchangeRateCard,
    },
    { ...dataGetExpensesParams, id: 1, title: "GASTOS", card: ExpensesCard },
    { ...dataGetSalesParams, title: "VENTAS", id: 2, card: SalesCard }, 
    {
      data: dataGetOtherFilesParams,
      id: 3,
      card: FileDescriptionCard,
      title: "Archivos y columnas",
    },
    {
      data: dataGetRelationshipsParams,
      id: 4,
      card: RelationshipsCard,
      title: "Relaciones",
    }
  ];

  const isData =
    dataExchangeParams &&
    dataGetExpensesParams &&
    dataGetSalesParams &&
    dataGetOtherFilesParams &&
    dataGetRelationshipsParams

  const isStepAbleToEdit = (step) => {
    const expensesAndSalesCompleted =
      dataGetExpensesParams?.completo && dataGetSalesParams?.completo;
    if (step.id === 0 || step.id === 1 || step.id === 2 || step.id === 4) {
      return true;
    } else {
      return expensesAndSalesCompleted;
    }
  };

  return (
    <Stack direction={"column"} width="100%">
      <SimpleBackdrop
        open={
          isLoadingGetExpensesParams ||
          isLoadingGetSalesParams ||
          isLoadingOtherFilesParams ||
          isLoadingRelationshipsParams 
        }
        message={`${t("general.loading")}`}
      />
      {isData && (
        <>
          <Stack gap={1.5}>
            {stepsElements.map((step) => {
              return (
                <CustomAccordion
                  key={step.id}
                  title={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span style={{ width: "100%", paddingRight: "10px" }}>
                        {step.title}
                      </span>
                      <Edit
                        onClick={() =>
                          isStepAbleToEdit(step) ? handleEdit(step.id) : <></>
                        }
                        fill={
                          isStepAbleToEdit(step)
                            ? "var(--icon-info) !important"
                            : "var(--bg-disabled) !important"
                        }
                      />             
                    </span>
                  }
                  backgroundColor="var(--cream)"
                  summaryColor={"var(--text-main)"}
                  arrowColor="var(--icon-main)"
                >
                  {<step.card item={step} showInfo={true} />}
                </CustomAccordion>
              );
            })}
          </Stack>

          <Stack sx={{ ...stepper_buttons_container_styles2, mt: "10px" }}>
            {userRolData && (
              <Button
                title="Editar"
                color="blue-greeny"
                type="button"
                onClick={() => handleEdit(0)}
                disabled={
                  !userRolData?.parametrizacion_de_la_empresa
                    .visualizacion_y_edicion
                }
              />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default SummaryView;
