export const ptTranslations = {
  administratorPanel: {
    sendCode: {
      codes: {
        200: "O código foi enviado com sucesso",
        400: "Ocorreu um erro e não foi possível enviar o código",
      },
    },
    postCode: {
      codes: {
        200: "A senha foi atualizada com sucesso",
        400: "O código está incorreto",
      },
    },
    companies: {
      createCompany: {
        codes: {
          200: "A empresa foi criada com sucesso",
          201: "A empresa foi criada com sucesso",
          400: "Ocorreu um erro e não foi possível criar a empresa",
        },
      },
      editCompany: {
        codes: {
          200: "A empresa foi editada com sucesso",
          400: "Ocorreu um erro e não foi possível editar a empresa",
        },
      },
      loadingCompanies: "Carregando empresas...",
      searchCompanyPlaceholder: "Pesquisa pelo nome da empresa",
      activeCompanies: "Empresas ativas",
      activeCompany: "Empresa ativa",
      inactiveCompanies: "Empresas inativas",
      noActiveCompanies: "Não há empresas ativas",
      noInactiveCompanies: "Não há empresas inativas",
      switchLabelActive: "Ativo",
      switchLabelInactive: "Inativo",
      duplicatedCompanyName: "Já existe uma empresa com esse nome",
      companyNameFormat:
        "O nome só pode conter números, letras e sublinhados (_)",
      newCompany: "Cadastro de nova empresa",
      editCompanyLabel: "Editar empresa",
      createCompanyLabel: "Criar empresa",
      creatingCompany: "Criando empresa...",
      updatingCompany: "Atualizando empresa...",
      copyingCompany: "Copiando empresa...",
      companyInformation: "Informações da empresa",
      name: "Nome",
      visualName: "Nome visual",
      editCompanyAction: "editar a empresa",
      createCompanyAction: "criar a empresa",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
    },
    users: {
      createUser: {
        codes: {
          200: "O usuário foi criado com sucesso",
          201: "O usuário foi criado com sucesso",
          400: "Ocorreu um erro e não foi possível criar o usuário",
        },
      },
      editUser: {
        codes: {
          200: "O usuário foi editado com sucesso",
          400: "Ocorreu um erro e não foi possível editar o usuário",
        },
      },
      editPassword: {
        codes: {
          200: "A senha foi atualizada com sucesso",
          400: "Ocorreu um erro e não foi possível atualizar a senha",
        },
      },
      incorrectFormat: "O formato não está correto",
      incorrectPasswordFormat: "O formato da senha não está correto",
      duplicatedEmail: "Já existe um usuário com este e-mail",
      newPassword: "Nova senha",
      repeatPassword: "Repetir senha",
      notMatchingPasswords: "As senhas não coincidem",
      passwordFormatLabel:
        "*A senha deve ter no mínimo 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula e pelo menos um número ou caractere especial.",
      authCode: "Código de autenticação",
      authCodeSentToEmail:
        "Enviamos um código de autenticação para o seu e-mail para validar sua identidade.",
      incorrectAuthCode: "O código de autenticação não está correto",
      rol: "Função",
      active: "Ativo",
      inactive: "Inativo",
      notPossibleToDisableUserLabel:
        "Não é possível desativar o seu próprio usuário",
      notPossibleToDisableUserError:
        "Não é possível desativar o seu próprio usuário. Somente outro usuário administrador pode realizar esta ação.",
      accept: "Aceitar",
      administrator: "Administrador",
      consultant: "Consultor",
      client: "Cliente",
      editUserLabel: "Editar usuário",
      newUserLabel: "Cadastrar novo usuário",
      userInformation: "Informações do usuário",
      optionalName: "Nome (opcional)",
      optionalLastName: "Sobrenome (opcional)",
      name: "Nome",
      lastName: "Sobrenome",
      email: "E-mail",
      generatePassword: "Gerar senha",
      companiesLabel: "Empresas",
      userEnabledCompanies: "Empresas habilitadas para o usuário",
      companyEnabledUsers: "Usuários habilitados para a empresa",
      multipleOptions: "Escolha uma ou mais opções...",
      assignedCompany: "Empresa atribuída",
      chooseCompanyLabel: "Escolha a empresa selecionada...",
      enableUser: "Habilitar usuário",
      activeUser: "Usuário ativo",
      editPasswordLabel: "Editar senha",
      editUserAction: "editar o usuário",
      createUserAction: "cadastrar o usuário",
      createUserLabel: "Cadastrar usuário",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      errorLoadingUsers:
        "Ocorreu um erro ao carregar os usuários. Por favor, tente novamente mais tarde.",
      loadingUsers: "Carregando usuários...",
      searchUserLabel: "Pesquisa por nome ou e-mail do usuário",
      activeUsers: "Usuários ativos",
      inactiveUsers: "Usuários inativos",
      noActiveUsers: "Não há usuários ativos",
      noInactiveUsers: "Não há usuários inativos",
    },
    roles: {
      createRole: {
        codes: {
          200: "O rol foi criado com sucesso",
          201: "O rol foi criado com sucesso",
          400: "Ocorreu um erro e não foi possível criar o rol",
        },
      },
      editRole: {
        codes: {
          200: "O rol foi editado com sucesso",
          400: "Ocorreu um erro e não foi possível editar o rol",
        },
      },
      deleteRole: {
        codes: {
          200: "O rol foi excluído com sucesso",
          400: "Ocorreu um erro e não foi possível excluir o rol",
        },
        loading: "Excluindo rol...",
      },
      searchRolePlaceholder: "Pesquisa pelo nome do rol",
      activeRoles: "Rols ativos",
      activeRole: "Rol ativo",
      inactiveRoles: "Rols inativos",
      noActiveRoles: "Não há roles ativos",
      noInactiveRoles: "Não há roles inativos",
      duplicatedRoleName: "Já existe um rol com este nome",
      roleNameFormat: "O nome só pode conter números, letras e sublinhados (_)",
      newRole: "Cadastro de novo rol",
      editRoleLabel: "Editar rol",
      createRoleLabel: "Criar rol",
      creatingRole: "Criando rol...",
      updatingRole: "Atualizando rol...",
      roleInformation: "Informações do rol",
      name: "Nome",
      placeholderName: "Nome do rol...",
      editRoleAction: "editar o rol",
      createRoleAction: "criar o rol",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      deleteRol: "excluir o rol",
    },
    usersLabel: "USUÁRIOS",
    companiesLabel: "EMPRESAS",
    rolesLabel: "FUNÇÕES",
    administratorPanel: "Painel de administrador",
  },
  appBarComponent: {
    adminDashboard: "Painel de administrador",
  },
  avatar: {
    logoutLoading: "Encerrando sessão...",
    logout: "Encerrar sessão",
    changePassword: "Alterar senha",
  },
  basicGrid: {
    noLinesLabel: "Ainda não adicionou linhas",
    column: "Coluna",
    function: "Função",
    name: "Nome",
  },
  buttons: {
    speedDialTooltipOpen: {
      edit: "Editar",
      duplicate: "Duplicar",
      seeMore: "Ver mais",
      delete: "Excluir",
      result: "Resultados",
    },
  },
  calculation: {
    getCompletedStages: {
      loading: "Obtendo etapas...",
      error: "Ocorreu um erro e não foi possível carregar as etapas.",
    },
  },
  charts: {
    allCharts: {
      groupsError:
        "Ocorreu um erro e não foi possível carregar os grupos. Por favor, tente novamente mais tarde.",
      chartsLabel: "Gráficos",
      periodsLabel: "Períodos",
      downloadingGroups: "Baixando grupos...",
      downloadingCharts: "Baixando gráficos...",
      groupsDownloadingError: "Ocorreu um erro ao baixar os grupos.",
      chartsDownloadingError: "Ocorreu um erro ao baixar os gráficos.",
      noActiveChartsError: "Ainda não existem gráficos criados ou ativos.",
    },
    barInfiniteDrilldownChart: {
      originalExpensePercent: "Percentual em relação à monto original",
    },
  },
  chartsManagement: {
    addChart: {
      codes: {
        201: "O gráfico foi criado com sucesso",
        400: "Ocorreu um erro e não foi possível criar o gráfico",
      },
    },
    editChart: {
      codes: {
        200: "O gráfico foi atualizado com sucesso",
        400: "Ocorreu um erro e não foi possível editar o gráfico",
      },
    },
    deleteChart: {
      codes: {
        200: "O gráfico foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o gráfico",
      },
    },
    putState: {
      codes: {
        200: "O estado foi alterado com sucesso",
        201: "O estado foi alterado com sucesso",
        400: "Ocorreu um erro e não foi possível alterar o estado",
      },
    },
    getGroup: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
    },
  },
  codeVerification: {
    twoStepsVerification: "Verificação de 2 passos",
    completeWithTheCodeSentToEmail:
      "Por favor, complete com o código enviado para o seu e-mail. Não compartilhe este código com ninguém.",
    codeSentToEmail: "O código foi enviado para o seu endereço de e-mail.",
    cancel: "Cancelar",
    enter: "Inserir",
  },
  company: {
    getCompanyError: "Não foi possível carregar a empresa",
    getCompaniesError: "Não foi possível carregar as empresas",
    loadingCompanies: "Carregando empresas...",
    selectCompany: "Selecione a sua empresa",
    chooseCompany: "Escolher empresa",
    userCompanyError: "Não foi possível obter a empresa para este usuário",
    companiesError: "Não foi possível obter as empresas",
    changeCompanyError:
      "Ocorreu um erro ao trocar de empresa. Por favor, tente novamente.",
    closingSession: "Encerrando sessão...",
    cancel: "Cancelar",
    start: "Iniciar",
    codes: {
      200: "A empresa foi alterada com sucesso",
      400: "Ocorreu um erro e não foi possível alterar a empresa",
      404: "Ocorreu um erro e não foi possível alterar a empresa",
    },
  },
  columns: {
    action: "ação",
    apportionment_criteria: "critério_rateio",
    bucket: "bucket",
    cause_error: "causa_erro",
    column: "Coluna",
    criteria: "Critério",
    date: "data",
    destination_receiver: "receptor_destino",
    destination: "destino",
    expenses_transferred: "despesas_transferidas",
    file: "arquivo",
    iteration: "iteração",
    lastPeriod: "ultimo_periodo",
    mount: "montante",
    period: "período",
    previous_destination: "destino_anterior",
    receiver_criteria: "receptor_critério",
    referenceName: "nome_referência",
    stage: "etapa",
    stages: "etapas",
    state: "estado",
    values: "Valores",
  },
  companyParam: {
    fileNameToUse: "Nome do arquivo a utilizar",
    stepsTitles: {
      step0: "Taxa de câmbio",
      step1: "Parametrização do arquivo de DESPESAS",
      step2: "Parametrização do arquivo de VENDAS",
      step3: "Parametrização do P&L",
      step4: "Parametrização de outros arquivos",
      step5: "Tipagem de colunas",
      step6: "Relações entre arquivos",
      step7: "Receptores de despesas",
    },
    foreignCurrencyExchangeRate: "Taxa de câmbio de moeda estrangeira",
    help1:
      "A taxa de câmbio é expressa no valor da moeda estrangeira por unidade da moeda local.",
    help2: "O fluxo sugerido para o carregamento de dados é:",
    help3: "Selecionar país e moeda local.",
    help4: "Selecionar todas as moedas estrangeiras a serem utilizadas.",
    help5:
      "Adicionar períodos (se os arquivos já estiverem carregados, esses períodos aparecerão automaticamente, mas também é possível adicionar mais).",
    help6:
      "Adicionar valores de taxa de câmbio manualmente clicando duas vezes em uma célula na tabela ou carregando um arquivo com os valores.",
    help7:
      "Para carregar um arquivo, você deve baixar o modelo, preenchê-lo e enviá-lo no mesmo formato. Do Excel, você pode adicionar mais moedas desde que o código da moeda corresponda a um existente (não recomendado). Use apenas formato numérico.",
    help8:
      "Tenha em mente que ao carregar um arquivo, os valores modificados após o download do modelo serão substituídos, portanto, é recomendável deixar as modificações manuais por último.",
    addCurrencies: "Adicionar moedas...",
    addValuesFromFile: "Adicionar valores do arquivo",
    downloadTemplateDescription:
      "Para carregar o arquivo com as taxas de câmbio, é necessário respeitar o formato de períodos e nomes de moedas. Clique no botão abaixo para baixar um modelo e preenchê-lo com os dados correspondentes. Em seguida, faça o upload do arquivo completo.",
    fileOrFileSlice: "Arquivo ou fatia de arquivo",
    dragFile: "Arraste o arquivo aqui ou clique para selecionar.",
    fileToUpload: "Arquivo a enviar (Clique aceitar para confirmar)",
    uploadFile: "Enviar arquivo",
    fileUpload: "Envio de arquivo",
    fillFromExternalSource: "Preencher de fonte externa",
    confirmFillValues:
      "Confirma que deseja preencher os valores a partir de uma fonte externa? Isso pode fazer com que valores atualizados manualmente sejam perdidos.",
    downloadTemplate: "Baixar modelo",
    templateDownload: "Baixar modelo",
    noCurrencies: "Nenhuma moeda selecionada.",
    deleteFile: "Excluir arquivo",
    columnName: "Nome da coluna",
    dataType: "Tipo de dado",
    description: "Descrição",
    fileDescription: "Descrição do arquivo",
    textNotAllowed:
      "porque existem arquivos carregados com este nome. Para poder eliminá-lo, primeiro é necessário eliminar os arquivos carregados com este nome.",
    easyNextSteps: "Isso facilitará os próximos passos.",
    identifyKeyColumns: "Identificar colunas chave.",
    abmPnl: "Linhas do P&L (arraste e solte para reordenar)",
    pnlStructure: "Estrutura do P&L",
    abmBuckets: "Buckets",
    transactional: "Transacional",
    descriptive: "Descritiva",
    maxLevelDetail: "Nível máximo de detalhe",
    existentColumn: "Coluna existente",
    multipleColumns: "Múltiplas colunas",
    addFileAndDescription: "Adicionar arquivo e descrição",
    otherFilesRows: {
      automaticImport: "Importar automaticamente",
    },
    customerMaster: "Cadastro de Clientes",
    routeMaster: "Cadastro de Rotas",
    ProductSKUMaster: "Cadastro de Produtos/SKU",
    assetAssignment: "Atribuição de Ativos",
    visitLog: "Registro de Visitas",
    replaceNoNumeric: "Substituir não numéricos por",
    complete: "Completo",
    incomplete: "Incompleto",
    columnTyping: "Tipagem de colunas (Deve haver um arquivo carregado)",
    existentCombination: "Combinação existente",
    relationshipName: "Nome da relação",
    file: "Arquivo",
    chooseAnOption: "Escolha uma opção",
    column: "Coluna",
    deleteRelationship: "Excluir relação",
    newRelationship: "Nova relação",
    expenseTargetName: "Nome do receptor de despesas",
    deleteExpenseTarget: "Excluir receptor de despesas",
    newTarget: "Novo receptor",
    required: "Obrigatório",
    allowNullValues: "Permitir valores nulos",
    onlyUniqueValues: "Somente valores únicos",
    average: "Média",
    value: "Valor...",
    selectAll: "Selecionar tudo",
    assignToMultiple: "Atribuir a múltiplos",
    elementType: "Tipo de elemento",
    newExpense: "Nova despesa",
    incomeType: "Tipo de renda",
    expenseType: "Tipo de despesa",
    bucketLevel: "Nível vinculado ao BUCKET",
    deleteLevels: "Excluir níveis",
    deleteLevel: "Excluir nível",
    confirmDeleteLevels:
      "Confirma que deseja modificar o nível do BUCKET? Isso excluirá todos os níveis a partir do nível ",
    filterByLevel: "Filtrar por nível",
    addLevel: "Adicionar nível",
    editName: "Editar nome",
    minimunNumberLevels: "O número mínimo de níveis é 3.",
    confirmCreateLevel: "Confirma que deseja criar um novo nível?",
    confirmEditLevelName: "Confirma que deseja editar o nome do nível?",
    writeNewLevelName: "Digite o nome para o novo nível...",
    writeNewName: "Digite o novo nome...",
    deleteThisLevel: "excluir este nível",
    differentLevelError:
      "O elemento não pode ser movido para um nível diferente.",
    differentTypeError:
      "O elemento não pode ser movido para um tipo de elemento diferente.",
    differentFileError:
      "O elemento não pode ser movido para outro com um arquivo diferente.",
    addElement: "Adicionar elemento",
    addTable: "Adicionar tabela",
    editTable: "Editar tabela",
    addSubelement: "Adicionar subelemento",
    deleteThisElement: "excluir este elemento",
    deleteElement: "Excluir elemento",
    createPnlElement: "Criar elemento do P&L",
    confirmEditName: "Confirma que deseja editar o nome do elemento?",
    postParam: {
      codes: {
        200: "A parametrização foi salva com sucesso",
        201: "A parametrização foi salva com sucesso",
        400: "Ocorreu um erro e não foi possível salvar a parametrização",
      },
      loading: "Salvando parametrização...",
    },
    getParams: {
      codes: {
        200: "A parametrização foi obtida com sucesso",
        400: "Ocorreu um erro e não foi possível obter a parametrização",
      },
      error: "Ocorreu um erro e não foi possível obter a parametrização",
    },
    postParamFile: {
      codes: {
        200: "O arquivo foi enviado com sucesso",
        201: "O arquivo foi enviado com sucesso",
        400: "Ocorreu um erro e não foi possível enviar o arquivo",
      },
      loading: "Enviando arquivo...",
    },
  },
  configCriteria: {
    addTargetModal: {
      repeatedTargetNameError: "Já existe um alvo com esse nome",
      emptyFieldsError: "Os campos não podem ficar vazios",
      succesCreatedTarget: "O receptor de despesa foi criado com sucesso",
      errorCreatedTarget:
        "Ocorreu um erro ao criar o receptor. Tente novamente.",
      loadingPostTarget: "Criando receptor de despesa...",
      newTarget: "Novo receptor",
      cancel: "Cancelar",
      accept: "Aceitar",
      name: "Nome",
      target: "Receptor de despesa",
      newTargetName: "Nome do novo receptor",
      targetPlaceholder: "Nome da coluna do arquivo (ex: COD_RUTA)",
      validatePreviousDestinity: "Validar destino anterior",
    },
    configCriteriaComponent: {
      criteriaAdmin: "Administração do critério",
      nonAppliedFilters:
        "Existem filtros não adicionados. Confirma que deseja criar o critério mesmo assim?",
      nonAppliedFilterWarning:
        "Existem filtros não adicionados. Deseja continuar mesmo assim?",
      previous: "Anterior",
      resetSteps: "Redefinir todas as etapas do critério",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
      editCriteria: "editar o critério",
      createCriteria: "criar o critério",
      createAndAssignCriteria:
        "Você confirma que deseja copiar o critério e atribuí-lo à linha atual?",
      editCriteriaTitle: "Editar critério",
      createCriteriaTitle: "Criar critério",
      noFilters: "Não há filtros",
      goBack: "Voltar",
      continue: "Continuar",
      incomingConditionOptions: ["e", "ou"],
      incomingFilterTypeOptionsCriteria: [
        "INCLUIR",
        "EXCLUIR",
        "MENOR A",
        "MENOR O IGUAL A",
        "IGUAL A",
        "MAYOR A",
        "MAYOR O IGUAL A",
        "DIFERENTE A",
      ],
      incomingFilterTypeOptions: ["INCLUIR", "EXCLUIR"],
      incomingValuesOptions: [
        { valor: "CROSSDOCKS" },
        { valor: "PLANTAS" },
        { valor: "T1" },
      ],
      steps: [
        "Parametrização inicial dos critérios",
        "Filtros do arquivo",
        "Validação do arquivo GASTOS com arquivo",
        "Filtros do arquivo com informações externas",
      ],
      filterMessages: {
        emptyError:
          "Todos os campos devem ser preenchidos para adicionar um filtro.",
        duplicatedError: "Já existem filtros com esses critérios de validação.",
        conditionTooltipInfo:
          "Só é possível aplicar uma das opções para todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
        duplicatedCriteriaNameError: "Já existe um critério com esse nome.",
      },
      tooltips: {
        conditionTooltip:
          "Só é possível aplicar uma das opções para todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
      },
    },
    filterRow: {
      filterWarning:
        "Só é possível aplicar uma das opções para todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
    },
    step1: {
      apportionment: "RATEIO",
      description: "Descrição",
      criteriaName: "Nome do critério",
      applyIn: "Aplicar em",
      bucket: "Bucket",
      all: "Todos",
      expenseTargets: "Receptores de despesas",
      calcParameterization: "Parametrização de cálculo",
      file: "Arquivo",
      selectFile: "Selecionar arquivo...",
      column: "Coluna",
      selectColumn: "Selecionar coluna...",
      function: "Função",
      selectFunction: "Selecionar função...",
      countFunction: "CONTAR",
      countUniqueFunction: "CONTAR.ÚNICO",
      sumUniqueFunction: "SUMA.UNICO",
      sumProductFunction: "SOMA.PRODUTO",
      sumProductFunctionLabel: "Soma de produtos",
      sumUniqueFunctionLabel: "Soma única",
      criteriaUniqueColumn: "Coluna de critério único",
      notModifyValues: "Não modificar valores negativos",
      convertNegativesToPositives: "Converter valores negativos em positivos",
      convertNegativesToZero: "Transformar valores negativos em zero",
      columnToMultiply: "Coluna a multiplicar",
      selectOptions: "Selecionar uma ou mais opções...",
      changeFileWarning:
        "Confirma que deseja editar o arquivo do critério? Esta ação redefinirá as etapas 2, 3 e 4 do critério, se forem utilizadas.",
      editFileLabel: "Editar arquivo do critério",
      targetExpenses: "Receptores de Despesas",
    },
    step2: {
      repeatedFilterError:
        "Já existe um filtro para esta coluna. Caso o adicione, o filtro anterior será substituído.",
      include: "INCLUIR",
      exclude: "EXCLUIR",
      previousFilter: "Filtros anteriores",
      in: " em ",
      condition: "Condição",
      filterType: "Tipo de filtro",
      type: "Tipo...",
      values: "Valores",
      value: "Valor",
      valuePlaceholder: "Insira um valor numérico",
      noFiltersWarning: "Ainda não foram adicionados filtros",
      conditionTooltip:
        "Apenas uma das opções pode ser aplicada a todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
    },
    step3: {
      expensesColumn: "Coluna de despesas",
      noValidationsWarning: "Ainda não foram adicionadas validações",
    },
    step4: {
      crossoverValues: "Cruzamento de Valores",
      validationFile: "Arquivo de Validação",
      columnValidationFile: "Coluna do Arquivo de Validação",
      columnFile: "Coluna do Arquivo",
    },
    virtualSelect: {
      loading: "Carregando...",
      noAvailableOptions: "Não há opções disponíveis",
      selectValues: "Selecione um ou mais valores...",
      selectOneValue: "Selecione um valor...",
      registeredRoutesSelectValues: "Selecione um valor...",
    },
    getCriteriaBucket: {
      error: "Ocorreu um erro e não foi possível baixar os buckets do critério",
    },
    getCriteriaTarget: {
      error:
        "Ocorreu um erro e não foi possível baixar os receptores do critério.",
    },
    getCriteriaFileColumn: {
      error:
        "Ocorreu um erro e não foi possível baixar os arquivos do critério.",
    },
    getCriteriaFunctions: {
      error:
        "Ocorreu um erro e não foi possível baixar as funções do critério.",
    },
    getExpensesColumn: {
      error:
        "Ocorreu um erro e não foi possível baixar as despesas do critério.",
    },
    sendCriteria: {
      loading: "Criando critério...",
      codes: {
        200: "O critério foi criado com sucesso",
        201: "O critério foi criado com sucesso",
        400: "Ocorreu um erro e não foi possível criar o critério",
      },
    },
    putCriteria: {
      loading: "Modificando critério...",
      codes: {
        200: "O critério foi modificado com sucesso",
        201: "O critério foi modificado com sucesso",
        400: "Ocorreu um erro e não foi possível modificar o critério",
      },
    },
  },
  copyGenericText: "_cópia",
  criteriaManagement: {
    deleteCriteria: {
      loading: "Excluindo critério...",
      codes: {
        200: "O critério foi excluído com sucesso",
        201: "O critério foi excluído com sucesso",
        203: "O critério foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o critério",
      },
    },
    getCriteria: {
      loading: "Obtendo critérios...",
      error: "Ocorreu um erro e não foi possível baixar os critérios.",
    },
  },
  criteriaManagementTexts: {
    criteriaCard: {
      receivers: "Receptores",
      filters: "Filtros",
      validations: "Validações",
      inUseBuckets: "Em uso, associado aos buckets",
      inUseBucket: "Em uso, associado ao bucket",
      notUsed: "Não utilizado",
    },
    criteriaManagement: {
      lookingCriteria: "Procurando critérios...",
      deletingCriteria: "Excluindo critério...",
      criteriaManagement: "Administração de Critérios",
      sortAlphabet: "CLASSIFICAR POR ALFABETO",
      sortDate: "CLASSIFICAR POR DATA",
      filter: "Filtro",
      searchByCriteriaBucket: "Buscar por nome de critério ou bucket",
      noCriteriaAvailable: "Ainda não há critérios disponíveis.",
      notGetCriteria:
        "Não foi possível obter os critérios. Tente novamente mais tarde.",
      deleteCriteria: "excluir o critério",
      noActiveCriteria: "Critérios Inativos",
      activeCriteria: "Critérios Ativos",
      noFilters: "Sem filtros",
      bucketsWhereItsUsed: "Buckets onde é utilizado",
    },
    viewInfoModal: {
      criteriaInfo: "Informações gerais do critério",
      criteriaBase: "Base para critério:",
      criteriaColumn: "Coluna de critério:",
      aplicatedFunction: "Função aplicada:",
      convertNegativesToPositives: " Converter valores negativos em positivos:",
      convertNegativesToZero: "Transformar valores negativos em zero:",
      columnsToValidate: "Coluna a validar exclusivamente:",
      yes: "Sim",
      no: "Não",
      columnsToMultiply: "Colunas para multiplicar",
      filterInFile: "Filtros no arquivo",
      expensesCrossover: "Cruzamento de valores de GASTOS com",
      filterBy: "Filtragem de ",
      withInfo: "com informações de",
      previousFilters: "Filtros anteriores no arquivo",
      crossoverValues: "Cruzamento de valores de",
      with: "com",
      previousDestinitiesValidations: "Validação de destinos anteriores",
      differentiatedTreatment: "Tratamento diferenciado",
      noDifferentiatedTreatment: "Sem tratamento diferenciado",
    },
  },
  dashboard: {
    duplicated: "Já existe um painel com este nome.",
    invalidLink: "O link deve ser um link válido do Power BI",
    noData: "Não há painéis para mostrar.",
    get: {
      loading: "Obtendo painéis...",
      error: "Ocorreu um erro ao obter os painéis, tente novamente.",
    },
    post: {
      codes: {
        200: "O painel foi adicionado com sucesso.",
        201: "O painel foi adicionado com sucesso.",
        400: "Ocorreu um erro ao adicionar o painel, tente novamente.",
        500: "Ocorreu um erro ao adicionar o painel, tente novamente.",
      },
    },
    delete: {
      success: "O painel foi excluído com sucesso.",
      error: "Ocorreu um erro ao excluir o painel, tente novamente.",
      codes: {
        200: "O painel foi excluído com sucesso.",
        400: "Ocorreu um erro ao excluir o painel, tente novamente.",
        500: "Ocorreu um erro ao excluir o painel, tente novamente.",
      },
    },
    newView: {
      codes: {
        200: "A visualização foi criada com sucesso.",
        201: "A visualização foi criada com sucesso.",
        400: "Ocorreu um erro ao criar a visualização, tente novamente.",
        500: "Ocorreu um erro ao criar a visualização, tente novamente.",
      },
      loading: "Criando visualização...",
      createView: "¿se deseja criar uma nova visualização?",
    },
    deleteView: {
      codes: {
        200: "A visualização foi excluída com sucesso.",
        201: "A visualização foi excluída com sucesso.",
        400: "Ocorreu um erro ao excluir a visualização, tente novamente.",
        500: "Ocorreu um erro ao excluir a visualização, tente novamente.",
      },
      loading: "Excluindo visualização...",
      modalTitle: "Excluir visualização",
      selectViewToDelete: "Selecione uma visualização para excluir",
      selectView: "Selecione uma visualização...",
    },
    newElement: {
      codes: {
        200: "O elemento foi criado com sucesso.",
        201: "O elemento foi criado com sucesso.",
        400: "Ocorreu um erro ao criar o elemento, tente novamente.",
        500: "Ocorreu um erro ao criar o elemento, tente novamente.",
      },
      loading: "Criando elemento...",
    },
    deleteElement: {
      codes: {
        200: "O elemento foi excluído com sucesso.",
        201: "O elemento foi excluído com sucesso.",
        400: "Ocorreu um erro ao excluir o elemento, tente novamente.",
        500: "Ocorreu um erro ao excluir o elemento, tente novamente.",
      },
      loading: "Excluindo elemento...",
      confirmationMessage: "Confirma a exclusão do elemento?",
      modalTitle: "Excluir elemento",
    },
    editElement: {
      codes: {
        200: "O elemento foi editado com sucesso.",
        201: "O elemento foi editado com sucesso.",
        400: "Ocorreu um erro ao editar o elemento, tente novamente.",
        500: "Ocorreu um erro ao editar o elemento, tente novamente.",
      },
      loading: "Editando elemento...",
    },
    importFrom: "Importar de:",
    analysis: "ANÁLISE",
    simulation: "SIMULAÇÃO",
    salesForecast: "SALES FORECAST",
    chart: "Gráfico",
    indicator: "Indicador",
    table: "Tabela",
    createNewElement: "Novo elemento",
    putElement: "Editar elemento",
    typeOfElement: "Tipo de elemento",
    selectType: "Selecione um tipo...",
    columnsQuantity: "Largura do elemento (número de colunas a ocupar)",
    rowsQuantity: "Altura do elemento (número de linhas a ocupar)",
    toUse: "a utilizar",
    moveElement: "Arraste para mover o elemento",
    editName: "Editar nome",
    create: "Criar",
    import: "Importar",
    waterfall: {
      positive: "Positivo",
      negative: "Negativo",
      intermediateSum: "Soma intermediária",
      totalSum: "Soma total",
    },
    createView: "Criar visualização",
    insertName: "Insira o nome",
    placeholderWriteName: "Escreva o nome...",
    noDataError: "Um erro ocorreu e não foi possível obter os dados.",
    noElementsYet: "Ainda não se adicionou nenhum elemento.",
  },
  dashboardTexts: {
    dashboard: {
      addDashboard: "Editar Painel",
      addNewDashboard: "Adicionar novo Painel",
    },
    newDashboardModalContent: {
      deletingDashboard: "Excluindo painel...",
      name: "Nome:",
      dashboardName: "Nome do painel...",
      linkDashboard: "Link do painel...",
      deleteDashboardFromListing: "Excluir painel da listagem.",
      close: "Fechar",
      deleteDashboard: "Excluir Painel",
    },
  },
  dataManagement: {
    downloadFile: {
      loading: "Baixando arquivo...",
      codes: {
        200: "O arquivo foi baixado com sucesso",
        201: "O arquivo foi baixado com sucesso",
        400: "Ocorreu um erro e não foi possível baixar o arquivo",
      },
      empty: "Não há arquivos para baixar",
    },
    getRowsPreview: {
      loading: "Obtendo visualização...",
      codes: {
        200: "A visualização foi obtida com sucesso",
        400: "Ocorreu um erro e não foi possível obter a visualização",
      },
    },
    getColumnsFiles: {
      loading: "Obtendo colunas...",
      error: "Ocorreu um erro e não foi possível baixar as colunas.",
    },
    getRowsFiles: {
      loading: "Obtendo linhas...",
      error: "Ocorreu um erro e não foi possível baixar as linhas.",
    },
    getAllowedFileNames: {
      loading: "Obtendo nomes de arquivos permitidos...",
      error:
        "Ocorreu um erro e não foi possível obter os nomes de arquivos permitidos.",
    },
    getCompletedStages: {
      codes: {
        400: "Ocorreu um erro e não foi possível obter as etapas.",
      },
      error: "Ocorreu um erro e não foi possível obter as etapas.",
    },
    deleteFile: {
      codes: {
        200: "O arquivo foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o arquivo",
        500: "Ocorreu um erro e não foi possível excluir o arquivo",
      },
      loading: "Excluindo arquivo...",
    },
    deletePeriod: {
      codes: {
        200: "O período foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o período",
        500: "Ocorreu um erro e não foi possível excluir o período",
      },
    },
    uploadModal: {
      restartCalculation: "Redefinir o cálculo",
    },
  },
  dataManagementTexts: {
    allowFilesModalContent: {
      fileNameExists: "Já existe um arquivo com este nome",
      nameRules:
        "O nome só pode conter letras, números, hífens e ter até 30 caracteres",
      nameFileDeleted: "O nome do arquivo foi excluído com sucesso.",
      errorAllowFileDeleting:
        "Ocorreu um erro ao excluir o arquivo permitido, tente novamente.",
      successAllowFileDeleting:
        "O nome do arquivo permitido foi adicionado com sucesso.",
      errorAddAllowFile:
        "Ocorreu um erro ao adicionar o arquivo permitido, tente novamente",
      addNewAllowFile: "Adicionar novo nome de arquivo permitido",
      fileName: "Nome do arquivo...",
      allowedFiles: "Arquivos permitidos",
      deleteAllowedFile: "Excluir arquivo permitido",
      close: "Fechar",
      delete: "excluir",
      offAllowdFileNames: "dos nomes de arquivos permitidos",
      deleteFile: "Excluir arquivo",
    },
    notAllowedToAddFileModal: {
      cannotAddName: "Não é possível adicionar o nome",
      notPossibleExpensesName:
        "Não é possível adicionar o nome PRE_GASTOS aos nomes de arquivos permitidos",
      accept: "Aceitar",
    },
    notAllowedToDeleteFileModal: {
      cannotAddName: "Não é possível adicionar o nome",
      notPossibleDelete_1: "Não é possível excluir ",
      notPossibleDelete_2:
        "dos nomes de arquivos permitidos, pois existem arquivos carregados com este nome. É necessário excluí-los primeiro",
      accept: "Aceitar",
    },
    dataManagement: {
      taskManager: "/gerenciador-de-tarefas",
      fileStillLoading:
        "Este arquivo ainda está sendo carregado, aguarde alguns instantes e tente novamente.",
      cannotGetPreView:
        "Não foi possível obter a pré-visualização deste arquivo",
      deletingPeriodFiles: "Excluindo arquivos do período...",
      downloadingFile: "Baixando arquivo...",
      dataManagement: "Gestão de Dados",
      errorCouldNotDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
      uploadFiles: "Enviar Arquivos",
      allowedFiles: "Arquivos Permitidos",
      cannotGetAllowedFiles: "Não foi possível obter os arquivos permitidos",
      fileUpload: "Upload de Arquivos",
      close: "Fechar",
      deleteSelectedPeriod: "excluir o período selecionado",
      deletePeriod: "Excluir Período",
      confirmDeleteFile_1: "Confirma a exclusão do arquivo",
      confirmDeleteFile_2:
        "? Isso também excluirá as tarefas relacionadas a este arquivo.",
      deleteFile: "Excluir Arquivo",
      preview: "Pré-visualização",
      uploading: "Enviando",
      files: "arquivos",
      closingWindowStopUpload:
        "Se fechar a janela, interromperá o processo de upload e os arquivos que ainda não iniciaram o processo de upload serão perdidos. Deseja interromper o upload?",
      closeFileUpload: "Fechar Envio de Arquivos",
      fileDownload: "Download de Arquivos",
      goDownloads: "Ir para Downloads",
      downloadProccesStarted: "O processo de download foi iniciado",
      viewDownloadStatus: `Para ver o status do download e baixar o arquivo, clique no botão "Ir para Downloads". Você também pode visualizá-lo na opção Downloads do "Gerenciador de Tarefas".`,
      importFromAlpha: "Importar do Alpha",
    },
    alphaImportTexts: {
      filesToImport: "Selecione os arquivos a serem importados",
      selectedFolders: "Pastas selecionadas",
      selectedFiles: "Arquivos selecionados",
      noFilesToImport: "Não há arquivos para importar",
    },
    linearDeterminate: {
      loading: "Carregando",
      completedLoading: "Carregamento concluído",
    },
    successUploadModalContent: {
      connectionError:
        "Houve um erro na conexão e não foi possível enviar o arquivo.",
      onlyUpload:
        "O arquivo, após ser enviado, precisa ser processado. O estado do processamento será mostrado na tabela de arquivos e períodos.",
      stopLoad:
        "Atualizar a página, fechar esta janela ou o navegador interromperá o upload e os arquivos que ainda não iniciaram o processo de upload serão perdidos.",
      charge: "enviar",
      reTry: "Tentar Novamente",
      cancel: "Cancelar",
      chargeAbort: "Envio Cancelado",
      errorFileNotLoaded: "Houve um erro e não foi possível carregar o arquivo",
      loading: "Carregando...",
      waiting: "Aguardando",
    },
    transformDataManagement: {
      downloadAllFilesFrom: "Baixar todos os arquivos de",
      preview: "Pré-visualizar",
      download: "Baixar",
      delete: "Excluir",
      validated: "Validado",
      notValidated: "Não Validado",
      notFound: "Não Encontrado",
      downloadAllFiles: "Baixar todos os arquivos",
      downloadAllPeriodFiles: "Baixar todos os arquivos do período",
      deletePeriod: "Excluir Período",
    },
    uploadModalContent: {
      fileNameFormat_1: "Os nomes dos arquivos devem seguir o formato",
      fileNameFormat_2: "NOME-MÊS-ANO.csv ou NOME-MÊS-ANO.parquet",
      fileNameFormat_3: "ex. VENDAS-02-2022.csv",
      allowedName: "Os nomes permitidos são:",
      cannotUploadNames:
        "Os nomes dos arquivos permitidos não foram carregados",
      dragSelectFiles: "Arraste os arquivos aqui ou clique para selecionar",
      filesToUpload: "Arquivos para enviar:",
      addAllowedFilesName: "Adicionar nome de arquivo permitido.",
      deleteFileFromListing: "Excluir arquivo da lista.",
      fileAlreadyExistsRestatedCalculation:
        " - Este arquivo já existe e substituirá o anterior. ",
      itWillBeRestarted: "O cálculo do período será reiniciado.",
      fileAlreadyIncluded: " - Este arquivo já está incluído na lista",
      fileNameNotAllowed: " - Nome de arquivo não permitido",
      cannotGetAllowedFiles: "Não foi possível obter os arquivos permitidos",
      cancel: "Cancelar",
      filledIllegalNames: "Existem arquivos com nome não permitido.",
      accept: "Aceitar",
    },
  },
  dataModel: {
    getMeasures: {
      error: "Ocorreu um erro e não foi possível obter as medidas",
      codes: {
        200: "As medidas foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as medidas",
        404: "Ocorreu um erro e não foi possível obter as medidas",
      },
    },
    getMeasure: {
      error: "Ocorreu um erro e não foi possível obter a medida",
      codes: {
        200: "A medida foi obtida",
        400: "Ocorreu um erro e não foi possível obter a medida",
        404: "Ocorreu um erro e não foi possível obter a medida",
      },
    },
    getGroups: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
      codes: {
        200: "Os grupos foram obtidos",
        400: "Ocorreu um erro e não foi possível obter os grupos",
        404: "Ocorreu um erro e não foi possível obter os grupos",
      },
    },
    getGroup: {
      error: "Ocorreu um erro e não foi possível obter o grupo",
      codes: {
        200: "O grupo foi obtido",
        400: "Ocorreu um erro e não foi possível obter o grupo",
        404: "Ocorreu um erro e não foi possível obter o grupo",
      },
    },
    getPeriods: {
      error: "Ocorreu um erro e não foi possível obter os períodos",
      codes: {
        200: "Os períodos foram obtidos",
        400: "Ocorreu um erro e não foi possível obter os períodos",
        404: "Ocorreu um erro e não foi possível obter os períodos",
      },
    },
    deleteGroup: {
      loading: "Excluindo grupo...",
      codes: {
        200: "O grupo foi excluído com sucesso",
        204: "O grupo foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o grupo",
      },
    },
    deleteMeasure: {
      loading: "Excluindo medida...",
      codes: {
        200: "A medida foi excluída com sucesso",
        400: "Ocorreu um erro e não foi possível excluir a medida",
      },
    },
    getAnalysis: {
      error: "Ocorreu um erro e não foi possível obter as análises",
      codes: {
        200: "As análises foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as análises",
        404: "Ocorreu um erro e não foi possível obter as análises",
      },
    },
    postNewAnalysis: {
      error: "Ocorreu um erro e não foi ",
    },
    deleteAnalysis: {
      loading: "Excluindo análise...",
      codes: {
        200: "A análise foi excluída com sucesso",
        201: "A análise foi excluída com sucesso",
        400: "Ocorreu um erro e não foi possível excluir a análise",
      },
    },
    postCalculate: {
      codes: {
        200: "O cálculo foi iniciado com sucesso",
        201: "O cálculo foi iniciado com sucesso",
        400: "Ocorreu um erro e não foi possível iniciar o cálculo",
      },
    },
    postNewMeasure: {
      loading: "Criando medida...",
      codes: {
        200: "A medida foi criada com sucesso",
        201: "A medida foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a medida",
      },
    },
    putMeasure: {
      loading: "Modificando medida...",
      codes: {
        200: "A medida foi modificada com sucesso",
        201: "A medida foi modificada com sucesso",
        400: "Ocorreu um erro e não foi possível modificar a medida",
      },
    },
    postNewGrouper: {
      loading: "Criando agrupação...",
      codes: {
        200: "A agrupação foi criada com sucesso",
        201: "A agrupação foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a agrupação",
      },
    },
    putGrouper: {
      loading: "Editando agrupação...",
      codes: {
        200: "A agrupação foi editada com sucesso",
        201: "A agrupação foi editada com sucesso",
        400: "Ocorreu um erro e não foi possível editar a agrupação",
      },
    },
    postDownloadAnalysis: {
      loading: "Baixando relatório...",
      codes: {
        200: "O download foi iniciado com sucesso",
        201: "O download foi iniciado com sucesso",
        400: "Ocorreu um erro e não foi possível iniciar o download da análise",
      },
    },
  },
  dataModelText: {
    analysisExecution: {
      analysisExecution: "Execução de análise",
      startingCalculation: "Iniciando cálculo...",
      analysis: "Análise",
      selectAnalysisToRun: "Selecionar análises para executar",
      periods: "Períodos",
      selectPeriods: "Selecionar períodos",
      runSelectedAnalysisPlural: "executar as análises selecionadas",
      runSelectedAnalysisSingular: "executar a análise selecionada",
      deleteData: "Apagar dados",
      runAnalysis: "Executar análise",
      noPeriods: "Não há períodos com buckets totalmente rateados.",
    },
    analysisManagement: {
      configAnalysis: {
        creatingAnalysis: "Criando análise...",
        editingAnalysis: "Editando análise...",
        analysisAlreadyCalculated:
          "Esta análise já foi calculada. Ao editá-la, a atualização de cálculo será removida.",
        analysisName: "Nome da análise",
        analysisAlreadyName: "Já existe uma medida com esse nome",
        groupers: "Agrupamentos:",
        chooseGrouper: "Escolher agrupamento...",
        measures: "Medidas:",
        cancel: "Cancelar",
        accept: "Aceitar",
        modifyAnalysis: "modificar a análise",
        createNewAnalysis: "criar uma nova análise",
        newAnalysis: "Nova análise",
      },
      transferList: {
        measureDisabled:
          "A medida está desativada porque nem todas as medidas primárias necessárias foram selecionadas.",
        analysisAdmin: "Administração de Análise",
        bucketGrouperInMeasures:
          "Se um bucket-grouper for usado em duas medidas escolhidas, elas ficarão com outra cor.",
        compositeWithPrimary:
          "As medidas compostas só serão habilitadas se todas as medidas primárias necessárias para essa medida tiverem sido escolhidas.",
        noMeasuresAvailable: "Não há medidas disponíveis para este tipo.",
      },
      analysisAdmin: {
        searchingAnalysis: "Procurando análises...",
        analysisAdmin: "Administração de análises",
        periods: "Períodos",
        searchByName: "Pesquisa por nome",
        noAnalysisYet: "Ainda não há análises disponíveis.",
        deleteAnalysisConfirmCalculate:
          "Tem certeza de que deseja excluir a análise? Essa ação também excluirá o cálculo associado.",
        deleteAnalysisConfirm: "Tem certeza de que deseja excluir a análise?",
        confirm: "Confirmar",
        writeDelete: "Escreva a palavra 'excluir'",
        delete: "excluir",
      },
      analysisCard: {
        grouper: "Agrupamento:",
        measures: "MEDIDAS:",
        calculated: "Calculado",
        noCalculated: "Não calculado",
      },
      viewInfoAnalysisModal: {
        grouper: "AGRUPAMENTO:",
        measures: "MEDIDAS:",
        analysisInfo: "Informações gerais da análise",
      },
    },
    downloadReport: {
      aggregationOperatorsLabels: {
        sum: "ADIÇÃO",
        average: "MÉDIA",
        maximum: "MÁXIMO",
        minimum: "MÍNIMO",
        count: "CONTAR",
        count_unique: "CONTAR ÚNICO",
        first: "PRIMEIRO",
        last: "DURAR",
        unique_values: "VALORES ÚNICOS",
      },
      year: "ANO",
      month: "MÊS",
      monthly: "MENSAL",
      quarterly: "TRIMESTRE",
      biannual: "SEM-ANNUAL",
      annual: "ANUAL",
      downloadReport: "Baixar relatório",
      saveReportConfig: "Salvar configuraçõe",
      getReportConfig: "Obter configuraçõe",
      confirmSaveReportConfig:
        "Confirma que deseja salvar as configurações de download do relatório?",
      configReportName: "Nome para a configuração de download",
      loading: "Carregando...",
      reports: "Relatórios",
      referenceName: "Nome de referencia",
      referenceNameAlreadyExists:
        "O nome de referência já está em uso. Se você continuar, o existente será substituído.",
      notAnalysisExecuted: "Nenhuma análise foi executada ainda",
      analysis: "Análise",
      chooseAnalysisToDownload: "Selecione a análise para download...",
      filters: "Filtros",
      columns: "Colunas",
      chooseColumns: "Escolher colunas...",
      periodicity: "Periodicidade",
      choosePeriodicity: "Escolha a periodicidade...",
      measures: "Medidas",
      selectAllMeasures: "SELECIONE TODAS AS MEDIDAS",
      noOptionsMeasuresAvailable: "Não há medidas disponíveis...",
      all: "TODOS",
      chooseMeasures: "Escolher medidas...",
      aggregationOperators: "Operadores de agregação",
      chooseAggregationOperators: "Escolha uma operadora...",
      deleteData: "Excluir dados",
      download: "Baixar",
      downloadAnalysisSelected: "baixar a análise selecionada",
      preview: "Vista prévia",
      periods: "Períodos",
    },
    groupManagement: {
      step1: {
        addBaseButton: {
          newBase: "Nova Base",
        },
        mergeModal: {
          firstOcurrence: "primeira-ocorrência",
          lastOcurrence: "última-ocorrência",
          dontDelete: "não-excluir",
          editMerge: "editar mesclagem",
          addMerge: "adicionar mesclagem",
          file: "Arquivo",
          selectFile: "Selecionar arquivo",
          alreadyExistBaseWithFile:
            "Já existe uma base com o arquivo selecionado",
          columns: "Colunas",
          columnsMergePreviousBase: "Colunas mescladas - Base anterior",
          chooseColumns: "Escolher colunas...",
          columnsMergeNewBase: "Colunas mescladas - Nova base",
          removeDuplicates: "Remover duplicados:",
          keepFirstOcurrence: "Manter primeira ocorrência",
          keepLastOcurrence: "Manter última ocorrência",
          doNotDelete: "Não excluir",
          notRecommended:
            "(Não recomendado) Não excluir duplicados pode resultar em valores duplicados.",
        },
        newBaseFile: {
          columnBeingUsed:
            "A coluna excluída está sendo usada em uma mesclagem, reconfigure as bases com erro para continuar",
          file: "Arquivo",
          chooseFile: "Escolher arquivo...",
          chooseAnalysis: "Escolher análise...",
          columns: "Colunas",
          chooseColumns: "Escolher colunas...",
          removeBase: "Remover base",
          confirmRemoveBase:
            "Tem certeza de que deseja remover a base? Essa ação também removerá as bases seguintes e você precisará reconfigurar a etapa 2 se alguma delas estiver sendo usada.",
          editBaseFile: "Editar arquivo da base",
          confirmEditFiles:
            "Tem certeza de que deseja editar o arquivo escolhido para a base? Essa ação redefinirá a etapa 2 e precisará ser reconfigurada.",
          editBaseColumns: "Editar colunas da base",
          confirmEditColumns:
            "Tem certeza de que deseja editar as colunas escolhidas? Essa ação redefinirá a etapa 2 e precisará ser reconfigurada.",
        },
        step1: {
          grouperName: "Nome do agrupamento",
          grouperNameAlreadyExists: "Já existe um agrupamento com esse nome",
          nameRules: "O nome deve ter entre 3 e 60 caracteres",
        },
      },
      step2: {
        customColumnInput: {
          nameColumnEmpty: "O nome da coluna não pode estar vazio",
          columnsNameAlreadyExists: "Já existe uma coluna com esse nome",
        },
        modifyColumnsModal: {
          renameColumns: "Renomear colunas",
          resetColumns: "Redefinir colunas",
          columnsOf: "Colunas de",
          cancel: "Cancelar",
          accept: "Aceitar",
        },
        step2: {
          columnToJoinResults: "Coluna para unir com resultados",
          file: "Arquivo",
          chooseFile: "Escolher arquivo...",
          column: "Coluna",
          chooseColumn: "Escolher coluna...",
          columnsToUse: "Colunas a serem usadas",
          chooseColumns: "Escolher colunas...",
          renameColumns: "Renomear Colunas",
          preview: "Visualização Prévia",
          resetColumnTitle: "Redefinir colunas",
          resetColumnMessage: "redefinir colunas",
        },
      },
      baseModal: {
        cancel: "Cancelar",
        accept: "Aceitar",
      },
      configGroup: {
        newGroup: "Novo grupo",
        previous: "Anterior",
        nonPossibleContinueTwoDataBases:
          "Não é possível continuar pois existem duas bases com o mesmo arquivo",
        columnsWithSameName:
          "Existem colunas com o mesmo nome; uma delas deve ser renomeada para continuar.",
        finish: "Finalizar",
        next: "Próximo",
        editGroup: "Editar grupo",
        createGroup: "Criar grupo",
      },
      groupAdmin: {
        groupsAdmin: "Administração de grupos",
        searchByName: "Pesquisar por nome",
        nonGroupsYet: "Ainda não existem grupos disponíveis.",
        removeGroup: "remover o grupo",
        confirm: "Confirmar",
      },
      groupCard: {
        usedColumns: "Colunas utilizadas:",
      },
      viewGrouperInfo: {
        name: "Nome:",
        base: "Base:",
        columns: "Colunas:",
        column: "Coluna:",
        generalInfoGroup: "Informações gerais do grupo",
        mergeColumnsPreviousBase: "Colunas de mesclagem - base anterior:",
        mergeColumnsCurrentBase: "Colunas de mesclagem - base atual:",
        removeDuplicates: "Remover duplicados:",
        finalColumns: "Colunas finais",
        usedColumns: "Colunas utilizadas:",
      },
    },
    measureManagement: {
      configMeasure: {
        measureInCurrency: "Medida em moeda",
        configCompounds: {
          operatorsCalculator: {
            operators: "Operadores",
          },
        },
        configCompoundsMeasure: {
          parenthesesMatches:
            "Certifique-se de que todos os parênteses estejam corretamente correspondidos",
          formulaFinishInOperator:
            "Certifique-se de que a fórmula não termine com um operador",
          measureName: "Nome da medida",
          measureNameAlreadyExists: "Já existe uma medida com esse nome",
          formula: "Fórmula",
          enterFormula: "Insira a fórmula",
          measureExpenses: "Medidas de despesas",
          measureSales: "Medidas de vendas",
          measureCompounds: "Medidas compostas",
          cancel: "Cancelar",
          accept: "Aceitar",
          createNewMeasure: "criar uma nova medida",
          newMeasure: "Nova medida",
        },
        configExpensesMeasure: {
          newMeasureName: "Nome da nova medida",
          cancel: "Cancelar",
          accept: "Aceitar",
          searchByBucketGrouper: "Pesquisa por Bucket ou Agrupador",
          editMeasure: "Editar medida",
          newMeasure: "Nova medida",
          newName: "Novo nome",
          repeatedMeasureName: "Já existe uma medida com esse nome",
        },
        configSalesMeasure: {
          tabs: {
            sales: "VENDAS",
            otherBase: "OUTRA FONTE",
          },
        },
        groupByRowSelects: {
          chooseColumn: "Escolher coluna...",
          chooseFunction: "Escolher função...",
          columnName: "Nome da coluna",
          nameAlreadyExists: "O nome já existe",
        },
        otherFontStep1: {
          file: "Arquivo",
          selectFile: "Selecionar arquivo",
          mergeColumnsCurrentBase: "Colunas de mesclagem - Base atual",
          chooseColumns: "Escolher colunas",
          mergeColumnsSalesBase: "Colunas de mesclagem - Base VENDAS",
          numberColumnsSelectedEqualSalesBase:
            "O número de colunas selecionadas na base atual deve ser igual ao da base VENDAS",
          columnsToAddFunctionAggregation:
            "Colunas a adicionar e função de agregação",
          avoidDuplicatingValues:
            "Para evitar duplicação de valores, as informações adicionadas serão agrupadas previamente, usando as colunas escolhidas para mesclagem",
          reset: "Redefinir",
          resetData: "Redefinir dados",
          deleteAllData: "excluir todos os dados",
        },
        otherMeasureFont: {
          createNewMeasure: "criar uma nova medida",
          newMeasure: "Nova medida",
          previous: "Anterior",
          finish: "Finalizar",
          next: "Próximo",
        },
        salesMeasure: {
          loading: "Carregando...",
          measureName: "Nome da Medida",
          newMeasureName: "Nome da nova medida",
          measureNameAlreadyExists: "Já existe uma medida com esse nome",
          operation: "Operação",
          chooseOperation: "Escolher operação...",
          numerators: "Numeradores",
          chooseColumns: "Escolher colunas...",
          chooseColumn: "Escolher coluna...",
          denominators: "Denominadores",
          columnsAddInfo:
            "As colunas adicionadas em cada entrada serão somadas automaticamente.",
          columns: "Colunas",
          column: "Coluna",
          modifyMeasure: "modificar a medida",
          createNewMeasure: "criar uma nova medida",
          newMeasure: "Nova medida",
          cancel: "Cancelar",
          accept: "Aceitar",
        },
      },
      measureAdmin: {
        tabs: {
          expenses: "DESPESAS",
          sales: "VENDAS",
          compounds: "COMPOSTOS",
          created: "CRIADAS",
          default: "PADRÃO",
          bucket: "BUCKET",
          bucketAgrupator: "BUCKET-AGRUPADOR",
        },
        cannotDeleteMeasureAsBeingUsed:
          "Não é possível excluir esta medida, pois está sendo usada por uma medida composta.",
        searchingMeasures: "Procurando medidas...",
        loadingMeasures: "Carregando medida...",
        measureAdmin: "Administração de medidas",
        searchByName: "Pesquisar por nome",
        notMeasuresYet: "Ainda não há medidas disponíveis.",
        removeMeasure: "remover a medida",
        confirm: "Confirmar",
      },
      viewInfoCompoundsModal: {
        generalInfoMeasure: "Informações gerais da medida",
        formula: "Fórmula",
      },
      viewInfoSalesModal: {
        file: "Arquivo",
        currentBaseColumns: "Colunas da base atual:",
        baseColumnsSales: "Colunas da base de Vendas:",
        operation: "Operação:",
        columns: "Colunas:",
        denominatorColumns: "Colunas do denominador:",
        groupers: "Agrupamentos",
      },
    },
  },
  delta: {
    postSalesForecast: {
      loading: "Criando Sales Forecast...",
      codes: {
        200: "El Sales Forecast foi criada com sucesso",
        201: "El Sales Forecast foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a Sales Forecast",
      },
    },
    putSalesForecast: {
      loading: "Editando Sales Forecast...",
      codes: {
        200: "El Sales Forecast foi editada com sucesso",
        201: "El Sales Forecast foi editada com sucesso",
        400: "Ocorreu um erro e não foi possível editar a Sales Forecast",
      },
    },
    deleteSalesForecast: {
      codes: {
        200: "El Sales Forecast foi removida com sucesso",
        201: "El Sales Forecast foi removida com sucesso",
        400: "Ocorreu um erro e não foi possível excluir el sales forecast",
      },
      loading: "Removendo a sales forecast...",
    },
    deleteSimulation: {
      codes: {
        200: "A simulação foi removida com sucesso",
        201: "A simulação foi removida com sucesso",
        400: "Ocorreu um erro e não foi possível excluir a simulação",
      },
      loading: "Removendo a simulação...",
    },
    postSimulation: {
      loading: "Criando a Simulação...",
      codes: {
        200: "A simulação foi criada com sucesso",
        201: "A simulação foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a Simulação",
      },
    },
    putSimulation: {
      loading: "Editando a Simulação...",
      codes: {
        200: "A simulação foi editada com sucesso",
        201: "A simulação foi editada com sucesso",
        400: "Ocorreu um erro e não foi possível editar a Simulação",
      },
    },
  },
  deltaTexts: {
    salesForecast: {
      title: "Previsão de Vendas",
      salesForecastAdmin: {
        noPredictionsYet: "Nenhuma previsão gerada ainda",
        startCreatingOne: "Comece a criar um novo a partir de uma análise",
        newSalesForecast: "Nova previsão de vendas",
        deleteSalesForecast: "excluir Sales Forecast",
        editSalesForecast: "editar Sales Forecast",
        deleteAssociatedSimulationsAndRules:
          "Todas as regras e simulações associadas serão removidas.",
      },
      newSalesForecastModal: {
        initialPeriod: "Período de início",
        chooseInitialPeriod: "Escolha o período inicial...",
        all: "TODOS",
        salesMeasuresToForecast: "Medidas de vendas para previsão",
        periodsToForecast: "Número de períodos a serem previstos",
        choosePeriods: "Escolha o número de períodos",
      },
      salesForecastCard: {
        allPeriodsExecuted: "Todos os períodos habilitados foram executados.",
        notAllPeriodsExecuted:
          "Nem todos os períodos habilitados foram executados.",
        enabledPeriodsNonExecuted: "Períodos habilitados não executados",
        none: "Nenhum",
      },
    },
    simulation: {
      title: "Gerenciamento de Simulação",
      simulation: "SIMULAÇÃO",
      simulationAdmin: {
        theSimulation: "a simulação",
      },
      simulationCard: {
        errorTryAgain: "Ocorreu um erro, tente novamente.",
        autoRun: "Execução automática",
        lastPeriodDataExportNeedToBePredicted:
          "O último período da exportação de dados precisa ser previsto",
      },
      simulationConfig: {
        simulationConfig: "Configuração de Simulações",
        autoRun: "Execução automática",
        onceRuleChosen:
          "Depois que a Regra de Simulação for selecionada, os dados de Previsão de Vendas correspondentes serão carregados e bloqueados para modificação.",
      },
      viewInfoModal: {
        routeConfig: "Configuração de rota",
        configType: "TIPO DE CONFIGURAÇÃO",
        cancelRoute: "CANCELAR",
        modificationRoute: "MODIFICAÇÃO",
        registeredRoute: "REGISTRO",
        filters: "FILTROS",
        modifications: "MODIFICAÇÕES",
        projectionMode: "MODO DE PROJEÇÃO",
        params: "PARÂMETROS",
        routesToAdd: "ROTAS PARA ADICIONAR",
        rule: "REGRA",
        simulationName: "NOME DA SIMULAÇÃO",
      },
      modifyModicationModal: {
        previouslySelectedValues: "Valores previamente selecionados",
      },
      step3NewModifyRouteModal: {
        averageSelectedRoutes: "Média de rotas selecionadas:",
        averageRoutesBasesOnSelectedValues:
          "Média de rotas com base nos valores selecionados:",
      },
      step2SimulationConfig: {
        noConfigurationsYet: "Ainda não há configurações",
        deleteConfigs: "excluir configurações",
      },
    },
    simulationRules: {
      assignDriverModal: {
        assignCostDriver: "Atribuir driver de custo",
      },
      configSimulationRule: {
        deleteAllCostDriver: "excluir drivers de custo",
        deleteCostDriver: "Excluir driver de custo",
      },
      ruleButtons: {
        noRowsWithDriverToDelete:
          "Não há linhas selecionadas com drivers para excluir",
        deleteDrivers: "Excluir Drivers",
        assignDrivers: "Atribuir Drivers",
      },
      transformDataRuleTable: {
        measure: "MEDIDA",
        variability: "VARIABILIDADE",
        costDriver: "COST DRIVER",
      },
    },
  },
  dialog: {
    cofirmThat: "Confirma que deseja ",
    cancel: "Cancelar",
    accept: "Aceitar",
    confirmTitle: "Confirmar",
    filter: "Filtrar",
  },
  errorTexts: {
    pageNotExist: "Esta página não existe",
    backToHome: "Voltar para a página inicial",
  },
  expensesSettings: {
    sendTransformations: {
      loading: "Salvando transformações...",
      codes: {
        200: "As transformações foram aplicadas com sucesso.",
        201: "As transformações foram aplicadas com sucesso.",
        400: "Ocorreu um erro e não foi possível aplicar as transformações",
        500: "Ocorreu um erro e não foi possível aplicar as transformações",
      },
    },
    getExpenses: {
      error: "Ocorreu um erro e não foi possível obter os gastos",
    },
    getView: {
      error: "Ocorreu um erro e não foi possível obter a visualização",
    },
    saveView: {
      loading: "Salvando visualização...",
      codes: {
        200: "A visualização foi salva com sucesso.",
        201: "A visualização foi salva com sucesso.",
        400: "Ocorreu um erro e não foi possível salvar a visualização.",
      },
    },
    expensesSettingsTexts: {
      expensesConfig: "Configurações de Despesas",
      applyingTrans: "Aplicando transformações...",
      savingView: "Salvando visualização...",
      filteredExpense: "Despesa Filtrada",
      totalExpense: "Despesa Total",
      noRowsSelected: "Nenhuma linha selecionada",
      modify: "Modificar",
      errorDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
      unaddedTransf:
        "Existem transformações não adicionadas. Confirma mesmo assim que deseja aplicar as transformações?",
      wantToApply: "Confirma que deseja aplicar essas transformações?",
    },
    modalContent: {
      valueExists: "*O valor já existe",
      emptyFieldsCannotAdded: "*Campos vazios não podem ser adicionados",
      chooseColumn: "Escolher coluna...",
      add: "Adicionar",
      chooseAddValue: "Escolher ou adicionar um valor...",
      newValue: "Novo valor",
      deleteRow: "Excluir linha",
      noTransToApply: "Não há transformações para aplicar.",
      changeWillAffect: "Essa alteração afetará ",
      rows: "linhas",
      cancel: "Cancelar",
      accept: "Aceitar",
    },
  },
  forms: {
    text: "Texto...",
    select: {
      loading: "Carregando...",
      noOptionsAvailable: "Não há opções disponíveis",
    },
  },
  general: {
    yes: "Sim",
    no: "Não",
    accept: "Aceitar",
    add: "ADICIONAR",
    all: "Todos",
    apply: "Aplicar",
    average: "MÉDIA",
    analysis: "Análise",
    bimonthly: "Bimestral",
    bucket: "Bucket",
    buckets: "Buckets",
    bucketGrouper: "Bucket-Agrupador",
    cancel: "Cancelar",
    chooseColumn: "Escolher coluna...",
    chooseColumns: "Escolher colunas...",
    clean: "Limpar",
    cleanAll: "Limpar tudo",
    close: "Fechar",
    column: "Coluna",
    columnas: "Colunas",
    composed: "Compostas",
    confirm: "Confirmar",
    consolidateDestinations: "Consolidar Destinos",
    consolidatePeriods: "Consolidar Períodos",
    count: "CONTAGEM",
    country: "País",
    currencyy: "Moeda",
    foreignCurrency: "Moedas estrangeiras",
    create: "Criar",
    created: "Criadas",
    delete: "Excluir",
    deleteAll: "Excluir todos os elementos",
    divide: "DIVIDIR",
    dividingColumn: "Coluna de divisão",
    dividingColumns: "Colunas de divisão",
    doNotConsolidate: "Não consolidar",
    edit: "Editar",
    emptyInputs: "Todos os campos devem ser preenchidos.",
    errorEmptyField: "O campo não pode ficar vazio",
    expenses: "Despesas",
    file: "Arquivo",
    filter: "Filtrar",
    findByName: "Pesquisar por nome",
    finish: "Finalizar",
    first: "PRIMEIRO",
    function: "Função",
    grouper: "Agrupador",
    home: "Início",
    indicator: "Indicador",
    import: "Importar",
    last: "ÚLTIMO",
    loading: "Carregando...",
    maximum: "MÁXIMO",
    minimum: "MÍNIMO",
    minValueIs: "O valor mínimo é",
    month: "Mês",
    monthly: "Mensal",
    multiply: "MULTIPLICAR",
    name: "Nome",
    none: "Nenhum",
    next: "Próximo",
    noCardsYet: "Ainda não há cartões disponíveis.",
    previous: "Anterior",
    quarterly: "Trimestral",
    repeatedName: "Já existe um nome com esse valor",
    repeatedRowsError: "Existem linhas repetidas",
    repeatedRowsName: "Não é possível adicionar linhas com o mesmo nome.",
    requiredDividingColumn: "A coluna de divisão é obrigatória",
    requiredField: "Campo obrigatório",
    sales: "Vendas",
    searchByName: "Pesquisar por nome",
    seeMore: "ver mais",
    selectFile: "Selecionar arquivo...",
    selectValue: "Selecionar valor...",
    selectValues: "Selecionar valores...",
    selectOption: "Selecionar opção...",
    selectOptions: "Selecionar opções...",
    since: "Desde",
    subtract: "SUBTRAIR",
    substractingColumns: "Colunas de subtração",
    sumProduct: "SOMA PRODUTO",
    type: "Tipo",
    uniqueCount: "CONTAGEM ÚNICA",
    uniqueValues: "VALORES ÚNICOS",
    units: "Unidades",
    currency: "Moeda",
    percentage: "Porcentagem",
    until: "Até",
    usedElement:
      "Este elemento está sendo utilizado por outro e não pode ser eliminado.",
    value: "Valor",
    valueBetween: "O valor deve estar entre",
    variable: "Variável",
    writeName: "Escreva o nome...",
    year: "Ano",
    equal: "Igual a",
    in: "em",
    notIn: "não em",
    greaterThan: "Maior que",
    greaterThanOrEqual: "Maior ou igual a",
    lessThan: "Menor que",
    lessThanOrEqual: "Menor ou igual a",
  },
  generatedDownloads: {
    downloads: "Descarregas",
  },
  header: {
    settings: "Configurações",
  },
  highCharts: {
    analysis: "Análise",
    periods: "Períodos",
    loading: "Loading...",
    filterLoading: "Filtrando a análise...",
    chooseAnalysis: "Escolher análise...",
    filters: "Filtros",
  },
  icons: {
    delete: "Excluir linha",
    deleteAll: "Excluir todos",
    edit: "Editar",
  },
  importElements: {
    importTheElements: "Importar os elementos",
    importElements: "Importar elementos",
    willDeleteAll:
      "Isso excluirá todos os elementos atuais e importará os novos.",
  },
  indicators: {
    getGroups: {
      error:
        "Ocorreu um erro e não foi possível obter os grupos de indicadores.",
    },
  },
  indicatorsManagement: {
    addGroup: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
      codes: {
        200: "O grupo foi adicionado com sucesso",
        201: "O grupo foi adicionado com sucesso",
        400: "Ocorreu um erro e não foi possível adicionar o grupo",
      },
    },
    addIndicator: {
      codes: {
        201: "O indicador foi criado com sucesso",
        400: "Ocorreu um erro e não foi possível criar o indicador",
      },
    },
    editIndicator: {
      codes: {
        200: "O indicador foi atualizado com sucesso",
        400: "Ocorreu um erro e não foi possível editar o indicador",
      },
    },
    deleteIndicator: {
      codes: {
        200: "O indicador foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o indicador",
      },
    },
    putState: {
      codes: {
        200: "O estado foi modificado com sucesso",
        201: "O estado foi modificado com sucesso",
        400: "Ocorreu um erro e não foi possível modificar o estado",
      },
    },
  },
  indicatorsManagementTexts: {
    addGroupModal: {
      groupNameAlreadyExists: "Já existe um grupo com esse nome",
      fieldCannotEmpty: "O campo não pode ficar vazio",
      newGroup: "Novo grupo",
      cancel: "Cancelar",
      accept: "Aceitar",
      name: "Nome",
      newGroupName: "Nome do novo grupo",
    },
    chartCard: {
      modifyingChartState: "Modificando estado do gráfico...",
      baseFile: "Arquivo base",
      analysis: "Análise",
      axisY: "Eixo Y",
      of: "de",
      graphicType: "Tipo de gráfico",
    },
    configCharts: {
      none: "Nenhum",
      creatingGraphic: "Criando gráfico...",
      modifyingGraphic: "Modificando gráfico...",
      editGraphic: "Editar gráfico",
      createGraphic: "Criar gráfico",
      editTheGraphic: "editar o gráfico",
      createTheGraphic: "criar o gráfico",
      resetAllGraphSteps: "Redefinir todos os passos do gráfico",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
    },
    configIndicators: {
      exclude: "EXCLUIR",
      include: "INCLUIR",
      totalInExpense: "Total em despesas",
      totalInResult: "Total em resultados",
      totalTransferred: "Total transferido",
      bucketPeriodsWithError: "Bucket-períodos com erro",
      nameIndicatorExists: "Já existe um indicador com este nome",
      creatingIndicator: "Criando indicador...",
      modifyingIndicator: "Modificando indicador...",
      editIndicator: "Editar indicador",
      createIndicator: "Criar indicador",
      editTheIndicator: "editar o indicador",
      createTheIndicator: "criar o indicador",
      former: "Anterior",
      resetAllIndicatorSteps: "Redefinir todos os passos do indicador",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
    },
    configTables: {
      creatingTable: "Criando tabela...",
      modifyingTable: "Modificando tabela...",
      editTable: "Editar tabela",
      createTable: "Criar tabela",
      editTheTable: "editar a tabela",
      createTheTable: "criar a tabela",
      resetAllTableSteps: "Redefinir todos os passos da tabela",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
      confirmDeleteElement:
        "Se eliminarán todos os elementos subsiguientes.  ¿Confirma que desea eliminar este elemento?",
      tableSample: "Amostra da tabela",
      newAction: "Nova ação",
      steps: {
        initalParametrization: "Parametrização inicial da tabela",
        limitResults: "Limitar resultados",
      },
      selectionAnAction: "Selecione uma ação",
      theAnalysis: "A análise",
      theSalesForecast: "A sales forecast",
      theSimulation: "A simulação",
      theTemplate: "O arquivo",
      sourceCannotModify: "não pode ser modificado após adicionar um elemento.",
    },
    indicatorCard: {
      unitOfMeasurement: "Unidade de medida",
      group: "Grupo",
      active: "Ativo",
      inactive: "Inativo",
    },
    indicatorsManagement: {
      tabs: {
        indicators: "INDICADORES",
        graphics: "GRÁFICOS",
        files: "ARQUIVOS",
        analysis: "ANÁLISES",
        simulation: "SIMULAÇÃO",
        salesForecast: "SALES_FORECAST",
        tables: "TABELAS",
      },
      indicatorConfigFile: "Configuração do Indicador - Arquivo",
      indicatorConfigAnalysis: "Configuración do Indicador -  Análise",
      indicatorConfigAnalysisSimulation:
        "Configuración do Indicador -  Simulação",
      indicatorConfigAnalysisSalesForecast:
        "Configuración do Indicador -  Sales Forecast",
      indicatorConfigTableFile: "Configuração da Tabela - Arquivo",
      indicatorConfigTableAnalysis: "Configuração da Tabela - Análise",
      indicatorConfigTableSimulation: "Table da Tabela - Simulation",
      indicatorConfigTableSalesForecast: "Table da Tabela - Sales Forecast",
      graphicConfigFile: "Configuração do Gráfico - Arquivo",
      graphicConfigAnalysis: "Configuração do Gráfico - Análise",
      graphicConfigAnalysisSimulation: "Configuração do Gráfico - Simulação",
      graphicConfigAnalysisSalesForecast:
        "Configuração do Gráfico - Sales Forecast",
      indicatorAdmin: "Administração de Dashboard",
      uploadingIndicators: "Carregando indicadores...",
      uploadingTables: "Carregando tabelas...",
      uploadingGráficos: "Carregando gráficos...",
      deletingIndicator: "Excluindo indicador...",
      deletingTable: "Excluindo tabela...",
      deletingGraphics: "Excluindo gráfico...",
      searchByNameIndicatorGroup: "Pesquisa por nome de indicador ou grupo",
      searchByTableName: "Pesquisa por nome de tabela",
      activeIndicators: "INDICADORES ATIVOS",
      nonActiveIndicators: "Não há indicadores ativos.",
      inactiveIndicators: "INDICADORES INATIVOS",
      nonInactiveIndicators: "Não há indicadores inativos.",
      activeTables: "TABELAS ATIVAS",
      nonActiveTables: "Não há tabelas ativas.",
      inactiveTables: "TABELAS INATIVAS",
      nonInactiveTables: "Não há tabelas inativas.",
      nonPossibleGetIndicators:
        "Não foi possível obter os indicadores. Tente novamente mais tarde.",
      nonPossibleGetTables:
        "Não foi possível obter as tabelas. Tente novamente mais tarde.",
      searchByNameGraphicCoord: "Pesquisa por nome de gráfico ou coordenadas",
      activeGraphics: "GRÁFICOS ATIVOS",
      nonActiveGraphics: "Não há gráficos ativos.",
      inactiveGraphics: "GRÁFICOS INATIVOS",
      nonInactiveGraphics: "Não há gráficos inativos.",
      nonPossibleGetGraphics:
        "Não foi possível obter os gráficos. Tente novamente mais tarde.",
      deleteTheIndicator: "excluir o indicador",
      deleteTheGraphic: "excluir o gráfico",
      deleteTheTable: "excluir a tabela",
      confirm: "Confirmar",
    },
    step1: {
      simulation: "Simulação",
      salesForecast: "Sales Forecast",
      coin: "Moeda",
      percentage: "Porcentagem",
      units: "Unidades",
      creatingIndicatorGroup: "Criando grupo de indicadores...",
      description: "Descrição",
      indicatorName: "Nome do Indicador",
      unitOfMeasurement: "Unidade de Medida",
      chooseUnit: "Escolher unidade...",
      chooseCreateGroup: "Escolher ou criar grupo...",
      parameterizationIndicator: "Parametrização do indicador",
      file: "Arquivo",
      analysis: "Análise",
      chooseFile: "Escolher arquivo...",
      chooseAnalysis: "Escolher Análise...",
      chooseSimulation: "Escolher Simulação...",
      chooseSalesForecast: "Escolher Sales Forecast...",
      column: "Coluna",
      chooseColumn: "Escolher coluna...",
      function: "Função",
      chooseFunction: "Escolher função...",
      inactiveIndactor: "Indicador Inativo",
      activeIndicator: "Indicador Ativo",
      singleSum: "Soma única",
      uniqueCriteriaColumn: "Coluna de critério único",
      productSum: "Soma de produto",
      columnToMultiply: "Coluna para multiplicar",
      chooseOneOrMoreOptions: "Escolher uma ou mais opções...",
      numerator: "Numerador",
      denominator: "Denominador",
    },
    step1Charts: {
      aplicationOptions: "OPÇÕES DE APLICAÇÃO",
      periods: "períodos",
      fileColumns: "COLUNAS DE ARQUIVO",
      creatingIndicatorGroup: "Criando grupo de indicador...",
      graphParameterization: "Parametrização do gráfico",
      graphicTitle: "Título do gráfico",
      writeGraphicName: "Escrever nome do gráfico",
      graphicNameExists: "Já existe um gráfico com este nome",
      file: "Arquivo",
      chooseFile: "Escolher arquivo...",
      axisY: "Eixo Y",
      chooseColumn: "Escolher coluna...",
      baseColumn: "Coluna base",
      axisYFunction: "Função eixo Y",
      baseColumnFunction: "Função coluna base",
      chooseFunction: "Escolher função...",
      axisYNameOptional: "Nome do eixo Y (opcional)",
      writeAxisYName: "Escrever nome do eixo Y",
      axisX: "Eixo X",
      chooseOption: "Escolher uma opção...",
      axisXNameOptional: "Nome do eixo X (opcional)",
      writeAxisXName: "Escrever nome do eixo X",
      drillDownColumn: "Coluna de detalhamento",
      optional: "opcional",
      serieColumnOptional: "Coluna de séries (opcional)",
      valuesColumnOptional: "Coluna de valores (opcional)",
      none: "Nenhum",
      group: "Grupo",
      chooseCreateGroup: "Escolher ou criar grupo...",
      activeGraphic: "Gráfico Ativo",
      auxiliaryLines: "Linhas auxiliares",
      line0AxisY: "Linha 0 eixo Y",
      averageLineAxisX: "Línea promedio eje X",
      averageLineAxisY: "Línea promedio eje Y",
    },
    viewInfoModal: {
      columns: {
        column: "Coluna",
        values: "Valores",
        criteria: "Critério",
        function: "Função",
        name: "Nome",
      },
      exlude: "Excluir",
      include: "Incluir",
      fileColumn: "Coluna de arquivo",
      analysis: "Análise",
      externFileColumn: "Coluna de arquivo externo",
      criteriaBaseColumn: "Coluna de Base de Critério",
      generalInfoGraph: "Informações gerais do gráfico",
      generalInfoIndicator: "Informações gerais do indicador",
      unitOfMeasurement: "Unidade de medida",
      graphType: "Tipo de gráfico",
      group: "Grupo",
      baseFile: "Arquivo base",
      baseColumn: "Coluna base",
      axisY: "Eixo Y",
      axisYName: "Nome do eixo Y",
      appliedFunction: "Função aplicada",
      validateUniqueColumn: "Coluna para validar únicos",
      multiplyColumn: "Colunas para multiplicar",
      axisX: "Eixo X",
      axisXName: "Nome do eixo X",
      drilldownColumn: "Colunas de detalhamento",
      serialColumns: "Colunas de séries",
      valuesColumn: "Coluna de valores",
      filtersOnFile: "Filtros no arquivo",
      filterOf_one: "Filtrado de ",
      filterOf_two: "com informações de",
      prefilterOnFile: "Filtros prévios no arquivo",
      crossoverValues: "Valores de interseção de",
      with: "com",
    },
    validateWaterfallRow: {
      rowNameAlreadyExists: "Já existe uma linha com este nome",
      repeteadLines: "Existem linhas repetidas",
      allFieldsMustBeCompleted: "Todos os campos devem ser preenchidos",
    },
  },
  indicatorsView: {
    getIndicators: {
      error: "Ocorreu um erro e não foi possível obter os indicadores",
      loading: "Obtendo indicadores...",
    },
    getIndicatorChart: {
      codes: {
        200: "Foi possível obter o gráfico",
        400: "Ocorreu um erro e não foi possível obter o gráfico do indicador",
      },
    },
    indicators: "Indicadores",
    periods: "Períodos",
    nonPossibleGetIndicators:
      "Não foi possível obter os indicadores. Tente novamente mais tarde.",
    noIndicatorsCreated: "Ainda não há indicadores criados.",
  },
  language: {
    languages: "Línguas",
  },
  login: {
    loginLoading: "Iniciando sessão...",
    getInto: "Entrar",
    login: "Iniciar Sessão",
    email: "E-mail",
    password: "Senha",
    loginError: "O usuário ou a senha estão incorretos.",
    loginProblem: "Ocorreu um erro ao iniciar sessão. Tente novamente",
    rememberAuth: "Lembrar código de autenticação",
    storeAuth:
      "Armazenar o código de autenticação de dois fatores por uma semana",
    trust: "Confiar neste computador",
    trustLogin:
      "Mantém a sessão iniciada neste computador por 24 horas. Use somente em computadores não públicos.",
  },
  marketBasket: {
    mergeFiles: "Mesclar arquivos",
    channel: "Canal",
    skuColumn: "Coluna SKU",
    transactionColumn: "Coluna de transação",
    transactionIdentifierColumn: "Coluna de identificação de transação",
    extraColumns: "Colunas extras",
    extraColumnsForAnalysis: "Colunas extras para análise",
    charts: "Gráficos",
    destinyIndicators: "Indicadores de destino",
    brand: "Marca",
    analysisLevel: "Nível de análise",
    currencySales: "Vendas",
    unitSales: "Unidade de vendas",
    periodicity: "Periodicidade",
    primarySkuMetrics: "Métricas SKU primárias",
    secondarySkuMetrics: "Métricas SKU secundárias",
    primaryDestinyMetrics: "Métricas destino primárias",
    secondaryDestinyMetrics: "Métricas destino secundárias",
    objectives: "Objetivos",
    advancedParameters: "Parâmetros avançados",
    advancedParametersOfAlgoritm: "Parâmetros avançados do algoritmo",
    initialParameterization: "Parametrização inicial",
    skuLevelMetrics: "Métricas no nível do SKU",
    destinyLevelMetrics: "Métricas no nível de destino",
    chartsa: "Gráficos",
    indicators: "Indicadores",
    admin: {
      title: "Administração de Market Basket",
      searchByName: "Pesquisar por nome",
      noAnalysisYet: "Ainda não há análises disponíveis.",
      deleteAnalysisConfirm: "Tem certeza de que deseja excluir a análise?",
      confirm: "Confirmar",
      analysisAdmin: "Administração de análises",
      periods: "Períodos",
      analysisInfo: "Informações gerais da análise",
      viewInfoModal: {
        title: "Informações gerais da análise",
        mergeFiles: "Mesclar arquivos",
        channel: "Canal",
        skuColumn: "Coluna SKU",
        transactionColumn: "Coluna de transação",
        extraColumns: "Colunas extras",
        charts: "Gráficos",
        destinyIndicators: "Indicadores de destino",
        brand: "Marca",
        analysisLevel: "Nível de análise",
        currencySales: "Vendas",
        unitSales: "Unidade de vendas",
        periodicity: "Periodicidade",
        primarySkuMetrics: "Métricas SKU primárias",
        secondarySkuMetrics: "Métricas SKU secundárias",
        primaryDestinyMetrics: "Métricas destino primárias",
        secondaryDestinyMetrics: "Métricas destino secundárias",
        objectives: "Objetivos",
        optional: "Opcional",
        advancedParameters: "Parâmetros avançados",
        initialParameterization: "Parametrização inicial",
        skuLevelMetrics: "Métricas no nível SKU",
        destinyLevelMetrics: "Métricas no nível destino",
        chartsa: "Gráficos",
        indicators: "Indicadores",
      },
      delete: {
        codes: {
          200: "A análise foi excluída com sucesso",
          400: "Ocorreu um erro e não foi possível excluir a análise",
        },
        loading: "Excluindo análise...",

        confirmationModal: {
          title: "Excluir análise",
          message: "Tem certeza de que deseja excluir a análise?",
        },
      },
      getAnalysis: {
        codes: {
          200: "A análise foi obtida com sucesso",
          400: "Ocorreu um erro e não foi possível obter a análise",
        },
        loading: "Obtendo análise...",
      },
    },
    config: {
      title: "Configuração de Market Basket",
      analysisName: "Nome da análise",
      codes: {
        200: "A análise foi criada com sucesso",
        201: "A análise foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a análise",
      },
      calculate: {
        codes: {
          200: "A análise foi calculada com sucesso",
          201: "A análise foi calculada com sucesso",
          400: "Ocorreu um erro e não foi possível calcular a análise",
        },
      },
      loading: "Criando análise...",
      calculating: "Calculando análise...",
      confirmationModal: {
        title: "Criar análise",
        message: "Tem certeza de que deseja criar a análise?",
      },
      indicators: "Indicadores",
      customerChain: "Cadeia clientes",
      pointOfSale: "Ponto de venda",
      fileOrAnalysisWithInformation: "Arquivo ou análise com informações",
      warningText:
        "Modificar ou excluir o arquivo limpará todos os campos relacionados.",
      addFont: "Adicionar fonte",
      otherFiles: "Outros arquivos para unir com o arquivo principal.",
      primaryMetricsAtSkuLevel: "Métricas primárias no nível SKU",
      secondaryMetricsAtSkuLevel: "Métricas secundárias no nível SKU",
      secondaryMetricsCalculation: "Cálculo de métricas secundárias",
      primaryMetricsAtDestinyLevel: "Métricas primárias no nível destino",
      secondaryMetricsAtDestinyLevel: "Métricas secundárias no nível destino",
      groupingVariable: "Variável de agrupamento",
      chooseIndicator: "Escolher indicador...",
      newChart: "Novo gráfico",
      clickOnIndicator:
        "Clique no indicador para ver o detalhe da evolução mensal.",
      indicatorExample: "Exemplo de indicador",
      generateIndicatorFor: "Gerar indicador para",
      generateDrilldownMonthlyEvolution:
        "Gerar detalhamento da evolução mensal",
      monthlyEvolutionDrilldown: "Detalhamento da evolução mensal",
      objectivesCollection: "Coleção de objetivos",
      columnTargetValue: "Coluna com valor alvo",
      ownerIdentifierTarget: "Identificador do proprietário do objetivo",
      targetColumnInAnalysis: "Coluna alvo na análise",
      ownerIdentifierTargetInAnalysis:
        "Identificador do proprietário do objetivo na análise",
      confirmCleanAll: "Tem certeza de que deseja limpar todos os campos?",
      minimumSupportApriori: "Suporte Mínimo Apriori",
      minimumSupportSet: "Suporte Mínimo de Conjunto",
      minimumSupportAntecedent: "Suporte Mínimo de Antecedente",
      minimumSupportConsequent: "Suporte Mínimo de Consequente",
      minimumConfidence: "Confiança Mínima",
      minimumLift: "Lift Mínimo",
      unitError: "Existem indicadores sem unidade de medida atribuída.",
    },
    view: {
      rateNotLessMinus100: "A taxa não pode ser menor que -100%",
      valueNotLessZero: "O valor não pode ser menor que 0",
      simulateScenario: "Simular cenário",
      deleteSimulation: "Excluir simulação",
      modifyRateOrValues:
        "Para simular um cenário, modifique os valores da taxa ou do volume de vendas.",
      oportunityForSku: "Oportunidade para SKUs já comprados",
      forSku: "para o SKU",
      scenariosSimulation: "Simulação de cenários",
      simulationDescription:
        "Para simular um cenário, selecione as linhas na tabela que precisam de ajuste e clique em 'Simular Cenário'. Os resultados serão refletidos nos gráficos.",
      COGS_PORCENTUAL: "COGS PERCENTUAL",
      PRECIO_UNITARIO: "PREÇO UNITÁRIO",
      VENTA_BRUTA_POTENCIAL: "VENDA BRUTA POTENCIAL",
      CONTRIBUCION_BRUTA_POTENCIAL: "CONTRIBUIÇÃO BRUTA POTENCIAL",
      VOLUMEN_PROMEDIO: "VOLUME MÉDIO",
      CONTRIBUCION_BRUTA_UNITARIA: "CONTRIBUIÇÃO BRUTA UNITÁRIA",
      ROTACION_PROMEDIO: "GIRO MÉDIO",
      ANTECEDENTS: "ANTECEDENTES",
      ANTECEDENT_SUPPORT: "SUPORTE DE ANTECEDENTE",
      SKU_SUPPORT: "SUPORTE DE SKU",
      SKU_ANTECEDENTS_SUPPORT: "SUPORTE DE SKU E ANTECEDENTES",
      CONFIDENCE: "CONFIANÇA",
      LIFT: "LIFT",
      LEVERAGE: "ALAVANCAGEM",
      CONVICTION: "CONVICÇÃO",
      ZHANGS_METRIC: "MÉTRICA DE ZHANGS",
      ROTACION_CLIENTE: "GIRO DO CLIENTE",
      MES_DESDE_ULTIMA_COMPRA: "MESES DESDE A ÚLTIMA COMPRA",
      VENTA_MONEDA_PROMEDIO: "VENDA MOEDA PROMEDIO",
      VENTA_UNIDADES_PROMEDIO: "VENDA UNIDADES PROMEDIO",
      VENTA_UNIDADES: "VENDA UNIDADES",
      VENTA_MONEDA: "VENDA MOEDA",
      CLIENTES_CON_OPORTUNIDAD: "CLIENTES COM OPORTUNIDADE",
      OPORTUNIDAD: "OPORTUNIDADE",
      OPORTUNIDAD_PARA_FILTRO: "OPORTUNIDADE PARA FILTRO",
    },
  },
  menuItemsOptions: {
    companyParameterization: {
      title: "Parametrização da Empresa",
      url: "parametrizacion-empresa",
    },
    dataManagement: {
      title: "Gestão de Dados",
      url: "administracion-datos",
    },
    validations: {
      title: "Validações",
      adminValidations: {
        title: "Administração de validações",
        url: "administracion-validaciones",
      },
      configCodeMatching: {
        title: "Configurar cruzamento de códigos",
        subtitleColumns: "Columnas",
      },
      matchesDashboard: {
        title: "Painel de Controle de Correspondências",
        url: "configuracion/cruce-codigos",
      },
    },
    criteriaManagement: {
      title: "Gestão de Critérios",
      expensesTarget: {
      title: "Despesas Alvo",
      url: "administracion-receptores-gasto",
      },
      preprorrationCriteria: {
        title: "Critérios Pré-proração",
        url: "criterios-preprorrateo",
      },
      schemasManagement: {
        title: "Gestão de Esquemas",
        url: "administracion-esquemas",
      },
      prorationCriteria: {
        title: "Critérios Prorata",
        url: "criterios-prorrateo",
      },
    },
    schemaManagement: {
      title: "Gestão de Esquemas",
      url: "administracion-esquemas",
    },
    preProrationParameterization: {
      title: "Parametrização Pré-proração",
      url: "parametrizacion-preprorrateo",
    },
    configPreProratedExpenses: {
      title: "Configurar Despesas Pré-proração",
      url: "configurar-gastos-preprorrateo",
    },
    configProRataExpenses: {
      title: "Configurar Despesas Prorata",
      url: "configuracion-gastos",
    },
    modelParameterization: {
      title: "Parametrização do Modelo",
    },
    parameterization: {
      title: "Parametrização",
    },
    controlPanel: {
      title: "Painel de Controle",
      url: "tablero-control",
    },
    dashboard: {
      title: "Dashboard",
      url: "",
    },
    taskManager: {
      title: "Gerenciador de Tarefas",
      url: "gestor-tareas",
    },
    indicatorManagement: {
      title: "Gestão de Dashboard",
      url: "administracion-dashboard",
    },
    dataExportModelling: {
      title: "Data Export Modelling",
      url: "",
    },
    pnLParameterization: {
      title: "Parametrização de P&L",
      costStructure: {
        title: "Estrutura de Custos",
        url: "/parametrizacion-p-l/estructura-costos",
      },
      pnLLDefinition: {
        title: "Definição de P&L",
        url: "/parametrizacion-p-l/definicion",
      },
    },
    simulator: {
      title: "Delta",
    },
    simulatorCollapseList: {
      salesForecast: {
        title: "Sales Forecast",
        url: "/delta/sales-forecast",
      },
      simulationRules: {
        title: "Regras de simulação",
        url: "/delta/administracion-reglas",
      },
      simulations: {
        title: "Administração de simulação",
        url: "/delta/simulaciones",
      },
      registeredRoutes: {
        title: "Administração de rotas",
        url: "/delta/administracion-rutas",
      },
      simulationsResults: {
        title: "Resultados da simulação",
        url: "/delta/resultados-simulacion",
      },
    },
    generatedDownloads: {
      title: "Descarregas",
      url: "descargas-generadas",
    },
    parameterizationCollapseList: {
      assignStages: {
        title: "Atribuir Estágios",
        url: "parametrizacion-modelo/asignacion-etapas",
      },
      criteriaCalculation: {
        title: "Critérios e Cálculo",
        url: "parametrizacion-modelo/calculo",
      },
      calculationReport: {
        title: "Relatório de Cálculo",
        url: "parametrizacion-modelo/reporte",
      },
    },
    dashboardCollapseList: {
      indicators: {
        title: "Indicadores",
        url: "dashboard/indicadores",
      },
      graphics: {
        title: "Gráficos",
        url: "dashboard/graficos",
      },
      analysis: {
        title: "Análises",
        url: "dashboard/highcharts",
      },
      powerBi: {
        title: "Power BI",
        url: "dashboard/powerbi",
      },
    },
    dataExportModellingCollapseList: {
      measureManagement: {
        title: "Gestão de Medidas",
        url: "data-modelling/administracion-medidas",
      },
      groupingsManagement: {
        title: "Gestão de Agrupamentos",
        url: "data-modelling/administracion-agrupaciones",
      },
      analysisManagement: {
        title: "Gestão de Análises",
        url: "data-modelling/administracion-analisis",
      },
      analysisExecution: {
        title: "Execução de Análises",
        url: "data-modelling/ejecucion-analisis",
      },
      downloadReport: {
        title: "Baixar relatório",
        url: "data-modelling/descargar-reporte",
      },
    },
    administrator: {
      title: "Administrador",
      url: "/administrador",
    },
    marketBasketCollapseList: {
      title: "Market Basket",
      admin: {
        title: "Administração de análises Market Basket",
        url: "market-basket/administracion-analisis",
      },
      execution: {
        title: "Execução de análises Market Basket",
        url: "market-basket/ejecucion-analisis",
      },
      analysis: {
        title: "Análises Market Basket",
        url: "market-basket/analisis",
      },
    },
  },
  modelParameterization: {
    sendStages: {
      loading: "Salvando estágios...",
      codes: {
        200: "Os estágios foram atribuídos com sucesso.",
        201: "Os estágios foram atribuídos com sucesso.",
        400: "Ocorreu um erro e não foi possível atribuir os estágios.",
      },
    },
    postCalculateProrration: {
      codes: {
        200: "O cálculo foi iniciado com sucesso.",
      },
    },
    postResetCalculation: {
      codes: {
        200: "O cálculo foi redefinido com sucesso.",
        400: "Ocorreu um erro e não foi possível redefinir o cálculo.",
      },
    },
    propagateCriteria: {
      loading: "Propagando critérios...",
      codes: {
        200: "O critério foi propagado com sucesso.",
        400: "Ocorreu um erro e não foi possível propagar o critério.",
      },
    },
    deleteCriteria: {
      singularCodes: {
        200: "O critério foi excluído com sucesso.",
        400: "Ocorreu um erro e não foi possível excluir o critério.",
      },
      singularLoading: "Excluindo critério...",
      pluralCodes: {
        200: "Os critérios foram excluídos com sucesso.",
        400: "Ocorreu um erro e não foi possível excluir os critérios.",
      },
      pluralLoading: "Excluindo critérios...",
    },
    dataForDummy: {
      codes: {
        200: "Os dados foram carregados com sucesso.",
        400: "Ocorreu um erro e não foi possível carregar os dados.",
      },
    },
    getGrouperRows: {
      loading: "Obtendo informações...",
      codes: {
        400: "Ocorreu um erro e não foi possível baixar as linhas.",
      },
    },
    getCriteriaData: {
      loading: "Obtendo informações...",
      codes: {
        400: "Ocorreu um erro e não foi possível baixar as informações do critério.",
      },
    },
    getProrationResults: {
      codes: {
        200: "Os resultados foram obtidos com sucesso.",
        400: "Ocorreu um erro e não foi possível baixar os resultados.",
        500: "Ocorreu um erro e não foi possível baixar os resultados.",
      },
      loading: "Obtendo resultados...",
    },
  },
  modelParameterizationTexts: {
    buckets: {
      error: "Ocorreu um erro e não foi possível obter os buckets.",
    },
    calculateBucketButton: {
      rowsWithoutAssignedCriteria:
        "É necessário que todas as linhas tenham um critério atribuído para iniciar o cálculo do Bucket.",
      amountIsZero:
        "Não é possível iniciar o cálculo do bucket se a despesa for $0.",
      calculated: "Calculado",
      notCalculated: "Calcular bucket",
      calculateActualBucket: "calcular o bucket atual",
      calculateBucketWithNotTransferred:
        "calcular bucket com despesas não transferidas",
    },
    calculations: {
      title: "Parametrização do modelo: Atribuição de critério e cálculo",
      periods: "Períodos",
    },
    charts: {
      origin: "Origem",
      percentage: "Porcentagem",
      stage: "Estágio",
      coin: "Moeda",
      formatterTitle: "Porcentagem em relação à despesa de origem",
    },
    criteriaModelContent: {
      propagatingCriteria: "Propagando critérios...",
      selectReceiver: "Selecionar receptor",
      selectCriteria: "Selecionar critério",
      apportionmentCriteria: "Critério de Prorrateamento",
      destinationCriteria: "Atribuir critérios diferenciados por destino",
      only50rows: "Válido apenas para até 50 linhas",
      completeFields: "Preencha todos os campos",
      finalRecipientOfExpense: "RECEPTOR FINAL DA DESPESA",
      chooseReceiver: "Escolher receptor...",
      confirm: "Confirmar",
      cancel: "Cancelar",
      confirmationMessage:
        "Confirma a atribuição dos critérios? Essa ação irá redefinir o cálculo se já tiver sido iniciado.",
    },
    customModelCell: {
      errorDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
    },
    customToolbar: {
      confirmationWithRowsFirstPart: "Confirma a exclusão dos critérios de ",
      confirmationWithRowsSecondPart:
        "linhas? Essa ação irá redefinir o cálculo.",
      confirmationsWithoutRows:
        "Confirma a exclusão dos critérios da linha? Essa ação irá redefinir o cálculo.",
      downloadingCSV: "Baixando arquivo CSV...",
      deletenCriteria: "Excluindo critério",
      exportCSV: "Exportar para CSV",
      totalCost: "Despesa Total",
      noCostDifInStageZero: "Não há despesas diferenciadas no estágio 0.",
      noCostDif: "Não há agrupadores com despesas diferenciadas.",
      difByDestiny: "Diferenciar por destino",
      noRowsSelectedWithCriterias:
        "Nenhuma linha selecionada com critérios para exclusão.",
      deleteCriterias: "Excluir critérios",
      noRowsSelected: "Nenhuma linha selecionada.",
      difReceivers:
        "Não é possível atribuir critérios a agrupadores com receptores diferentes.",
      noCriteriaStage0: "Não é possível atribuir critérios ao estágio 0.",
      assingCriteria: "Atribuir Critérios",
      confirm: "Confirmar",
      close: "Fechar",
      accept: "Aceitar",
    },
    customTypographyTableText: {
      mount: "montante",
    },
    dummyGrouperGroup: {
      groupers: "Agrupadores",
      expenseColumn: "Coluna de Despesa",
      salesFileColumn: "Coluna de Arquivo de Vendas",
    },
    iterationsButtons: {
      iterations: "ITERAÇÕES",
    },
    getGroupingColDef: {
      grouper: "Agrupador",
      collapse: "Colapsar",
      expand: "Expandir",
    },
    calculationTabValue: {
      nonTransferredExpenses: "DESPESAS NÃO TRANSFERIDAS",
    },
    modelTable: {
      succesEditName: "O nome foi editado com sucesso.",
      errorEditName: "Ocorreu um erro ao editar o nome.",
      grouper: "agrupador",
      all: "todos",
      withoutCritera: "sem critério",
      editName: "Editar nome",
      newName: "Novo nome...",
      apply: "Aplicar",
      editingName: "Editando nome...",
      putNewName: {
        codes: {
          200: "O nome foi editado com sucesso.",
          400: "Ocorreu um erro ao editar o nome.",
        },
        loading: "Editando nome...",
      },
      getCriteriaTargetError:
        "Ocorreu um erro e não foi possível obter os receptores.",
      getCriteriaError:
        "Ocorreu um erro e não foi possível obter os critérios.",
    },
    onChangeSelectValue: {
      stage: "etapa",
      column: "coluna",
      doNotSend: "não enviar",
    },
    useRadioGroup: {
      doNotValidate: "não validar",
      doNotValidateLabel: "Não Validar",
      flaw: "defeito",
      flawLabel: "Padrão",
      other: "outro",
      otherLabel: "Outra coluna",
      file: "Arquivo",
      column: "Coluna",
      chooseColumn: "Escolher coluna",
    },
    resetButton: {
      toReset: "Redefinir",
      resetOptions: "OPÇÕES DE REDEFINIÇÃO",
      currentStageCalculation: "o cálculo da etapa atual",
      resetCurrentStageCalculation: "Redefinir cálculo da etapa atual",
      allCalculation: "todo o cálculo",
      resetAllCalculation: "Redefinir todo o cálculo",
      calculationStageBucket: "o cálculo e etapas do bucket",
      resetCalculationStageBucket: "Redefinir cálculo e etapas do bucket",
      allCalculationBucketStages: "todos os cálculos e etapas",
      resetAllCalculationStages: "Redefinir todos os cálculos e etapas",
      currentIterationCalculation: "o cálculo da iteração atual",
      resetCurrentIterationCalculation: "Redefinir cálculo da iteração atual",
    },
    stagesButtons: {
      stage: "ETAPA",
    },
    step2: {
      tabValueExpenses: "DESPESAS",
      tabValueExpensesNotTransferred: "DESPESAS NÃO TRANSFERIDAS",
      calculateEverything: "calcular tudo",
      calculateEverythingTitle: "Calcular Tudo",
      startingCalculation: "Iniciando cálculo...",
      resetting: "Redefinindo...",
      checkPreviousStageCalculationError:
        "Ocorreu um erro e há agrupadores duplicados. Verifique o cálculo da etapa anterior.",
      assignCriteria: "Atribuir Critério",
      resetCalcultatonStageBucket: "redefinir o cálculo e etapas do bucket",
      resetAllCalculationsStages: "redefinir todos os cálculos e etapas",
      confirmPartOne: "Confirma que deseja ",
      confirmPartTwo:
        " Essa ação o redirecionará de volta para a atribuição de etapas.",
      confirm: "Confirmar",
      stillExpensesNotTransferredStage:
        "Ainda há despesas não transferidas na etapa",
      confirmStartCalculationAnyway:
        "Confirma que deseja iniciar o cálculo mesmo assim?",
      confirmStartCalculation: "Confirmar início do cálculo",
      editCriteria: "Editar Critério",
      apportionment: "PRORRATEAMENTO",
      reassingNewCriteriaInAllGroupersWithReceiverAndOldCriteria:
        "reatribuir o novo critério em todos os agrupadores com o mesmo receptor e critério antigo",
      reassingCriteriaToRows: "Reatribuir critério às linhas",
      close: "Fechar",
      resetCalculationCurrentIteration: "redefinir o cálculo da iteração atual",
      all: "todos",
      calculateCurrentBucket: "calcular o bucket atual",
    },
    switchListSecondary: {
      onlyGroupersWithDifferentiatedExpenses:
        "Apenas os agrupadores com despesas diferenciadas são mostrados.",
      recordedInGrouperMessagePartOne:
        "registros neste agrupador. Por questões de desempenho, o limite para diferenciar despesas por destino é de ",
      recordedInGrouperMessagePartTwo: "registros",
      noGroupersWithDifferentiatedExpenses:
        "Não há agrupadores com despesas diferenciadas.",
    },
    totalAmount: {
      expense: "Despesa",
    },
    transformData: {
      destination: "destino",
      oldReceiverCode: "código receptor antigo",
      totalExpense: "Despesa total",
    },
    nestedList: {
      flaw: "defeito",
      relatedGroupers: "Agrupadores relacionados:",
    },
    viewDummyModal: {
      expensesColumn: "Coluna de Despesas",
      fileSalesColumn: "Coluna de Arquivo de Vendas",
      stage: "etapa",
      dummyDirectTransfer: "Transferência Direta Dummy",
      criteriaInfo: "Informações do Critério",
      finalRecieverExpense: "Receptor Final de Despesas",
    },
    columnsTreeData: {
      getColumns: {
        grouper: "agrupador",
        amount: "monto",
        stages: "etapas",
        amountExpense: "MONTO GASTO",
      },
      getGroupingColDef: {
        bucketExpense: "MONTANTE DO BUCKET",
        collapse: "Colapsar",
        expand: "Expandir",
      },
    },
    formattedRows: {
      missingAssingStages: "Faltam etapas para atribuir",
      assignStages: "Etapas atribuídas",
    },
    stages: {
      stages: "Etapas",
      nowRowsSelected: "Nenhuma linha selecionada",
      assignToSelected: "Atribuir aos selecionados",
      stage: "etapa",
    },
    stagesSettings: {
      stagesSettingTitle: "Parametrização do modelo: Atribuição de etapas",
      periods: "Períodos",
    },
    step1: {
      stagesGreaterThan10: "O número de etapas não pode ser maior que 10",
      stagesGreaterThan0: "O número de etapas deve ser maior que 0",
      missingToAssingStages: "Faltam etapas para alguns agrupadores.",
      editingCauseCleared:
        "Editar o número de etapas de um agrupador irá apagar sua atribuição de critério se houver uma.",
      assigningStages: "Atribuindo etapas",
    },
    treeDataCustomGroupingColumn: {
      successfullyEditName: "O nome foi editado com sucesso.",
      errorEditName: "Ocorreu um erro ao editar o nome.",
      all: "todas",
      noRows: "sem linhas",
      errorNotPossibleToDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
      editName: "Editar nome",
      newName: "Novo nome...",
      apply: "Aplicar",
      unifiedBucketsGroupers:
        "Se o novo nome do bucket coincidir com um nome de bucket já existente, eles e seus agrupadores serão unificados. Essa ação não pode ser desfeita.",
      editingName: "Editando nome...",
    },
    customCheckbox: {
      all: "Todas",
      withoutCriteria: "sem critério",
      rowsWithoutCriteria: "Linhas sem critério atribuído",
      withoutRow: "sem linha",
      rowsWithoutStages: "Linhas sem etapa atribuída",
    },
    customGridTreeDataGroupingCell: {
      editName: "Editar nome:",
    },
    dummyTransfer: "TRANSFERÊNCIA DUMMY",
    validationDropdown: {
      codeValidation: "Validação de Códigos",
      stage: "Etapa",
    },
  },
  pageinProgress: {
    underDevelopment: "Esta página ainda está em desenvolvimento.",
  },
  periods: {
    multipleSelectCheckmarks: {
      periods: "Períodos",
    },
    enabledPeriodsTable: {
      year: "Ano",
      enabledPeriods: "Períodos habilitados",
      notEnabledPeriods: "Períodos não habilitados",
      nonExistingPeriods: "Períodos não existentes",
    },
    periodsDialogContent: {
      filterPeriodsSuccess:
        "Os filtros para períodos foram aplicados com sucesso.",
      errorSendingPeriods: "Erro ao enviar os períodos",
      applyingPeriods: "Aplicando períodos...",
      filter: "Filtrar",
      noPeriodsExported: "Nenhum período exportado",
      selectAll: "Selecionar Todos",
      cleanAll: "Limpar Todos",
      enabledPeriods: "Períodos habilitados",
      onlyWithExpenses: "mostrar apenas os que têm despesas",
      noPeriodsAvailableYet: "Ainda não há períodos disponíveis",
      cancel: "Cancelar",
      apply: "Aplicar",
    },
    periodsFilter: {
      periods: "Períodos",
    },
  },
  persistLogin: {
    logoutLoading: "Fechando sessão...",
    role: "Cliente",
  },
  preprorration: {
    preprorrationCalculate: {
      loading: "Iniciando cálculo...",
      codes: {
        200: "O cálculo foi iniciado com sucesso",
        201: "O cálculo foi iniciado com sucesso",
      },
    },
  },
  preprorationParameterization: {
    getPreprorationBuckets: {
      loading: "Obtendo buckets...",
      error: "Ocorreu um erro e não foi possível baixar os buckets.",
    },
    getPreprorationGrouper: {
      loading: "Obtendo agrupadores...",
      error: "Ocorreu um erro e não foi possível baixar os agrupadores.",
    },
    schemaInfoModal: {
      bucketExpenseUnderscore: "BUCKET_DESPESA",
      bucketExpense: "BUCKET DESPESA",
      grouper: "AGRUPADOR",
      percentage: "Porcentagem",
      criteriaOutline: "Esquema de Critérios",
    },
    api: {
      getPreprorationCriterias: {
        errorReceiveCriteria: "Erro ao receber os critérios",
      },
    },
    newSchemaContent: {
      criteria: "critério",
      percentage: "Porcentagem",
      schemaWithNameAlreadyExists: "Já existe um esquema com este nome",
      necessaryCompleteFields: "Todos os campos precisam ser preenchidos",
      alreadyCriteriaApplied:
        "Já existe um critério aplicado no destino selecionado",
      percentageCannotLessOne: "A porcentagem não pode ser menor que 1%",
      alreadyPercentageApplied:
        "Já existe uma porcentagem aplicada no destino selecionado",
      percentageCannotExceedOneHundred:
        "A porcentagem total não pode ser maior que 100%",
      necessaryCompletFieldsForSchema:
        "Todos os campos precisam ser preenchidos para criar um esquema",
      totalPercentageMustBeOneHundred:
        "A porcentagem total dos critérios deve ser 100%",
      schemaName: "Nome do Esquema",
      bucketExpense: "Bucket Despesa",
      bucketDestination: "bucket destino",
      grouperDestination: "agrupador destino",
      add: "Adicionar",
      chooseAddValue: "Escolher ou adicionar valor...",
      chooseCriteria: "Escolher critério...",
      cancel: "Cancelar",
      save: "Salvar",
      choosePercentage: "Escolher porcentagem...",
    },
    preprorationParameterization: {
      tabs: {
        bucketExpense: "Bucket Despesa",
        grouper: "Agrupador",
        noExpensesToPreprorate: "Não há despesas para preprorratear em",
      },
      schemaTabs: {
        selectSchema: "Selecionar Esquema",
        createSchema: "Criar Esquema",
      },
      criteria: "critério",
      applySchemas: {
        codes: {
          200: "A aplicação de esquema/s foi exitosa",
          201: "A aplicação de esquema/s foi exitosa",
          400: "Ocorreu um erro e não foi possível aplicar o esquema",
        },
      },
      createSchema: {
        codes: {
          200: "Esquema criado com sucesso",
          201: "Esquema criado com sucesso",
          400: "Erro ao criar o esquema",
        },
      },
      reverseCalculationMessages: {
        codes: {
          200: "Cálculo revertido com sucesso",
          201: "Cálculo revertido com sucesso",
          400: "Erro ao reverter o cálculo",
        },
      },
      successApplySchemas: "Esquemas aplicados com sucesso.",
      successApplySchema: "Esquema aplicado com sucesso.",
      errorApplySchema: "Erro ao aplicar o esquema",
      successCreateSchema: "Esquema criado com sucesso",
      errorCreateSchema: "Erro ao criar o esquema",
      bucketExpensesUnderscore: "BUCKET_DESPESA",
      successReversedCalculation: "Cálculo revertido com sucesso.",
      errorReversedCalculation: "Erro ao reverter o cálculo",
      schemasToApplyMissing:
        "O cálculo não pode ser realizado, faltam esquemas a serem aplicados",
      noDataToCalculation: "Não há dados para calcular",
      applyingSchemas: "Aplicando esquemas...",
      applyingSchema: "Aplicando esquema...",
      creatingSchema: "Criando esquema...",
      reversingCalculation: "Revertendo cálculo...",
      settingUpPreproration: "Configuração de Preproração",
      periods: "Períodos",
      allRowsNeedSchemas:
        "Todas as linhas precisam ter esquemas aplicados para iniciar o cálculo",
      calculate: "Calcular",
      reverseCalculation: "Reverter cálculo",
      assingSchema: "Atribuir esquema",
      errorDownloadingInfo:
        "Ocorreu um erro e não foi possível baixar as informações.",
      noExpensesToPreprorate: "Não há despesas para preprorratear em",
      criteriaOutline: "Esquema de Critérios",
      startCalculation: "iniciar o cálculo de preproração",
      resetCalculation: "reiniciar o cálculo de preproração",
      confirmCalculation: "Confirmar cálculo",
      confirmReset: "Confirmar reinício",
    },
    selectSchemaContent: {
      bucketExpense: "Bucket Despesa",
      bucketExpenseUnderscore: "BUCKET_DESPESA",
      grouper: "Agrupador",
      criteria: "Critério",
      chooseExistingSchema: "Escolher um esquema existente",
      selectSchema: "Selecionar esquema...",
      detail: "Detalhe",
      schemaWillAplly: "O esquema será aplicado a",
      row: "linha",
      selected: "selecionada",
      cancel: "Cancelar",
      apply: "Aplicar",
    },
  },
  report: {
    percentage: "Porcentagem",
    incorrectCalculation: "Cálculo incorreto",
    expensesTotal: "Total em despesas",
    resultsTotal: "Total em resultados",
    totalTransferred: "Total trasladado",
    bucketPeriodsError: "Bucket-períodos com erro",
    getPercentages: {
      loading: "Obtendo dados de períodos...",
      error: "Ocorreu um erro ao obter os dados de períodos, tente novamente.",
    },
    getKpis: {
      loading: "Obtendo KPIs...",
      error: "Ocorreu um erro ao obter os KPIs, tente novamente.",
    },
    getFiles: {
      loading: "Obtendo arquivos...",
      error: "Ocorreu um erro ao obter os arquivos, tente novamente.",
    },
    getAnalysis: {
      loading: "Obtendo análises...",
      error: "Ocorreu um erro ao obter as análises, tente novamente.",
    },
    getPeriods: {
      loading: "Obtendo períodos...",
      codes: {
        200: "Os períodos foram obtidos com sucesso.",
        400: "Ocorreu um erro ao obter os períodos, tente novamente.",
      },
      error: "Ocorreu um erro ao obter os períodos, tente novamente.",
    },
    getCharts: {
      loading: "Obtendo gráficos...",
      error: "Ocorreu um erro ao obter os gráficos, tente novamente.",
    },
  },
  reportTexts: {
    graphicsTab: {
      failedDownload: "Não foi possível baixar informações",
      downloadingIndicators: "Baixando indicadores...",
      resultsIndicators: "INDICADORES DE RESULTADOS",
      period: "Período",
    },
    preprorrationErrorsTable: {
      failedDownload: "Não foi possível baixar informações",
      preProrrated: "PREPRORRATEO",
    },
    preprorrationReportTab: {
      tabs: {
        transferredPercentage: "PORCENTAGEM TRASLADADA",
        destinationsByGroup: "DESTINOS POR GRUPO",
      },
    },
    preprorrationSumTable: {
      failedDownload: "Não foi possível baixar informações",
      preProrrated: "PREPRORRATEO",
    },
    transformDataForSumTable: {
      columns: {
        group: "GRUPO",
        destination: "DESTINO",
        period: "PERÍODO",
        amountExpense: "MONTO_DESPESA",
        destinationType: "TIPO_DESTINO",
        percentage: "PORCENTAGEM",
        grouper: "agrupador",
        transferred: "trasladado",
        amountExpenseResult: "MONTO_DESPESA_RESULTADO",
        amountExpenseOrigin: "MONTO_DESPESA_ORIGEM",
        relativeError: "ERRO_RELATIVO",
        correctCalculation: "CÁLCULO_CORRETO",
      },
      coin: "moeda",
    },
    prorrationDifTable: {
      failedDownload: "Não foi possível baixar informações",
      apportionment: "PRORRATEO",
    },
    prorrationErrorTable: {
      failedDownload: "Não foi possível baixar informações",
      apportionment: "PRORRATEO",
    },
    prorrationIterationTable: {
      failedDownload: "Não foi possível baixar informações",
      apportionment: "PRORRATEO",
    },
    prorrationReportTab: {
      tabs: {
        percentageTransfer: "PORCENTAGEM DE TRASLADO",
        notTransferredExpenses: "DESPESAS NÃO TRASLADADAS",
        transferredPerIterationExpenses: "DESPESAS TRASLADADAS POR ITERAÇÃO",
      },
    },
    transformDataForDifTable: {
      columns: {
        period: "período",
        bucketExpense: "bucket-despesa",
        grouper: "agrupador",
        mount: "montante",
        result: "resultado",
        difference: "diferença",
        transferred: "trasladado",
      },
    },
    transformDataForErrorsTable: {
      destination1: "DESTINO_1",
      destination: "DESTINO",
      expenseMount: "MONTO_DESPESA",
      coin: "moeda",
    },
    getColumnsForErrorsTable: {
      columns: {
        bucket: "BUCKET",
        destination: "DESTINO",
        stage: "ETAPA",
        period: "PERÍODO",
      },
    },
    transformDataForIterationTable: {
      columns: {
        solved: "SOLUCIONADO",
        expenseMount: "MONTO_DESPESA",
        coin: "moeda",
      },
    },
    getColumnsForIterationTable: {
      columns: {
        mount: "MONTO",
        grouper: "AGRUPADOR",
        channel: "CANAL",
        period: "PERÍODO",
        stage: "ETAPA",
        bucket: "BUCKET",
        iteration: "ITERAÇÃO",
      },
    },
    calculationReport: {
      tabs: {
        graphics: "GRÁFICOS",
        approrateReport: "REPORTE DE PRORRATEO",
        preProrateReport: "REPORTE DE PREPRORRATEO",
      },
      calculationReport: "Reporte de cálculo",
    },
    kpi: {
      coin: "moeda",
      units: "unidades",
      totalInExpenses: "Total em despesas",
      totalInResults: "Total em resultados",
      totalTransferred: "Total trasladado",
      bucketPeriodsError: "Bucket-períodos com erro",
    },
    kpiError: {
      incorrectCalculation: "Cálculo incorreto",
    },
    periodChart: {
      periods: "Períodos",
      percentageExchange: "% de cambio",
      percentage: "porcentaje",
    },
  },
  simulator: {
    postRule: {
      loading: "Criando regra...",
      codes: {
        200: "A regra foi criada com sucesso",
        201: "A regra foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a regra",
      },
    },
    putRule: {
      codes: {
        200: "A regra foi alterada com sucesso",
        201: "A regra foi alterada com sucesso",
        400: "Ocorreu um erro e não foi possível alterar a regra",
      },
    },
    getRules: {
      error: "Ocorreu um erro e não foi possível obter as regras",
      codes: {
        200: "As regras foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as regras",
        404: "Ocorreu um erro e não foi possível obter as regras",
      },
    },
    deleteRules: {
      error: "Ocorreu um erro e a regra não pôde ser excluída",
      codes: {
        200: "A regra foi removida",
        400: "Ocorreu um erro e a regra não pôde ser excluída",
        404: "Ocorreu um erro e a regra não pôde ser excluída",
      },
    },
    getSchemas: {
      error: "Ocorreu um erro e não foi possível obter o esquema",
      codes: {
        200: "Os esquemas foram obtidos",
        400: "Ocorreu um erro e não foi possível obter o esquema",
        404: "Ocorreu um erro e não foi possível obter o esquema",
      },
    },
    postSchema: {
      cargando: "Criando esquema...",
      codes: {
        200: "O esquema foi criado com sucesso",
        201: "O esquema foi criado com sucesso",
        400: "Ocorreu um erro e o esquema não pôde ser criado",
      },
    },
    putSchema: {
      codes: {
        200: "O esquema foi modificado com sucesso",
        201: "O esquema foi modificado com sucesso",
        400: "Ocorreu um erro e o esquema não pôde ser modificado",
      },
    },
    deleteSchema: {
      error: "Ocorreu um erro e o esquema não pôde ser excluído",
      codes: {
        200: "Esquema foi excluído",
        400: "Ocorreu um erro e o esquema não pôde ser excluído",
        404: "Ocorreu um erro e o esquema não pôde ser excluído",
      },
    },
    postRoute: {
      loading: "Criando rota...",
      codes: {
        200: "A rota foi criada com sucesso",
        201: "A rota foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a rota",
      },
    },
    putRoute: {
      codes: {
        200: "A rota foi alterada com sucesso",
        201: "A rota foi alterada com sucesso",
        400: "Ocorreu um erro e não foi possível alterar a rota",
      },
    },
    getRoutes: {
      error: "Ocorreu um erro e não foi possível obter as rotas",
      codes: {
        200: "As rotas foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as rotas",
        404: "Ocorreu um erro e não foi possível obter as rotas",
      },
    },
    deleteRoutes: {
      error: "Ocorreu um erro e a configuração não pôde ser excluída",
      codes: {
        200: "A configuração foi excluída",
        400: "Ocorreu um erro e a configuração não pôde ser excluída",
        404: "Ocorreu um erro e a configuração não pôde ser excluída",
      },
    },
    getAnalysisValues: {
      error: "Ocorreu um erro e não foi possível obter os parâmetros",
      codes: {
        200: "Os parâmetros foram obtidos",
        400: "Ocorreu um erro e não foi possível obter os parâmetros",
        404: "Ocorreu um erro e não foi possível obter os parâmetros",
      },
    },
    getSimulationsResults: {
      error: "Ocorreu um erro e não foi possível obter as simulações",
      codes: {
        200: "As simulações foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as simulações",
        404: "Ocorreu um erro e não foi possível obter as simulações",
      },
    },
    getSimulationResult: {
      error: "Ocorreu um erro e não foi possível obter a simulação",
      codes: {
        200: "Simulação obtida",
        400: "Ocorreu um erro e não foi possível obter a simulação",
        404: "Ocorreu um erro e não foi possível obter a simulação",
      },
    },
  },
  simulatorTexts: {
    simulations: {
      simulationAdmin: {
        simulationAdmin: "Administração de simulação",
        noSimulationsYet: "Ainda não há simulação disponíveis.",
        step1SimulationConfig: {
          simulationName: "Nome da Simulação",
        },
      },
      simulationConfig: {
        simulationConfig: "Configuração de Simulação",
      },
    },
    salesForecast: {
      newSalesForecastModal: {
        create: "Criar",
        salesForecast: "Sales Forecast",
        cannotRepitAnalysis: "Uma análise não pode ser repetida.",
      },
    },
    simulationsRulesAdmin: {
      simulationAdmin: "Regras de Simulação",
      loading: "Carregando...",
      deletingRule: "Excluindo regra...",
      searchByName: "Pesquisa por nome",
      noSimulationsRulesYet: "Ainda não há regras disponíveis.",
      deleteSimulationRule: "excluir a regra de simulação",
      confirm: "Confirmar",
      cannotDeleteRuleAsBeingUsed:
        "Esta regra não pode ser excluída porque está sendo usada.",
      cannotDeleteSchemaAsBeingUsed:
        "Não é possível eliminar o esquema deste, pois ele está sendo utilizado.",
      deleteAssociatedSimulation:
        "Todas as simulações associadas serão removidas.",
    },
    configSimulationRule: {
      simulationRuleConfig: "Configuração de Regra de simulação",
      loading: "Carregando...",
      simulationName: "Nome da simulação",
      ruleName: "Nome da regra",
      alreadyExisteRuleName: "Já existe uma regra com esse nome",
      analysisToUse: "Análise para usar",
      chooseAnalysis: "Escolha Análise",
      noValuesCalculated:
        "Os valores necessários para criar a regra de simulação não foram calculados. Verifique os dados e tente novamente.",
      costVariability: "Variabilidade de Custos",
      assignMultiple: "Atribuir a vários",
      dependentVariable: "Driver de custo...",
      cancel: "Cancelar",
      edit: "Editar",
      save: "Salvar",
      editSimulationRule: "Editar regra",
      createSimulationRule: "criar uma nova regra de simuação",
      newSimulationRule: "Nova regra",
      compundsMeasures: "MEDIDAS COMPOSTAS",
      salesMeasures: "MEDIDAS VENDAS",
      default: "POR PADRÃO",
      quantityRoutes: "QUANTIDADE_RUTAS",
      selectAll: "TUDO",
      selectAllWithOutDriver: "SEM DRIVER",
      selectAllWithOutPL: "SEM P&L",
      editRuleWillDeleteSimulationsPeriods:
        "Os períodos de simulação associados serão executados novamente",
    },
    simulationRuleCard: {
      analysisToUse: "Análise a Usar:",
    },
    viewInfoRuleModal: {
      infoSimulationRule: "Informações de Regra de simulação",
      rule: "Regra",
      analysisToUse: "Análise a Usar:",
      columns: {
        measure: "MEDIDA",
        variability: "VARIABILIDADE",
        costDriver: "DRIVER DO CUSTO",
        plLine: "LINHA P&L",
      },
    },
    inputSlider: {
      fixed: "fixo",
      variable: "variável",
    },
    assignVariabilityModal: {
      assignVariability: "Atribuir variabilidade",
      cancel: "Cancelar",
      accept: "Aceitar",
      dependentVariable: "Drive de custo...",
      plLine: "Atribuir à linha P&L...",
    },
    registerRoutes: {
      configRegisterRoute: {
        registeredRoute: "REGISTRO DE ROTA",
        canceledRoute: "CANCELAMENTO DE ROTA",
        modifiedRoute: "MODIFICAÇÃO DE ROTA",
        registered: "ALTO",
        canceled: "BAIXO",
        modification: "MODIFICAÇÃO",
        registeredRouteConfig: "Configuração de rota",
        loading: "Carregando...",
        configName: "Nome da configuração",
        routeNameAlreadyExists: "Já existe uma rota com esse nome",
        simulationRule: "Regra de simulação",
        chooseSimulationRule: "Escolha uma regra de simulação...",
        simulationSchema: "Esquema de simulação",
        chooseSimulationSchema: "Escolha um esquema de simulação...",
        simulationSchemaCannotChange:
          "Uma vez que uma configuração é adicionada, o esquema de simulação não pode ser alterada.",
        newRegisteredRoute: "Registro de Rota",
        modifyAclaration:
          "Para editar, selecione apenas uma entrada de rota por vez.",
        modify: "Modificar",
        delete: "Excluir",
        noRegistrationConfigYet:
          "Ainda não há configurações de Registro de Rota",
        noCancelationConfigYet:
          "Ainda não há configurações de Cancelamento de Rota",
        noModificationConfigYet:
          "Ainda não há configurações de Modificação de Rota",
        cancel: "Cancelar",
        edit: "Editar",
        save: "Salvar",
        editConfiguration: "editar a configuração",
        saveConfiguration: "salvar a configuração",
        newRegisterRoute: "Configuração de rota",
        deleteRegisterRouteConfiguration: {
          title: "Excluir configuração",
          message: "excluir a configuração",
        },
        columnGroupingModel: {
          balance: "Balançado",
          currentRoutes: "Rotas Atuais",
          registeredRoutes: "Registros de Rota",
          newRoutes: "Rotas Totais",
          simulationParams: "Parâmetros de Simulação",
          volumeProjectioMode: "Modo de projeção de volume",
          averageMonthlyVolumePerRoute: "Volume Médio Mensal por Rota",
          averageMonthlyVolumePerRouteActual: "VMMxR: Atual",
          averageMonthlyVolumePerRouteAdjustment: "VMMxR: Ajuste",
          averageMonthlyVolumePerClient: "Volume médio mensal por Cliente",
          averageMonthlyVolumePerClientActual: "VMMxC: Atual",
          averageMonthlyVolumePerClientAdjustment: "VMMxC: Ajuste",
          averagePrice: "Preço Médio",
          clientePerRoute: "Clientes por Rota",
          clientePerRouteActual: "CxR: Atual",
          clientePerRouteAdjustment: "CxR: Ajuste",
        },
      },
      inputAdjustmentRate: {
        adjustmentRate: "Taxa de ajuste",
        adjustedValue: "Valor ajustado",
      },
      newRouteModal: {
        noRoutesSelectedFiltered: "Não há rotas para os filtros selecionados",
        steps: {
          paramSelection: "Seleção de filtro",
          settings: "Configurações",
          routeResults: "Resultados da rotas",
          routesSelection: "Seleção de rotas",
          cancelRoutes: "Rotas para cancelar",
          routesToModify: "Rotas para modificar",
        },
        rows: {
          volumeProjectionMode: "Modo de projeção de volume",
          averageMonthlyVolumePerRouteActual: "VMMxR: Atual",
          averageMonthlyVolumePerRouteAdjustment: "VMMxR: Ajuste",
          volumePerOutletActual: "VMMxC: Atual",
          volumePerOuletAdjustment: "VMMxC: Ajuste",
          averagePrice: "Preço Médio",
          customerPerRouteActual: "CxR: Atual",
          customerPerRouteAdjustment: "CxR: Ajuste",
          actualRoutes: "Rotas Atuais",
          registerRoutes: "Registros de Rota",
          newRoutes: "Rotas Totais",
        },
        goBack: "Voltar",
        finish: "Finalizar",
        continue: "Continuar",
        closeModalWithInfo:
          "Fechar configurações de rota, mesmo que haja informações importantes",
        routeRegistration: "Registro de Rota",
        loading: "Carregando...",
      },
      registerRouteCard: {
        schema: "Esquema",
      },
      registerRoutesAdmin: {
        searchByName: "Pesquisar por nome",
        registerRoute: "Administração de Rotas",
        loading: "Carregando...",
        notRegisteredRouteYet: "Ainda não há configurações de rota disponíveis",
        deleteRegisterRoute: "Excluir configuração de rota",
        confirm: "Confirmar",
      },
      step1NewRouteModal: {
        paramSelection: "SELEÇÃO DE FILTRO",
        column: "Coluna",
        selectColumn: "Selecione a coluna...",
        values: "Valores",
        chooseValue: "Escolha o valor...",
        noFilterYet: "Você ainda não adicionou nenhum filtro adicional.",
      },
      step2NewRouteModal: {
        volumenProjectionModeOptions: {
          averageMonthlyVolumePerClient: "Volume médio mensal por Cliente",
          totalRoute: "Rota total",
        },
        adjustments: "Ajustes",
        volumeProjectionMode: "Modo de projeção de volume",
        chooseProjectionMode: "Escolha o modo...",
        totalAverageMonthlyVolumePerRoute: "Volume médio mensal total por rota",
        averageMonthlyVolumePerClient: "Volume médio mensal por Cliente",
        clientsPerRoute: "Clientes por Rota",
        averagePrice: "Preço Médio",
      },
      step3NewRouteModal: {
        totalRoutesResult: "RESULTADO TOTAL DE ROTAS",
        actualRoutes: "Rotas atuais",
        routesToAdd: "Rotas a adicionar",
        newRoutes: "Rotas totais",
        cancelRoutes: "ROTAS PARA CENCELAR",
      },
      viewInfoRegisterRouteModal: {
        registerRouteInfo: "Informações de Configurações de Rota",
        loading: "Carregando...",
        registerRouteName: "Nomear Configurações de Rota",
        simulationSchema: "Esquema de simulação",
        numbersNewRoutes: "Número de novas rotas",
        volumeProjectionMode: "Modelo de projeção de volume",
        requiredFilters: "Filtros Necessários",
        aditionalFilters: "Filtros Adicionais",
        params: "Parâmetros",
        actualValue: "Valor atual",
        adjustmentValue: "Valor ajustado",
      },
      getColumnsRegisteredRoutes: {
        projectionMode: "MODO DE PROJEÇÃO",
        routeRegistrations: "REGISTROS DE ROTA",
        customersByCurrentRoute: "CLIENTES POR ROTA ATUAL",
        customersByAdjustedRoute: "CLIENTES POR ROTA AJUSTADA",
        averagePrice: "PREÇO MÉDIO",
        averageMonthlyVolumePerCurrentRoute:
          "VOLUME MÉDIO TOTAL MENSAL POR ROTA ATUAL",
        averageMonthlyVolumePerAdjustedRoute:
          "VOLUME MÉDIO TOTAL MENSAL POR ROTA AJUSTADO",
        currentAverageVolumePerClient: "VOLUME MÉDIO MENSAL POR CLIENTE ATUAL",
        adjustedAverageVolumePerClient:
          "VOLUME MÉDIO MENSAL POR CLIENTE AJUSTADO",
      },
    },
    canceledRoutes: {
      editingOptions: "OPÇÕES DE EDIÇÃO",
      assignRetentionsModal: {
        assign: "Atribuir",
        salesRetentionPercentage: "PERCENTAGEM DE RETENÇÃO DE VENDAS",
      },
      modifyCancelRouteModal: {
        modifyRetentions: "Modificar retenções",
        modify: "modificar",
        assignToVarios: "ATRIBUIR A VÁRIOS",
        retentionPercentageInfo:
          "A porcentagem de retenção de vendas será distribuída para rotas ativas",
      },
      newCancelRouteModal: {
        cancelRoute: "Cancelamento de rota",
        loading: "Carregando...",
      },
      step1NewCancelRouteModal: {
        chooseReferenColumn: "Escolher coluna de referência",
        chosenCedis:
          "Depois que uma coluna de referência for selecionada, ela será aplicada a todas as outras configurações.",
      },
      step2NewCancelRouteModal: {
        routesSelection: "SELEÇÃO DE ROTA",
      },
      step3NewCancelRouteModal: {
        routesToCanceled: "ROTAS PARA CANCELAR",
      },
      step3ModelTable: {
        clients: "CLIENTES",
        salesRetention: "% DE RETENÇÃO DE VENDAS",
      },
    },
    modifiedRoutes: {
      modifiedRoute: "Modificação de Rota",
      step3NewModifyRouteModal: {
        noValuesYet: "Você ainda não atribuiu nenhum valor.",
        valueSelectedInfo:
          "Após selecionar o(s) valor(es), serão exibidas as médias das rotas com base nesse(s) valor(es) selecionado(s).",
      },
      modifyModificationRouteModal: {
        modifyRoutes: "Modificar rotas",
      },
    },
    simulationRules: {
      nonSimulationsYet: "Nenhum resultado disponível ainda.",
      simulationResults: "Resultados simulados",
    },
    simulationResultById: {
      previousMonth: "VS. MÊS ANTERIOR",
      noChangesSimulation: "VS. SIMU SEM ALTERAÇÕES",
      graphics: "GRÁFICOS",
      cleanFilters: "Limpar filtros",
      noFiltersYet: "Não há filtros",
      loading: "Carregando...",
      simulationResultCardById: {
        before: "ANTES",
        after: "DEPOIS",
        variation: "VAR",
      },
      filterModal: {
        simulationResultFilter: "Filtros para resultado da simulação",
        cancel: "Cancelar",
        apply: "Aplicar",
      },
      waterfallSimulationResult: {
        titleText: "Impacto por componente de P&L",
      },
      columns: {
        measure: "MEDIDA",
        previousMonth: "MÊS ANTERIOR",
        simulatedMonthwithoutchanges: "MÊS SIMULADO SEM ALTERAÇÕES",
        simulatedMonth: "MÊS DO CENÁRIO SIMULADO",
        percentageVariation: "PERCENTAGEM VARIAÇÃO",
        absoluteVariation: "VARIAÇÃO ABSOLUTA",
      },
      simulatorLineChart: {
        grossSalesChartTitle: "Análise de Vendas Brutas em Múltiplas Rotas",
        costToServeChartTitle:
          "Análise do Custo para Servir em Múltiplas Rotas",
        profitToServeChartTitle:
          "Análise de Lucro para Servir em Múltiplas Rotas",
      },
      results: "Resultados",
      simulationResultInfoModal: {
        routeConfiguration: "CONFIGURAÇÃO DE ROTA",
        schema: "ESQUEMA",
        type: "TIPO",
        costVariability: "VARIABILIDADE DE CUSTOS",
        ruleSimulation: "REGRA DE SIMULAÇÃO",
      },
    },
    simulationSchema: {
      viewInfoSchemaModal: {
        schemSimulation: "Esquema de Simulação",
        loading: "Carregando...",
        name: "NOME",
        rule: "REGRA",
        principalColumns: "COLUNAS PRINCIPAIS",
        defaultIndicators: "INDICADORES PADRÃO",
        cards: "FORMATO DE CARTÃO",
        personalizedIndicators: "INDICADORES PERSONALIZADOS",
        variability: "FORMATO DE TABELA",
      },
      simulationSchemaConfig: {
        steps: {
          principalColumns: "Colunas Principais",
          indicators: "Indicadores em Formato de Cartão",
          kpis: "Indicadores em Formato de Tabela",
        },
        schemaConfig: "Configuração do esquema",
        loading: "Carregando...",
        saving: "Salvando...",
        cancel: "Cancelar",
      },
      simulationSchemaAdmin: {
        schemaAdmin: "Esquemas de simulação",
        deletingSchema: "Excluindo esquema...",
        noSchemasYet: "Nenhum esquema disponível ainda.",
        deleteSchema: "excluir esquema de simulação",
      },
      step3NewSimulationSchema: {
        defaultKpis: "KPIs padrão",
        personalizedKpis: "KPIs personalizados",
        noPersonalizedKpisYet:
          "Você ainda não adicionou nenhum KPI personalizado.",
        kpiTableExample: "Exemplo de tabela de KPIs",
        newKpi: "Novo Kpi",
      },
      step2NewSimulationSchema: {
        defaultIndicators: "Indicadores padrão",
        indicatorExample: "Exemplo de indicador",
        personalizedIndicators: "Indicadores Personalizados",
        noPersonalizedIndicators:
          "Você ainda não adicionou nenhum indicador personalizado.",
        newIndicator: "Novo indicador",
      },
      step1NewSimulationSchema: {
        chooseReferenceColumn: "Escolha a coluna de referência...",
        schemaName: "Nome do esquema",
        schemaNameAlreadyExists: "Já existe um esquema com esse nome",
        principalColumns: "Colunas Principais",
        dependency: "Dependência",
      },
      personalizedIndicatorInfoModal: {
        close: "Fechar",
        operator: "Operador",
        function: "Função",
        denominators: "Denominadores",
      },
      newPersonalizedKpiModal: {
        newKpi: "Novo Kpi",
        add: "Adicionar",
        kpiName: "Nome do Kpi",
        kpiNameAlreadyExists: "Já existe um kpi com este nome",
        numerator: "Numeradores",
        chooseMeasures: "Escolher medidas...",
        denominators: "Denominadores",
        chooseDenominators: "Escolha os denominadores...",
        denominatorsRepresentation:
          "Os denominadores representam as colunas que você selecionou na etapa 1.",
        measureUnit: "Unidade de medida",
        chooseMeasureUnit: "Escolha a unidade de medida...",
        newDenominator: "Novo Denominador",
      },
      newPersonalizedIndicatorModal: {
        measurement_units: {
          coin: "MOEDA",
          kilograms: "QUILOGRAMAS",
          units: "UNIDADES",
        },
        newIndicator: "Novo indicador",
        add: "Adicionar",
        indicatorName: "Nome  do indicador",
        indicatorNameAlreadyExists: "Já existe um indicador com este nome",
        unitOfMeasurement: "Unidade de medida",
        chooseUnitOfMeasurement: "Escolha a unidade de medida...",
      },
      newDenominatorModal: {
        newDenominator: "Novo denominator",
        add: "Adicionar",
        denominatorName: "Nome do denominador",
        denominatorNameAlreadyExists: "Já existe um denominador com este nome",
        denominatorNameMatchesWithInformation:
          "O nome não pode corresponder a uma coluna pai",
        measures: "Medidas",
        chooseMeasures: "Escolher medidas...",
      },
    },
    routeAdministration: {
      getColumnsCancelRoutes: {
        route: "ROTAS",
        retentionSales: "RETENÇÃO DE VENDAS %",
      },
    },
  },
  stagesSettings: {
    withoutStages: "Existem agrupadores sem etapas atribuídas.",
    noFiles: "Não há arquivos carregados.",
    editName: {
      codes: {
        200: "O nome foi editado com sucesso.",
        400: "Ocorreu um erro ao editar o nome.",
      },
    },
  },
  tablesManagement: {
    addTable: {
      codes: {
        201: "A tabela foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a tabela",
      },
    },
    editTable: {
      codes: {
        200: "A tabela foi editada com sucesso",
        400: "Ocorreu um erro e não foi possível editar a tabela",
      },
    },
    deleteTable: {
      codes: {
        200: "A tabela foi excluída com sucesso",
        400: "Ocorreu um erro e não foi possível excluir a tabela",
      },
    },
    putState: {
      codes: {
        200: "O estado foi modificado com sucesso",
        201: "O estado foi modificado com sucesso",
        400: "Ocorreu um erro e não foi possível modificar o estado",
      },
    },
    getGroup: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
    },
  },
  tablesTools: {
    errorRows: "Ocorreu um erro e não foi possível carregar os dados.",
    noRows: "Não há dados.",
    transformDataSimpleTable: {
      message: "Mensagem",
    },
  },
  tabs: {
    preproration: "PREPRORRATAÇÃO",
    proration: "PRORRATAÇÃO",
    downloads: "DOWNLOADS",
    datamodel: "DATAMODEL",
    simulation: "SIMULAÇÃO",
    salesForecast: "SALES FORECAST",
    marketBasket: "MARKET BASKET",
    prepro: "PREPRORRATAÇÃO",
  },
  taskManager: {
    loadingCancelTask: "Cancelando tarefa...",
    loadingDownloadFile: "Baixando...",
    error: "Ocorreu um erro e não foi possível baixar as informações.",
    errorDetail: "Detalhes do erro",
    ejecutionDetail: "Detalhes da execução",
    noDetailYet: "Ainda não temos informações detalhadas disponíveis...",
    cancelTitle: "Cancelar Tarefa",
    cancelThisTask: "Cancelar esta tarefa",
    saveViewButtonTooltip:
      "Salva a configuração de largura e visibilidade de colunas para este usuário.",
    getTasks: {
      error: "Ocorreu um erro e não foi possível obter as tarefas",
    },
    getDownloads: {
      error: "Ocorreu um erro e não foi possível obter os downloads",
    },
    getSimulations: {
      error: "Ocorreu um erro e não foi possível obter as simulações",
    },
    getSalesForecast: {
      error: "Ocorreu um erro e não foi possível obter os sales forecast",
    },
    cancelTask: {
      codes: {
        200: "A tarefa foi cancelada com sucesso",
        201: "A tarefa foi cancelada com sucesso",
        204: "A tarefa foi cancelada com sucesso",
        400: "Ocorreu um erro e não foi possível cancelar a tarefa",
        500: "Ocorreu um erro e não foi possível cancelar a tarefa",
      },
    },
    getTaskDetails: {
      codes: {
        200: "Os detalhes da tarefa foram obtidos",
        400: "Ocorreu um erro e não foi possível obter os detalhes da tarefa",
      },
      loading: "Obtendo detalhes da tarefa...",
    },
    states: {
      pending: "Pendente",
      running: "Rodando",
      finishedWithErrors: "Finalizada com erros",
      finishedSuccessfully: "Finalizada com sucesso",
      error: "Erro ao calcular",
      downloadError: "Erro ao baixar",
      canceled: "Cancelada",
    },
    actions: {
      viewDetail: "Ver detalhe",
      notViewYet: "Ainda não há detalhes",
      cancel: "Cancelar",
      download: "Baixar",
      edit: "Editar",
      delete: "Eliminar",
    },
  },
  titles: {
    taskManager: "Gerenciador de Tarefas",
  },
  validations: {
    validationsAdmin: {
      title: "Administração de Validações",
      tabs: {
        codeMatching: "COINCIDÊNCIA DE CÓDIGOS",
      },
      notCodeMatchingYet: "Ainda não há coincidência de códigos.",
      getCodeMatching: {
        error:
          "Ocorreu um erro e não foi possível obter a coincidência de códigos",
        loading: "Obtendo coincidência de códigos...",
      },
      codeMatchingCard: {
        file1: "Arquivo 1:",
        file2: "Arquivo 2:",
        matchIndicator: "Indicador:",
        completeMatchIndicator: "Indicador de coincidência:",
      },
      viewInfoModal: {
        title: "Informações Gerais de Validação",
      },
      deleteValidation: {
        codes: {
          200: "Validação excluída com sucesso",
          400: "Ocorreu um erro e a validação não pôde ser excluída",
        },
        loading: "Excluindo validação...",
        confirmationModal: {
          title: "Excluir validação",
          message: "Você confirma que deseja excluir a validação?",
        },
      },
    },
    configValidations: {
      subtitleColumns: "Colunas",
      titleIndicatorMatchSelect: "Selecionar indicador de coincidência",
      matchName: "Nome de coincidência de Códigos",
      errorEmptyName: "O nome não pode estar vazio",
      errorDuplicatedName: "O nome já existe",
      textIndicatorMatchSelect: {
        percentaje: "% de códigos de",
        in: "em",
      },
      placeholderIndicatorMatchSelect: "Selecionar indicador de cruzamento...",
      codes: {
        200: "Validação salva com sucesso.",
        201: "Validação salva com sucesso.",
        400: "Ocorreu um erro e não foi possível salvar a validação.",
      },
      calculate: {
        codes: {
          200: "O cálculo foi iniciado com sucesso",
          201: "O cálculo foi iniciado com sucesso",
          400: "Ocorreu um erro e não foi possível iniciar o cálculo",
        },
      },
      loading: "Salvando validação...",
      calculating: "Calculando...",
      confirmationModal: {
        title: "Salvar validação",
        message: "Você confirma que deseja salvar a validação?",
      },
      notEqualColumnsLength:
        "O número de colunas selecionadas deve ser igual para ambos os arquivos.",
      getValidation: {
        codes: {
          200: "Validação obtida com sucesso",
          400: "Ocorreu um erro e não foi possível obter a validação",
        },
        loading: "Obtendo validação...",
      },
    },
    matchCodeResults: {
      getValidations: {
        error: "Ocorreu um erro e não foi possível obter as validações",
      },
      getVennModalInfo: {
        codes: {
          200: "Informações obtidas com sucesso",
          400: "Ocorreu um erro e não foi possível obter as informações",
        },
        loading: "Obtendo informações...",
      },
    },
  },
  verificationCode: {
    codes: {
      200: "O código está correto",
      400: "O código está incorreto",
      500: "Ocorreu um erro e não foi possível enviar o código, tente novamente",
    },
    loading: "Enviando o código de autenticação...",
    resend: {
      codes: {
        200: "O código foi reenviado com sucesso",
        400: "O código não pôde ser reenviado",
        500: "Ocorreu um erro e não foi possível reenviar o código, tente novamente",
      },
    },
  },
  virtualizedTable: {
    saveViewButton: "Salvar visualização",
    transformData: {
      appliedSchema: "ESQUEMA_APLICADO",
      totalExpense: "GASTO_TOTAL",
    },
    toolbars: {
      columns: "Colunas",
      selectColumns: "Selecionar colunas",
      findColumn: "Encontrar coluna",
      columnTitle: "Título da coluna",
      reorderColum: "Reordenar coluna",
      colunasPanelShowAllButton: "Mostrar tudo",
      colunasPanelHideAllButton: "Ocultar tudo",
      filters: "filtros",
      filterPlaceHolder: "Filtrar valores",
      addFilter: "Adicionar filtro",
      filterHide: "Ocultar filtros",
      filterShow: "Mostrar filtros",
      filterOperator: "Operador",
      andFilter: "E",
      orFilter: "Ou",
      filterInputLabel: "Valor",
      density: "densidade",
      compact: "Compacto",
      standard: "Padrão",
      comfortable: "Confortável",
      export: "exportar",
      print: "Imprimir",
      asCSV: "Baixar como CSV",
    },
  },
  unauthorized: {
    title: "Não autorizado",
    message: "Você não tem permissão para acessar esta página",
    goBack: "Voltar para o início",
  },
  useLogout: {
    errorLogout: "Erro ao sair",
  },
};
