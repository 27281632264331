import Typography from "@mui/material/Typography";
import {
  no_data_box_styles,
  no_data_typography_styles,
} from "../../styles/app-styles";
import Stack from "@mui/material/Stack";

interface NoDataBoxProps {
  text: string;
  error?: boolean
  component?: any;
}

const NoDataBox = ({ text, error, component }: NoDataBoxProps) => {
  return (
    <Stack sx={no_data_box_styles}>
      <Typography variant="h6" sx={no_data_typography_styles(error)}>
        {text}
      </Typography>
      {component}
    </Stack>
  );
};

export default NoDataBox;
