import { form_subtitle_styles } from "../../../../styles/app-styles";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import KeyColumnSelect from "./KeyColumnSelect";
import KeyColumnSelectForMulti from "./KeyColumnSelectForMulti";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
interface KeyColumnsProps {
  columnsSet: {
    inputLabel: string;
    columnKey: string;
    optional?: boolean;
    defaultValue?: {
      value: string;
      label: string;
    };
    isMulti?: boolean;
  }[];
  subtitle?: string;
  setKeyColumns: any;
  dataGetParams: any;
  fileData: any;
}

const KeyColumns = ({
  columnsSet,
  subtitle,
  setKeyColumns,
  dataGetParams,
  fileData
}: KeyColumnsProps) => {
  const { t } = useLanguage();

  return (
    <FormSelectLayout
      title={t("companyParam.identifyKeyColumns")}
      required={true}
    >
      {subtitle && (
        <Typography sx={form_subtitle_styles}>{subtitle}</Typography>
      )}
      <Grid2 container direction={"column"} md={12} mt={1} gap={2}>
        {columnsSet.map((item) => {
          if (item?.isMulti) {
            return (
              <Grid2 md={12} key={item.columnKey}>
                <KeyColumnSelectForMulti
                  inputLabel={item.inputLabel}
                  optional={item.optional}
                  columnKey={item.columnKey}
                  setKeyColumns={setKeyColumns}
                  dataGetParams={dataGetParams}
                  fileData={fileData}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "var(--text-main)",
                    mt: "5px",
                  }}
                >
                  Para impactar el cambio de las nuevas columnas extra en los
                  periodos cargados, se deben volver a cargar los archivos de
                  GASTOS
                </Typography>
              </Grid2>
            );
          } else {
            return (
              <Grid2 md={12} key={item.columnKey}>
                <KeyColumnSelect
                  inputLabel={item.inputLabel}
                  optional={item.optional}
                  columnKey={item.columnKey}
                  setKeyColumns={setKeyColumns}
                  dataGetParams={dataGetParams}
                  fileData={fileData}
                />
              </Grid2>
            );
          }
        })}
      </Grid2>
    </FormSelectLayout>
  );
};

export default KeyColumns;
