import { Button } from "../../../buttons";
import { ConfirmationModal } from "../../../dialog";
import { GET_MEASURE_URL, MEASURE_URL } from "../../../../api/axios";
import { useState, useEffect, useMemo } from "react";
import InfoIcon from "@mui/icons-material/Info";
import OperatorsCalculator from "../../../calculator/OperatorsCalculator";
import SelectMeasureCard from "./ConfigCompounds/SelectMeasureCard";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import {
  Stack,
  TextField,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  checkParentheses,
  getConcatenatedData,
  handleAddOperatorToFormula,
  handleKeyDownFormula,
  handleValidateFormula,
  lastItemOperator,
  numbers,
  operadores,
  operatorsCalculator,
} from "../../../calculator/utils";
import { handleElementRepeatedName } from "../../../../utils/util";
interface ConfigCompoundsMeasureProps {
  handleFinish: () => void;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  dataGetExpenses: any[];
  dataGetSales: any[];
  dataGetComposed: any;
  dataGetMeasure: any;
  setConfigMode: (configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  refetchGetComposed: () => void;
}

const ConfigCompoundsMeasure = ({
  handleFinish,
  configMode,
  dataGetExpenses,
  dataGetSales,
  dataGetComposed,
  dataGetMeasure,
  setConfigMode,
  refetchGetComposed,
}: ConfigCompoundsMeasureProps) => {
  const { t } = useLanguage();
  const [measureName, setMeasureName] = useState(
    configMode === "EDIT" ? dataGetMeasure.nombre : ""
  );
  const [formula, setFormula] = useState<
    { tipo: string; dato: string | number; orden: number }[]
  >(
    configMode === "EDIT" || configMode === "COPY" ? dataGetMeasure.formula : []
  );
  const [medidaInLast, setMedidaInLast] = useState<boolean>(false);
  const [dotOperator, setDotOperator] = useState<boolean>(false);
  const [operatorInLast, setOperatorInLast] = useState<boolean>(false);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [message, setMessage] = useState<string>("");

  /*************************** MANEJO DE FÓRMULA ***********************************/

  // formula para armar la formula en base al objeto
  const formulaToString = useMemo(() => {
    let namesString = "";
    formula.forEach((element) => {
      if (element.tipo === "MEDIDA") {
        const { dato } = element;
        const measureExpense =
          dato === -1
            ? { id: -1, nombre: "GASTOS-NO-CONTEMPLADOS" }
            : dataGetExpenses &&
              dataGetExpenses?.find((expense) => expense.id === dato);
        const measureSale =
          dataGetSales && dataGetSales?.find((sale) => sale.id === dato);
        const measureComposed =
          dataGetComposed &&
          dataGetComposed?.find((composed) => composed.id === dato);
        if (measureExpense) {
          // Agregar coma antes solo si el anterior también es una medida
          namesString += `'${measureExpense.nombre.toUpperCase()}'`;
        } else if (measureSale) {
          namesString += `'${measureSale.nombre.toUpperCase()}'`;
        } else if (measureComposed) {
          namesString += `'${measureComposed.nombre.toUpperCase()}'`;
        }
      } else {
        namesString += element.dato;
      }
    });
    return namesString;
  }, [formula, dataGetExpenses, dataGetSales]);

  const handleAddMeasureToFormula = (id: number, name: string) => {
    setFormula((prevData) => {
      const newMeasure = {
        tipo: "MEDIDA",
        dato: id,
        orden: prevData.length,
      };
      return [...prevData, newMeasure];
    });
  };

 /*  const handleKeyDownFormula = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      // Bloquear la tecla "Backspace"
      e.preventDefault();
      handleRemoveLastElement();
    }
  }; */

  const handleRemoveLastElement = () => {
    if (formula.length > 0) {
      setFormula((prevData) => prevData.slice(0, -1));
    }
  };

  const handleChangeMeasureName = (e: any) => {
    setMeasureName(e.target.value);
  };

  /*************************** VALIDACIÓN DE FÓRMULA ***********************************/

  useEffect(() => {
    const lastElement = formula[formula.length - 1];

    if (lastElement && lastElement.dato === ")") {
      setMedidaInLast(true);
    } else if (lastElement && lastElement.tipo === "MEDIDA") {
      setMedidaInLast(true);
    } else {
      setMedidaInLast(false);
    }

    if (lastElement?.dato?.toString()?.endsWith(".")) {
      setDotOperator(true);
    } else {
      setDotOperator(false);
    }

    if (
      lastElement &&
      lastElement.tipo === "OPERADOR" &&
      lastElement.dato != ")" &&
      lastElement.dato != "("
    ) {
      setOperatorInLast(true);
    } else {
      setOperatorInLast(false);
    }
  }, [formula]);

  const errorMessage = checkParentheses(getConcatenatedData(formula))
    ? t(
        "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.parenthesesMatches"
      )
    : lastItemOperator(formula)
    ? t(
        "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.formulaFinishInOperator"
      )
    : "";

  const handleValidateMeasureName = () => {
    const fullList = [...dataGetExpenses, ...dataGetSales, ...dataGetComposed];
    if (measureName.trim().toUpperCase() === "GASTOS-NO-CONTEMPLADOS") {
      setMessage(t("general.repeatedName"));
    } else {
      handleElementRepeatedName(
        t,
        measureName,
        fullList,
        dataGetMeasure?.id,
        configMode,
        setMessage
      );
    }
  };

  useEffect(() => {
    handleValidateMeasureName();
  }, [measureName]);

  const isValidData = () => {
    return !(
      message !== "" ||
      handleValidateFormula(formula, formulaToString) ||
      measureName.trim() === ""
    );
  };

  /*************************** POST ***********************************/

  const closeAndClean = (data: any, newCreation?: boolean) => {
    setMeasureName("");
    setFormula([]);
    if (newCreation === true) {
      refetchGetComposed();
      toggleConfirmationModal();
      setConfigMode("CREATE");
    } else {
      handleFinish();
    }
  };

  const {
    isLoading: isLoadingPostCompoundsMeasure,
    callApi: postOtherFontMeasure,
  } = useApi(
    MEASURE_URL,
    "POST",
    t("dataModel.postNewMeasure.codes"),
    undefined,
    closeAndClean,
    toggleConfirmationModal
  );

  const {
    isLoading: isLoadingPutCompoundsMeasure,
    callApi: putOtherFontMeasure,
  } = useApi(
    GET_MEASURE_URL(dataGetMeasure?.id),
    "PUT",
    t("dataModel.putMeasure.codes"),
    undefined,
    closeAndClean,
    toggleConfirmationModal
  );

  const handleFinishPost = (newCreation?: boolean) => {
    configMode === "EDIT"
      ? putOtherFontMeasure(
          GET_MEASURE_URL(dataGetMeasure.id),
          prepareInfo(),
          newCreation
        )
      : postOtherFontMeasure(MEASURE_URL, prepareInfo(), newCreation);
  };

  const prepareInfo = () => {
    return {
      nombre: measureName,
      tipo: "COMPUESTOS",
      formula: formula,
    };
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
        }}
      >
        <SimpleBackdrop
          open={isLoadingPostCompoundsMeasure || isLoadingPutCompoundsMeasure}
          message={
            isLoadingPostCompoundsMeasure
              ? t("dataModel.postNewMeasure.loading")
              : t("dataModel.putMeasure.loading")
          }
        />
        <Stack
          component="form"
          noValidate
          autoComplete="off"
          mb="15px"
          gap="20px"
        >
          <TextField
            sx={{ wordWrap: "break-word" }}
            type="text"
            label={t(
              "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.measureName"
            )}
            variant="outlined"
            fullWidth
            value={measureName}
            onChange={handleChangeMeasureName}
            error={message !== ""}
            helperText={message}
            FormHelperTextProps={{
              sx: {
                color: "var(--text-error)",
                margin: 0,
              },
            }}
          />
          <Stack>
            <Stack sx={{ flexDirection: "row", gap: "5px", height: "25px" }}>
              <InputLabel
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  fontSize: "var(--text)",
                }}
              >
                {t(
                  "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.formula"
                )}
              </InputLabel>
              <Tooltip
                title="Solo se admite: 0-9, +, -, /, *, %, (, ) y . "
                placement="right"
              >
                <InfoIcon
                  color="primary"
                  fontSize="medium"
                  sx={{ pb: "2px" }}
                />
              </Tooltip>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "var(--text-error)",
                  textAlign: "center",
                }}
              >
                {errorMessage}
              </Typography>
            </Stack>
            <TextField
              type="text"
              placeholder={t(
                "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.enterFormula"
              )}
              variant="outlined"
              fullWidth
              value={formulaToString}
              error={handleValidateFormula(formula, formulaToString)}
              disabled={false}
              onKeyDown={(e) => handleKeyDownFormula(e, formula, setFormula)}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <OperatorsCalculator
            operators={operatorsCalculator}
            handleAddOperatorToFormula={handleAddOperatorToFormula}
            /* handleRemoveLastElement={handleRemoveLastElement} */
            operatorInLast={operatorInLast}
            formula={formula}
            medidaInLast={medidaInLast}
            dotOperator={dotOperator}
            setFormula={setFormula}
          />
          <Stack sx={{ width: "100%", gap: 3, flexDirection: "row" }}>
            <SelectMeasureCard
              handleAddMeasureToFormula={handleAddMeasureToFormula}
              title={t(
                "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.measureExpenses"
              )}
              measures={[
                {
                  id: -1,
                  nombre: "GASTOS-NO-CONTEMPLADOS",
                },
                ...dataGetExpenses,
              ]}
              medidaInLast={medidaInLast || dotOperator}
              formula={formula}
              type="expenses"
            />
            <SelectMeasureCard
              handleAddMeasureToFormula={handleAddMeasureToFormula}
              title={t(
                "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.measureSales"
              )}
              measures={dataGetSales}
              medidaInLast={medidaInLast || dotOperator}
              formula={formula}
              type="sales"
            />
            <SelectMeasureCard
              handleAddMeasureToFormula={handleAddMeasureToFormula}
              title={t(
                "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.measureCompounds"
              )}
              measures={dataGetComposed}
              medidaInLast={medidaInLast || dotOperator}
              formula={formula}
              type="composed"
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          pt: "10px",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          title={t(
            "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.cancel"
          )}
          color="light-grey"
          type="button"
          onClick={() => closeAndClean(false)}
        />
        <Button
          title={t(
            "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.accept"
          )}
          color="blue-greeny"
          type="submit"
          onClick={toggleConfirmationModal}
          disabled={!isValidData()}
        />
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleAccept={() => handleFinishPost(false)}
        message={t(
          "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.createNewMeasure"
        )}
        title={t(
          "dataModelText.measureManagement.configMeasure.configCompoundsMeasure.newMeasure"
        )}
      >
        <Button
          title={"Aceptar y crear otra medida"}
          color="outlined"
          type="button"
          onClick={() => handleFinishPost(true)}
          width={320}
          margin={false}
        />
      </ConfirmationModal>
    </>
  );
};

export default ConfigCompoundsMeasure;
