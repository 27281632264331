import { ActionMeta } from "react-select";
import { criteriaOptions } from "../selectOptions";
import { SchemaRowType } from "../../types";
import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AddButton from "../../../../buttons/AddButton";
import CreatableSelect from "react-select/creatable";
import FormSelectLayout from "../../../../forms/FormSelectLayout";
import MessageBlock from "../../../../forms/MessageBlock";
import Select from "../../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../../context/LanguageProvider";
import useMultiSelect from "../../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import {
  getBackgroundColor,
  getCriteriasLastIteration,
  isValidSchemaLine,
} from "../../utils";
import {
  form_helper_text_styles,
  grid_item_element_styles,
} from "../../../../../styles/app-styles";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../../configCriteria/select.styles";
import {
  formatOptions,
  transformDataForCheckboxSelect2,
} from "../../../../../utils/util";
import SchemaRow from "../shared/SchemaRow";

const IterationImplicitNoMatch = ({
  criteriaData,
  newSchemaRows,
  newIterationSchemaRow,
  setNewIterationSchemaRow,
  criteriaSelectedOption,
  iterations,
  firstCriteria,
  destinyTypeSelectedOption,
  destinitySelectOptions,
  iterationToEdit,
}) => {
  const { t } = useLanguage();
  const destinityCreatableSelectRef: any = useRef();
  const validationsSelectRef: any = useRef();
  const criteriaSelectRef: any = useRef();
  const criteriaActionSelectRef: any = useRef();
  const criteriaValSelectRef: any = useRef();
  const percentageRef: any = useRef<HTMLInputElement>(null);
  const [creatableDestinityValueSelected, setCreatableDestinityValueSelected] =
    useState<string>("");
  const [percentage, setPercentage] = useState<string | number>(100);
  const columnSelectRef: any = useRef();
  const [validationRow, setValidationRow] = useState<any>(null);
  const [message, setMessage] = useState<{
    text: string;
    type: string;
  }>({ text: "", type: "" });
  const [percentageRows, setPercentageRows] = useState<any[]>(
    iterationToEdit && iterationToEdit.sin_match.caso === "PORCENTAJE"
      ? iterationToEdit.sin_match?.porcentaje
      : []
  );
  const [validationsOptions, setValidationsOptions] = useState<any[]>([]);

  const onCriteriaValChange = (crit) => {
    const selectedCrit = [criteriaData?.find((c) => c?.id === crit?.value)];
    if (crit && selectedCrit && selectedCrit.length > 0) {
      setValidationsOptions(transformDataForCheckboxSelect2(selectedCrit));
    } else {
      setValidationsOptions([]);
    }
    setValidationSelectedOptions([]);
    validationsSelectRef.current?.clearValue();
  };

  const [
    criteriaValSelectedOption,
    setCriteriaValSelectedOption,
    criteriaValSelectedOptionChangeHandler,
  ] = useSingleSelect(
    (crit) => onCriteriaValChange(crit),
    iterationToEdit && iterationToEdit.sin_match.caso === "ELIMINAR"
      ? iterationToEdit.sin_match.criterio_receptor?.validaciones_conservar[0]
          ?.criterio
      : undefined
  );

  const [
    validationSelectedOptions,
    setValidationSelectedOptions,
    validationSelectedOptionsChangeHandler,
  ] = useMultiSelect(
    iterationToEdit && iterationToEdit.sin_match.caso === "ELIMINAR"
      ? iterationToEdit.sin_match.criterio_receptor?.validaciones_conservar[0]
          ?.validaciones
      : undefined
  );

  useEffect(() => {
    const newDestinyValidationRow = {
      validaciones_conservar: [
        {
          validaciones: [],
          criterio: criteriaValSelectedOption,
          receptor: getCriteriasLastIteration(iterations, firstCriteria).find(
            (c) => c.criterio.value === criteriaValSelectedOption?.value
          )?.receptor,
        },
      ],
    };
    setValidationRow(newDestinyValidationRow);
  }, []);

  const onCustomCriteriaTypeChange = (crit) => {
    if (crit?.value === "nuevo") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: { caso: "CRITERIO_NUEVO" },
      }));
    } else if (crit?.value === "modificar") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: { caso: "ELIMINAR" },
      }));
    } else {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: { caso: "PORCENTAJE" },
      }));
    }
  };

  const [
    criteriaActionSelectedOption,
    setCriteriaActionSelectedOption,
    criteriaActionSelectedOptionsChangeHandler,
  ] = useSingleSelect(
    (crit) => onCustomCriteriaTypeChange(crit),
    iterationToEdit
      ? iterationToEdit.sin_match.caso === "CRITERIO_NUEVO"
        ? { value: "nuevo", label: "Criterio nuevo" }
        : iterationToEdit.sin_match.caso === "ELIMINAR"
        ? { value: "modificar", label: "Modificar un criterio" }
        : { value: "porcentaje", label: "Porcentaje" }
      : undefined
  );

  const onCriteriaChange = () => {
    setColumnSelectedOption(undefined);
    columnSelectRef?.current?.clearValue();
  };

  const [
    newCriteriaSelectedOption,
    setNewCriteriaSelectedOption,
    newCriteriaSelectedOptionsChangeHandler,
  ] = useSingleSelect(
    () => onCriteriaChange(),
    iterationToEdit && iterationToEdit.sin_match.caso === "CRITERIO_NUEVO"
      ? iterationToEdit.sin_match.criterio_receptor.criterio
      : undefined
  );

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnSelectedOptionChangeHandler,
  ] = useSingleSelect(
    undefined,
    iterationToEdit && iterationToEdit.sin_match.caso === "CRITERIO_NUEVO"
      ? iterationToEdit.sin_match.criterio_receptor.receptor
      : undefined
  );

  const handleChangeDestinityCreatableSelect = (
    value: any,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        setCreatableDestinityValueSelected(value.label);
        break;
      case "clear":
        setCreatableDestinityValueSelected("");
        break;
      case "create-option":
        destinityCreatableSelectRef.current.value = value.label;
        setCreatableDestinityValueSelected(value.label);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (criteriaActionSelectedOption?.value === "nuevo") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: {
          ...prev?.sin_match,
          criterio_receptor: {
            criterio: newCriteriaSelectedOption,
            receptor: columnSelectedOption,
          },
          porcentaje: undefined,
        },
      }));
    }
  }, [
    criteriaActionSelectedOption,
    newCriteriaSelectedOption,
    columnSelectedOption,
  ]);

  useEffect(() => {
    const updatedValidationRow = {
      validaciones_conservar: validationRow?.validaciones_conservar?.map(
        (row) => {
          return {
            ...row,
            validaciones: validationSelectedOptions,
            criterio: criteriaValSelectedOption,
            receptor: getCriteriasLastIteration(iterations, firstCriteria).find(
              (c) => c.criterio.value === criteriaValSelectedOption?.value
            )?.receptor,
          };
        }
      ),
    };
    if (criteriaActionSelectedOption?.value === "modificar") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: {
          ...prev?.sin_match,
          criterio_receptor: {
            validaciones_conservar: updatedValidationRow.validaciones_conservar,
          },
          porcentaje: undefined,
        },
      }));
    }
  }, [validationSelectedOptions, criteriaActionSelectedOption]);

  useEffect(() => {
    if (criteriaActionSelectedOption?.value === "porcentaje") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: {
          ...prev?.sin_match,
          porcentaje: percentageRows,
          criterio_receptor: undefined,
        },
      }));
    }
  }, [criteriaActionSelectedOption, percentageRows]);

  const handleAddPercentageRow = () => {
    if (
      isValidSchemaLine(
        t,
        percentageRows,
        { value: "porcentaje", label: "porcentaje" },
        creatableDestinityValueSelected,
        criteriaSelectedOption,
        percentage,
        setMessage
      )
    ) {
      setPercentageRows([
        ...percentageRows,
        {
          destino: creatableDestinityValueSelected,
          porcentaje: percentage as number,
        },
      ]);
      setMessage({ type: "", text: "" });
      setPercentage("");
      setCreatableDestinityValueSelected("");
      destinityCreatableSelectRef.current.clearValue();
    }
  };

  const handleDeletePercentageRow = (row) => {
    const updatedRows = newSchemaRows.filter(
      (line: SchemaRowType) => line.destino !== row.destino
    );
    setPercentageRows(updatedRows);
  };

  const newCriteria = criteriaActionSelectedOption?.value === "nuevo";
  const modifyCriteria = criteriaActionSelectedOption?.value === "modificar";
  const percentageOption = criteriaActionSelectedOption?.value === "porcentaje";

  return (
    <Stack width="100%" gap={1}>
      <Stack
        sx={{
          ...grid_item_element_styles(
            getBackgroundColor,
            "match",
            () => "100%"
          ),
          color: "white",
          minHeight: "30px",
          borderRadius: "4px",
        }}
      >
        Caso sin match
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <FormSelectLayout
          title={t("Criterio nuevo / Modificar un criterio / %")}
          margin="0 0 0 0"
        >
          <Select
            reference={criteriaActionSelectRef}
            styles={selectStyles(criteriaActionSelectedOption)}
            options={[
              { value: "nuevo", label: "Criterio nuevo" },
              { value: "modificar", label: "Modificar un criterio" },
              { value: "porcentaje", label: "Porcentaje" },
            ]}
            onChange={criteriaActionSelectedOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t(
              "preprorationParameterization.newSchemaContent.chooseCriteria"
            )}
            defaultValue={criteriaActionSelectedOption}
            isClearable
          />
        </FormSelectLayout>
      </Stack>
      {newCriteria && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={"space-between"}
          gap={1}
        >
          <Stack width={"50%"}>
            <FormSelectLayout title={t("Criterio")} margin="0 0 0 0">
              <Select
                reference={criteriaSelectRef}
                styles={selectStyles(newCriteriaSelectedOption)}
                options={criteriaOptions(criteriaData)}
                onChange={newCriteriaSelectedOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseCriteria"
                )}
                defaultValue={newCriteriaSelectedOption}
                isClearable
              />
            </FormSelectLayout>
          </Stack>
          <Stack sx={{ width: "50%" }}>
            <FormSelectLayout
              title={t("Columna con receptor")}
              margin="0 0 0 0"
            >
              <Select
                reference={columnSelectRef}
                styles={selectStyles(columnSelectedOption)}
                options={
                  criteriaData?.find(
                    (criteria) => criteria.id === criteriaSelectedOption?.value
                  )?.columnas
                }
                onChange={columnSelectedOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseCriteria"
                )}
                defaultValue={columnSelectedOption}
                isClearable
              />
            </FormSelectLayout>
          </Stack>
        </Stack>
      )}
      {modifyCriteria && (
        <Stack width="100%" gap={1}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            width="100%"
            gap={1}
          >
            <Stack
              sx={{
                ...grid_item_element_styles(
                  getBackgroundColor,
                  "header",
                  () => "100%"
                ),
                color: "white",
                minHeight: "30px",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              Criterio a modificar
            </Stack>
            <Stack
              sx={{
                ...grid_item_element_styles(
                  getBackgroundColor,
                  "header",
                  () => "100%"
                ),
                color: "white",
                minHeight: "30px",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              Validaciones a incluir
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            width="100%"
            gap={1}
          >
            <Stack width={"100%"}>
              <Select
                reference={criteriaValSelectRef}
                styles={selectStyles(criteriaValSelectedOption)}
                options={getCriteriasLastIteration(
                  iterations,
                  firstCriteria
                ).map((criterio) => {
                  return criterio.criterio;
                })}
                onChange={criteriaValSelectedOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseCriteria"
                )}
                defaultValue={criteriaValSelectedOption}
                isClearable
              />
            </Stack>
            <Stack width={"100%"}>
              <Select
                reference={validationsSelectRef}
                styles={optionalSelectStyles(validationSelectedOptions)}
                options={validationsOptions}
                onChange={validationSelectedOptionsChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t("Seleccionar validaciones...")}
                defaultValue={validationSelectedOptions}
                isClearable
                isMulti
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      {percentageOption && (
        <Stack width={"100%"} gap={1}>
          <MessageBlock message={message} />
          <Stack direction="row" justifyContent={"space-between"} spacing={1}>
            <Stack width={"50%"} gap={0.7}>
              <Stack
                sx={{
                  ...grid_item_element_styles(
                    getBackgroundColor,
                    "header",
                    () => "100%"
                  ),
                  color: "white",
                  minHeight: "37px",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  borderTop: "1px solid white",
                }}
              >
                {destinyTypeSelectedOption?.value === "BUCKET_GASTO"
                  ? t(
                      "preprorationParameterization.newSchemaContent.bucketDestination"
                    )
                  : destinyTypeSelectedOption.label}
              </Stack>
              <CreatableSelect
                ref={destinityCreatableSelectRef}
                isClearable
                styles={selectStyles(creatableDestinityValueSelected)}
                options={formatOptions(destinitySelectOptions)}
                formatCreateLabel={(userInput) =>
                  `${t(
                    "preprorationParameterization.newSchemaContent.add"
                  )} ${userInput}`
                }
                onChange={handleChangeDestinityCreatableSelect}
                closeMenuOnSelect
                isSearchable
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseAddValue"
                )}
                createOptionPosition="first"
              />
            </Stack>

            <Stack
              justifyContent={"flex-start"}
              sx={{ width: "50%" }}
              gap={0.8}
            >
              <Stack
                sx={{
                  ...grid_item_element_styles(
                    getBackgroundColor,
                    "header",
                    () => "100%"
                  ),
                  color: "white",
                  minHeight: "37px",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  borderTop: "1px solid white",
                }}
              >
                PORCENTAJE
              </Stack>
              <TextField
                ref={percentageRef}
                type="number"
                variant="outlined"
                fullWidth
                value={percentage}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPercentage(event.target.value);
                }}
                error={Number(percentage) === 0 || Number(percentage) > 100}
                FormHelperTextProps={{ sx: form_helper_text_styles }}
                size="small"
                required={true}
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.choosePercentage"
                )}
              />
            </Stack>
            <Stack
              justifyContent={"space-between"}
              sx={{
                margin: 0,
                justifyContent: "flex-end",
                display: "flex",
                height: "80px",
              }}
            >
              <AddButton onClick={handleAddPercentageRow} />
            </Stack>
          </Stack>
          <Stack sx={{ marginTop: "15px" }}>
            {percentageRows?.length > 0 &&
              percentageRows.map((row, i) => (
                <SchemaRow
                  key={i}
                  row={row}
                  handleDeleteSchemaRow={handleDeletePercentageRow}
                />
              ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default IterationImplicitNoMatch;
