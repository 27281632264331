import Tooltip from "@mui/material/Tooltip";

export const convertToText = (array: any, t: any): string => {
  return array.length !== 0
    ? array
        ?.map((item: any) => {
          return " " + item.label;
        })
        .toString() || ""
    : t("general.all");
};

export const transformText = (text: string, length = 83, t) => {
  return text.length > length ? (
    <>
      {text.slice(0, length)}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={text}
        placement="top-end"
        enterDelay={50}
      >
        <span
          style={{ color: "var(--text-secondary)", fontWeight: "bold" }}
          onMouseOver={(e) => {
            e.currentTarget.style.color = "var(--text-error)";
            e.currentTarget.style.cursor = "pointer";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.color = "var(--text-secondary)";
          }}
        >
          ...{t("general.seeMore")}
        </span>
      </Tooltip>
    </>
  ) : (
    text
  );
};

export const convertObjectArrayToText = (array: any, t: any): string => {
  return array.length !== 0
    ? array
        ?.map((item: any) => {
          return " " + item.valor;
        })
        .toString() || ""
    : t("general.all");
};

export const sortByName = (array: any[]) => {
  return array.sort((a: any, b: any) => {
    return a.nombre.localeCompare(b.nombre);
  });
};

export const sortByIdDesc = (array: any[]) => {
  return array.sort((a: any, b: any) => {
    return b.id - a.id;
  });
};

export const options = (t) => [
  {
    value: "criterio",
    label: t(
      "preprorationParameterization.newSchemaContent.criteria"
    ).toUpperCase(),
  },
  {
    value: "porcentaje",
    label: t(
      "preprorationParameterization.newSchemaContent.percentage"
    ).toUpperCase(),
  },
];

export const getBackgroundColor = (type) => {
  switch (type) {
    case "iteration":
      return "var(--bg-main)";
    case "match":
      return "var(--bg-success)";
    case "criteria_zero":
      return "var(--chart-23)";
    case "change_criteria":
      return "var(--icon-info)";
    case "header":
      return "var(--bg-secondary)";
    default:
      break;
  }
};

const areRowsAndName = (t, newSchemaRows, schemaName, setMessage) => {
  if (newSchemaRows.length === 0 || !schemaName) {
    setMessage({
      type: "error",
      text: t(
        "preprorationParameterization.newSchemaContent.necessaryCompletFieldsForSchema"
      ),
    });
    return false;
  }
  return true;
};

const isTotalPercentage100 = (t, newSchemaRows, setMessage) => {
  if (newSchemaRows[0].porcentaje) {
    let sum = 0;
    newSchemaRows.forEach(function (line) {
      sum += parseFloat(line.porcentaje as string);
    });
    if (sum !== 100) {
      setMessage({
        type: "error",
        text: t(
          "preprorationParameterization.newSchemaContent.totalPercentageMustBeOneHundred"
        ),
      });
      return false;
    }
    return true;
  }
  return true;
};

export const isValidSchema = (t, newSchemaRows, schemaName, setMessage) => {
  let isValid = true;
  if (!areRowsAndName(t, newSchemaRows, schemaName, setMessage)) {
    isValid = false;
  } else if (!isTotalPercentage100(t, newSchemaRows, setMessage)) {
    isValid = false;
  }
  return isValid;
};

const isDestinityDuplicated = (destinity: string, newSchemaRows) => {
  return newSchemaRows.find((line) => line.destino === destinity);
};

const isPercentageGreaterThan100 = (percentaje: string, newSchemaRows) => {
  let sum = parseFloat(percentaje);
  newSchemaRows.forEach(function (line) {
    sum += parseFloat(line.porcentaje as string);
  });
  return sum > 100;
};

export const isValidSchemaLine = (
  t,
  newSchemaRows,
  selectedCriteriaType,
  creatableDestinityValueSelected,
  criteriaSelectedOption,
  percentage,
  setMessage
) => {
  let isValid = true;
  if (
    selectedCriteriaType?.value ===
    t("preprorationParameterization.newSchemaContent.criteria")
  ) {
    if (!creatableDestinityValueSelected || !criteriaSelectedOption) {
      setMessage({
        type: "error",
        text: t(
          "preprorationParameterization.newSchemaContent.necessaryCompleteFields"
        ),
      });
      isValid = false;
    } else {
      if (
        isDestinityDuplicated(creatableDestinityValueSelected, newSchemaRows)
      ) {
        setMessage({
          type: "error",
          text: t(
            "preprorationParameterization.newSchemaContent.alreadyCriteriaApplied"
          ),
        });
        isValid = false;
      }
    }
  } else {
    if (!creatableDestinityValueSelected || !percentage || percentage === "") {
      setMessage({
        type: "error",
        text: t(
          "preprorationParameterization.newSchemaContent.necessaryCompleteFields"
        ),
      });
      isValid = false;
    } else {
      if (Number(percentage) < 1) {
        setMessage({
          type: "error",
          text: t(
            "preprorationParameterization.newSchemaContent.percentageCannotLessOne"
          ),
        });
        isValid = false;
      }
      if (
        isDestinityDuplicated(creatableDestinityValueSelected, newSchemaRows)
      ) {
        setMessage({
          type: "error",
          text: t(
            "preprorationParameterization.newSchemaContent.alreadyPercentageApplied"
          ),
        });
        isValid = false;
      }
      if (isPercentageGreaterThan100(percentage as string, newSchemaRows)) {
        setMessage({
          type: "error",
          text: t(
            "preprorationParameterization.newSchemaContent.percentageCannotExceedOneHundred"
          ),
        });
        isValid = false;
      }
    }
  }
  return isValid;
};

export const isSum100 = (listWithPercentages) => {
  let sum = 0;
  listWithPercentages.forEach((line: any) => {
    sum += parseFloat(line.porcentaje as string);
  });  
  if (sum !== 100) {
    return false;
  }
  return true;
};

export const isPercentageComplete = (listWithPercentages) => {
  let isValid = true;
  listWithPercentages.forEach((line) => {
    if (
      (!line.porcentaje && line?.porcentaje !== 0) ||
      line.porcentaje === ""
    ) {
      isValid = false;
    }
  });
  return isValid;
};

export const isValidPercentage = (t, rows) => {
  let isValid = true;
  let message = "";

  if (
    rows &&
    rows.length > 0 &&
    (rows[0]?.porcentaje || rows[0]?.porcentaje === 0)
  ) {
    if (!isPercentageComplete(rows)) {
      message = t(
        "preprorationParameterization.newSchemaContent.necessaryCompleteFields"
      );
      isValid = false;
    }
    if (!isSum100(rows)) {
      message = t(
        "preprorationParameterization.newSchemaContent.totalPercentageMustBeOneHundred"
      );
      isValid = false;
    }
  } 
  return { isValid, message };
};

export const getCriteriasByDestiny = (
  destiny: string,
  iterations,
  newSchemaRows,
  iterationToEdit?: any
) => {
  if (!iterations || iterations.length === 0 || iterationToEdit?.iteracion === 1) {
    const findCriteria = newSchemaRows.find((row) => row.destino === destiny);
    return findCriteria?.criterio ? [findCriteria?.criterio] : [];
  } else {
    //obtener el último elemento de iterations
    const lastIteration = iterations[iterations.length - 1];

    const criterio_destino_sm =
      lastIteration?.sin_match?.criterios_destinos?.find(
        (row) => row.destino === destiny
      );

    const val_conservar_sm = criterio_destino_sm?.validaciones_conservar;

    const criterios_sin_match =
      val_conservar_sm && val_conservar_sm.length > 0
        ? val_conservar_sm.map((row) => row?.criterio)
        : criterio_destino_sm?.criterio
        ? [criterio_destino_sm?.criterio]
        : [];        

    const criterio_destino_cc =
      lastIteration?.criterio_cero?.criterios_destinos?.find(
        (row) => row.destino === destiny
      );
    const val_conservar_cc = criterio_destino_cc?.validaciones_conservar;
    const criterios_cero =
      val_conservar_cc && val_conservar_cc.length > 1
        ? val_conservar_cc.map((row) => row?.criterio)
        : criterio_destino_cc?.criterio
        ? [criterio_destino_cc?.criterio]
        : [];        

    let criterios_totales: any = [];
    if (criterios_sin_match && criterios_sin_match.length > 0) {
      criterios_sin_match.forEach((criterio) =>
        criterios_totales.push(criterio)
      );
    }
    if (criterios_cero && criterios_cero.length > 0) {
      criterios_cero.forEach((criterio) => criterios_totales.push(criterio));
    }
    
    //eliminar duplicados
    criterios_totales =
      criterios_totales?.filter(
        (criterio, index, self) =>
          index === self.findIndex((t) => t.value === criterio.value)
      ) || [];
    return criterios_totales;
  }
};

export const getCriteriasLastIteration = (
  iterations,
  firstCriteria
) => {
  if (!iterations || iterations.length === 0) {    
    return [firstCriteria];
  } else {
    //obtener el último elemento de iterations
    const lastIteration = iterations[iterations.length - 1];

    const criterio_receptor_sm = lastIteration?.sin_match?.criterio_receptor;   

    const val_conservar_sm = criterio_receptor_sm?.validaciones_conservar;    

    const criterios_sin_match =
      val_conservar_sm && val_conservar_sm.length > 0
        ? val_conservar_sm.map((row) => { return {criterio: row?.criterio, receptor: row?.receptor} })
        : criterio_receptor_sm?.criterio
        ? [{criterio: criterio_receptor_sm?.criterio, receptor: criterio_receptor_sm?.receptor}]
        : [];       

    const criterio_receptor_cc =
      lastIteration?.criterio_cero?.criterio_receptor;

    const val_conservar_cc = criterio_receptor_cc?.validaciones_conservar;
    const criterios_cero =
      val_conservar_cc && val_conservar_cc.length > 0
        ? val_conservar_cc.map((row) => { return {criterio: row?.criterio, receptor: row?.receptor} })
        : criterio_receptor_cc?.criterio
        ? [{criterio: criterio_receptor_cc?.criterio, receptor: criterio_receptor_cc?.receptor}]
        : [];

    let criterios_totales: any = [];
    if (criterios_sin_match && criterios_sin_match.length > 0) {
      criterios_sin_match.forEach((criterio) =>
        criterios_totales.push(criterio)
      );
    }
    if (criterios_cero && criterios_cero.length > 0) {
      criterios_cero.forEach((criterio) => criterios_totales.push(criterio));
    }
    //eliminar duplicados
    criterios_totales =
      criterios_totales?.filter(
        (criterio, index, self) =>
          index === self.findIndex((t) => t.criterio.value === criterio.criterio.value)
      ) || [];
      
    return criterios_totales;
  }
};
