import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import ExpenseTargetRow from "./ExpenseTargetRow";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Stack from "@mui/material/Stack";
import TargetInput from "./TargetInput";
import useLanguage from "../../../../context/LanguageProvider";

const TargetsFilesInputs = () => {
  const { t } = useLanguage();
  const { message, setExpensesTargets } = useCompanyParamProvider();
  const [newTarget, setNewTarget] = useState<any>({});
  const targetNameRef: any = useRef(null);
  const columnaNameRef: any = useRef(null);
  const [newName, setNewName] = useState<string>("");
  const [columnName, setColumnName] = useState<string>("");

  const handleAddRelationShip = () => {
    setExpensesTargets((prev: any) => [...prev, newTarget]);
    setNewTarget({ nombre: "", involucrados: [] });
    setNewName("");
    targetNameRef.current.value = "";
  };

  return (
    <FormSelectLayout title={""} required={false}>
      <Stack width="100%" sx={{ minHeight: "200px", padding: "10px" }}>
        <TargetInput
          setNewTarget={setNewTarget}
          newName={newName}
          setNewName={setNewName}
          targetNameRef={targetNameRef}
          newTarget={newTarget}
          columnaNameRef={columnaNameRef}
          columnName={columnName}
           setColumnName={setColumnName}
        />
        {newTarget &&
          newTarget.involucrados &&
          newTarget.involucrados.map((item, index) => {
            return (
              <ExpenseTargetRow
                key={index}
                row={item}
                rows={newTarget.involucrados}
                setRows={setNewTarget}
              />
            );
          })}
      </Stack>
      <Stack alignItems="center">
        <Button
          title={`+ ${t('companyParam.newTarget')}`}
          color="blue-greeny"
          type="button"
          onClick={() => handleAddRelationShip()}
          disabled={
            newTarget.nombre === "" ||
            !newTarget.involucrados ||
            newTarget.involucrados?.length === 0 ||
            message.text !== ""
          }
          width={200}
        />
      </Stack>
    </FormSelectLayout>
  );
};

export default TargetsFilesInputs;
