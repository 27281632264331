import { criteriaOptions } from "../selectOptions";
import { form_helper_text_styles } from "../../../../../styles/app-styles";
import { Item } from "../../../../basicGrid/BasicGrid";
import { selectStyles } from "../../../../configCriteria/select.styles";
import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Select from "../../../../forms/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../hooks/useSingleSelect";

const DestinyCriteriaRow = ({
  newSchemaRows,
  schemaRow,
  destinyCriteriaRows,
  setDestinyCriteriaRows,
  criteriaData,
  selectedCriteriaType,
  iterationToEdit,
}) => {
  const { t } = useLanguage();
  const percentageRef: any = useRef<HTMLInputElement>(null);
  const criteriaSelectRef: any = useRef();
  const actualRow = destinyCriteriaRows?.find(
    (row) => row.destino === schemaRow.destino
  );

  const [percentage, setPercentage] = useState<string | number>(
    actualRow?.porcentaje || undefined
  );

  const onCriteriaChange = (crit) => {
    const newDestinyCriteriaRow = {
      destino: schemaRow.destino,
      criterio: crit,
    };
    if (destinyCriteriaRows && destinyCriteriaRows.length > 0) {
      if (actualRow) {
        setDestinyCriteriaRows((prev) => {
          const filtered = prev.filter(
            (row) => row.destino !== schemaRow.destino
          );
          if (crit) {
            return filtered.concat(newDestinyCriteriaRow);
          } else {
            return filtered;
          }
        });
      } else {
        setDestinyCriteriaRows((prev) => {
          return [...prev, newDestinyCriteriaRow];
        });
      }
    } else {
      setDestinyCriteriaRows((prev) => {
        return [...prev, newDestinyCriteriaRow];
      });
    }
  };

  useEffect(() => {
    if (selectedCriteriaType?.value === "porcentaje") {
      const newDestinyCriteriaRow = {
        destino: schemaRow.destino,
        porcentaje: percentage,
      };
      if (destinyCriteriaRows && destinyCriteriaRows.length > 0) {
        if (actualRow) {
          setDestinyCriteriaRows((prev) => {
            const filtered = prev.filter(
              (row) => row.destino !== schemaRow.destino
            );
            if (percentage) {
              return filtered.concat(newDestinyCriteriaRow);
            } else {
              return filtered;
            }
          });
        } else {
          setDestinyCriteriaRows((prev) => {
            return [...prev, newDestinyCriteriaRow];
          });
        }
      } else {
        setDestinyCriteriaRows([newDestinyCriteriaRow]);
      }
    }
  }, [percentage, selectedCriteriaType]);

  const [
    criteriaSelectedOption,
    setCriteriaSelectedOption,
    criteriaSelectedOptionsChangeHandler,
  ] = useSingleSelect((crit) => onCriteriaChange(crit), actualRow?.criterio);

  useEffect(() => {
    if (!iterationToEdit) {
      setCriteriaSelectedOption(undefined);
      criteriaSelectRef.current?.clearValue();
    }
    //Para el caso que sí haya iterationToEdit y que se haya cambiado el tipo de criterio
    //En ese caso, se debe de borrar el criterio seleccionado    
    if (
      iterationToEdit &&
      criteriaSelectedOption?.value !== actualRow?.criterio?.value
    ) {
      setCriteriaSelectedOption(undefined);
      criteriaSelectRef.current?.clearValue();
    }
  }, [selectedCriteriaType]);

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      width="100%"
      gap={1}
    >
      <Item sx={{ width: "100%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {schemaRow?.destino}
        </Typography>
      </Item>
      <Stack width={"100%"}>
        {selectedCriteriaType?.value ===
        t("preprorationParameterization.newSchemaContent.criteria") ? (
          <Select
            reference={criteriaSelectRef}
            styles={selectStyles(criteriaSelectedOption)}
            options={criteriaOptions(criteriaData)}
            onChange={criteriaSelectedOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t(
              "preprorationParameterization.newSchemaContent.chooseCriteria"
            )}
            defaultValue={criteriaSelectedOption}
            isClearable
          />
        ) : (
          <TextField
            ref={percentageRef}
            type="number"
            variant="outlined"
            fullWidth
            value={percentage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPercentage(event.target.value);
            }}
            error={Number(percentage) < 0 || Number(percentage) > 100}
            FormHelperTextProps={{ sx: form_helper_text_styles }}
            size="small"
            required={true}
            placeholder={t(
              "preprorationParameterization.newSchemaContent.choosePercentage"
            )}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default DestinyCriteriaRow;
