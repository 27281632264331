import { Badge, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { grid_chip_styles, grid_element_styles } from "../../../../styles/app-styles";
import { Item } from "../../../basicGrid/BasicGrid";
import { truncateStringByLevelsQty } from "../../../companyParameterization/utils";
import { usePnLParamProvider } from "../../../../context/PnLParamProvider";
import AddIcon from "@mui/icons-material/Add";
import CardIcon from "../shared/CardIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useLanguage from "../../../../context/LanguageProvider";
interface LevelProps {
  element: any;
  handleAddLevel: (e: any, element) => void;
  handleEditLevel: (e: any, element) => void;
  handleDeleteLevel: (e: any, element) => void;
}

const Level = ({
  element,
  handleAddLevel,
  handleEditLevel,
  handleDeleteLevel,
}: LevelProps) => {
  const { t } = useLanguage();
  const { selectedLevel, setSelectedLevel, dataGetPnlLevels } =
  usePnLParamProvider();
  const levelsQuantity = dataGetPnlLevels?.length;
  const isLevelSelected = selectedLevel === element.orden;  

  return (
    <Item id="gridItem" elevation={8} sx={grid_element_styles(isLevelSelected)}>
      <Badge
        invisible={!element?.vinculo}
        badgeContent={element?.vinculo ? element?.vinculo.charAt(0) : " "}
        color={isLevelSelected ? "warning" : "primary"}
        overlap="circular"
        sx={{ zIndex: 0 }}
      >
        <Tooltip title={t('companyParam.filterByLevel')} placement="top">
          <Chip
            label={element.orden}
            onClick={() => setSelectedLevel(element.orden)}
            sx={grid_chip_styles(isLevelSelected)}
          />
        </Tooltip>
      </Badge>
      <Stack
        width={"75%"}
        justifyContent={"center"}
        alignItems={"flex-end"}
        gap={0.5}
      >
        <Tooltip title={element.nombre} placement="top-start">
          <Typography
            variant="h4"
            fontSize={15}
            color={isLevelSelected ? "white" : "var(--text-main)"}
            fontWeight={"bold"}
          >
            {truncateStringByLevelsQty(levelsQuantity, element.nombre)}
          </Typography>
        </Tooltip>
        <Stack direction="row" gap={0.3}>
          {element.tipo !== "RESULTADO" && (
            <CardIcon
              Icon={AddIcon}
              onClickAction={(e) => handleAddLevel(e, element)}
              tooltipText={t('companyParam.addLevel')}
              backgroundColor={
                isLevelSelected
                  ? "var(--transparent-grey1)"
                  : "var(--transparent-dark-grey)"
              }
              hoverBackgroundColor={
                isLevelSelected ? "var(--transparent-grey2)" : undefined
              }
            />
          )}
          <CardIcon
            Icon={EditIcon}
            onClickAction={(e) => handleEditLevel(e, element)}
            tooltipText={t('companyParam.editName')}
            backgroundColor={
              isLevelSelected ? "var(--transparent-grey1)" : "var(--transparent-dark-grey)"
            }
            hoverBackgroundColor={
              isLevelSelected ? "var(--transparent-grey2)" : undefined
            }
          />
          {element.vinculo !== "AGRUPADOR" && element.vinculo !== "BUCKET" && (
            <CardIcon
              Icon={DeleteIcon}
              onClickAction={(e) => handleDeleteLevel(e, element)}
              tooltipText={t('general.delete')}
              backgroundColor={
                isLevelSelected
                  ? "var(--transparent-grey1)"
                  : "var(--transparent-dark-grey)"
              }
              hoverBackgroundColor={
                isLevelSelected ? "var(--transparent-grey2)" : undefined
              }
            />
          )}
        </Stack>
      </Stack>
    </Item>
  );
};

export default Level;
