import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import { Company, Periods } from "./PeriodsFilter";
import { postPeriods } from "./api";
import { useEffect, useState } from "react";
import CheckmarkSelect from "./CheckmarkSelect";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import EnabledPeriodsTable from "./EnabledPeriodsTable";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLanguage from "../../context/LanguageProvider";
import useSnackbar from "../../hooks/useSnackbar";
import useSwitch from "../../hooks/useSwitch";
import {
  filterPeriods,
  getPeriodsById,
  getPeriodsId,
  getTotalPeriods,
} from "./utils";
interface PeriodsDialogContentProps {
  totalPeriodsData: Periods;
  handleTogglePeriodsDialog: () => void;
  dataGetEnabledPeriods: Periods;
  dataGetCompany: Company | undefined;
}

const PeriodsDialogContent = ({
  totalPeriodsData,
  handleTogglePeriodsDialog,
  dataGetEnabledPeriods
}: PeriodsDialogContentProps) => {
  const axiosPrivate = useAxiosPrivate();
  const [checked, handleChangeSwitch] = useSwitch(
    /* !!dataGetCompany?.ver_exportados */ true
  );
  const { t } = useLanguage();
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [isLoadingPostPeriods, setIsLoadingPostPeriods] = useState(false);
  const [poseeGastos, setPoseeGastos] = useState<undefined | boolean>(
    undefined
  );
  const nonExportedPeriods = filterPeriods(
    totalPeriodsData,
    "EXPORTABLE",
    false
  );
  const [
    open,
    handleToggleSnackbar,
    message,
    setMessage,
    severity,
    setSeverity,
    showSnackbar,
  ] = useSnackbar();

  useEffect(() => {
    setSelectedPeriods(getPeriodsId(dataGetEnabledPeriods || []));
  }, []);

  useEffect(() => {
    if (!checked) {
      setSelectedPeriods((prev) =>
        getPeriodsId(
          filterPeriods(
            getPeriodsById(totalPeriodsData, prev),
            "EXPORTABLE",
            false
          )
        )
      );
    }
  }, [checked]);

  const handleClearAll = () => {
    setSelectedPeriods([]);
  };

  const handleSelectAll = () => {
    setSelectedPeriods(
      getPeriodsId(checked ? totalPeriodsData : nonExportedPeriods)
    );
  };

  const handleApplyPeriodsResponse = (status: number) => {
    if (status === 200) {
      setIsLoadingPostPeriods(false);
      setSeverity("success");
      setMessage(t("periods.periodsDialogContent.filterPeriodsSuccess"));
      showSnackbar();
      setTimeout(() => {
        handleTogglePeriodsDialog();
        location.reload();
      }, 1300);
    } else {
      setIsLoadingPostPeriods(false);
      setSeverity("error");
      setMessage(t("periods.periodsDialogContent.errorSendingPeriods"));
      showSnackbar();
    }
  };

  const handleApply = () => {
    setIsLoadingPostPeriods(true);
    postPeriods(
      axiosPrivate,
      selectedPeriods,
      checked,
      handleApplyPeriodsResponse
    );
  };

  useEffect(() => {
    if (totalPeriodsData) {
      const hasGastos = totalPeriodsData.some((period: any) => period.posee_gastos === true);
      setPoseeGastos(hasGastos);
    }
  }, [totalPeriodsData]);

  return (
    <Stack
      justifyContent="space-between"
      sx={{ minWidth: "30vw", margin: "5px 12px", height: "100%" }}
    >
      <SimpleBackdrop
        open={isLoadingPostPeriods}
        message={t("periods.periodsDialogContent.applyingPeriods")}
      />
      {poseeGastos === undefined ? (
        <CustomSkeleton height={5} />
      ) : totalPeriodsData.length && poseeGastos ? (
        <>
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {t("periods.periodsDialogContent.filter")}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
              alignItems="center"
              mb="15px"
              mt="15px"
            >
              {checked ? (
                totalPeriodsData ? (
                  <CheckmarkSelect
                    dates={totalPeriodsData}
                    selectedPeriods={selectedPeriods}
                    setSelectedPeriods={setSelectedPeriods}
                  />
                ) : (
                  <p style={{ color: "var(--text-main)" }}>
                    {" "}
                    {t("periods.periodsDialogContent.noPeriodsExported")}
                  </p>
                )
              ) : nonExportedPeriods ? (
                <CheckmarkSelect
                  dates={nonExportedPeriods}
                  selectedPeriods={selectedPeriods}
                  setSelectedPeriods={setSelectedPeriods}
                />
              ) : (
                <p style={{ color: "var(--text-main)" }}>
                  {" "}
                  {t("periods.periodsDialogContent.noPeriodsExported")}
                </p>
              )}
              <Button
                title={t("periods.periodsDialogContent.selectAll")}
                color="blue"
                type="button"
                onClick={handleSelectAll}
                disabled={!totalPeriodsData.length}
              />
              <Button
                title={t("periods.periodsDialogContent.cleanAll")}
                color="magenta"
                type="button"
                onClick={handleClearAll}
                disabled={!totalPeriodsData.length}
              />
            </Stack>
          </Stack>

          <Stack>
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                {t("periods.periodsDialogContent.enabledPeriods")}
                <span style={{ textTransform: "lowercase" }}>
                  {` (${t("periods.periodsDialogContent.onlyWithExpenses")})`}
                </span>
              </Typography>
              <EnabledPeriodsTable
                totalYears={getTotalPeriods(totalPeriodsData)}
                periods={getPeriodsById(totalPeriodsData, selectedPeriods)}
                totalPeriodsData={totalPeriodsData}
              />
            </>
          </Stack>
        </>
      ) :
        <Box
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "700", color: "var(--text-info)" }}
          >
            {t("periods.periodsDialogContent.noPeriodsAvailableYet")}.
          </Typography>
        </Box>
      }

      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("periods.periodsDialogContent.cancel")}
          color="grey"
          type="button"
          onClick={handleTogglePeriodsDialog}
        />
        <Button
          title={t("periods.periodsDialogContent.apply")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={selectedPeriods.length === 0}
        />
      </Stack>
      <CustomSnackbar
        open={open}
        handleClose={handleToggleSnackbar}
        message={message}
        severity={severity}
      />
    </Stack>
  );
};

export default PeriodsDialogContent;