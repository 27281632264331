import { getBackgroundColor } from "../../utils";
import { grid_item_element_styles } from "../../../../../styles/app-styles";
import DestinyCriteriaRowElement from "./DestinyCriteriaRowElement";
import DestinyValidationRowElement from "./DestinyValidationRowElement";
import Stack from "@mui/material/Stack";

const IterationExplicitNoMatchElement = ({ iteration }) => {
  const newCriteria = iteration?.sin_match?.caso === "CRITERIO_NUEVO";
  const modifyCriteria = iteration?.sin_match?.caso === "ELIMINAR";
  const percentageOption = iteration?.sin_match?.caso === "PORCENTAJE";
  const areSchemaRows =
    (iteration?.sin_match?.criterios_destinos &&
      iteration?.sin_match?.criterios_destinos.length > 0) ||
    (iteration?.sin_match?.porcentaje &&
      iteration?.sin_match?.porcentaje.length > 0);
  const newSchemaRows =
    iteration?.sin_match?.criterios_destinos ||
    iteration?.sin_match?.porcentaje;

  return (
    <Stack width="100%" gap={1}>
      <Stack
        sx={{
          ...grid_item_element_styles(
            getBackgroundColor,
            "match",
            () => "100%"
          ),
          color: "white",
          minHeight: "30px",
          borderRadius: "4px",
        }}
      >
        Caso sin match -{" "}
        {newCriteria || percentageOption
          ? "Criterio nuevo"
          : "Modificar un criterio"}
      </Stack>

      {(newCriteria || percentageOption) && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          gap={1}
        >
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Destino
          </Stack>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            {percentageOption ? "Porcentaje" : "Criterio"}
          </Stack>
        </Stack>
      )}

      {(newCriteria || percentageOption) &&
        areSchemaRows &&
        newSchemaRows.map((schemaRow, i) => (
          <DestinyCriteriaRowElement
            key={i}
            schemaRow={schemaRow}
          />
        ))}

      {modifyCriteria && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          gap={1}
        >
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Destino
          </Stack>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Validaciones a incluir
          </Stack>
        </Stack>
      )}
      {modifyCriteria &&
        areSchemaRows &&
        newSchemaRows.map((schemaRow, i) => (
          <DestinyValidationRowElement key={i} schemaRow={schemaRow} />
        ))}
    </Stack>
  );
};

export default IterationExplicitNoMatchElement;
