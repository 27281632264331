import { ActionMeta, SingleValue } from "react-select";
import { getBackgroundColor, options } from "../../utils";
import { grid_item_element_styles } from "../../../../../styles/app-styles";
import { IdOption } from "../../../../configCriteria/types";
import { selectStyles } from "../../../../configCriteria/select.styles";
import { useEffect, useRef, useState } from "react";
import DestinyValidationRow from "./DestinyValidationRow";
import FormSelectLayout from "../../../../forms/FormSelectLayout";
import Select from "../../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import DestinyCriteriaRow from "./DestinyCriteriaRow";

const IterationExplicitZero = ({
  newSchemaRows,
  setNewIterationSchemaRow,
  criteriaData,
  setMessage,
  iterations,
  newIterationSchemaRow,
  destinyTypeSelectedOption,
  iterationToEdit,
}) => {
  const { t } = useLanguage();
  const criteriaSelectRef: any = useRef();
  const [zeroCriteriaRows, setZeroCriteriaRows] = useState<any[]>(
    iterationToEdit
      ? iterationToEdit?.criterio_cero?.caso === "CRITERIO_NUEVO"
        ? iterationToEdit?.criterio_cero?.criterios_destinos
        : iterationToEdit?.criterio_cero?.caso === "PORCENTAJE"
        ? iterationToEdit?.criterio_cero?.porcentaje
        : []
      : []
  );
  const [zeroValidationRows, setZeroValidationRows] = useState<any[]>(
    iterationToEdit
      ? iterationToEdit?.criterio_cero?.caso === "ELIMINAR"
        ? iterationToEdit?.criterio_cero?.criterios_destinos
        : []
      : []
  );
  const [selectedCriteriaType, setSelectedCriteriaType] = useState<any>(
    iterationToEdit && iterationToEdit?.criterio_cero?.porcentaje && !iterationToEdit?.criterio_cero?.porcentaje.every(
      (percentage) => percentage.porcentaje === 0
    )
      ? {
          value: "porcentaje",
          label: t(
            "preprorationParameterization.newSchemaContent.percentage"
          ).toUpperCase(),
        }
      : {
          value: "criterio",
          label: t(
            "preprorationParameterization.preprorationParameterization.criteria"
          ).toUpperCase(),
        }
  );

  const criteriaTypeChangeHandler = (
    value: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    switch (action.action) {
      case "select-option":
        /* if (newSchemaRows && newSchemaRows?.length > 0) {
          setNewCriteriaTypeSelectedOption(value);
          toggleConfirmationCriteriaTypeModal();
        } else { */
        if (value?.value === "porcentaje") {
          setNewIterationSchemaRow((prev) => ({
            ...prev,
            criterio_cero: { ...prev?.criterio_cero, caso: "PORCENTAJE" },
          }));
        } else if (value?.value === "criterio") {
          setNewIterationSchemaRow((prev) => ({
            ...prev,
            criterio_cero: { ...prev?.criterio_cero, caso: "CRITERIO_NUEVO" },
          }));
        }
        setSelectedCriteriaType(value);
        setMessage({
          type: "success",
          text: "",
        });
        break;
      default:
        break;
    }
  };

  const onCustomCriteriaTypeChange = (crit) => {
    if (
      crit?.value === "nuevo" &&
      selectedCriteriaType?.value === "porcentaje"
    ) {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        criterio_cero: { caso: "PORCENTAJE" },
      }));
    } else if (crit?.value === "nuevo") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        criterio_cero: { caso: "CRITERIO_NUEVO" },
      }));
    } else {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        criterio_cero: { caso: "ELIMINAR" },
      }));
    }
  };

  const [
    criteriaTypeSelectedOption,
    setCriteriaTypeSelectedOption,
    criteriaTypeSelectedOptionChangeHandler,
  ] = useSingleSelect(
    (crit) => onCustomCriteriaTypeChange(crit),
    iterationToEdit
      ? iterationToEdit.criterio_cero?.caso === "CRITERIO_NUEVO" || iterationToEdit.sin_match?.caso === "PORCENTAJE"
        ? { value: "nuevo", label: "Criterio nuevo" }
        : { value: "modificar", label: "Modificar un criterio" }
      : undefined
  );

  useEffect(() => {
    setNewIterationSchemaRow((prev) => ({
      ...prev,
      criterio_cero: {
        ...prev?.criterio_cero,
        criterios_destinos:
          selectedCriteriaType.value === "criterio"
            ? zeroCriteriaRows
            : undefined,
        porcentaje:
          selectedCriteriaType.value === "porcentaje"
            ? zeroCriteriaRows
            : undefined,
      },
    }));
  }, [zeroCriteriaRows, criteriaTypeSelectedOption]);

  useEffect(() => {
    setNewIterationSchemaRow((prev) => ({
      ...prev,
      criterio_cero: {
        ...prev?.criterio_cero,
        criterios_destinos: zeroValidationRows,
      },
    }));
  }, [zeroValidationRows]);

  const newCriteria = criteriaTypeSelectedOption?.value === "nuevo";
  const modifyCriteria = criteriaTypeSelectedOption?.value === "modificar";
  const areSchemaRows = newSchemaRows && newSchemaRows?.length > 0;

  return (
    <Stack width="100%" gap={1}>
      <Stack
        sx={{
          ...grid_item_element_styles(
            getBackgroundColor,
            "match",
            () => "100%"
          ),
          color: "white",
          minHeight: "30px",
          borderRadius: "4px",
        }}
      >
        Caso criterio cero
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <FormSelectLayout
          title={t("Criterio nuevo / Modificar un criterio")}
          margin="0 0 0 0"
        >
          <Select
            reference={criteriaSelectRef}
            styles={selectStyles(criteriaTypeSelectedOption)}
            options={[
              { value: "nuevo", label: "Criterio nuevo" },
              { value: "modificar", label: "Modificar un criterio" },
            ]}
            onChange={criteriaTypeSelectedOptionChangeHandler}
            closeMenuOnSelect
            placeholder={t(
              "preprorationParameterization.newSchemaContent.chooseCriteria"
            )}
            defaultValue={criteriaTypeSelectedOption}
            isClearable
          />
        </FormSelectLayout>
      </Stack>
      {newCriteria && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          gap={1}
        >
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Destino: {destinyTypeSelectedOption.label}
          </Stack>
          <Select
            value={selectedCriteriaType}
            styles={{
              singleValue: (provided) => ({
                ...provided,
                color: "white !important",
                fontWeight: "700",
              }),
            }}
            options={options(t)}
            placeholder={t(
              "preprorationParameterization.newSchemaContent.criteria"
            ).toUpperCase()}
            defaultValue={selectedCriteriaType}
            closeMenuOnSelect
            onChange={criteriaTypeChangeHandler}
            isClearable={false}
            isSearchable={false}
            className="selectDestinity"
          />
        </Stack>
      )}
      {newCriteria &&
        areSchemaRows &&
        newSchemaRows.map((schemaRow, i) => (
          <DestinyCriteriaRow
            key={i}
            newSchemaRows={newSchemaRows}
            schemaRow={schemaRow}
            destinyCriteriaRows={zeroCriteriaRows}
            setDestinyCriteriaRows={setZeroCriteriaRows}
            criteriaData={criteriaData}
            selectedCriteriaType={selectedCriteriaType}
            iterationToEdit={iterationToEdit}
          />
        ))}

      {modifyCriteria && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          gap={1}
        >
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Destino: {destinyTypeSelectedOption.label}
          </Stack>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Criterio a modificar
          </Stack>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Validaciones a incluir
          </Stack>
        </Stack>
      )}
      {modifyCriteria &&
        areSchemaRows &&
        newSchemaRows.map((schemaRow, i) => (
          <DestinyValidationRow
            key={i}
            schemaRow={schemaRow}
            destinyValidationRows={zeroValidationRows}
            setDestinyValidationRows={setZeroValidationRows}
            criteriaData={criteriaData}
            iterations={iterations}
            newSchemaRows={newSchemaRows}
            iterationToEdit={iterationToEdit}
          />
        ))}
    </Stack>
  );
};

export default IterationExplicitZero;
