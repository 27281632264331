import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface EditCriteriaButtonProps {
  handleClick: (e: any, id: number) => void;
  selectedCriteria: any;
  bottom?: string;
  backgroundColor?: string;
}

const DeleteButton = ({
  handleClick,
  selectedCriteria,
  bottom,
  backgroundColor,
}: EditCriteriaButtonProps) => {
  return (
    <Box
      sx={{
        position: "absolute",
        marginTop: "0px !important",
        right: "32px",
        bottom: bottom ? bottom : "0px",
        width: "35px",
        zIndex: 99,
      }}
    >
      <Button
        onClick={(e) => {
          handleClick(e, selectedCriteria.id);
        }}
        variant="contained"
        sx={{
          minWidth: "50px !important",
          borderRadius: "100%",
          width: "50px",
          height: "50px",
          backgroundColor: backgroundColor ? backgroundColor : "#cccfd9",
          boxShadow: "5px 5px 10px var(--text-info)",
          transition: "all 0.4s",
          ":hover": {
            scale: "1.1",
            boxShadow: "5px 5px 10px var(--text-info)",
          },
          ":active": { scale: "0.95" },
          zIndex: 99,
        }}
      >
        <DeleteForeverIcon
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "var(--icon-info)",
            zIndex: 99,
          }}
        />
      </Button>
    </Box>
  );
};

export default DeleteButton;
