import { Stack } from "@mui/material";
import TitleDescription from "../../viewInfoModal/TitleDescription";
import ViewInfoTable from "../../viewInfoModal/ViewInfoTable";
import IterationElement from "./configSchema/iterationVisualElements/IterationElement";

const ViewInfoModalContent = ({ item }) => {

  const COLUMNS1 = ["Destino", "Criterio"];
  const ROWS1 = item?.criterios_destinos?.map((it: any) => {
    return {
      Destino: it?.destino,
      Criterio: it?.criterio?.label,
    };
  });

  const COLUMNS2 = ["Destino", "Porcentaje"];
  const ROWS2 = item?.porcentaje?.map((it: any) => {
    return {
      Destino: it?.destino,
      Criterio: it?.porcentaje,
    };
  });

  const areIterations = item?.iteraciones && item.iteraciones.length > 0;

  return (
    <Stack gap={1}>
      <TitleDescription title="Nombre" description={item?.nombre} />
      <TitleDescription title="Tipo destino" description={item?.tipo_destino} />
      <TitleDescription
        title="Tipo preprorrateo"
        description={item?.tipo_preprorrateo}
      />
      <TitleDescription
        title="Criterio"
        description={item?.criterio_receptor?.criterio?.label}
      />
      <TitleDescription
        title="Receptor"
        description={item?.criterio_receptor?.receptor?.label}
      />
      <ViewInfoTable
        title="Criterios destinos"
        columns={COLUMNS1}
        rows={ROWS1}
        width="100%"
      />
      <ViewInfoTable title="Porcentajes" columns={COLUMNS2} rows={ROWS2} />
      {areIterations &&
        item?.iteraciones?.map((iteration: any, index: number) => (
          <IterationElement
            key={index}
            iteration={iteration}
            tipo_preprorrateo={item?.tipo_preprorrateo}
          />
        ))}
    </Stack>
  );
};

export default ViewInfoModalContent;
