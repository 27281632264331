import {
  getTextBeforeHyphen,
  removeDuplicateStrings,
} from "../../../../utils/util";
import { transformDataExchangeTable } from "./transformDataExchangeTable";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useState } from "react";
import EditableTable from "../../../virtualizatedTable/EditableTable";
import useLanguage from "../../../../context/LanguageProvider";

const ExchangeTable = () => {
  const { t } = useLanguage();
  const {
    exchangeTableData,
    setExchangeTableData,
    dataExchangeParams,
    foreignCurrencySelectedOptionState,
    currencySelectedOptionState,
  } = useCompanyParamProvider();
  const [exchangeTableColumns, setExchangeTableColumns] = useState<any[]>([]);

  const exchangeColumns = () => {
    let columns = ["PERIODO"];
    if (
      foreignCurrencySelectedOptionState &&
      foreignCurrencySelectedOptionState.length > 0
    ) {
      foreignCurrencySelectedOptionState.forEach((item: any) => {  
        if (!columns.includes(item)) {
          columns.push(getTextBeforeHyphen(item.label));     
        }
      });
    } else {
      columns = ["PERIODO"];
      setExchangeTableData((prev: any) => {
        return prev?.map((item: any) => {
          return { id: item.id,  PERIODO: item.PERIODO };
        });
      });
    }
    return removeDuplicateStrings(columns);
  };

  useEffect(() => {
    setExchangeTableColumns(exchangeColumns());
  }, [
    /* foreignCurrencySelectedOptionState, */
    foreignCurrencySelectedOptionState?.length,
    dataExchangeParams,
    /* exchangeTableData, */
    currencySelectedOptionState,
  ]);

  return (
    <EditableTable
      columns={
        transformDataExchangeTable(t, exchangeTableData, exchangeTableColumns)
          .columns
      }
      rows={
        transformDataExchangeTable(t, exchangeTableData, exchangeTableColumns)
          .rows
      }
      rowHeight={35}
      tableHeight={"75vh"}
      hideFooter={true}
      toolbar={true}
      columnsButton={false}
      filterButton={true}
      exportButton={true}
      checkboxSelection={false}
      setEditData={setExchangeTableData}
    />
  );
};

export default ExchangeTable;
