import { fileOptions, flowTypeOptions, typeOptions } from "../../../companyParameterization/StepperView/data";
import { getValueLabelFromSingleOptions } from "../../../companyParameterization/utils";
import { IdOption } from "../../../configCriteria/types";
import { modal_container_450 } from "../../../../styles/app-styles";
import { useEffect, useState } from "react";
import { usePnLParamProvider } from "../../../../context/PnLParamProvider";
import Button from "../../../buttons/Button";
import MessageBlock from "../../../forms/MessageBlock";
import MultiOptionSelector from "./MultiOptionSelector";
import Name from "./Name";
import SingleOptionSelector from "./SingleOptionSelector";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";

const PnlConfigurationModal = ({ handleToggleModal, handleAddElement }) => {
  const { t } = useLanguage();
  const {
    pnlElementConfig,
    setPnlElementConfig,
    dataGetPnlLevels,
    dataGetSalesParams,
    dataGetExpensesParams,
    pnlElements,
  } = usePnLParamProvider();
  const [message, setMessage] = useState({ type: "", text: "" });
  const [flowType, setFlowType] = useState<IdOption | undefined>(undefined);

  /****************************************** Opciones para select de buckets y agrupadores **************************/

  // filterBuckets filtra los buckets de la lista del step de gastos, devolviendo los datos de los elementos que no han sido seleccionados previamente en los elementos P&L para evitar que se seleccionen buckets repetidos.

  const filterBuckets = () => {
    const selectedBuckets = pnlElements
      .filter((el) => el.buckets && el.buckets.length > 0)
      .map((el) => el.buckets)
      .flat()
      .map((el) => el.value);
    const totalBuckets = dataGetExpensesParams?.buckets.map((b) => b.value);
    const filteredBuckets = totalBuckets.filter(
      (id) => !selectedBuckets.includes(id)
    );
    const filteredBucketsData = dataGetExpensesParams?.buckets.filter((b) =>
      filteredBuckets.includes(b.value)
    );
    return filteredBucketsData;
  };

  //getBucket busca y devuelve el primer "bucket" de un elemento padre específico. Comienza buscando el elemento padre del elemento guardado en pnlElementConfig. Si lo tiene, devuelve el primer "bucket". Si no tiene "buckets", busca recursivamente el padre del padre hasta encontrar un "bucket" o llegar al final de la jerarquía. Finalmente, devuelve el "bucket" encontrado o undefined si no se encuentra ninguno.
  const getBucket = () => {
    const recorrer = (padreId) => {
      const padre = pnlElements?.find((el) => el.id === padreId);
      if (padre && padre.buckets && padre.buckets.length > 0) {
        return padre.buckets[0];
      } else {
        if (!padre) return undefined;
        if (!padre.padre) return undefined;
        return recorrer(padre.padre);
      }
    };
    const bucket = pnlElementConfig.padre && recorrer(pnlElementConfig.padre);
    return bucket;
  };

  //getGroupers obtiene y devuelve los "agrupadores" asociados al "bucket" obtenido previamente mediante la función getBucket. Primero, busca el "bucket" correspondiente en los parámetros de los datos de gastos. Luego, extrae los "agrupadores" de ese "bucket" si se encuentra, y los devuelve. Si no se encuentra el "bucket" o no tiene "agrupadores", devuelve undefined
  const getGroupers = () => {
    const groupers = dataGetExpensesParams?.buckets?.find(
      (b) => b.value === getBucket()?.value
    )?.agrupadores;

    return groupers;
  };

  //filterGroupers busca el ID del padre en pnlElementConfig. Luego, extrae los "agrupadores" seleccionados de los subelementos del padre. Si no se encuentran "agrupadores" seleccionados, la función devuelve los "agrupadores" obtenidos a través de la función getGroupers. De lo contrario, compara los "agrupadores" totales con los seleccionados y devuelve solo aquellos que no están seleccionados para evitar seleccionar agrupadores repetidos en un mismo bucket.

  const filterGroupers = () => {
    const padreId = pnlElementConfig.padre;

    const selectedGroupers = pnlElements
      .find((el) => el.id === padreId)
      ?.subelementos?.map((el) => el?.agrupadores)
      .flat()
      .map((el) => el.value);

    if (selectedGroupers === undefined || selectedGroupers.length === 0) {
      return getGroupers();
    } else {
      const totalGroupers = getGroupers();

      const filteredGroupers = totalGroupers?.filter(
        (group) => !selectedGroupers.includes(group.value)
      );

      return filteredGroupers;
    }
  };

  /***************************************** Limpieza de datos ****************************************/

  useEffect(() => {
    if (!showName()) {
      setMessage({ type: "", text: "" });
    }
  }, [
    pnlElementConfig?.tipo,
    pnlElementConfig?.archivo,
    pnlElementConfig?.nivel,
    flowType,
  ]);

  /*************************************** Seteo de información al seleccionar opción **************************/

  const saveTypeOption = (option: IdOption) => {
    setPnlElementConfig((prev) => {
      return {
        ...prev,
        tipo: option.value,
      };
    });
    setFlowType(undefined);
    setMessage({ type: "", text: "" });
  };

  const saveFlowTypeOption = (option: IdOption) => {
    setFlowType(option);
  };

  const saveFileOption = (option: IdOption) => {
    setPnlElementConfig((prev) => ({ ...prev, archivo: option?.value }));
  };

  const saveColumnsOptions = (options: IdOption[]) => {
    setPnlElementConfig((prev) => ({ ...prev, columnas: options }));
  };

  const saveBucketOptions = (options: IdOption[]) => {
    setPnlElementConfig((prev) => ({ ...prev, buckets: options }));
  };

  const saveGroupOptions = (options: IdOption[]) => {
    setPnlElementConfig((prev) => ({ ...prev, agrupadores: options }));
  };

  /*********************************** Validaciones para mostrar campos requeridos ************************************/

  const showFile = () => {
    return pnlElementConfig?.tipo === "EGRESO";
  };

  const showName = () => {
    return (
      pnlElementConfig?.tipo === "RESULTADO" ||
      (pnlElementConfig?.tipo === "EGRESO" &&
        pnlElementConfig?.archivo === "VENTAS" &&
        flowType?.value === "CATEGORIA") ||
      (pnlElementConfig?.tipo === "EGRESO" &&
        pnlElementConfig?.archivo === "VENTAS" &&
        flowType?.value === "COLUMNAS") ||
      (pnlElementConfig?.tipo === "EGRESO" &&
        pnlElementConfig?.archivo === "GASTOS" &&
        dataGetPnlLevels?.find((el) => el.orden === pnlElementConfig?.nivel)
          ?.vinculo !== "BUCKET" &&
        dataGetPnlLevels?.find((el) => el.orden === pnlElementConfig?.nivel)
          ?.vinculo !== "AGRUPADOR") ||
      (pnlElementConfig?.tipo === "INGRESO" &&
        flowType?.value === "CATEGORIA") ||
      (pnlElementConfig?.tipo === "INGRESO" && flowType?.value === "COLUMNAS")
    );
  };

  const showColumns = () => {
    return (
      (pnlElementConfig?.tipo === "INGRESO" &&
        flowType?.value === "COLUMNAS") ||
      (pnlElementConfig?.tipo === "EGRESO" &&
        pnlElementConfig?.archivo === "VENTAS" &&
        flowType?.value === "COLUMNAS")
    );
  };

  const showFlowType = () => {
    return (
      pnlElementConfig?.tipo === "INGRESO" ||
      (pnlElementConfig?.tipo === "EGRESO" &&
        pnlElementConfig?.archivo === "VENTAS")
    );
  };

  const showBuckets = () => {
    return (
      pnlElementConfig?.tipo === "EGRESO" &&
      pnlElementConfig?.archivo === "GASTOS" &&
      dataGetPnlLevels?.find((el) => el.orden === pnlElementConfig?.nivel)
        ?.vinculo === "BUCKET"
    );
  };

  const showGroupers = () => {
    return (
      pnlElementConfig?.tipo === "EGRESO" &&
      pnlElementConfig?.archivo === "GASTOS" &&
      dataGetPnlLevels?.find((el) => el.orden === pnlElementConfig?.nivel)
        ?.vinculo === "AGRUPADOR"
    );
  };

  /*************************************** Confirmación y validación del modal *************************************/

  const handleApply = () => {
    handleAddElement();
  };

  const isValidApply = () => {
    if (!pnlElementConfig?.tipo) return false;
    if (showName() && !pnlElementConfig?.nombre) {
      return false;
    } else if (showFlowType() && !flowType) {
      return false;
    } else if (showFile() && !pnlElementConfig?.archivo) {
      return false;
    } else if (
      showColumns() &&
      (!pnlElementConfig?.columnas || pnlElementConfig?.columnas.length === 0)
    ) {
      return false;
    } else if (
      showBuckets() &&
      (!pnlElementConfig?.buckets || pnlElementConfig?.buckets.length === 0)
    ) {
      return false;
    } else if (
      showGroupers() &&
      (!pnlElementConfig?.agrupadores ||
        pnlElementConfig?.agrupadores.length === 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Stack sx={modal_container_450}>
      <Stack sx={{ alignItems: "center", gap: 2 }}>
        <MessageBlock message={message} />
        <SingleOptionSelector
          defaultValue={
            pnlElementConfig?.tipo
              ? { value: pnlElementConfig.tipo, label: pnlElementConfig.tipo }
              : undefined
          }
          options={typeOptions}
          title={t("companyParam.elementType")}
          saveSelectedOption={saveTypeOption}
          isDisabled={
            pnlElementConfig?.padre && pnlElementConfig?.tipo ? true : false
          }
        />
        {showFile() && (
          <SingleOptionSelector
            defaultValue={
              pnlElementConfig?.archivo
                ? {
                    value: pnlElementConfig.archivo,
                    label: pnlElementConfig.archivo,
                  }
                : undefined
            }
            options={fileOptions}
            title={t("companyParam.newExpense")}
            saveSelectedOption={saveFileOption}
            placeholder={t("general.selectFile")}
            isDisabled={
              pnlElementConfig?.padre && pnlElementConfig?.archivo
                ? true
                : false
            }
          />
        )}
        {showFlowType() && (
          <SingleOptionSelector
            defaultValue={undefined}
            options={flowTypeOptions}
            title={
              pnlElementConfig?.tipo === "INGRESO"
                ? t("companyParam.incomeType")
                : t("companyParam.expenseType")
            }
            saveSelectedOption={saveFlowTypeOption}
          />
        )}
        {showColumns() && (
          <MultiOptionSelector
            defaultValue={undefined}
            options={getValueLabelFromSingleOptions(
              dataGetSalesParams?.archivo?.columnas
            )}
            title={t("general.columns")}
            saveSelectedOption={saveColumnsOptions}
          />
        )}
        {showBuckets() && (
          <MultiOptionSelector
            defaultValue={undefined}
            options={filterBuckets()}
            title={t("general.buckets")}
            saveSelectedOption={saveBucketOptions}
          />
        )}
        {showGroupers() && (
          <MultiOptionSelector
            defaultValue={undefined}
            options={filterGroupers()}
            title={t("general.grouper")}
            saveSelectedOption={saveGroupOptions}
          />
        )}
        {showName() && <Name setMessage={setMessage} />}
      </Stack>

      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleToggleModal}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!isValidApply()}
        />
      </Stack>
    </Stack>
  );
};

export default PnlConfigurationModal;
