import Typography from "@mui/material/Typography";

const ErrorMessage = ({ message }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        fontWeight: "bold",
        color: "var(--text-error)",
        textAlign: "center",
      }}
    >
      {message}
    </Typography>
  );
};

export default ErrorMessage;
