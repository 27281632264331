import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
import { convertNumberToMoney } from "../parameterization/utils";

/**
 * @name transformData
 * @description Función que transforma los datos de los gastos de preprorrateo de la tabla virtualizada. Se usa DataGrid Pro de Material-UI.
 * @param data
 */
export const transformData = (
  data: any,
  handleClickOnSchemaName: (schema: string) => void
) => {
  //Saco la columna _id para que no se renderice

  const columnsWithoutId = Object.keys(data[0]).filter((key) => key !== "_id");

  const arrayColumns = columnsWithoutId.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 120,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
        {params.field.replace("_", " ")}
      </Typography>
    ),
    renderCell: (params: any) => {
      if (params.field === "FLUJO_APLICADO") {
        return (
          <Typography
            sx={{
              fontSize: "15px",
              color: "var(--text-success)",
              fontWeight: "600",
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => handleClickOnSchemaName(params.value)}
          >
            {params.value}
            {params.value && (
              <SearchIcon
                sx={{ fontSize: "20px", marginTop: "1px", marginLeft: "1px" }}
              />
            )}
          </Typography>
        );
      } else {
        return (
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={params.value}
          >
            <Typography sx={{ fontSize: "15px" }}>
              {params.field === "GASTO_TOTAL"
                ? convertNumberToMoney(params.value)
                : params.value}
            </Typography>
          </Tooltip>
        );
      }
    },
  }));

  const arrayRows = data.map((fila: any) => {
    const obj: any = {};
    obj["id"] = fila.GRUPO;
    for (const key in fila) {
      if (key !== "_id") {
        if (key === "FLUJO_APLICADO") { //TODO y revisar archivo
          obj[key] = fila[key].NOMBRE;
        } else {
          obj[key] = fila[key];
        }
      }
    }
    return obj;
  });

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
