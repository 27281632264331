import { Button } from "../../buttons";
import { ConfirmationModal } from "../../dialog";
import { formatOptions } from "../../../utils/util";
import { EDIT_PASSWORD_URL, GET_ALL_USERS_URL, ROLES_URL } from "../../../api/axios";
import { IdOption, Message } from "../../configCriteria/types";
import { selectStyles } from "../../configCriteria/select.styles";
import { Typography } from "@mui/material";
import { useGetCompaniesAdmin } from "../../company/api";
import { User } from "../types";
import { useState, useEffect, useRef } from "react";
import CustomInput from "../../forms/CustomInput";
import CustomizedDialogs from "../../dialog/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import EditPasswordModal from "./EditPassWordModal";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useMultiSelect from "../../../hooks/useMultiSelect";
import useSingleSelect from "../../../hooks/useSingleSelect";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  form_label,
  form_label_asterisc,
  form_subtitle_styles,
  step_message_styles,
  step_title_styles,
} from "../../../styles/app-styles";
import useLanguage from "../../../context/LanguageProvider";
import { useApiQuery } from "../../../hooks/useApiQuery";

interface UserFormProps {
  handleFinish: (() => void) | ((status: number | undefined) => void);
  configMode: "CREATE" | "EDIT" | "";
  user?: any;
  users: User[];
}

const UserForm = ({ configMode, user, handleFinish, users }: UserFormProps) => {
  const { t } = useLanguage();
  const {
    data: dataGetAllCompanies,
    isLoading: isLoadingAllCompanies,
    isError: isErrorAllCompanies,
  } = useGetCompaniesAdmin();

  const { isLoading: isLoadingPostUser, callApi: postUser } = useApi(
    "",
    "POST",
    t("administratorPanel.createUser.codes"),
    undefined,
    handleFinish
  );

  const { isLoading: isLoadingPutCriteria, callApi: putUser } = useApi(
    "",
    "PUT",
    t("administratorPanel.editUser.codes"),
    undefined,
    handleFinish
  );

  const {
    data: dataRoles,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useApiQuery(ROLES_URL, false);

  const [message, setMessage] = useState<Message>();
  const [errorPassword, setErrorPassword] = useState<Message>();
  const [repeatPasswordError, setRepeatPasswordError] = useState<Message>();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [userFormComplete, setUserFormComplete] = useState(false);

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const userNameRef: any = useRef(null);
  const [userName, setUserName] = useState(
    configMode === "EDIT" && user?.nombre ? user?.nombre : ""
  );

  const userLastNameRef: any = useRef(null);
  const [userLastName, setUserLastName] = useState(
    configMode === "EDIT" && user?.apellido ? user?.apellido : ""
  );

  const userEmailRef: any = useRef(null);
  const [userEmail, setUserEmail] = useState(
    configMode === "EDIT" && user?.email ? user?.email : ""
  );

  console.log(user, "user");
  
  console.log(userEmail, "userEmail");
  console.log(configMode, "configMode");
  

  const userPasswordRef: any = useRef(null);
  const [userPassword, setUserPassword] = useState(
    configMode === "EDIT" ? "123456789" : ""
  );

  const repeatPasswordRef: any = useRef(null);
  const [repeatPassword, setRepeatPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    userPasswordRef.current.type = showPassword ? "password" : "text";
    repeatPasswordRef.current.type = showPassword ? "password" : "text";
    setShowPassword((prev) => !prev);
  };

  const [openEditPassword, handleToggleEditPassword] = useDialog();

  const roleRef: any = useRef(null);
  const roleOptions = [
    {
      id: t("administratorPanel.users.administrator").toLowerCase(),
      nombre: t("administratorPanel.users.administrator"),
    },
    {
      id: t("administratorPanel.users.consultant").toLowerCase(),
      nombre: t("administratorPanel.users.consultant"),
    },
    {
      id: t("administratorPanel.users.client").toLowerCase(),
      nombre: t("administratorPanel.users.client"),
    },
  ];
  const [
    roleSelectedOptions,
    setRoleSelectedOptions,
    roleOptionsChangeHandler,
  ] = useSingleSelect(
    () => undefined,
    configMode === "EDIT" && user?.rol
      ? { value: user?.rol.id, label: user?.rol.nombre }
      : undefined
  );

  const companiesRef: any = useRef(null);
  const [
    companiesSelectedOptions,
    setCompaniesSelectedOptions,
    companiesOptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT"
      ? user?.empresas_habilitadas.map((empresa) => {
          return { value: empresa.id, label: empresa.nombre };
        })
      : undefined
  );

  const companyRef: any = useRef(null);
  const [
    companySelectedOptions,
    setCompanySelectedOptions,
    companyOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" && user?.empresa_asignada
      ? {
          value: user?.empresa_asignada.id,
          label: user?.empresa_asignada.nombre,
        }
      : undefined
  );

  const [activeUser, setActiveUser] = useState(
    configMode === "EDIT" && user?.is_active !== undefined
      ? user?.is_active
      : true
  );

  //crear un useEffect que esté a la escucha de la lista de emails de usuarios, y si ya existe ese email en la lista, entonces mostrar un mensaje de error
  useEffect(() => {
    setMessage(undefined);
    if (userEmail) {
      const userEmailExists = users?.find(
        (user: any) => user.email.toLowerCase() === userEmail.toLowerCase()
      );
      if (userEmailExists && configMode === "CREATE") {
        setMessage({
          type: "error",
          text: t("administratorPanel.users.duplicatedEmail"),
        });
      } else if (
        userEmailExists &&
        configMode === "EDIT" &&
        user?.email.toLowerCase() !== userEmail.toLowerCase()
      ) {
        setMessage({
          type: "error",
          text: t("administratorPanel.users.duplicatedEmail"),
        });
      } else {
        setMessage(undefined);
      }
    }
  }, [userEmail]);

  //chequea si la contraseña cumple con el formato correcto
  useEffect(() => {
    setErrorPassword(undefined);
    if (userPassword && configMode === "CREATE") {
      const validFormatRegExp = new RegExp(
        /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/gm
      );
      const validFormat = validFormatRegExp.test(userPassword);
      if (validFormat) {
        setErrorPassword(undefined);
      } else {
        setErrorPassword({
          type: "error",
          text: t("administratorPanel.users.passwordFormatLabel"),
        });
      }
    }
  }, [userPassword]);

  //chequea si las contraseñas coinciden
  useEffect(() => {
    setRepeatPasswordError(undefined);
    if (repeatPassword) {
      if (repeatPassword === userPassword) {
        setRepeatPasswordError(undefined);
      } else {
        setRepeatPasswordError({
          type: "error",
          text: t("administratorPanel.users.notMatchingPasswords"),
        });
      }
    }
  }, [repeatPassword, userPassword]);

  const handleUserFormComplete = () => {
    if (
      !!userEmail &&
      !!userPassword &&
      !!roleSelectedOptions &&
      !!companiesSelectedOptions &&
      companiesSelectedOptions.length > 0 &&
      !!companySelectedOptions
    ) {
      setUserFormComplete(true);
    } else {
      setUserFormComplete(false);
    }
  };

  useEffect(() => {
    handleUserFormComplete();
  }, [
    userEmail,
    userPassword,
    roleSelectedOptions,
    companiesSelectedOptions,
    companySelectedOptions,
  ]);

  const handleRestart = () => {
    setUserName("");
    setUserEmail("");
    setUserLastName("");
    setUserPassword("");
    setRoleSelectedOptions(undefined);
    setCompanySelectedOptions(undefined);
    setCompaniesSelectedOptions(undefined);
    userNameRef.value = "";
    userLastNameRef.value = "";
    userEmailRef.value = "";
    userPasswordRef.value = "";
    roleRef.current?.clearValue();
    companyRef.current?.clearValue();
    companiesRef.current?.clearValue();
  };

  const saveUser = () => {
    const usuario = {
      nombre: userName,
      apellido: userLastName,
      email: userEmail,
      rol: roleSelectedOptions?.value,
      empresa_asignada: companySelectedOptions?.value,
      is_active: activeUser,
      empresas_habilitadas: companiesSelectedOptions
        ? companiesSelectedOptions.map((option: IdOption) => {
            return option.value;
          })
        : [],
    };
    if (configMode === "CREATE") {
      postUser(GET_ALL_USERS_URL, { ...usuario, password: userPassword });
    } else {
      user && putUser(GET_ALL_USERS_URL, { ...usuario, id: user?.id });
    }
    handleCloseConfirmationModal();
  };

  const toggleActiveUser = () => {
    setActiveUser((prev: boolean) => !prev);
  };

  const handleEditPassword = () => {
    handleToggleEditPassword();
  };

  const generatePassword = () => {
    const wishlist =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$";
    const newPass = Array.from(crypto.getRandomValues(new Uint32Array(18)))
      .map((x) => wishlist[x % wishlist.length])
      .join("");

    setUserPassword(newPass);
    userPasswordRef.value = newPass;
    setRepeatPassword(newPass);
    repeatPasswordRef.value = newPass;
  };

  return (
    <>
      <Typography sx={step_title_styles}>
        {configMode === "EDIT"
          ? t("administratorPanel.users.editUserLabel")
          : t("administratorPanel.users.newUserLabel")}
      </Typography>
      {message && (
        <Typography sx={step_message_styles(message.type)}>
          {message.text}
        </Typography>
      )}
      {errorPassword && (
        <Stack sx={{ width: "70%" }}>
          <div className="cc_info_filters_box">
            <p className={`cc_info_filters_${errorPassword.type}`}>
              {errorPassword.text}
            </p>
          </div>
        </Stack>
      )}
      {repeatPasswordError && (
        <div className="cc_info_filters_box">
          <p className={`cc_info_filters_${repeatPasswordError.type}`}>
            {repeatPasswordError.text}
          </p>
        </div>
      )}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <FormLayout width="48%">
          <Typography variant="h2" sx={form_subtitle_styles}>
            {t("administratorPanel.users.userInformation")}
          </Typography>
          <FormSelectLayout title={t("administratorPanel.users.optionalName")}>
            <CustomInput
              type="text"
              id={t("administratorPanel.users.optionalName")}
              inputRef={userNameRef}
              value={userName}
              setValue={setUserName as (value: string | number) => void}
              required={false}
              placeholder={t("administratorPanel.users.name")}
              autoFocus={true}
              maxLength={60}
            />
          </FormSelectLayout>
          <FormSelectLayout
            title={t("administratorPanel.users.optionalLastName")}
          >
            <CustomInput
              type="text"
              id={t("administratorPanel.users.optionalLastName")}
              inputRef={userLastNameRef}
              value={userLastName}
              setValue={setUserLastName as (value: string | number) => void}
              required={false}
              placeholder={t("administratorPanel.users.lastName")}
              autoFocus={true}
              maxLength={50}
            />
          </FormSelectLayout>
          <FormSelectLayout
            title={t("administratorPanel.users.email")}
            required={true}
          >
            <CustomInput
              type="email"
              name="userEmail"
              inputRef={userEmailRef}
              value={userEmail}
              setValue={setUserEmail as (value: string | number) => void}
              required={true}
              placeholder={t("administratorPanel.users.email")}
              autoFocus={false}
              maxLength={60}
            />
          </FormSelectLayout>

          <div>
            <Stack
              sx={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <label style={form_label} htmlFor="userPassword">
                <span style={form_label_asterisc}>*</span>
                {t("login.password")}
              </label>
              {configMode === "CREATE" && (
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "14px",
                    color: "var(--text-success)",
                  }}
                  onClick={generatePassword}
                >
                  {t("administratorPanel.users.generatePassword")}
                </Typography>
              )}
            </Stack>
            <div className="login_password_container">
              <CustomInput
                type="password"
                name="userPassword"
                inputRef={userPasswordRef}
                value={userPassword}
                setValue={setUserPassword as (value: string | number) => void}
                required={true}
                placeholder={t("login.password")}
                disabled={configMode === "EDIT"}
                autoFocus={false}
                maxLength={50}
              />
              {showPassword && configMode === "CREATE" ? (
                <VisibilityIcon
                  className="login_password_icon"
                  onClick={handlePassword}
                />
              ) : (
                configMode === "CREATE" && (
                  <VisibilityOffIcon
                    className="login_password_icon"
                    onClick={handlePassword}
                  />
                )
              )}
              {configMode === "EDIT" && (
                <EditIcon
                  className="login_password_icon"
                  onClick={handleEditPassword}
                />
              )}
            </div>
          </div>

          {configMode === "CREATE" && (
            <FormSelectLayout
              title={t("administratorPanel.users.repeatPassword")}
              required={true}
            >
              <CustomInput
                type="password"
                name="repeatPassword"
                inputRef={repeatPasswordRef}
                value={repeatPassword}
                setValue={setRepeatPassword as (value: string | number) => void}
                required={true}
                placeholder={t("administratorPanel.users.repeatPassword")}
                autoFocus={false}
                maxLength={50}
              />
              {showPassword ? (
                <VisibilityIcon
                  className="login_password_icon"
                  onClick={handlePassword}
                />
              ) : (
                <VisibilityOffIcon
                  className="login_password_icon"
                  onClick={handlePassword}
                />
              )}
            </FormSelectLayout>
          )}

          <FormSelectLayout
            title={t("administratorPanel.users.rol")}
            required={true}
          >
            <Select
              id="Rol"
              reference={roleRef}
              options={formatOptions(dataRoles)}
              styles={selectStyles(roleSelectedOptions)}
              className="cc_select"
              name="Rol"
              onChange={roleOptionsChangeHandler}
              closeMenuOnSelect={true}
              placeholder={t("administratorPanel.users.rol")}
              defaultValue={roleSelectedOptions}
              isClearable
            />
          </FormSelectLayout>
        </FormLayout>
        <FormLayout width="48%">
          <Typography variant="h2" sx={form_subtitle_styles}>
            {t("administratorPanel.users.companiesLabel")}
          </Typography>
          <FormSelectLayout
            title={t("administratorPanel.users.userEnabledCompanies")}
            required={true}
          >
            <Select
              reference={companiesRef}
              styles={selectStyles(companiesSelectedOptions)}
              options={formatOptions(dataGetAllCompanies)}
              form="cc_form"
              className="cc_select"
              name="companies"
              onChange={companiesOptionsChangeHandler}
              closeMenuOnSelect={false}
              isMulti
              placeholder={t("administratorPanel.users.multipleOptions")}
              defaultValue={companiesSelectedOptions?.map((option) => option)}
              isClearable
            />
          </FormSelectLayout>
          <FormSelectLayout
            title={t("administratorPanel.users.assignedCompany")}
            required={true}
          >
            <Select
              reference={companyRef}
              styles={selectStyles(companySelectedOptions)}
              options={companiesSelectedOptions}
              form="cc_form"
              className="cc_select"
              name="Empresa"
              onChange={companyOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t("administratorPanel.users.chooseCompanyLabel")}
              defaultValue={companySelectedOptions}
              isClearable
            />
            <Typography variant="h2" sx={form_subtitle_styles}>
              {t("administratorPanel.users.enableUser")}
            </Typography>
            <div className="cc_step_form_checkbox cc_checkbox_container">
              <input
                type="checkbox"
                className="cc_checkbox"
                name="userActive"
                onChange={toggleActiveUser}
                defaultChecked={activeUser}
              />
              <label style={form_label} htmlFor="userActive">
                {t("administratorPanel.users.activeUser")}
              </label>
            </div>
          </FormSelectLayout>
        </FormLayout>
        <CustomizedDialogs
          open={openEditPassword}
          handleClose={handleToggleEditPassword}
          title={t("administratorPanel.users.editPasswordLabel")}
          maxWidth="sm"
        >
          <EditPasswordModal
            handleClose={handleToggleEditPassword}
            user={user as User}
            NEW_PASSWORD_URL={EDIT_PASSWORD_URL}
          />
        </CustomizedDialogs>
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleAccept={saveUser}
        message={
          configMode === "EDIT"
            ? t("administratorPanel.users.editUserAction")
            : t("administratorPanel.users.createUserAction")
        }
        title={
          configMode === "EDIT"
            ? t("administratorPanel.users.editUserLabel")
            : t("administratorPanel.users.createUserLabel")
        }
      />
      <Stack
        className="cc_steps_buttons"
        sx={{ flexDirection: "row", marginTop: "0px !important" }}
      >
        <Button
          title={t("administratorPanel.users.cleanAll")}
          color="grey"
          type="button"
          onClick={handleRestart}
        />
        <Button
          title={t("administratorPanel.users.finish")}
          color="blue"
          type="button"
          onClick={() => setOpenConfirmationModal(true)}
          disabled={
            !userFormComplete ||
            message?.type === "error" ||
            errorPassword?.type === "error" ||
            repeatPasswordError?.type === "error"
          }
        />
      </Stack>
    </>
  );
};

export default UserForm;
