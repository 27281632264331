import { selectStyles } from "../../../configCriteria/select.styles";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";

const MultiOptionSelector = ({
  defaultValue,
  options,
  title,
  saveSelectedOption,
}) => {
  const { t } = useLanguage();
  const onChangeOption = (option) => {
    saveSelectedOption(option);
  }
  const [selectedOptions, setSelectedOptions, optionsChangeHandler] =
    useMultiSelect(defaultValue, (op) => onChangeOption(op));

  return (
    <Stack sx={{ width: "100%" }}>
      <FormSelectLayout title={title} required={true} margin="0">
        <Select     
          isClearable
          styles={selectStyles(selectedOptions)}
          options={options}
          className="pp_select_schema"
          onChange={optionsChangeHandler}
          closeMenuOnSelect={false}
          isSearchable
          isMulti
          placeholder={t("general.selectValue")}
          defaultValue={defaultValue}
        />
      </FormSelectLayout>
    </Stack>
  );
};
export default MultiOptionSelector