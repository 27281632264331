const TitleDescription = ({ title, description }) => {
  return description ? (
    <p style={{ color: "var(--text-main)", margin: "5px" }}>
      <span style={{ fontWeight: "bold" }}>{title + ": "}</span>
      {description}
    </p>
  ) : (
    <></>
  );
};

export default TitleDescription;
