import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useState } from "react";
import Button from "../../../buttons/Button";
import Dialog from "../../../dialog/Dialog";
import FormLayout from "../../../forms/FormLayout";
import KeyColumns from "../Shared/KeyColumns";
import MaxLevelDetail from "./MaxLevelDetail";
import MessageBlock from "../../../forms/MessageBlock";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TypeModalContent from "../Step3/TypeModalContent";
import Typography from "@mui/material/Typography";
import UploadedFile from "../Shared/UploadedFile";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import {
  ALLOWED_FILE_NAMES_URL,
  COMPANY_PARAMETERS_FILE_URL,
} from "../../../../api/axios";
import {
  form_layouts_container_styles,
  form_subtitle_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";
import {
  columnsSetDescriptive,
  columnsSetTransactional,
  stepperData,
} from "../data";

const Step2 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setIsStepComplete,
    setSalesFileName,
    setKeyTransactionalColumns,
    setKeyDescriptiveColumns,
    dataGetSalesParams,
    maxLevelDetail,
    setMaxLevelDetail,
    getSalesParams,
    keyTransactionalColumns,
    allowedFileNames,
    refetchAllowedFileNames

  } = useCompanyParamProvider();
  const [openAllowedFilesModal, toggleAllowedFilesModal] = useDialog();
  const [fileData, setFileData] = useState<any>();

  /********************************** Seteo inicial de variables *************************************/

  const onSuccessGetInitialFilesParams = (data: any) => {
    setFileData(data);
  };

  const {
    isLoading: isLoadingInitialFilesParams,
    callApi: getInitialFilesParams,
  } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessGetInitialFilesParams,
    undefined,
    false
  );

  useEffect(() => {
    setMessage({ type: "", text: "" });
    refetchAllowedFileNames();
    if (existentFile) {
      getInitialFilesParams(
        `${COMPANY_PARAMETERS_FILE_URL}/${existentFile.id}`
      );
    } else {
      setFileData({ nombre: "VENTAS" });
    }
    if (dataGetSalesParams) {
      setSalesFileName(
        dataGetSalesParams.nombre ? dataGetSalesParams.nombre : "VENTAS"
      );
      setKeyTransactionalColumns({
        venta_bruta: dataGetSalesParams?.columnas_clave?.venta_bruta,
        venta_kg: dataGetSalesParams?.columnas_clave?.venta_kg,
        venta_m3: dataGetSalesParams?.columnas_clave?.venta_m3,
        venta_unidades: dataGetSalesParams?.columnas_clave?.venta_unidades,
        venta_cajas: dataGetSalesParams?.columnas_clave?.venta_cajas,
        venta_tarimas: dataGetSalesParams?.columnas_clave?.venta_tarimas,
        cogs: dataGetSalesParams?.columnas_clave?.cogs,
        descuentos: dataGetSalesParams?.columnas_clave?.descuentos,
        devoluciones: dataGetSalesParams?.columnas_clave?.devoluciones,
      });
      setKeyDescriptiveColumns({
        codigo_cliente: dataGetSalesParams?.columnas_clave?.codigo_cliente,
        codigo_ruta: dataGetSalesParams?.columnas_clave?.codigo_ruta,
        codigo_producto: dataGetSalesParams?.columnas_clave?.codigo_producto,
        categoria_producto:
          dataGetSalesParams?.columnas_clave?.categoria_producto,
        marca: dataGetSalesParams?.columnas_clave?.marca,
        unidad_negocio: dataGetSalesParams?.columnas_clave?.unidad_negocio,
        canal: dataGetSalesParams?.columnas_clave?.canal,
        modelo_atencion: dataGetSalesParams?.columnas_clave?.modelo_atencion,
        tipo_ruta: dataGetSalesParams?.columnas_clave?.tipo_ruta,
        fecha: dataGetSalesParams?.columnas_clave?.fecha,
      });
      if (dataGetSalesParams?.nivel_detalle) {
        setMaxLevelDetail(dataGetSalesParams?.nivel_detalle);
      }
    }
  }, [dataGetSalesParams]);

  const existentFile =
    allowedFileNames?.find(
      (file) => file?.nombre === dataGetSalesParams?.nombre
    ) || undefined;

  /*********************************** Traer datos de tipado *******************************************/

  const onSuccessGetFilesParams = (data: any) => {
    setFileData(data);
    getSalesParams();
    toggleAllowedFilesModal();
  };

  const { isLoading: isLoadingFilesParams, callApi: getFilesParams } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessGetFilesParams,
    undefined,
    false
  );

  const handleSetFileData = () => {
    if (existentFile) {
      getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${existentFile.id}`);
    } else {
      setFileData({ nombre: "VENTAS" });
      toggleAllowedFilesModal();
    }
  };

  const refetchData = () => {
    getSalesParams();
    refetchAllowedFileNames();
  };

  /************************************* Validación step completo **********************************/

  const isStep2Complete = () => {
    if (keyTransactionalColumns?.venta_bruta && maxLevelDetail) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isStep2Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step2: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step2: false };
      });
    }
  }, [keyTransactionalColumns.venta_bruta, maxLevelDetail]);

  const isFileColumnKey =
    dataGetSalesParams &&
    dataGetSalesParams.archivo &&
    dataGetSalesParams.archivo.columnas &&
    dataGetSalesParams.archivo.columnas.length;

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingFilesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[2]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={{ ...form_layouts_container_styles }}>
        <FormLayout width="48%" padding="10px 25px 120px 25px">
          {dataGetSalesParams?.nombre && (
            <Typography
              sx={{ ...form_subtitle_styles, margin: "15px 0 5px 0" }}
            >
              {dataGetSalesParams?.nombre}
            </Typography>
          )}
          {dataGetSalesParams?.descripcion && (
            <Typography
              sx={{
                ...form_subtitle_styles,
                fontSize: "15px",
                margin: 0,
              }}
            >
              {dataGetSalesParams?.descripcion}
            </Typography>
          )}
          {isFileColumnKey && (
            <UploadedFile dataGetParams={dataGetSalesParams} />
          )}
          <Stack width={"100%"} direction="row" justifyContent={"center"}>
            <Button
              title={t("Parametrizar archivo")}
              color="blue-greeny"
              type="button"
              onClick={handleSetFileData}
              width={200}
              disabled={false}
            />
          </Stack>
          {existentFile?.tipado && (
            <KeyColumns
              columnsSet={columnsSetTransactional}
              subtitle={t("companyParam.transactional")}
              setKeyColumns={setKeyTransactionalColumns}
              dataGetParams={dataGetSalesParams}
              fileData={fileData}
            />
          )}
        </FormLayout>
        <FormLayout width="48%">
          {existentFile?.tipado && (
            <>
              <KeyColumns
                columnsSet={columnsSetDescriptive}
                subtitle={t("companyParam.descriptive")}
                setKeyColumns={setKeyDescriptiveColumns}
                dataGetParams={dataGetSalesParams}
                fileData={fileData}
              />
              <MaxLevelDetail
                defaultValue={dataGetSalesParams.maximo_detalle}
                dataGetParams={dataGetSalesParams}
                fileData={fileData}
              />
            </>
          )}
        </FormLayout>
      </Stack>
      <Dialog
        open={openAllowedFilesModal}
        handleClose={toggleAllowedFilesModal}
        title={t("dataManagementTexts.dataManagement.allowedFiles")}
        maxWidth="xl"
      >
        <Stack width={"1200px"}>
          <TypeModalContent
            fileData={fileData}
            setFileData={setFileData}
            handleClose={toggleAllowedFilesModal}
            dataFilesList={allowedFileNames}
            refetchData={refetchData}
            type="VENTAS"
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Step2;
