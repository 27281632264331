import "../styles.css";
import {
  background_grey_styles,
  check_circle_icon_styles,
  delete_icon_styles,
  list_item_tooltip_styles,
  param_list_item_text_primary,
  param_list_item_text_styles,
  upload_file_icon_styles,
} from "../../../../styles/app-styles";
import { useDropzone } from "react-dropzone";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import IconButton from "@mui/material/IconButton";
import Info from "../../../icons/Info";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useLanguage from "../../../../context/LanguageProvider";

interface DropzoneInputProps {
  fileToUpload: any;
  setFileToUpload: any;
  postFile: any;
  url?: string;
  dropzoneLabel?: string;
  required?: boolean;
  acceptedTypes?: any;
  maxFiles?: number;
}

const DropzoneInput = ({
  fileToUpload,
  setFileToUpload,
  postFile,
  url,
  dropzoneLabel,
  required,
  acceptedTypes,
  maxFiles,
}: DropzoneInputProps) => {
  const { t } = useLanguage();
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes || {
      "text/csv": [".csv", ".parquet"],
    },
    onDrop: (acceptedFiles) => {
      setFileToUpload(acceptedFiles);
    },
    maxFiles: maxFiles ? maxFiles : 1,
  });

  const handleAccept = () => {
    const formData = new FormData();
    formData.append("file", fileToUpload[0]);
    postFile(url ? url : undefined, formData, "UPLOAD FILE");
  };

  const handleDeleteFile = () => {
    setFileToUpload([]);
  };

  const getFileName = (path: string) => {
    return path.split(".")[0].toUpperCase() + "." + path.split(".")[1];
  };

  const isFile = fileToUpload && fileToUpload.length > 0;

  return (
    <>
      <Box width="100%">
        {!isFile &&
          (dropzoneLabel === "" ? (
            <div {...getRootProps({ className: "paramDropzone" })}>
              <input {...getInputProps()} />
              <p>{t("companyParam.dragFile")}</p>
              <UploadFileIcon sx={upload_file_icon_styles} />
            </div>
          ) : (
            <FormSelectLayout
              title={
                dropzoneLabel
                  ? dropzoneLabel
                  : dropzoneLabel === ""
                  ? " "
                  : t("companyParam.fileOrFileSlice")
              }
              required={required === false ? false : true}
              icon={
                dropzoneLabel ? (
                  <></>
                ) : (
                  <Info text={t("companyParam.easyNextSteps")} color="blue" />
                )
              }
            >
              <div {...getRootProps({ className: "paramDropzone" })}>
                <input {...getInputProps()} />
                <p>{t("companyParam.dragFile")}</p>
                <UploadFileIcon sx={upload_file_icon_styles} />
              </div>
            </FormSelectLayout>
          ))}
      </Box>

      {isFile && (
        <FormSelectLayout
          title={dropzoneLabel !== "" ? t("companyParam.fileToUpload") : ""}
          required={false}
        >
          <List dense={true} sx={{ height: "70px", width: "100%" }}>
            {fileToUpload?.map((file: any) => {
              return (
                <ListItem
                  key={file.path}
                  sx={{ width: "100%" }}
                  secondaryAction={
                    <>
                      <Tooltip
                        title={t("companyParam.uploadFile")}
                        sx={list_item_tooltip_styles}
                      >
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => {
                            handleAccept();
                          }}
                        >
                          {dropzoneLabel !== "" && (
                            <FileUploadIcon
                              sx={{
                                ...check_circle_icon_styles,
                                color: "var(--icon-main) !important",
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={t("companyParam.deleteFile")}
                        sx={list_item_tooltip_styles}
                      >
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteFile()}
                        >
                          <DeleteIcon sx={delete_icon_styles} />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={background_grey_styles}>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={param_list_item_text_styles}
                    primary={
                      <p style={param_list_item_text_primary}>
                        {getFileName(file.path)}
                      </p>
                    }
                    secondary={file.size + " bytes"}
                  />
                </ListItem>
              );
            })}
          </List>
        </FormSelectLayout>
      )}
    </>
  );
};

export default DropzoneInput;
