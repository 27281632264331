import { usePnLParamProvider } from "../../../../context/PnLParamProvider";
import { useRef } from "react";
import FileNameInput from "../../../companyParameterization/StepperView/Shared/FileNameInput";
import Stack from "@mui/material/Stack/Stack";
import useLanguage from "../../../../context/LanguageProvider";

const Name = ({ setMessage }) => {
  const { t } = useLanguage();
  const { pnlElementConfig, setPnlElementConfig } =
  usePnLParamProvider();
  const nameRef: any = useRef(null);

  const handleSetName = (name) => {
    setPnlElementConfig({ ...pnlElementConfig, nombre: name });
  }

  return (
    <Stack sx={{ width: "100%" }}>      
      <FileNameInput
        fileNameRef={nameRef}
        fileName={pnlElementConfig?.nombre}
        setfileName={handleSetName}
        setMessage={setMessage}
        dataFilesList={[]}
        label={t("general.name")}
        placeholder={t("general.writeName")}
      />
    </Stack>
  );
};

export default Name;
