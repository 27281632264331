import { usePnLParamProvider } from "../../../context/PnLParamProvider";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import ChipsBox from "./ChipsBox";
import CloseIcon from "@mui/icons-material/Close";
import CustomAccordion from "../../accordion/CustomAccordion";
import EditValueLabelNameModal from "../../dialog/EditValueLabelNameModal";
import FormSelectLayout from "../../forms/FormSelectLayout";
import IconButton from "@mui/material/IconButton";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";

const AbmGroupers = () => {
  const { t } = useLanguage();
  const { bucketsList, setBucketsList, setCounter } = usePnLParamProvider();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openEditNameDialog, toggleEditNameDialog] = useDialog();
  const [openCreateNameDialog, toggleCreateNameDialog] = useDialog();
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [configMode, setConfigMode] = useState<
    "CREATE" | "EDIT" | "INFO" | "COPY" | ""
  >("");
  const [elementToEdit, setElementToEdit] = useState<any>({
    value: "",
    label: "",
  });
  const [message, setMessage] = useState<string>("");
  const [bucketToEdit, setBucketToEdit] = useState<any>([]);

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const editGrouper = (grouper, bucket) => {
    setConfigMode("EDIT");
    setElementToEdit(grouper);
    setBucketToEdit(bucket);
    toggleEditNameDialog();
  };

  const handleDelete = (
    chipToDelete: { value: string; label: string, puede_eliminarse: boolean},
    mapInfo?: any
  ) => {    
    if (chipToDelete?.puede_eliminarse === false) {
      handleClickVariant("Este agrupador ya está en uso y no puede eliminarse", "error");
    } else {
      const updatedBuckets = bucketsList.map((bucket) => {
        if (bucket.value === mapInfo.value) {
          const updatedGroupers = bucket.agrupadores.filter(
            (grouper) => grouper.value !== chipToDelete.value
          );
          return { ...bucket, agrupadores: updatedGroupers };
        } else {
          return bucket;
        }
      });
      setBucketsList(updatedBuckets);
      setCounter(prev => prev + 1);
    }
  };

  const onClickAddButton = (bucket) => {
    setConfigMode("CREATE");
    setBucketToEdit(bucket);
    toggleCreateNameDialog();
  };

  const setItems = (newList) => {
    const updatedBuckets = bucketsList.map((bucket) => {
      if (bucket.value === bucketToEdit.value) {
        return { ...bucket, agrupadores: newList };
      } else {
        return bucket;
      }
    });
    setBucketsList(updatedBuckets);
    setCounter(prev => prev + 1);
  };

  return (
    <FormSelectLayout title={t("Agrupadores por bucket")} required={true}>
      {bucketsList &&
        bucketsList.length > 0 &&
        bucketsList.map((bucket) => {
          return (
            <CustomAccordion
              key={bucket.value}
              title={bucket.label}
              backgroundColor={"white"}
              summaryColor={"var(--text-main)"}
              arrowColor={"var(--icon-main)"}
              detailsColor={"var(--text-main)"}
            >
              <ChipsBox
                chipsList={bucket?.agrupadores || []}
                editChip={editGrouper}
                handleDelete={handleDelete}
                onClickAddButton={onClickAddButton}
                onDelete={undefined}
                openConfirmationModal={openConfirmationModal}
                toggleConfirmationModal={toggleConfirmationModal}
                draggable={false}
                mapInfo={bucket}
              />
            </CustomAccordion>
          );
        })}
      <EditValueLabelNameModal
        openEditNameDialog={openEditNameDialog}
        handleToggleEditNameDialog={toggleEditNameDialog}
        elementToEdit={elementToEdit}
        setElementToEdit={setElementToEdit}
        configMode={configMode}
        setConfigMode={setConfigMode}
        setItems={setItems}
        itemsList={bucketToEdit?.agrupadores || []}
        message={message}
        setMessage={setMessage}
      />
      {bucketToEdit && bucketToEdit.value && (
        <EditValueLabelNameModal
          openEditNameDialog={openCreateNameDialog}
          handleToggleEditNameDialog={toggleCreateNameDialog}
          elementToEdit={bucketToEdit}
          setElementToEdit={setBucketToEdit}
          configMode={configMode}
          setConfigMode={setConfigMode}
          setItems={setItems}
          itemsList={bucketToEdit?.agrupadores || []}
          message={message}
          setMessage={setMessage}
        />
      )}
    </FormSelectLayout>
  );
};

export default AbmGroupers;
