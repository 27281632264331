import { getFileName } from "../utils";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../../context/LanguageProvider";

const AddFileIcon = ({ file, handleAddName }) => {
  const { t } = useLanguage();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={t("Parametrizar nombre y/o columnas del archivo.")}
      sx={{ verticalAlign: "center", fontSize: "16px" }}
    >
      <IconButton
        edge="end"
        aria-label="add"
        onClick={() => {
          handleAddName(getFileName(file.path));
        }}
      >        
        <ControlPointIcon
          sx={{
            color: "var(--icon-secondary) !important",
            cursor: "pointer",
            transition: "all 1s linear",
            "& :hover": { color: "var(--icon-main)" },
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default AddFileIcon;
