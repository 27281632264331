import { edit_name_modal_container_styles } from "../../styles/app-styles";
import {
  handleElementRepeatedNameValueLabel,
} from "../companyParameterization/utils";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "../buttons/Button";
import CustomizedDialogs from "./Dialog";
import TextField from "@mui/material/TextField/TextField";
import useLanguage from "../../context/LanguageProvider";
interface EditNameModalProps {
  openEditNameDialog: boolean;
  handleToggleEditNameDialog: () => void;
  elementToEdit: {
    value: string;
    label: string;
  };
  setElementToEdit: (value: { value: string; label: string }) => void;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  setConfigMode: (value: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  setItems: (value: any) => void;
  itemsList: any;
  message?: string;
  setMessage: (value: string) => void;
  onCreateName?: (value: string) => void;
}

const EditStringNameModalValueLabel = ({
  openEditNameDialog,
  handleToggleEditNameDialog,
  elementToEdit,
  setElementToEdit,
  configMode,
  setConfigMode,
  setItems,
  itemsList,
  message,
  setMessage,
  onCreateName
}: EditNameModalProps) => {
  const { t } = useLanguage();
  const newNameRef = useRef<HTMLInputElement>(null);
  const [newName, setNewName] = useState<string>("");  

  useEffect(() => {
    if (openEditNameDialog) {
      newNameRef.current?.focus();
      setMessage("");
      configMode === "EDIT" ? setNewName(elementToEdit.label) : setNewName("");
    }
  }, [openEditNameDialog]);

  useEffect(() => {
    handleElementRepeatedNameValueLabel(
      t,
      elementToEdit,
      newName,
      itemsList,
      configMode,
      setMessage
    );
  }, [newName]);

  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
  };

  const handleEditName = () => {
    if (configMode === "EDIT") {
      //Busca el elemento en el array prev y le cambia el nombre
      const copyListItems = [...itemsList];
      const index = copyListItems.findIndex(
        (item) => item.value === elementToEdit.value
      );
      copyListItems[index].label = newName;
      setItems(copyListItems);
    } else if (configMode === "CREATE") {            
      //Agrega el elemento al array
      const copyListItems = [...itemsList];
      copyListItems.push({ value: newName, label: newName });      
      setItems(copyListItems);
      onCreateName && onCreateName(newName);
    }
    setConfigMode("");
    setElementToEdit({ value: "", label: "" });
    handleToggleEditNameDialog();
  };

  return (
    <CustomizedDialogs
      open={openEditNameDialog}
      handleClose={handleToggleEditNameDialog}
      title={
        configMode === "EDIT"
          ? t("modelParameterizationTexts.modelTable.editName")
          : t("general.create")
      }
      maxWidth="md"
    >
      <Box style={edit_name_modal_container_styles}>
        <TextField
          type="text"
          ref={newNameRef}
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={
            configMode === "EDIT"
              ? t(
                  "dataModelText.measureManagement.configMeasure.configExpensesMeasure.newName"
                )
              : t("general.name")
          }
          autoFocus
          size="small"
          error={message !== ""}
          helperText={message}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={() => handleEditName()}
          disabled={
            message !== "" ||
            newName?.trim()?.toLowerCase() ===
              elementToEdit?.label?.trim()?.toLowerCase()
          }
        />
      </Box>
    </CustomizedDialogs>
  );
};

export default EditStringNameModalValueLabel;
