import { Typography } from "@mui/material";

const ViewInfoModalContent = ({ item }) => {
  return (
    <>
      <Typography>Nombre: {item.nombre}</Typography>
      <Typography>Columna: {item.columna}</Typography>
      <Typography>
        Involucrados:{" "}
        {item.involucrados
          .map((inv: any) => inv.archivo.label + " " + inv.columna.label)
          .join(", ")}
      </Typography>
    </>
  );
};

export default ViewInfoModalContent;
