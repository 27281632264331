import "./styles.css";
import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import { Info } from "../icons";
import { postLogin } from "./api";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import FormWrapper from "./login.styles";
import logoPhi from "../../assets/logoPhi.png";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useAuth from "../../hooks/useAuth";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  login_container_styles,
  login_title_styles,
  video_container_styles,
} from "../../styles/app-styles";

/**
 * @component
 * @name Login
 * @description - Componente que muestra el formulario de login.
 * @returns {JSX.Element}
 * @Category Login
 */

const Login = () => {
  const userRef: any = useRef();
  const errorRef: any = useRef();
  const passwordRef: any = useRef();
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const { setAuth, persist, setPersist }: any = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<number | null>(null);
  const { setLoggedIn }: any = useInfoContext();
  const { t } = useLanguage();

  useEffect(() => {
    userRef.current.focus();
    const remember = localStorage.getItem("remember");
    if (remember !== null) {
      setRemember(JSON.parse(remember));
    }
  }, []);

  /**
   * @name handleSubmit
   * @description - Función que se ejecuta al presionar el botón de Sign In, y se encarga de hacer el login.  Unwrap es un método de RTK toolkit que desenvuelve la llamada de la mutation para proporcionar la respuesta.  El password se guarda únicamente para enviarlo en el formulario y luego se elimina, no quedando almacenado en ningún lugar del estado.
   * @param e El evento que se produce al presionar el botón de Sign In
   * @returns {void}
   * @memberof Login
   *
   */

  const handlePostLogin = (status: number, data: any) => {
    if (status === 200) {
      setLoggedIn(true);
      setError(null);
      if (data.access_token) {
        setAuth({
          user: data.email,
          roles: data.rol.rol_config,
          access_token: data.access_token,
          empresa: data.empresa_asignada,
        });
        setUser("");
        setPwd("");
        navigate("/empresa", { replace: true });
      } else {
        navigate("/verificacion", { replace: true });
      }
    } else {
      setError(status);
    }
    setIsLoading(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    postLogin(
      { email: user, password: pwd, recordar: remember },
      handlePostLogin
    );
  };

  const togglePersist = () => {
    setPersist((prev: boolean) => !prev);
  };

  const toggleRemember = (rem: boolean) => {
    setRemember((prev: boolean) => !prev);
    localStorage.setItem("remember", JSON.stringify(rem));
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  const handlePassword = () => {
    passwordRef.current.type = showPassword ? "password" : "text";
    setShowPassword((prev) => !prev);
  };

  return (
    <Box sx={login_container_styles}>
      <Box sx={video_container_styles}>
        <img
          className="login_logo"
          src={"https://i.imgur.com/WdjAwrF.png"}
          alt="logo"
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Box>
      <SimpleBackdrop open={isLoading} message={t("login.loginLoading")} />
      <FormWrapper className="login_form">
        <div>
          <Typography component="h1" sx={login_title_styles}>
            {t("login.login")}
          </Typography>
        </div>
        <div className="login_logo-container">
          <img className="login_logo" src={logoPhi} alt="logo" />
        </div>
        <form className="login_form_box" onSubmit={handleSubmit} method="POST">
          <div>
            <label htmlFor="username">{t("login.email")}</label>
            <input
              className="login_form_input"
              type="text"
              id="username"
              ref={userRef}
              value={user}
              onChange={(e) => setUser(e.target.value)}
              autoComplete="on"
              required
              placeholder="email@example.com"
            />
          </div>
          <div>
            <label htmlFor="password">{t("login.password")}</label>
            <div className="login_password_container">
              <input
                className="login_form_input"
                type="password"
                id="password"
                ref={passwordRef}
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                placeholder="Password"
                autoComplete="on"
              />
              {showPassword ? (
                <VisibilityIcon
                  className="login_password_icon"
                  onClick={handlePassword}
                />
              ) : (
                <VisibilityOffIcon
                  className="login_password_icon"
                  onClick={handlePassword}
                />
              )}
            </div>
          </div>
          {error !== null && error === 401 && (
            <p ref={errorRef} className="errmsg" aria-live="assertive">
              {t("login.loginError")}
            </p>
          )}
          {error !== null && error !== 401 && (
            <Typography
              className="errmsg"
              sx={{
                width: "70%",
                padding: "0",
                margin: "0",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {t("login.loginProblem")}
            </Typography>
          )}

          <div className="login_buttons_container">
            <Button
              title={t("login.getInto")}
              color="blue"
              disabled={isLoading}
              type="submit"
            />

            {/*
             <Tooltip
              disableFocusListener
              disableTouchListener
              title="Funcionalidad en desarrollo"
              sx={{ verticalAlign: "center", fontSize: "16px" }}
            >
              <div>
                <Button
                  title="Registrarse"
                  color="grey"
                  disabled={true}
                  type="button"
                />
              </div>
            </Tooltip>
            */}
          </div>
          <div
            className={
              error !== null ? "checksContainerError" : "checksContainer"
            }
          >
            <Stack alignItems="flex-start">
              <div className="rememberMeContainer">
                <input
                  id="rememberMe"
                  type="checkbox"
                  name="rememberMe"
                  onChange={(e: any) => toggleRemember(e.target.checked)}
                  checked={remember}
                />
                <div className="rememberMeLabel">
                  <label htmlFor="rememberMe">{t("login.rememberAuth")}</label>
                </div>
                <Info text={t("login.storeAuth")} color="blue" />
              </div>
              <div className="trustContainer">
                <input
                  type="checkbox"
                  id="persist"
                  onChange={togglePersist}
                  checked={/* persist */ true}
                  disabled={true}
                />
                <div className="trustLabel">
                  <label
                    htmlFor="persist"
                    style={{ color: "var(--text-disabled)" }}
                  >
                    {t("login.trust")}
                  </label>
                </div>
                <Info text={t("login.trustLogin")} color="blue" />
              </div>
            </Stack>
          </div>
        </form>
        {/*
         <div className="forgotPasswordContainer">
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Funcionalidad en desarrollo"
            sx={{ verticalAlign: "center", fontSize: "16px" }}
          >
            <p style={{ color: "var(--text-disabled)" }}>Olvidé mi contraseña</p>
             <Link to="/forgot-password">Olvidé mi contraseña</Link> 
          </Tooltip>
        </div>
        */}
      </FormWrapper>
    </Box>
  );
};

export default Login;
