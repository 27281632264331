import { useEffect, useState } from "react";
import KeyColumnMultiSelect from "./KeyColumnMultiSelect";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  key_column_label_styles,
  key_column_select_styles,
} from "../../../../styles/app-styles";

interface KeyColumnSelectForMultiProps {
  inputLabel: string;
  optional?: boolean;
  columnKey: string;
  defaultValue?:
    | {
        value: string | undefined;
        label: string | undefined;
      }[]
    | [];
  setKeyColumns: any;
  dataGetParams: any;
  fileData: any;
}

const KeyColumnSelectForMulti = ({
  inputLabel,
  optional,
  columnKey,
  setKeyColumns,
  dataGetParams,
  fileData
}: KeyColumnSelectForMultiProps) => {
  const [defaultValue, setDefaultValue] = useState<
    { value: string | undefined; label: string | undefined }[] | [] | undefined
  >(undefined);

  useEffect(() => {
    if (dataGetParams) {
      if (
        dataGetParams.columnas_clave &&
        dataGetParams.columnas_clave[columnKey] &&
        dataGetParams.columnas_clave[columnKey].length > 0
      ) {
        setDefaultValue(
          dataGetParams.columnas_clave[columnKey].map((column: string) => ({
            value: column,
            label: column,
          }))
        );
      } else {
        setDefaultValue([]);
      }
    }
  }, [dataGetParams]);

  return (
    <Stack sx={key_column_select_styles}>
      <Typography
        sx={{
          ...key_column_label_styles,
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {inputLabel}
      </Typography>
      <Stack sx={{ width: "100%" }}>
        {defaultValue && (          
          <KeyColumnMultiSelect
            defaultValue={defaultValue}
            columnKey={columnKey}
            optional={optional}
            setKeyColumns={setKeyColumns}
            dataGetParams={dataGetParams}
            fileData={fileData}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default KeyColumnSelectForMulti;
