import { getBackgroundColor } from "../../utils";
import { grid_item_element_styles } from "../../../../../styles/app-styles";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../../context/LanguageProvider";
import DestinyCriteriaRowElement from "./DestinyCriteriaRowElement";

const IterationImplicitPercentageRowsElements = ({ iteration, type }) => {
  const { t } = useLanguage();
  const percentageRows =
    type === "sin_match"
      ? iteration?.sin_match?.porcentaje
      : iteration?.criterio_cero?.porcentaje;
     

  return (
    <Stack width="100%" gap={1}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        width="100%"
        gap={1}
      >
        <Stack
          sx={{
            ...grid_item_element_styles(
              getBackgroundColor,
              "header",
              () => "100%"
            ),
            color: "white",
            minHeight: "30px",
            borderRadius: "4px",
            width: "100%",
          }}
        >
          Destino
        </Stack>
        <Stack
          sx={{
            ...grid_item_element_styles(
              getBackgroundColor,
              "header",
              () => "100%"
            ),
            color: "white",
            minHeight: "30px",
            borderRadius: "4px",
            width: "100%",
          }}
        >
          Porcentaje
        </Stack>
      </Stack>
      {percentageRows?.map((percentRow, i) => (
        <DestinyCriteriaRowElement
          key={i}
          schemaRow={percentRow}
        />
      ))}
    </Stack>
  );
};

export default IterationImplicitPercentageRowsElements;
