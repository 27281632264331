import React from "react";
import useLanguage from "../../context/LanguageProvider";

interface CustomInputProps {
  value: string | number;
  setValue: (value: string | number) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  type?: React.HTMLInputTypeAttribute;
  autoFocus?: boolean;
  name?: string;
  defaultValue?: string | number;
  minValue?: number;
  maxValue?: number;
  disabled?:boolean;
  error?: boolean;
  id?: string;
}

const CustomInput = ({
  value,
  setValue,
  inputRef,
  placeholder,
  required,
  maxLength,
  disabled,
  type,
  autoFocus,
  name,
  minValue,
  maxValue,
  error,
  id
}: CustomInputProps) => {
  const { t } = useLanguage();
  return (
    <input
      className={
        required? 
        (value === "" || error ? "cc_step_form_input_empty" : disabled? "cc_step_form_input_disabled" :  "cc_step_form_input")
        : disabled ? "cc_step_form_input_disabled" : "cc_step_form_input"
      }
      type={type || "text"}
      name={name}      
      ref={inputRef}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      autoComplete="off"
      disabled={disabled}
      required={required}
      placeholder={placeholder || t("forms.text")}
      autoFocus={autoFocus || false}
      maxLength={maxLength !== undefined ? maxLength : 40}
      min={minValue}
      max={maxValue}
      id={id}
    />
  );
};

export default CustomInput;
