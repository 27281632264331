import { getFileColumns, getValueLabel } from "../../utils";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useRef } from "react";
import AddButton from "../../../buttons/AddButton";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import useApi from "../../../../hooks/useApi";
import { COMPANY_PARAMETERS_FILE_URL } from "../../../../api/axios";
interface TargetInputProps {
  setNewTarget: any;
  newName: string;
  setNewName: any;
  targetNameRef: any;
  newTarget: any;
  columnaNameRef: any;
  columnName: any;
  setColumnName: any;
}

const TargetInput = ({
  setNewTarget,
  newName,
  setNewName,
  targetNameRef,
  newTarget,
  columnaNameRef,
  columnName,
  setColumnName,
}: TargetInputProps) => {
  const { t } = useLanguage();

  const fileSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const { message, setMessage, expensesTargets, allowedFileNames } =
    useCompanyParamProvider();
  console.log(allowedFileNames, "dataGetColumnsTypesParams");

  const {
    isLoading: isLoadingFilesParams,
    callApi: getFilesParams,
    data: dataGetFilesParams,
  } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined /* onSuccessGetFilesParams */,
    undefined,
    false
  );

  const onFileColumn = (file) => {
    if (file && file.value) {
      getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${file?.value}`);
    }
    setColumnSelectedOption(undefined);
    columnSelectRef.current.clearValue();
  };

  const [fileSelectedOption, setFileSelectedOption, fileOptionChangeHandler] =
    useSingleSelect((file) => onFileColumn(file), undefined);

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(undefined, undefined);

  useEffect(() => {
    setNewTarget({
      nombre: newName,
      involucrados: [],
    });
  }, []);

  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
    setNewTarget((prev: any) => {
      return { ...prev, nombre: e.target.value };
    });
    const namesList = expensesTargets?.map((item: any) =>
      item.nombre.trim().toLowerCase()
    );
    if (namesList?.includes(e.target.value.trim().toLowerCase())) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  };

  const handleChangeColumnName = (e: any) => {
    setColumnName(e.target.value);
    setNewTarget((prev: any) => {
      return { ...prev, columna: e.target.value };
    });
    const namesList = expensesTargets?.map((item: any) =>
      item.columna.trim().toLowerCase()
    );
    if (namesList?.includes(e.target.value.trim().toLowerCase())) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  };

  const handleAddRow = () => {
    if (!fileSelectedOption || !columnSelectedOption) return;
    const fileColumnList = newTarget.involucrados?.map(
      (item: any) => item.archivo.label + item.columna.label
    );
    const newFileColumn =
      fileSelectedOption?.label + columnSelectedOption?.label;
    if (fileColumnList?.includes(newFileColumn)) {
      setMessage({
        type: "warning",
        text: t("companyParam.existentCombination"),
      });
      return;
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
    setNewTarget((prev) => {
      return {
        ...prev,
        involucrados: [
          ...prev.involucrados,
          {
            archivo: fileSelectedOption,
            columna: columnSelectedOption,
          },
        ],
      };
    });
    setFileSelectedOption(undefined);
    setColumnSelectedOption(undefined);
    fileSelectRef.current.clearValue();
    columnSelectRef.current.clearValue();
  };

  return (
    <Grid2 container direction={"row"} xs={12}  gap={2} alignItems={"flex-end"}>
      <Grid2 xs={5.3}>
        <TextField
          type="text"
          ref={targetNameRef}
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={t("companyParam.expenseTargetName")}
          autoFocus
          size="small"
          error={
            message.type === "error" && (message?.text !== "" || newName === "")
          }
          helperText={message.type === "error" && message?.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
      </Grid2>
      <Grid2 xs={5.7}>
        <TextField
          type="text"
          ref={columnaNameRef}
          value={columnName}
          onChange={handleChangeColumnName}
          variant="outlined"
          fullWidth
          required
          label={t("Nombre de la columna")}
          autoFocus
          size="small"
          error={
            message.type === "error" && (message?.text !== "" || columnName === "")
          }
          helperText={message.type === "error" && message?.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
      </Grid2>
      <Grid2 xs={0.3}></Grid2>
      <Grid2 xs={5.3}>
        <Stack sx={{ width: "100%" }}>
          <FormSelectLayout title={t("companyParam.file")} required={false} margin="0px">
            <Select
              reference={fileSelectRef}
              isClearable
              styles={selectStyles(fileSelectedOption)}
              options={
                allowedFileNames?.map((item: any) => {
                  return {
                    value: item.id,
                    label: item.nombre,
                  };
                })
                /* getValueLabel(allowedFileNames) */
              }
              className="pp_select_schema"
              onChange={fileOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("companyParam.chooseAnOption")}
            />
          </FormSelectLayout>
        </Stack>
      </Grid2>
      <Grid2 xs={5}>
        {
          <Stack sx={{ width: "100%" }}>
            <FormSelectLayout title={t("companyParam.column")} required={false} margin="0px">
              <Select
                reference={columnSelectRef}
                isClearable
                styles={selectStyles(columnSelectedOption)}
                options={
                  dataGetFilesParams?.columnas?.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.nombre,
                    };
                  }) || []
                }
                className="pp_select_schema"
                onChange={columnOptionChangeHandler}
                closeMenuOnSelect
                isSearchable
                placeholder={t("companyParam.chooseAnOption")}
              />
            </FormSelectLayout>
          </Stack>
        }
      </Grid2>
      <Grid2 xs={0.5} justifyContent={"flex-end"}>
        <AddButton
          onClick={() => handleAddRow()}
          disabled={
            columnSelectedOption === undefined ||
            fileSelectedOption === undefined
          }
        />
      </Grid2>
    </Grid2>
  );
};

export default TargetInput;
